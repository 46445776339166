/*!
 * jquery-confirm v2.5.1 (http://craftpip.github.io/jquery-confirm/)
 * Author: boniface pereira
 * Website: www.craftpip.com
 * Contact: hey@craftpip.com
 *
 * Copyright 2013-2016 jquery-confirm
 * Licensed under MIT (https://github.com/craftpip/jquery-confirm/blob/master/LICENSE)
 */

@keyframes jconfirm-rotate {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.jconfirm {
    z-index: 99999999;
    font-family: inherit;
    overflow: hidden
}

.jconfirm,
.jconfirm .jconfirm-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.jconfirm .jconfirm-bg {
    opacity: 0;
    transition: all .4s
}

.jconfirm .jconfirm-bg.seen {
    opacity: 1
}

.jconfirm .jconfirm-scrollpane {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    perspective: 500px;
    perspective-origin: 50%
}

.jconfirm .jconfirm-box {
    background: #fff;
    position: relative;
    outline: none;
    padding: 15px 15px 0
}

.jconfirm .jconfirm-box div.closeIcon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    opacity: .6;
    text-align: center;
    transition: opacity .1s ease-in;
    display: none;
    font-size: 27px;
    line-height: 14px
}

.jconfirm .jconfirm-box div.closeIcon .fa,
.jconfirm .jconfirm-box div.closeIcon .glyphicon,
.jconfirm .jconfirm-box div.closeIcon .zmdi {
    font-size: 16px
}

.jconfirm .jconfirm-box div.closeIcon:hover {
    opacity: 1
}

.jconfirm .jconfirm-box div.title-c {
    display: block;
    font-size: 22px;
    line-height: 20px
}

.jconfirm .jconfirm-box div.title-c .icon-c {
    font-size: inherit;
    padding-bottom: 15px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle
}

.jconfirm .jconfirm-box div.title-c .icon-c i {
    vertical-align: middle
}

.jconfirm .jconfirm-box div.title-c .icon-c:empty {
    display: none
}

.jconfirm .jconfirm-box div.title-c .title {
    font-size: inherit;
    font-family: inherit;
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 15px
}

.jconfirm .jconfirm-box div.title-c .title:empty {
    display: none
}

.jconfirm .jconfirm-box div.content-pane {
    margin-bottom: 15px;
    height: auto;
    transition: height .4s ease-in;
    display: inline-block;
    width: 100%;
    position: relative
}

.jconfirm .jconfirm-box div.content-pane .content {
    position: absolute;
    top: 0;
    left: 0;
    transition: all .2s ease-in;
    right: 0
}

.jconfirm .jconfirm-box div.content-pane .content img {
    width: 100%;
    height: auto
}

.jconfirm .jconfirm-box div.content-pane .content:empty {
    display: none
}

.jconfirm .jconfirm-box div.content-pane .content:empty.loading {
    height: 40px;
    position: relative;
    opacity: .6;
    display: block
}

.jconfirm .jconfirm-box div.content-pane .content:empty.loading:before {
    content: '';
    height: 20px;
    width: 20px;
    border: 2px solid transparent;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    border-radius: 50%;
    animation: jconfirm-rotate 1s infinite linear;
    border-bottom-color: #aaa;
    top: 50%;
    margin-top: -10px
}

.jconfirm .jconfirm-box div.content-pane .content:empty.loading:after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -15px
}

.jconfirm .jconfirm-box .buttons {
    padding-bottom: 15px
}

.jconfirm .jconfirm-box .buttons button+button {
    margin-left: 5px
}

.jconfirm .jquery-clear {
    clear: both
}

.jconfirm.rtl {
    direction: rtl
}

.jconfirm.rtl div.closeIcon {
    left: 12px;
    right: auto
}

.jconfirm.jconfirm-white .jconfirm-bg {
    background-color: rgba(0, 0, 0, .2)
}

.jconfirm.jconfirm-white .jconfirm-box {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
}

.jconfirm.jconfirm-white .jconfirm-box .buttons {
    float: right
}

.jconfirm.jconfirm-white .jconfirm-box .buttons button {
    border: none;
    background-image: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-shadow: none;
    transition: background .1s;
    color: #fff
}

.jconfirm.jconfirm-white .jconfirm-box .buttons button.btn-default {
    box-shadow: none;
    color: #333
}

.jconfirm.jconfirm-white .jconfirm-box .buttons button.btn-default:hover {
    background: #ddd
}

.jconfirm.jconfirm-black .jconfirm-bg {
    background-color: rgba(0, 0, 0, .5)
}

.jconfirm.jconfirm-black .jconfirm-box {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
    background: #444;
    color: #fff
}

.jconfirm.jconfirm-black .jconfirm-box .buttons {
    float: right
}

.jconfirm.jconfirm-black .jconfirm-box .buttons button {
    border: none;
    background-image: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-shadow: none;
    transition: background .1s;
    color: #fff
}

.jconfirm.jconfirm-black .jconfirm-box .buttons button.btn-default {
    box-shadow: none;
    color: #fff;
    background: none
}

.jconfirm.jconfirm-black .jconfirm-box .buttons button.btn-default:hover {
    background: #666
}

.jconfirm .jconfirm-box.hilight {
    animation: hilight .82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translateZ(0)
}

@keyframes hilight {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0)
    }
    20%,
    80% {
        transform: translate3d(4px, 0, 0)
    }
    30%,
    50%,
    70% {
        transform: translate3d(-8px, 0, 0)
    }
    40%,
    60% {
        transform: translate3d(8px, 0, 0)
    }
}

.jconfirm {
    perspective: 400px
}

.jconfirm .jconfirm-box {
    opacity: 1;
    transition-property: transform, opacity, box-shadow
}

.jconfirm .jconfirm-box.anim-bottom,
.jconfirm .jconfirm-box.anim-left,
.jconfirm .jconfirm-box.anim-none,
.jconfirm .jconfirm-box.anim-opacity,
.jconfirm .jconfirm-box.anim-right,
.jconfirm .jconfirm-box.anim-rotate,
.jconfirm .jconfirm-box.anim-rotatex,
.jconfirm .jconfirm-box.anim-rotatey,
.jconfirm .jconfirm-box.anim-scale,
.jconfirm .jconfirm-box.anim-scalex,
.jconfirm .jconfirm-box.anim-scaley,
.jconfirm .jconfirm-box.anim-top,
.jconfirm .jconfirm-box.anim-zoom {
    opacity: 0
}

.jconfirm .jconfirm-box.anim-rotate {
    transform: rotate(90deg)
}

.jconfirm .jconfirm-box.anim-rotatex {
    transform: rotateX(90deg);
    transform-origin: center
}

.jconfirm .jconfirm-box.anim-rotatexr {
    transform: rotateX(-90deg);
    transform-origin: center
}

.jconfirm .jconfirm-box.anim-rotatey {
    transform: rotatey(90deg);
    transform-origin: center
}

.jconfirm .jconfirm-box.anim-rotateyr {
    transform: rotatey(-90deg);
    transform-origin: center
}

.jconfirm .jconfirm-box.anim-scaley {
    transform: scaley(1.5);
    transform-origin: center
}

.jconfirm .jconfirm-box.anim-scalex {
    transform: scalex(1.5);
    transform-origin: center
}

.jconfirm .jconfirm-box.anim-top {
    transform: translateY(-100px)
}

.jconfirm .jconfirm-box.anim-left {
    transform: translate(-100px)
}

.jconfirm .jconfirm-box.anim-right {
    transform: translate(100px)
}

.jconfirm .jconfirm-box.anim-bottom {
    transform: translateY(100px)
}

.jconfirm .jconfirm-box.anim-zoom {
    transform: scale(1.2)
}

.jconfirm .jconfirm-box.anim-scale {
    transform: scale(.5)
}

.jconfirm .jconfirm-box.anim-none {
    display: none
}

.jconfirm.jconfirm-supervan .jconfirm-bg {
    background-color: rgba(54, 70, 93, .95)
}

.jconfirm.jconfirm-supervan .jconfirm-box {
    background-color: transparent
}

.jconfirm.jconfirm-supervan .jconfirm-box div.closeIcon {
    color: #fff
}

.jconfirm.jconfirm-supervan .jconfirm-box div.title-c {
    text-align: center;
    color: #fff;
    font-size: 28px;
    font-weight: 400
}

.jconfirm.jconfirm-supervan .jconfirm-box div.title-c>* {
    padding-bottom: 25px
}

.jconfirm.jconfirm-supervan .jconfirm-box div.content-pane {
    margin-bottom: 25px
}

.jconfirm.jconfirm-supervan .jconfirm-box div.content {
    text-align: center;
    color: #fff
}

.jconfirm.jconfirm-supervan .jconfirm-box .buttons {
    text-align: center
}

.jconfirm.jconfirm-supervan .jconfirm-box .buttons button {
    font-size: 16px;
    background: #303f53;
    text-shadow: none;
    border: none;
    color: #fff;
    padding: 10px;
    min-width: 100px
}

.jconfirm.jconfirm-material .jconfirm-bg {
    background-color: rgba(0, 0, 0, .67)
}

.jconfirm.jconfirm-material .jconfirm-box {
    background-color: #fff;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12);
    padding: 30px 25px 10px
}

.jconfirm.jconfirm-material .jconfirm-box div.closeIcon {
    color: rgba(0, 0, 0, .87)
}

.jconfirm.jconfirm-material .jconfirm-box div.title-c {
    color: rgba(0, 0, 0, .87);
    font-size: 22px;
    font-weight: 700
}

.jconfirm.jconfirm-material .jconfirm-box div.content {
    text-align: left;
    color: rgba(0, 0, 0, .87)
}

.jconfirm.jconfirm-material .jconfirm-box .buttons {
    text-align: right
}

.jconfirm.jconfirm-material .jconfirm-box .buttons button {
    text-transform: uppercase;
    font-weight: 500
}

.jconfirm.jconfirm-bootstrap .jconfirm-bg {
    background-color: rgba(0, 0, 0, .21)
}

.jconfirm.jconfirm-bootstrap .jconfirm-box {
    background-color: #fff;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .2);
    border: 1px solid rgba(0, 0, 0, .4);
    padding: 15px 0 0
}

.jconfirm.jconfirm-bootstrap .jconfirm-box div.closeIcon {
    color: rgba(0, 0, 0, .87)
}

.jconfirm.jconfirm-bootstrap .jconfirm-box div.title-c {
    color: rgba(0, 0, 0, .87);
    font-size: 22px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px
}

.jconfirm.jconfirm-bootstrap .jconfirm-box div.content {
    text-align: left;
    color: rgba(0, 0, 0, .87);
    padding: 0 15px
}

.jconfirm.jconfirm-bootstrap .jconfirm-box .buttons {
    text-align: right;
    padding: 0;
    margin: -5px 0 0;
    border-top: 1px solid #ddd;
    overflow: hidden;
}

.jconfirm.jconfirm-bootstrap .jconfirm-box .buttons button {
    font-weight: 500;
    margin: 0;
    border-left: 1px solid #ddd
}