.topbar ul li, .topbar ul li a {
	color: #fff;
	font-size: 14px;
	font-weight: 400
}
.topbar-left, .topbar-right {
	transition: all .5s ease-in-out
}
.header, .search-open {
	background: 0 0;
	width: 100%
}
.bg-color-org{

	background:#de6926 ;
	color:white;
	margin-bottom: 6px;
}
.bg-color-grn{
	background: #18a103;
	color:white;
	margin-bottom: 6px;
}
#animated-slider, #animated-slider-2, .why-choose-us {
	-webkit-backface-visibility: hidden
}
.header {
	z-index: 999;
	padding: 15px 0 20px;
	position: absolute
}
.topbar ul li {
	display: inline-block;
	margin-right: 10px
}
.topbar ul li:last-child {
	margin-right: 0
}
.topbar ul li a {
	font-style: normal
}
.topbar-left {
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out
}
.topbar-left ul li i {
	margin-right: 5px;
	color: #c15601
}
.topbar-left ul li a {
	margin-right: 30px
}
.topbar-right {
	position: relative;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out
}
.topbar-right ul li a {
	margin-left: 5px;
	color: #fff
}
.topbar-right ul li a i {
	margin-right: 5px
}
.login-1 a.button, .topbar-right ul li a i.fa.fa-search {
	margin-right: 0
}
.topbar ul li a:hover {
	color: #c15601
}
.search-open {
	display: none;
	height: 120px;
	position: absolute;
	z-index: 99999;
	padding: 2px 7px 10px 15px;
	left: 0
}
.search-open .search-close {
	position: absolute;
	z-index: 9999;
	cursor: pointer;
	top: 0;
	right: 15px;
	float: left
}
.search-main .search-open {
	width: 100%;
	color: #fff
}
.search-main {
	position: relative;
	transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out
}
.search-open input {
	border: none;
	border-bottom: 1px solid #fff;
	float: left;
	height: 100px;
	font-size: 35px;
	text-transform: uppercase;
	width: 99.3%;
	text-align: center;
	padding: 0
}
.search-open .form-control::-moz-placeholder {
color:#fff
}
.search-open .form-control:focus {
	border-color: #c15601
}
body.top-search-open #header .logo, body.top-search-open #header .mega-menu, body.top-search-open #header .topbar-left, body.top-search-open #header .topbar-right {
	opacity: 0
}
.mega-menu .drop-down-multilevel.right-menu {
	right: 0
}
.mega-menu .menu-links>li:last-child>a {
	padding-right: 0
}
.mega-menu .menu-links>li.active .drop-down-multilevel a {
	color: #555
}
.mega-menu .menu-links>li.active .drop-down-multilevel a:hover {
	color: #c15601
}
.mega-menu .menu-links>li.active .drop-down a {
	color: #323232
}
.mega-menu .menu-links>li.active .drop-down a i:hover, .mega-menu .menu-links>li.active .drop-down a:hover, .mega-menu .menu-links>li.active .drop-down li.active a, .mega-menu .menu-links>li.active .drop-down li.active a i {
	color: #c15601
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active a {
	color: #c15601;
	background: #eceff7
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li a {
	color: #323232;
	background: 0 0
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li a:hover {
	color: #c15601
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li.active a {
	color: #c15601;
	background: #eceff7
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li.active .drop-down-multilevel li a {
	color: #323232;
	background: 0 0
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li a .drop-down-multilevel li a:hover, .mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li.active i, .mega-menu .menu-links>li.active .drop-down-multilevel li.active i {
	color: #c15601
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li.active .drop-down-multilevel li.active a {
	color: #c15601;
	background: #eceff7
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li a i {
	color: #323232
}
.mega-menu .menu-links>li.active .drop-down-multilevel li.active .drop-down-multilevel li.active .drop-down-multilevel li a:hover, .mega-menu .menu-links>li.active .drop-down-multilevel li.active:hover .drop-down-multilevel li a:hover i {
	color: #c15601
}
.mega-menu .row {
	margin-left: 0;
	margin-right: 0
}
.mega-menu .col-md-12 {
	padding-left: 0;
	padding-right: 0
}
.login-1 {
	display: none;
	background: #fff;
	padding: 20px;
	position: absolute;
	z-index: 9999;
	margin-top: 13px;
	right: 127px;
	width: 300px
}
.login-1 .button span {
	padding: 7px 0;
	color: #fff;
	width: 102px;
	text-align: center
}
.login-1 .button.button-grey span {
	padding: 7px 18px;
	color: #c15601
}
.login-1 input {
	background: #eceff8;
	border: 2px solid #eceff8;
	height: 45px;
	color: #363636
}
.login-1 span, .topbar-right .login-1 a, .topbar-right .login-with a.fb:hover, .topbar-right .login-with a.google-plus:hover, .topbar-right .login-with a.twitter:hover, .topbar-right .login-with a:hover {
	color: #fff
}
.login-1 input:focus {
	background: 0 0;
	border: 2px solid #c15601 !important;
}
.login-1 span {
	display: inline-block;
	font-size: 15px;
	padding: 20px 10px 0;
	vertical-align: top
}
.login-1 strong {
	font-size: 15px;
	padding: 0 10px;
	font-weight: 400
}
.login-with {
	border-top: 1px solid #eceff8;
	margin-top: 20px;
	padding-top: 18px;
	text-align: center
}
.topbar-right .login-1 a {
	font-size: 15px;
	margin-left: 0;
	display: inline-block;
	margin-top: 12px
}
.topbar-right .login-1 a:hover {
	text-decoration: underline
}
.topbar-right .login-with span {
	float: none;
	color: #fff;
	padding-top: 5px
}
.topbar-right .login-with a {
	background: 0 0;
	border-radius: 50%;
	display: inline-block;
	height: 30px;
	line-height: 29px;
	margin-top: 0;
	padding-left: 6px;
	text-align: center;
	width: 30px
}
.topbar-right .login-with a.fb, .topbar-right .login-with a.google-plus, .topbar-right .login-with a.twitter {
	border: 1px solid #fff
}
.header-2 .logo {
	margin-top: 10px
}
.header-2 {
	padding: 0;
	box-shadow: 0 1px 6px rgba(0,0,0,.03);
	position: relative
}
.header-2 .mega-menu {
	min-height: 104px;
	border-bottom: 1px solid #eee
}
.header-2 .mega-menu .menu-links>li>a {
	color: #363636;
	line-height: 105px
}
.header-2 .mega-menu .menu-links>li.active a {
	color: #c15601
}
.header-2 .mega-menu .menu-links>li.active .drop-down a {
	color: #323232
}
.header-2 .mega-menu .menu-links>li.active .drop-down a:hover {
	color: #c15601
}
.header-2 .mega-menu>section.menu-list-items {
	padding: 0;
	background: #fff
}
.header-2 .mega-menu img {
	margin: 23px 0
}
.header-2 .mega-menu .drop-down-multilevel.right-menu {
	right: 28px
}
.header-2 .mega-menu .menu-links {
	padding-right: 30px
}
.header-2 .mega-menu .menu-links>li.active .drop-down-multilevel a {
	color: #555
}
.header-2 .mega-menu .menu-links>li.active .drop-down-multilevel a:hover {
	color: #c15601
}
.header-2 .mega-menu.desktopTopFixed .menu-list-items .menu-logo img {
	margin: 20px 0
}
.header-2 .mega-menu.desktopTopFixed .menu-list-items .menu-links>li>a {
	line-height: 81px
}
.header-2 .mega-menu.desktopTopFixed .menu-list-items {
	background: #fff;
	box-shadow: 0 1px 6px rgba(0,0,0,.03)
}
.header-2 .mega-menu.desktopTopFixed .menu-list-items .search-2 a {
	padding: 29px 0 26px 10px
}
.header-2 .mega-menu.desktopTopFixed .menu-list-items .search-2-open {
	height: 80px
}
.header-2 .mega-menu.desktopTopFixed .menu-list-items .search-2-open input {
	height: 56px
}
.header-2 .mega-menu.desktopTopFixed .menu-list-items .search-2-open .search-2-close {
	top: 15px
}
.header-2 .mega-menu .menu-mobile-collapse-trigger span, .header-2 .mega-menu .menu-mobile-collapse-trigger:after, .header-2 .mega-menu .menu-mobile-collapse-trigger:before {
	background: #323232
}
.search-2 {
	position: absolute;
	right: 0;
	z-index: 999
}
.search-2 a {
	display: block;
	font-size: 15px;
	padding: 41px 0 40px 10px;
	cursor: pointer
}
.search-2 i {
	color: #626262
}
.search-2-open {
	display: none;
	background: #fff;
	height: 103px;
	width: 100%;
	border: none;
	position: absolute;
	z-index: 99999;
	padding: 10px 0;
	left: 0;
	top: 0
}
.search-2-open input {
	border: none;
	border-bottom: 0 solid #fff;
	float: left;
	height: 86px;
	font-size: 35px;
	text-transform: uppercase;
	width: 97%;
	padding-left: 0;
	text-align: center;
	color: #363636
}
.search-2-open input:focus {
	border-bottom: 0
}
.search-2-open .search-2-close {
	cursor: pointer;
	float: left;
	position: relative;
	right: -20px;
	top: 29px;
	z-index: 9999
}
.search-2-open .search-2-close i {
	color: #626262
}
.header-3 {
	padding: 0;
	position: inherit
}
.search-3, .search-3 .search-btn {
	position: relative;
	display: block
}
.header-3 .topbar {
	background: #363636;
	height: 38px
}
.header-3 .topbar ul li {
	border-right: 1px solid #565656;
	border-left: 1px solid #565656;
	margin-right: 0
}
.header-3 .topbar-right ul li {
	padding: 0 0 0 50px;
	margin-left: 50px
}
.header-3 .mega-menu img, .header-3 .mega-menu.desktopTopFixed .menu-list-items .menu-logo img {
	margin: 0
}
.header-3 .topbar ul li:first-child {
	border-right: 0 solid
}
.header-3 .mega-menu {
	min-height: 65px
}
.header-3 .mega-menu .menu-links>li>a {
	color: #fff;
	padding: 0 12px;
	text-transform: uppercase;
	line-height: 65px
}
.header-3 .mega-menu .menu-links>li.active a {
	color: #c15601
}
.header-3 .mega-menu .menu-links>li.active .drop-down a {
	color: #323232
}
.header-3 .mega-menu .menu-links>li.active .drop-down a:hover {
	color: #c15601
}
.header-3 .mega-menu .menu-links>li.active .drop-down-multilevel a, .header-5 .mega-menu .menu-links>li.active .drop-down-multilevel a {
	color: #555
}
.header-3 .mega-menu .drop-down-multilevel.right-menu {
	right: 24px
}
.header-3 .mega-menu section.menu-list-items {
	padding: 0;
	background: #df6708
}
.header-3 .mega-menu .menu-links>li.active .drop-down-multilevel a:hover {
	color: #c15601
}
.header-3 .mega-menu.desktopTopFixed .menu-list-items {
	background: #df6708
}
.header-3 .mega-menu.desktopTopFixed .menu-list-items .menu-links>li>a {
	line-height: 65px
}
.header-3 .mega-menu.desktopTopFixed .menu-list-items .search-3 {
	padding: 30px 0
}
.header-3 .mega-menu.desktopTopFixed .menu-list-items .search-3.search-3-open .search-3-box {
	top: 80px!important;
	border: 1px solid #eceff7;
	border-top: 0
}
.search-3 {
	float: right;
	line-height: 20px;
	padding: 42px 0;
	vertical-align: top;
	z-index: 999
}
.search-3 .search-btn {
	height: 20px;
	line-height: 21px;
	text-indent: -9999px;
	width: 14px;
	color: #626262;
	-o-transition: color .3s ease 0s;
	-moz-transition: color .3s ease 0s;
	-ms-transition: color .3s ease 0s;
	-webkit-transition: color .3s ease 0s;
	transition: color .3s ease 0s
}
#menu-icon, #menu-icon span, #menu-toggle, .header-4, .search-3-box, .search-3.search-3-open i, .submenu-toggle {
	position: absolute
}
.search-3 .search-btn:before {
	content: "";
	font-family: FontAwesome;
	font-size: 14px;
	font-weight: 400;
	left: 0;
	position: absolute;
	text-indent: 0;
	top: 0
}
.search-3.search-3-open .search-btn:before {
	content: ""
}
.search-3-box {
	background: #fff;
	display: block;
	margin: 0;
	opacity: 0!important;
	padding: 15px;
	right: 0;
	top: -9999px!important;
	transition: visibility .3s ease 0s, opacity .3s ease 0s;
	visibility: hidden;
	width: 327px;
	z-index: 888;
	border-radius: 0 0 3px 3px
}
.search-3.search-3-open .search-3-box {
	opacity: 1!important;
	top: 104px!important;
	visibility: visible!important
}
.search-3 .search-3-box input {
	background: 0 0;
	border: 1px solid #eceff8;
	padding-left: 15px;
	box-shadow: none
}
.search-3 .search-3-box input:focus {
	background: 0 0;
	border: 1px solid #c15601
}
.search-3.search-3-open i {
	cursor: pointer;
	font-size: 15px;
	right: 27px;
	top: 31px
}
.search-3.search-3-open i:hover {
	color: #c15601
}
.header-3 .login-1 {
	margin-top: 0;
	right: 0
}
.header-4 .logo {
	margin: 23px 0
}
.header-4 {
	padding: 0 30px;
	background: 0 0;
	z-index: 999;
	width: 100%
}
.header-4 .search-3 {
	padding: 22px 0 20px 10px
}
.header-4 .search-3 .search-btn {
	color: #fff;
	line-height: 23px;
	width: 15px
}
.header-4 .search-3.search-3-open .search-3-box {
	top: 67px!important;
	border-radius: 0 0 3px 3px;
	margin-left: 5px
}
.header-4 .mega-menu .drop-down-multilevel.right-menu {
	right: 38px
}
#menu-toggle, #menu-toggle.open {
	right: 0;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out
}
.header-4 .mega-menu>section.menu-list-items {
	padding: 25px 0
}
.header-4 .mega-menu.desktopTopFixed .menu-list-items {
	background: #323232
}
.header-4 .mega-menu.desktopTopFixed>section.menu-list-items {
	padding: 0 20px
}
.header-4 .mega-menu.desktopTopFixed .menu-links>li>a {
	line-height: 74px
}
.header-4 .mega-menu.desktopTopFixed .search-3 .search-btn {
	line-height: 32px
}
.header-4 .mega-menu.desktopTopFixed .search-3.search-3-open .search-3-box {
	top: 74px!important;
	border: 1px solid #eceff8;
	border-top: 0
}
.mega-menu .menu-links>li:last-child {
	padding-right: 0
}
#menu-toggle {
	width: 100%;
	top: 0;
	color: #444;
	z-index: 99999;
	transition: .5s ease-in-out
}
#menu-toggle.open {
	transition: .5s ease-in-out
}
#menu-icon {
	width: 25px;
	height: 25px;
	right: 46px;
	top: 47px;
	-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer
}
#menu-icon span, .header-5 #menu-icon {
	-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0)
}
#menu-icon span {
	display: block;
	height: 2px;
	width: 50%;
	background: #fff;
	opacity: 1;
	transform: rotate(0);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-ms-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out
}
#menu, .submenu-toggle:after, .submenu-toggle:before {
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out
}
#menu-icon span:nth-child(even) {
	left: 50%
}
#menu-icon span:nth-child(odd) {
	left: 0
}
#menu-icon span:nth-child(1), #menu-icon span:nth-child(2) {
	top: 5px
}
#menu-icon span:nth-child(3), #menu-icon span:nth-child(4) {
	top: 12px
}
#menu-icon span:nth-child(5), #menu-icon span:nth-child(6) {
	top: 19px
}
#menu-icon.open span:nth-child(1), #menu-icon.open span:nth-child(6) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg)
}
#menu-icon.open span:nth-child(2), #menu-icon.open span:nth-child(5) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg)
}
#menu-icon.open span:nth-child(1) {
	left: 0;
	top: 10px
}
#menu-icon.open span:nth-child(2) {
	left: calc(50% - 5px);
	top: 10px
}
#menu-icon.open span:nth-child(3) {
	left: -50%;
	opacity: 0
}
#menu-icon.open span:nth-child(4) {
	left: 100%;
	opacity: 0
}
#menu-icon.open span:nth-child(5) {
	left: 0;
	top: 19px
}
#menu-icon.open span:nth-child(6) {
	left: calc(50% - 5px);
	top: 19px
}
.submenu-toggle {
	right: 0;
	top: 0;
	cursor: pointer;
	width: 45px;
	height: 45px;
	text-indent: -5000px;
	overflow: hidden
}
.submenu-toggle.open {
	background: rgba(255,255,255,.1);
	height: 100%;
	z-index: 5000;
	top: 0
}
#menu, .header-5 #menu {
	z-index: 9999;
	bottom: 0
}
.submenu-toggle:after, .submenu-toggle:before {
	content: '';
	display: block;
	position: absolute;
	height: 16px;
	width: 16px;
	top: 12px;
	border-top: 5px solid #929292;
	border-right: 5px solid #929292
}
.submenu-toggle:before {
	right: 15px;
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg)
}
.submenu-toggle:after {
	right: 50px;
	-webkit-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg)
}
.submenu-toggle:hover:after, .submenu-toggle:hover:before {
	border-color: #fff
}
.submenu-toggle.open:after, .submenu-toggle.open:before {
	right: 23px
}
#menu {
	list-style-type: none;
	padding: 46px 30px;
	margin: 0;
	transition: .5s ease-in-out;
	background: url(../images/bg/10.jpg) no-repeat;
	width: 350px;
	position: fixed;
	top: 0;
	right: -370px
}
.header-5 #menu-toggle, .header-5 #menu-toggle.open {
	left: 0;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out
}
#menu li {
	position: relative;
	display: inline-block
}
#menu h3 {
	margin-bottom: 20px
}
#menu.open {
	right: 0
}
#menu .menu-side-top a {
	display: block;
	color: #fff;
	font-size: 14px;
	line-height: 35px
}
#menu .menu-side-top a:hover {
	color: #c15601
}
#menu .login-side {
	display: block;
	background: 0 0
}
#menu a.button {
	margin-right: 0;
	float: left
}
#menu .button span {
	padding: 7px 0;
	color: #fff;
	width: 80px;
	text-align: center
}
#menu .button.button-grey span {
	padding: 7px 18px;
	color: #c15601
}
#menu .login-side label {
	color: #fff
}
#menu .login-side input {
	background: 0 0;
	border: 1px solid #fff;
	height: 45px;
	color: #fff
}
#menu .login-side input:focus {
	background: 0 0;
	border: 1px solid #c15601
}
#menu .login-side span {
	color: #363636;
	display: inline-block;
	font-size: 15px;
	padding: 5px 10px;
	vertical-align: top
}
#menu .login-side .form-control::-moz-placeholder {
color:#fff
}
#menu .login-side strong {
	font-size: 15px;
	padding: 0 10px;
	font-weight: 400
}
#menu .login-side a.forgot {
	float: right;
	font-size: 15px;
	margin-top: 25px
}
#menu .login-side p.register a {
	color: #c15601;
	display: inline-block
}
.header-5 {
	padding: 30px 0;
	z-index: 999;
	position: absolute;
	width: 100%
}
.header-5 .mega-menu .drop-down-multilevel.right-menu {
	right: 44px
}
.header-5 .logo {
	display: block;
	position: relative
}
.header-5 .logo img {
	width: inherit;
	height: 45px
}
.header-5 .mega-menu.menuFullWidth {
	width: 100%
}
.header-5 .mega-menu .menu-links>li>a {
	padding: 0 30px
}
.header-5 #menu-icon {
	position: absolute;
	left: 0;
	top: 0
}
.header-5 #menu-toggle {
	position: absolute;
	width: 100%;
	top: 12px;
	color: #444;
	z-index: 99999;
	transition: .5s ease-in-out
}
.header-5 #menu-toggle.open {
	transition: .5s ease-in-out
}
.header-5 #menu-icon {
	width: 25px;
	height: 25px;
	transform: rotate(0);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer
}
.header-5 #menu-icon span, .header-7 #menu-icon {
	-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0)
}
.header-5 #menu-icon span {
	display: block;
	position: absolute;
	height: 2px;
	width: 50%;
	background: #fff;
	opacity: 1;
	transform: rotate(0);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-ms-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out
}
.header-5 #menu-icon span:nth-child(even) {
	left: 50%
}
.header-5 #menu-icon span:nth-child(odd) {
	left: 0
}
.header-5 #menu-icon span:nth-child(1), .header-5 #menu-icon span:nth-child(2) {
	top: 5px
}
.header-5 #menu-icon span:nth-child(3), .header-5 #menu-icon span:nth-child(4) {
	top: 12px
}
.header-5 #menu-icon span:nth-child(5), .header-5 #menu-icon span:nth-child(6) {
	top: 19px
}
.header-5 #menu-icon.open span:nth-child(1), .header-5 #menu-icon.open span:nth-child(6) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg)
}
.header-5 #menu-icon.open span:nth-child(2), .header-5 #menu-icon.open span:nth-child(5) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg)
}
.header-5 #menu-icon.open span:nth-child(1) {
	left: 0;
	top: 10px
}
.header-5 #menu-icon.open span:nth-child(2) {
	left: calc(50% - 5px);
	top: 10px
}
.header-5 #menu-icon.open span:nth-child(3) {
	left: -50%;
	opacity: 0
}
.header-5 #menu-icon.open span:nth-child(4) {
	left: 100%;
	opacity: 0
}
.header-5 #menu-icon.open span:nth-child(5) {
	left: 0;
	top: 19px
}
.header-5 #menu-icon.open span:nth-child(6) {
	left: calc(50% - 5px);
	top: 19px
}
.header-5 #menu {
	background: url(../images/bg/13.jpg) no-repeat;
	width: 280px;
	position: fixed;
	top: 0;
	left: -300px;
	list-style-type: none;
	padding: 46px 0;
	margin: 0;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out
}
.search-4, .search-4 input {
	transition: all .25s linear
}
.header-5 #menu:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	background: rgba(0,0,0,.7)
}
.search-4, .search-4 a, .search-4 input {
	height: 2em;
	padding: 0
}
.header-5 #menu li {
	position: relative;
	display: block
}
.header-5 #menu.open {
	left: 0
}
.header-5 .mega-menu .menu-links>li.active .drop-down-multilevel a:hover {
	color: #4fc1e9
}
.search-4 {
	width: 234px;
	margin: 11px 0 0;
	line-height: 2em;
	position: relative;
	cursor: pointer;
	z-index: 0
}
.header-6, .header-7, .search-4 a {
	position: absolute
}
.search-4 input {
	width: 226px;
	line-height: 15px;
	border: none;
	outline: 0;
	background-color: transparent;
	font-size: 14px;
	color: #fff;
	border-bottom: 1px solid #fff;
	-webkit-transition: all .25s linear;
	-ms-transition: all .25s linear;
	-moz-transition: all .25s linear;
	-o-transition: all .25s linear
}
.search-4 a {
	width: 2em;
	line-height: 2em;
	color: #ccc;
	text-align: center;
	text-decoration: none;
	display: block;
	top: 0;
	right: 0;
	transition: all .25s linear
}
.header-7 #menu-toggle, .header-7 #menu-toggle.open {
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out
}
.search-4 a:before {
	content: "\f002";
	font-family: FontAwesome;
	font-style: normal;
	color: #fff
}
.search-4 a:hover {
	color: #eee
}
.search-4.collapsed {
	width: 2em;
	margin-left: 10em
}
.search-4.collapsed input {
	width: 0
}
.search-4.collapsed a {
	color: #999
}
.search-4.collapsed a:hover {
	color: #bbb
}
.header-6 {
	padding: 20px 0;
	width: 100%
}
.header-6 .mega-menu>section.menu-list-items {
	padding: 10px 0
}
.header-6 .menu-list-items .container-fluid {
	padding: 0 60px
}
.header-6 .mega-menu .menu-links>li>a {
	color: #323232
}
.header-6 .mega-menu .menu-links>li.active a {
	color: #c15601
}
.header-6 .mega-menu .menu-links>li.active .drop-down a {
	color: #323232
}
.header-6 .mega-menu .menu-links>li.active .drop-down a:hover {
	color: #c15601
}
.header-6 .mega-menu .menu-links>li.active .drop-down-multilevel a, .header-7 .mega-menu .menu-links>li.active .drop-down-multilevel a {
	color: #555
}
.header-6 .mega-menu .menu-links>li.active .drop-down-multilevel a:hover {
	color: #c15601
}
.header-6 .mega-menu .menu-links>li.search {
	margin: 0 5px
}
.header-6 .search-3 {
	padding: 23px 0;
	margin: 0 3px
}
.header-6 .search-3.search-3-open .search-3-box {
	top: 64px!important
}
.header-6 .mega-menu .menu-links>li:last-child {
	margin-left: 15px
}
.header-6 .mega-menu .menu-links>li:last-child>a {
	line-height: 20px;
	background: #c15601;
	color: #fff;
	text-transform: uppercase;
	padding: 2px 12px;
	font-size: 12px;
	border-radius: 3px;
	margin-top: 21px
}
.header-6 .mega-menu .drop-down-multilevel.right-menu {
	right: 100px
}
.header-6 .mega-menu.desktopTopFixed .menu-list-items {
	background: #fff
}
.header-6 .mega-menu.desktopTopFixed .menu-links>li:last-child, .header-6 .mega-menu.desktopTopFixed .search-3 {
	display: none
}
.header-6 .mega-menu.desktopTopFixed>section.menu-list-items {
	padding: 0;
	box-shadow: 0 1px 6px rgba(0,0,0,.03)
}
.header-6 .mega-menu.desktopTopFixed .menu-list-items .menu-logo>li>a img {
	margin: 16px 0
}
.header-6 .mega-menu.desktopTopFixed .drop-down-multilevel.right-menu {
	right: 12px
}
.header-6 .login-1 {
	right: 0
}
.header-6 .login-1:before {
	border-bottom-color: #ECEFF7
}
.header-6 .login-1 a {
	font-size: 15px;
	color: #626262;
	margin-left: 0;
	display: inline-block;
	margin-top: 12px
}
.header-6 .login-1 a:hover {
	color: #c15601
}
.header-6 .login-with span {
	float: none;
	color: #626262;
	padding-top: 5px
}
.header-6 .login-with a {
	background: 0 0;
	border-radius: 50%;
	display: inline-block;
	height: 30px;
	line-height: 29px;
	margin-top: 0;
	text-align: center;
	width: 30px
}
.header-6 .login-with a:hover {
	color: #fff
}
.header-6 .login-with a.fb {
	border: 1px solid #3b5998
}
.header-6 .login-with a.fb:hover {
	background: #3b5998;
	border: 1px solid #3b5998;
	color: #fff
}
.header-6 .login-with a.twitter {
	border: 1px solid #55acee
}
.header-6 .login-with a.twitter:hover {
	background: #55acee;
	border: 1px solid #55acee;
	color: #fff
}
.header-6 .login-with a.google-plus {
	border: 1px solid #dd4b39
}
.header-6 .login-with a.google-plus:hover {
	background: #dd4b39;
	border: 1px solid #dd4b39;
	color: #fff
}
.header-7 {
	padding: 30px 40px;
	z-index: 999;
	width: 100%
}
.header-7 .mega-menu .drop-down-multilevel.right-menu {
	right: 44px
}
.header-7 .logo {
	display: block;
	position: relative
}
.header-7 .logo img {
	width: inherit;
	height: 60px
}
.header-7 .mega-menu.menuFullWidth {
	width: 100%
}
.header-7 .mega-menu .menu-links>li>a {
	padding: 0 30px
}
.header-7 #menu-icon {
	position: absolute;
	right: 15px;
	top: 0
}
.header-7 #menu-toggle {
	position: absolute;
	width: 100%;
	right: 0;
	left: inherit;
	top: 12px;
	color: #444;
	z-index: 99999;
	transition: .5s ease-in-out
}
.header-7 #menu-toggle.open {
	left: 0;
	transition: .5s ease-in-out
}
.header-7 #menu-icon {
	width: 25px;
	height: 25px;
	transform: rotate(0);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer
}
.header-7 #menu-icon.open {
	right: 192px
}
.header-7 #menu-icon span {
	display: block;
	position: absolute;
	height: 2px;
	width: 50%;
	background: #fff;
	opacity: 1;
	-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-ms-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out
}
.header-7 #menu-icon span:nth-child(even) {
	left: 50%
}
.header-7 #menu-icon span:nth-child(odd) {
	left: 0
}
.header-7 #menu-icon span:nth-child(1), .header-7 #menu-icon span:nth-child(2) {
	top: 5px
}
.header-7 #menu-icon span:nth-child(3), .header-7 #menu-icon span:nth-child(4) {
	top: 12px
}
.header-7 #menu-icon span:nth-child(5), .header-7 #menu-icon span:nth-child(6) {
	top: 19px
}
.header-7 #menu-icon.open span:nth-child(1), .header-7 #menu-icon.open span:nth-child(6) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg)
}
.header-7 #menu-icon.open span:nth-child(2), .header-7 #menu-icon.open span:nth-child(5) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg)
}
.header-7 #menu-icon.open span:nth-child(1) {
	left: 0;
	top: 10px
}
.header-7 #menu-icon.open span:nth-child(2) {
	left: calc(50% - 5px);
	top: 10px
}
.header-7 #menu-icon.open span:nth-child(3) {
	left: -50%;
	opacity: 0
}
.header-7 #menu-icon.open span:nth-child(4) {
	left: 100%;
	opacity: 0
}
.header-7 #menu-icon.open span:nth-child(5) {
	left: 0;
	top: 19px
}
.header-7 #menu-icon.open span:nth-child(6) {
	left: calc(50% - 5px);
	top: 19px
}
.header-7 #menu {
	background: #A0A0A0;
	width: 280px;
	position: fixed;
	top: 0;
	bottom: 0;
	right: -300px;
	z-index: 9999;
	list-style-type: none;
	padding: 46px 0;
	margin: 0;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-ms-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out
}
#animated-slider .carousel-indicators, #animated-slider-2 .carousel-indicators {
	bottom: 30px
}
.header-7 #menu:before {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	content: "";
	background: rgba(0,0,0,.7)
}
.header-7 #menu li {
	position: relative;
	display: block
}
.header-10, .header-8, .header-9 {
	position: absolute;
	width: 100%
}
.header-7 #menu.open {
	right: 0
}
.header-7 .mega-menu .menu-links>li.active .drop-down-multilevel a:hover {
	color: #4fc1e9
}
.header-7 .mega-menu .menu-links>li.active .drop-down a {
	color: #323232
}
.header-7 .mega-menu .menu-links>li.active .drop-down a:hover {
	color: #c15601
}
.header-8 {
	border-bottom: 1px solid rgba(255,255,255,.1);
	padding: 0;
	z-index: 9
}
.header-8 .mega-menu .menu-links>li.search {
	margin: 0 5px
}
.header-8 .search-3 {
	padding: 23px 0
}
.header-8 .search-3 .search-btn {
	color: #fff;
	margin-left: 10px;
	line-height: 59px
}
.header-10 .mega-menu .menu-links>li.search, .header-9 .mega-menu .menu-links>li.search {
	margin: 0 5px
}
.header-8 .search-3 .search-btn:hover {
	color: #c15601
}
.header-8 .search-3.search-3-open .search-3-box {
	top: 107px!important
}
.header-8 .mega-menu>section.menu-list-items {
	padding: 0
}
.header-8 .mega-menu .menu-links>li>a {
	padding: 20px 13px
}
.header-8 .mega-menu .menu-logo {
	padding: 23px 0
}
.header-8 .mega-menu .drop-down-multilevel.right-menu {
	right: 36px
}
.header-8 .mega-menu.desktopTopFixed .menu-links>li>a {
	padding: 3px 13px
}
.header-8 .mega-menu.desktopTopFixed .menu-logo {
	padding: 5px 0 3px
}
.header-9, .header-9 .mega-menu>section.menu-list-items {
	padding: 0
}
.header-8 .mega-menu.desktopTopFixed .search-3 .search-btn {
	line-height: 34px
}
.header-8 .mega-menu.desktopTopFixed .search-3.search-3-open .search-3-box {
	top: 78px!important;
	border: 1px solid #eceff7;
	border-top: 0
}
.header-9 {
	border-bottom: 1px solid rgba(255,255,255,.1)
}
.header-9 .mega-menu .menu-links>li>a {
	padding: 20px 13px;
	color: #626262
}
.header-9 .mega-menu .menu-links>li.active>a {
	color: #c15601
}
.header-9 .mega-menu .menu-logo {
	padding: 23px 0
}
.header-9 .mega-menu .drop-down-multilevel.right-menu {
	right: 12px
}
.header-9 .mega-menu.desktopTopFixed .menu-links>li>a {
	padding: 3px 13px
}
.header-9 .mega-menu.desktopTopFixed .menu-logo {
	padding: 5px 0 3px
}
.header-9 .mega-menu.desktopTopFixed .menu-list-items {
	background: #fff;
	border-bottom: 1px solid #eceff7
}
.header-10 {
	border-bottom: 1px solid rgba(255,255,255,.1);
	padding: 0
}
.header-10 .search-3 {
	padding: 23px 0
}
.header-10 .search-3 .search-btn {
	color: #323232;
	margin-left: 10px;
	line-height: 59px
}
.header-10 .search-3 .search-btn:hover {
	color: #c15601
}
.header-10 .search-3.search-3-open .search-3-box {
	top: 107px!important
}
.header-10 .mega-menu>section.menu-list-items {
	padding: 0
}
.header-10 .mega-menu .menu-links>li>a {
	padding: 20px 13px;
	color: #626262
}
.header-10 .mega-menu .menu-links>li.active>a {
	color: #c15601
}
.header-10 .mega-menu .menu-logo {
	padding: 23px 0
}
.header-10 .mega-menu .drop-down-multilevel.right-menu {
	right: 36px
}
.header-10 .mega-menu.desktopTopFixed .menu-list-items {
	background: #fff;
	border-bottom: 1px solid #eceff8
}
.header-10 .mega-menu.desktopTopFixed .menu-links>li>a {
	padding: 3px 13px
}
.header-10 .mega-menu.desktopTopFixed .menu-logo {
	padding: 5px 0 3px
}
.header-10 .mega-menu.desktopTopFixed .search-3 .search-btn {
	line-height: 34px
}
.header-10 .mega-menu.desktopTopFixed .search-3.search-3-open .search-3-box {
	top: 78px!important;
	border: 1px solid #eceff7;
	border-top: 0
}
.rev-slider {
	overflow: hidden;
	position: relative
}
.tp-simpleresponsive .slotholder *, .tp-simpleresponsive img {
	background-size: cover
}
.modern_medium_fat_white, .tp-caption.modern_medium_fat_white {
	position: absolute;
	color: #fff;
	text-shadow: none;
	font-weight: 500;
	font-size: 24px;
	line-height: 20px;
	margin: 0;
	border-width: 0;
	border-style: none;
	white-space: nowrap
}
.title-1-desc, .tp-caption.title-1-desc {
	font-weight: 300;
	font-family: Lato;
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	padding: 10px;
	text-shadow: none;
	text-transform: capitalize;
	border-width: 0;
	border-color: #000;
	border-style: none
}
.black-align-center, .title-2, .tp-caption.black-align-center, .tp-caption.title-2 {
	font-weight: 500;
	font-size: 60px;
	font-family: Lato;
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	padding: 10px;
	text-shadow: none;
	text-transform: capitalize;
	border-width: 0;
	border-color: #000;
	border-style: none
}
.black-align-center, .tp-caption.black-align-center {
	text-align: center
}
.desc-align-center, .tp-caption.desc-align-center {
	font-weight: 300;
	font-family: Lato;
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	padding: 10px;
	text-shadow: none;
	text-transform: capitalize;
	border-width: 0;
	border-color: #000;
	border-style: none
}
.extra-bold, .tp-caption.extra-bold {
	font-size: 60px;
	font-weight: 500;
	font-family: Lato;
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	padding: 10px;
	text-shadow: none;
	text-transform: capitalize;
	text-align: center;
	border-width: 0;
	border-color: #000;
	border-style: none
}
.tp-caption.fullscreenvideo:before {
	background: rgba(0,0,0,.7);
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 99999
}
.tp-caption.fullscreenvideo video {
	width: 100%!important;
	height: 100%!important
}
img#background {
	width: 100%;
	height: 100vh
}
.move-carousel {
	left: 0;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	z-index: 99
}
.move-carousel .owl-carousel-2 {
	overflow: hidden
}
.move-carousel .owl-carousel-2 .owl-nav {
	display: block;
	position: absolute;
	text-indent: inherit;
	top: 46%;
	left: 0;
	width: 100%;
	cursor: pointer
}
.tp-banner, .tp-banner-container {
	position: relative;
	width: 100%
}
.move-carousel .owl-carousel-2 .owl-nav i:hover {
	color: #626262;
	background: rgba(255,255,255,1)
}
.move-carousel .slider-contents h1 {
	text-transform: uppercase;
	font-size: 50px;
	font-weight: 700
}
.move-carousel .slider-contents p {
	font-size: 18px;
	font-weight: 300;
	margin: 20px 0 30px
}
.move-carousel .slider-contents a.button-border-white span {
	font-size: 14px
}
.move-carousel .slider-contents.slide-1 h1 {
	margin-bottom: 30px
}
.move-carousel .slider-contents.slide-2 h1 {
	line-height: 60px;
	margin-bottom: 0;
	margin-top: 20px
}
.move-carousel .slider-contents.slide-2 p {
	margin: 20px 0 30px
}
.move-carousel .slider-contents.slide-3 h1 {
	margin-top: 70px
}
.move-carousel .slider-contents.slide-3 p {
	margin: 20px 0
}
.tp-banner-container {
	padding: 0;
	background: #df6708
}
.tp-banner-fullscreen-container {
	width: 100%;
	position: relative;
	padding: 0
}
.rev-slider-2 .tp-caption.title-1, .rev-slider-2 .tp-caption.title-1-blue, .rev-slider-2 .tp-caption.title-1-desc, .rev-slider-2 .tp-caption.title-2, .title-1, .title-1-blue, .title-1-desc, .title-2 {
	background-color: transparent;
	padding: 10px;
	border-color: #000;
	font-family: Lato;
	text-shadow: none;
	border-width: 0;
	border-style: none;
	text-decoration: none
}
.rev-slider-2 .tp-caption.title-1, .title-1 {
	font-size: 60px;
	font-weight: 500;
	color: #363636;
	text-transform: capitalize
}
.rev-slider-2 .tp-caption.title-1-blue, .title-1-blue {
	font-size: 60px;
	font-weight: 600;
	color: #c15601
}
.rev-slider-2 .tp-caption.title-1-desc, .title-1-desc {
	font-weight: 500;
	color: #363636
}
.rev-slider-2 .tp-caption.title-2, .title-2 {
	font-size: 60px;
	color: #363636
}
.rev-slider-2 .tp-caption.text-white.title-2.sft.tp-resizeme {
	color: #363636;
	text-align: center;
	font-weight: 500
}
.rev-slider-2 .tp-caption.text-white.title-1-desc.sfl.tp-resizeme {
	color: #363636
}
.slider-fade-carousel .bg-opacity-black-50:before {
	z-index: 9
}
.slider-fade-carousel {
	height: 100vh;
	background: #000;
	overflow: hidden
}
.slider-fade-carousel .owl-carousel-1 {
	overflow: hidden
}
.slider-fade-carousel .owl-carousel-1 .owl-nav {
	display: block;
	position: absolute;
	text-indent: inherit;
	top: 45%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	left: 0;
	width: 100%;
	cursor: pointer;
	z-index: 999
}
.slider-fade-carousel .owl-carousel-1 .owl-nav .owl-prev {
	display: block;
	position: absolute;
	text-indent: inherit;
	left: -44px;
	width: auto;
	cursor: pointer;
	-webkit-transition: opacity .3s ease 0s, left .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, left .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, left .3s ease 0s;
	-o-transition: opacity .3s ease 0s, left .3s ease 0s;
	transition: opacity .3s ease 0s, left .3s ease 0s
}
.slider-fade-carousel .owl-carousel-1 .owl-nav .owl-next {
	display: block;
	position: absolute;
	text-indent: inherit;
	right: -44px;
	width: auto;
	cursor: pointer;
	-webkit-transition: opacity .3s ease 0s, right .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, right .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, right .3s ease 0s;
	-o-transition: opacity .3s ease 0s, right .3s ease 0s;
	transition: opacity .3s ease 0s, right .3s ease 0s
}
#animated-slider .item img, #animated-slider-2 .item img, .slider-fade-carousel .owl-carousel-1 .item img {
	width: 100%
}
.feature, .slider-fade-carousel .owl-carousel-1 .owl-nav i {
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s
}
.slider-fade-carousel .owl-carousel-1 .owl-nav i {
	font-weight: 400;
	font-size: 40px;
	color: #fff;
	padding: 12px;
	transition: all .3s ease-out 0s
}
.slider-fade-carousel .owl-carousel-1 .owl-nav i:hover {
	color: #fff
}
.slider-fade-carousel:hover .owl-carousel-1 .owl-nav .owl-prev {
	left: 3%
}
.slider-fade-carousel:hover .owl-carousel-1 .owl-nav .owl-next {
	right: 3%
}
.slider-fade-carousel .owl-carousel-1 .slider-contents {
	left: 0;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	z-index: 9999
}
.slider-fade-carousel .slider-contents h1 {
	text-transform: uppercase;
	font-size: 60px;
	font-weight: 800
}
.slider-fade-carousel .slider-contents p {
	font-size: 18px;
	font-weight: 300;
	margin: 40px 0 30px
}
.slider-fade-carousel .slider-contents .button {
	margin-right: 10px;
	margin-top: 10px
}
.slider-fade-carousel .slider-contents.slide-2 h1 {
	text-transform: uppercase;
	font-size: 60px;
	font-weight: 800;
	line-height: 80px
}
.slider-fade-carousel .slider-contents.slide-2 p {
	font-size: 20px;
	font-weight: 300;
	margin: 20px 0 30px
}
.slider-fade-carousel .slider-contents.slide-3 h1 {
	text-transform: uppercase;
	font-size: 60px;
	font-weight: 800;
	line-height: 80px
}
.slider-fade-carousel .slider-contents.slide-3 p {
	font-size: 20px;
	font-weight: 300;
	margin: 20px 0 30px
}
.rev-slider-3 .tp-banner {
	background: #000
}
.image-holder-2-bg, .image-holder-bg {
	background-size: cover!important;
	background-repeat: no-repeat;
	background-position: center bottom
}
.largeboldwhite-align-right, .rev-slider-3 .tp-caption.largeboldwhite-align-right {
	font-size: 58px;
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	text-align: right;
	border-width: 0;
	font-weight: 400;
	border-color: #ffd658;
	border-style: none;
	line-height: 70px
}
.desc-align-right, .rev-slider-3 .tp-caption.desc-align-right {
	font-size: 22px;
	font-weight: 300;
	font-family: Lato;
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	padding: 10px;
	text-shadow: none;
	text-transform: capitalize;
	text-align: right;
	border-width: 0;
	border-color: #000;
	border-style: none
}
#animated-slider {
	overflow: hidden;
	-webkit-transform-style: preserve-3d
}
#animated-slider .item .slider-content {
	z-index: 0;
	opacity: 0;
	-webkit-transition: opacity .5s;
	-moz-transition: opacity .5s;
	-o-transition: opacity .5s;
	-ms-transition: opacity .5s;
	transition: opacity .5s
}
#animated-slider .item.active .slider-content {
	z-index: 0;
	opacity: 1;
	-webkit-transition: opacity .1s;
	-ms-transition: opacity .1s;
	-moz-transition: opacity .1s;
	-o-transition: opacity .1s;
	transition: opacity .1s
}
#animated-slider .slider-content {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 55%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	width: 100%;
	z-index: 2
}
#animated-slider .slider-content h1 {
	font-size: 60px;
	color: #323232;
	margin: 10px 0;
	font-weight: 600;
	line-height: 80px;
	text-transform: uppercase
}
#animated-slider .slider-content span {
	color: #c15601;
	font-size: 18px;
	margin-bottom: 10px;
	line-height: 20px;
	letter-spacing: 4px;
	font-weight: 400;
	display: block;
	text-transform: uppercase
}
#animated-slider .slider-content .slider-2 p {
	font-size: 18px
}
#animated-slider .slider-content p {
	padding: 0 200px;
	margin-bottom: 30px
}
#animated-slider .slider-content a.button span {
	letter-spacing: inherit;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
	color: #fff
}
.carousel .item.active .animated1 {
	-webkit-animation: lightSpeedIn 1s ease-in .2s both;
	-o-animation: lightSpeedIn 1s ease-in .2s both;
	-ms-animation: lightSpeedIn 1s ease-in .2s both;
	-moz-animation: lightSpeedIn 1s ease-in .2s both;
	animation: lightSpeedIn 1s ease-in .2s both
}
.carousel .item.active .animated2 {
	-webkit-animation: bounceInRight 1s ease-in-out .5s both;
	-o-animation: bounceInRight 1s ease-in-out .5s both;
	-ms-animation: bounceInRight 1s ease-in-out .5s both;
	-moz-animation: bounceInRight 1s ease-in-out .5s both;
	animation: bounceInRight 1s ease-in-out .5s both
}
.carousel .item.active .animated3 {
	-webkit-animation: bounceInLeft 1s ease-in-out .5s both;
	-o-animation: bounceInLeft 1s ease-in-out .5s both;
	-ms-animation: bounceInLeft 1s ease-in-out .5s both;
	-moz-animation: bounceInLeft 1s ease-in-out .5s both;
	animation: bounceInLeft 1s ease-in-out .5s both
}
.carousel .item.active .animated4 {
	-webkit-animation: flipInX 1s ease-in .5s both;
	-o-animation: flipInX 1s ease-in .5s both;
	-ms-animation: flipInX 1s ease-in .5s both;
	-moz-animation: flipInX 1s ease-in .5s both;
	animation: flipInX 1s ease-in .5s both
}
.carousel .item.active .animated5 {
	-webkit-animation: bounceInLeft 1s ease-in-out .1s both;
	-o-animation: bounceInLeft 1s ease-in-out .1s both;
	-ms-animation: bounceInLeft 1s ease-in-out .1s both;
	-moz-animation: bounceInLeft 1s ease-in-out .1s both;
	animation: bounceInLeft 1s ease-in-out .1s both
}
.carousel .item.active .animated6 {
	-webkit-animation: bounceIn 1s ease-in .5s both;
	-o-animation: bounceIn 1s ease-in .5s both;
	-ms-animation: bounceIn 1s ease-in .5s both;
	-moz-animation: bounceIn 1s ease-in .5s both;
	animation: bounceIn 1s ease-in .5s both
}
.carousel .item.active .animated7 {
	-webkit-animation: fadeInDown .7s ease-in 1s both;
	-o-animation: fadeInDown .7s ease-in 1s both;
	-moz-animation: fadeInDown .7s ease-in 1s both;
	-ms-animation: fadeInDown .7s ease-in 1s both;
	animation: fadeInDown .7s ease-in 1s both
}
.carousel .item.active .animated8 {
	-webkit-animation: fadeInUp .7s ease-in 1s both;
	-o-animation: fadeInUp .7s ease-in 1s both;
	-moz-animation: fadeInUp .7s ease-in 1s both;
	-ms-animation: fadeInUp .7s ease-in 1s both;
	animation: fadeInUp .7s ease-in 1s both
}
#animated-slider-2 {
	overflow: hidden;
	-webkit-transform-style: preserve-3d
}
#animated-slider-2 .item .slider-content {
	z-index: 0;
	opacity: 0;
	-webkit-transition: opacity .5s;
	-moz-transition: opacity .5s;
	-o-transition: opacity .5s;
	-ms-transition: opacity .5s;
	transition: opacity .5s
}
#animated-slider-2 .item.active .slider-content {
	z-index: 0;
	opacity: 1;
	-webkit-transition: opacity .1s;
	-ms-transition: opacity .1s;
	-moz-transition: opacity .1s;
	-o-transition: opacity .1s;
	transition: opacity .1s
}
#animated-slider-2 .slider-content .slider-1 {
	float: right;
	width: 50%
}
#animated-slider-2 .slider-content .slider-2 {
	float: left;
	width: 50%
}
#animated-slider-2 .slider-content {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 55%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	width: 100%;
	z-index: 2
}
.carousel-indicators li {
	border-radius: 0;
	width: 15px!important;
	height: 15px!important;
	border: 3px solid black;;
	margin: 1px!important;
	background-color: #f46a1a;
}
#animated-slider-2 .slider-content h1 {
	font-size: 80px;
	color: #fff;
	margin: 10px 0;
	font-weight: 800;
	line-height: 90px;
	text-transform: uppercase
}
#animated-slider-2 .slider-content p {
	margin-bottom: 30px;
	color: #fff;
	font-style: italic;
	font-size: 18px
}
.image-holder-bg {
	position: relative;
	height: 800px;
	width: 100%
}
.image-holder {
	display: table;
	width: 100%;
	height: 100%;
	position: relative
}
.image-holder-content {
	display: table-cell;
	height: 100%;
	vertical-align: middle
}
.image-holder-content .content {
	padding: 0 100px;
	margin-top: 100px
}
.image-holder .image-holder-content span {
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
	margin-bottom: 15px;
	display: block
}
.image-holder .image-holder-content h2 {
	font-size: 45px;
	color: #fff;
	font-weight: 300;
	margin-top: 0;
	line-height: 60px;
	margin-bottom: 0
}
.image-holder .image-holder-content a {
	color: #fff;
	text-transform: uppercase;
	border-bottom: 2px solid #fff;
	font-size: 14px;
	margin-top: 30px;
	display: inline-block;
	padding-bottom: 5px
}
.image-holder .image-holder-content a:hover {
	color: #c15601;
	border-color: #c15601
}
.image-holder-2-bg {
	position: relative;
	height: 900px;
	width: 100%
}
.image-holder-2 {
	display: table;
	width: 100%;
	height: 100%;
	position: relative
}
.image-holder-2-content {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
	margin-top: 70px
}
.image-holder-2-content .content {
	padding: 0 100px;
	margin-top: 100px
}
.image-holder-2 .image-holder-2-content span {
	font-size: 14px;
	color: #fff;
	background: #c15601;
	text-transform: uppercase;
	margin-bottom: 15px;
	display: inline-block;
	padding: 5px 20px
}
.image-holder-2 .image-holder-2-content h1 {
	font-size: 60px;
	text-transform: uppercase;
	color: #323232;
	font-weight: 700;
	margin-top: 10px;
	line-height: 60px;
	margin-bottom: 20px
}
.image-holder-2 .image-holder-2-content a {
	color: #323232;
	text-transform: uppercase;
	border-bottom: 2px solid #323232;
	font-size: 14px;
	margin-top: 30px;
	display: inline-block;
	padding-bottom: 5px
}
.image-holder-2 .image-holder-2-content a:hover {
	color: #c15601;
	border-color: #c15601
}
#typer {
	height: 40px
}
#typer h2 {
	font-size: 32px;
	color: #323232;
	font-weight: 600;
	text-transform: uppercase;
	font-family: Raleway, sans-serif
}
.parallax-banner {
	width: 100%;
	height: 100vh;
	background: #f6f6f6;
	overflow: hidden;
	position: relative
}
.parallax-banner .scene {
	padding: 0;
	margin: 0;
	height: 100%
}
.parallax-banner .banner-content {
	padding: 0 25%
}
.parallax-banner .banner-content span {
	background: #c15601;
	color: #fff;
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 3px;
	padding: 7px 15px;
	display: inline-block
}
.mobile-slider, .mobile-slider .row {
	padding-right: 15px;
	padding-left: 15px
}
.parallax-banner .banner-content h1 {
	text-transform: uppercase;
	display: block;
	margin: 25px 0;
	line-height: 55px;
	font-weight: 600
}
.parallax-banner .banner-content a {
	color: #c15601;
	text-transform: uppercase;
	border-bottom: 2px solid #c15601;
	font-size: 14px;
	display: inline-block;
	padding-bottom: 5px
}
.parallax-banner .banner-content a:hover {
	color: #323232;
	border-color: #323232
}
.feature i, .feature-2 span, .highlight.active h3 {
	color: #c15601
}
.parallax-banner .layer:nth-child(1) {
	position: absolute;
	left: -100px;
	top: 60px
}
.parallax-banner .layer:nth-child(2) {
	position: absolute;
	bottom: 20px
}
.parallax-banner .layer:nth-child(3) {
	position: absolute;
	width: 100%;
	text-align: center;
	top: 40%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 1
}
.parallax-banner .layer:nth-child(4) {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center
}
.parallax-banner .layer:nth-child(5) {
	position: absolute;
	right: -200px;
	bottom: 100px
}
.parallax-banner .layer:nth-child(6) {
	position: absolute;
	top: 60px;
	right: 50px
}
#fon, .phone-holder {
	top: 0;
	overflow: hidden
}
.mobile-slider .row {
	z-index: 2;
	position: relative;
	min-height: 1px;
	width: 31.777777%;
	margin-right: -15px;
	margin-left: -15px
}
.mobile-slider ul {
	list-style: none;
	z-index: 2
}
.highlight h3 {
	position: relative;
	margin-top: 10px;
	display: block;
	font-size: 18px
}
.highlight-title span {
	position: relative;
	z-index: 1;
	font-size: 28px;
	color: #c15601
}
.highlight {
	cursor: pointer;
	margin: 40px 0
}
li:last-child.highlight {
	margin-bottom: 0
}
.highlights-phone {
	width: 40%;
	height: 620px;
	float: left
}
.phone-holder {
	display: block;
	height: 620px;
	position: relative;
	left: 16px;
	width: 306px;
	margin: auto
}
#fon, .hgi {
	position: absolute
}
#fon {
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2
}
.highlights-phone.wht .phone-holder #fon {
	background: url(../images/feature/11.png) center top no-repeat;
	background-position-x: 0
}
.hgi {
	top: 58px;
	left: 15px;
	width: 276px;
	height: 480px;
	visibility: hidden
}
.hgi img {
	width: 100%;
	height: 480px
}
.highlights-phone.wht .hgi {
	top: 62px
}
.bg-opacity-black-10:before, .bg-opacity-black-20:before, .bg-opacity-black-30:before, .bg-opacity-black-40:before, .bg-opacity-black-50:before, .bg-opacity-black-60:before, .bg-opacity-black-70:before {
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%
}
.hgi.active {
	visibility: visible
}
.right-row {
	padding-right: 0;
	float: right
}
.left-row {
	float: left;
	margin-right: 0
}
.left-row .highlight-title {
	text-align: right
}
.right-row .highlight-title {
	text-align: left
}
.white-bg {
	background: #fff
}
.gray-bg {
	background: #eceff7
}
.blue-bg {
	background: #c15601
}
.black-bg {
	background: #323232
}
.bg-opacity-black-10:before {
	background: rgba(0,0,0,.1);
	z-index: -1
}
.bg-opacity-black-20:before {
	background: rgba(0,0,0,.2);
	z-index: -1
}
.bg-opacity-black-30:before {
	background: rgba(0,0,0,.3);
	z-index: -1
}
.bg-opacity-black-40:before {
	background: rgba(0,0,0,.4);
	z-index: -1
}
.bg-opacity-black-50:before {
	background: rgba(0,0,0,.5);
	z-index: -1
}
.bg-opacity-black-60:before {
	background: rgba(0,0,0,.6);
	z-index: -1
}
.bg-opacity-black-70:before {
	background: rgba(0,0,0,.7);
	z-index: 0
}
.bg-opacity-black-80:before, .bg-opacity-black-90:before, .bg-opacity-blue-20:before, .bg-opacity-blue-50:before, .bg-opacity-blue-70:before, .bg-opacity-blue-90:before, .bg-opacity-white-10:before, .bg-opacity-white-20:before, .bg-opacity-white-30:before, .bg-opacity-white-40:before, .bg-opacity-white-50:before, .bg-opacity-white-70:before, .bg-opacity-white-80:before, .bg-opacity-white-90:before {
	z-index: -1;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	content: ""
}
.bg-opacity-black-80:before {
	background: rgba(0,0,0,.8)
}
.bg-opacity-black-90:before {
	background: rgba(0,0,0,.9)
}
.bg-opacity-white-10:before {
	background: rgba(255,255,255,.1)
}
.bg-opacity-white-20:before {
	background: rgba(255,255,255,.2)
}
.bg-opacity-white-30:before {
	background: rgba(255,255,255,.3)
}
.bg-opacity-white-40:before {
	background: rgba(255,255,255,.4)
}
.bg-opacity-white-50:before {
	background: rgba(255,255,255,.5)
}
.bg-opacity-white-60:before {
	background: rgba(255,255,255,.6);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1
}
.bg-opacity-white-70:before {
	background: rgba(255,255,255,.7)
}
.bg-opacity-white-80:before {
	background: rgba(255,255,255,.8)
}
.bg-opacity-white-90:before {
	background: rgba(255,255,255,.9)
}
.bg-opacity-blue-20:before {
	background: rgba(0,169,218,.2)
}
.bg-opacity-blue-50:before {
	background: rgba(0,169,218,.5)
}
.bg-opacity-blue-70:before {
	background: rgba(0,169,218,.7)
}
.bg-opacity-blue-90:before {
	background: rgba(0,169,218,.9)
}
.bg-1, .bg-10, .bg-11, .bg-12, .bg-13, .bg-14, .bg-15, .bg-16, .bg-17, .bg-18, .bg-2, .bg-3, .bg-4, .bg-5, .bg-6, .bg-7, .bg-8, .bg-gradient:before {
	z-index: 0
}
.bg-gradient:before {
	background: rgba(0,0,0,.8);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #c15601), color-stop(100%, #000));
	background: -webkit-linear-gradient(0deg, #c15601 0, #000 100%);
	background: linear-gradient(0deg, #c15601 0, #000 100%);
	opacity: .6;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%
}
.bg-1, .bg-10, .bg-12, .bg-13, .bg-14, .bg-15, .bg-16, .bg-17, .bg-18, .bg-2, .bg-3, .bg-4, .bg-5, .bg-6, .bg-7, .bg-8, .bg-9, .feature, .feature a, .feature-content {
	position: relative
}
.bg-1 {
	background: url(../images/bg/01.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-2 {
	background: url(../images/bg/02.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-3 {
	background: url(../images/bg/03.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-4 {
	background: url(../images/bg/04.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-5 {
	background: url(../images/bg/05.jpg) center center no-repeat;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-6 {
	background: url(../images/bg/06.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-7 {
	background: url(../images/bg/07.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-8 {
	background: url(../images/bg/08.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-9 {
	background: url(../images/bg/09.jpg) center center no-repeat;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important;
	z-index: 0
}
.bg-10 {
	background: url(../images/bg/11.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-11 {
	background: url(../images/bg/12.jpg) center center no-repeat;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important;
	position: relative
}
.bg-12 {
	background: url(../images/bg/15.jpg) center center no-repeat;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-13 {
	background: url(../images/bg/16.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-14 {
	background: url(../images/bg/18.png) center center no-repeat;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-15 {
	background: url(../images/bg/19.jpg) center center no-repeat;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-16 {
	background: url(../images/bg/20.jpg) center center no-repeat;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-17 {
	background: url(../images/bg/21.jpg) center center no-repeat fixed;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.bg-18 {
	background: url(../images/bg/22.jpg) top center no-repeat;
	background-clip: initial;
	background-color: rgba(0,0,0,0);
	background-origin: initial;
	background-size: cover!important
}
.pattern-bg {
	background: url(../images/pattern/pattern-bg.png) top right;
	background-color: rgba(236,239,247,1)
}
.pattern-1 {
	background: url(../images/pattern/pattern-bg.png) top right fixed
}
.feature {
	background: #eceff7;
	padding: 3px;
	transition: all .3s ease-out 0s;
	z-index: 1
}
.feature-content {
	z-index: 9
}
.feature i {
	background: #fff;
	width: 100%;
	font-size: 30px;
	padding: 35px 10px;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.feature h4, .feature p {
	transition: all .3s ease-out 0s
}
.feature h4 {
	text-transform: none;
	padding: 30px 0 10px;
	font-weight: 400
}
.feature p {
	font-weight: 100;
	padding: 0 20px
}
.feature .border, .feature .feature-img {
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	left: 0;
	bottom: 0
}
.feature a {
	font-size: 14px;
	font-weight: 100;
	color: grey;
	margin: 25px 0 10px;
	display: block;
	z-index: 3
}
.feature5 p, .feature5 span {
	font-weight: 400
}
.feature .feature-img {
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: all .3s ease-out 0s;
	z-index: 0;
	background-size: cover;
	background-position: center center
}
.feature .border {
	position: absolute;
	background: #c15601;
	height: 0;
	z-index: 2;
	width: 100%;
	transition: all .3s ease-out 0s
}
.feature.active:before, .feature:hover:before, .owl-carousel-8 .owl-nav i {
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s
}
.feature.active .feature-img, .feature:hover .feature-img {
	opacity: 1
}
.feature.active .border, .feature:hover .border {
	height: 45px
}
.feature.active i, .feature:hover i {
	background: rgba(255,255,255,0);
	color: #fff;
	padding-bottom: 15px
}
.feature.active h4, .feature.active p, .feature:hover h4, .feature:hover p {
	color: #fff
}
.feature.active a, .feature:hover a {
	color: #fff;
	margin-top: 45px
}
.feature.active:before, .feature:hover:before {
	background: rgba(0,0,0,.5);
	color: #fff;
	content: "";
	display: inline-block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
	-ms-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.feature-2 span {
	float: left;
	margin-right: 15px;
	font-size: 26px
}
.feature-3-title span, .feature-4 span {
	font-size: 34px;
	color: #c15601
}
.feature-2 h5 {
	display: table;
	padding-top: 4px
}
.feature-3-title {
	float: left;
	display: table;
	margin-right: 20px
}
.feature-3-info {
	display: table-cell;
	vertical-align: top
}
.feature-3 .work-hr p {
	line-height: 30px
}
.feature-3-info p {
	margin-bottom: 0
}
.feature-4 span {
	float: left;
	margin-right: 19px
}
.feature-4 h4 {
	display: block
}
.feature-4 p {
	margin-left: 18px;
	padding-left: 36px;
	border-left: 1px solid #eceff7
}
.feature-4-sec li {
	font-size: 14px;
	color: #626262;
	margin-bottom: 10px
}
.feature-4-sec i {
	color: #626262;
	font-size: 16px;
	margin-left: 17px;
	padding-right: 28px;
	padding-top: 4px
}
.feature5 span {
	font-size: 30px;
	color: #c15601;
	margin-bottom: 20px;
	display: block
}
.feature5 h4 {
	position: relative;
	padding-bottom: 15px
}
.feature5 h4:before {
	position: absolute;
	bottom: 0;
	content: "";
	width: 70px;
	height: 2px;
	background: #c15601
}
.feature5 a {
	font-size: 15px;
	color: #363636;
	position: relative
}
.feature5 i {
	opacity: 0;
	position: absolute;
	left: 75px;
	margin-top: 9px;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	font-size: 12px
}
.feature5 a:hover {
	color: #c15601
}
.feature5 a:hover i {
	left: 85px;
	opacity: 1;
	color: #c15601
}
.feature6 strong {
	font-size: 200px;
	font-weight: 700;
	color: #eceff8;
	position: relative;
	font-family: Lato, sans-serif;
	line-height: 140px
}
.feature6-item-top {
	transform: translate3d(-50%, 0, 0);
	-webkit-transform: translate3d(-50%, 0, 0);
	-o-transform: translate3d(-50%, 0, 0);
	-ms-transform: translate3d(-50%, 0, 0);
	-moz-transform: translate3d(-50%, 0, 0);
	-ms-transform: translate(-50%, 0);
	left: 50%;
	position: absolute;
	text-align: center;
	top: 15%
}
.feature6-item-top span {
	font-size: 30px;
	line-height: 34px;
	background: 0 0;
	color: #c15601;
	margin-bottom: 10px;
	display: block;
	border: none;
	outline: 0;
	transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out
}
.feature-10 p, .feature-8-info p, .work-process .feature6 {
	margin-bottom: 0
}
.feature6-item-top h3 {
	transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out
}
.feature6 p {
	font-size: 15px
}
.feature6:hover .feature6-item-top span {
	margin-top: 40px
}
.feature6:hover .feature6-item-top h3 {
	margin-top: -100px
}
.feature7 .feature7-icon {
	float: left;
	width: 70px;
	height: 70px;
	background: 0 0;
	border: 2px solid #fff;
	text-align: center;
	margin-right: 25px
}
.feature-9, .feature-9-bg-1 {
	background: #00c6ff
}
.feature7 .feature7-icon span {
	font-size: 25px;
	line-height: 65px;
	color: #fff
}
.feature7 .feature7-info {
	display: table
}
.feature7 .feature7-info p {
	color: #fff;
	line-height: 20px
}
.feature-8-title {
	float: left;
	display: table;
	margin-right: 20px
}
.feature-8-title span {
	font-size: 34px;
	color: #c15601
}
.feature-8-info {
	display: table-cell;
	vertical-align: top
}
.feature-8-info p {
	font-size: 14px
}
.feature-8-main .feature-8 {
	margin-bottom: 0;
	padding: 60px 0
}
.feature-8-main .feature-8-info p {
	font-size: 13px
}
.feature-8-main .owl-carousel-10 {
	padding: 0 15px
}
.owl-carousel-10 .owl-nav {
	display: block;
	position: absolute;
	text-indent: inherit;
	left: 0;
	width: 100%;
	cursor: pointer;
	top: 4px
}
.owl-carousel-10 .owl-item {
	cursor: url(../images/client-arrow.png) 25 25, pointer;
	padding: 0 15px
}
.feature-9 {
	padding: 25px 35px;
	position: relative;
	z-index: 9;
	height: 250px
}
.feature-9-bg-2 {
	background: #c15601
}
.feature-9-bg-3 {
	background: #0091bb
}
.feature-9 .feature-9-title-left {
	float: left;
	opacity: .09
}
.feature-9 .feature-9-title-left h1 {
	font-size: 84px;
	line-height: 63px;
	margin-right: 20px;
	color: #000
}
.feature-9 .feature-9-title-right {
	display: table
}
.feature-9-icon {
	position: absolute;
	top: 14px;
	z-index: -1;
	right: 0;
	opacity: .05;
	-webkit-transition: all 1.5s ease-in-out;
	-moz-transition: all 1.5s ease-in-out;
	-o-transition: all 1.5s ease-in-out;
	-ms-transition: all 1.5s ease-in-out;
	transition: all 1.5s ease-in-out
}
.feature-9-icon span {
	font-size: 200px;
	color: #000
}
.feature-9:hover .feature-9-icon {
	right: 52%
}
.feature-10 span {
	font-size: 33px
}
.feature-10 p {
	font-size: 13px
}
.feature-11 .feature-11-icon {
	background: 0 0;
	border: 1px solid #eceff8;
	border-radius: 50%;
	display: block;
	height: 90px;
	margin: 0 auto;
	padding: 9px;
	text-align: center;
	width: 90px;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}
.feature-11 .feature-11-icon span, .feature-15 {
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out
}
.feature-11 .feature-11-icon span {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	color: #363636;
	font-size: 26px;
	text-align: center;
	line-height: 75px;
	background: #eceff8;
	display: inline-block;
	transition: all .3s ease-in-out
}
.feature-11 .feature-11-info h4 {
	position: relative
}
.feature-11 .feature-11-info h4:before {
	position: absolute;
	content: "";
	width: 70px;
	height: 2px;
	background: #c15601;
	margin: 0 auto;
	left: 37%;
	bottom: 0
}
.custom-content-3-info, .features13 p {
	position: relative
}
.feature-11 .feature-11-info p {
	margin-bottom: 0
}
.feature-11:hover .feature-11-icon {
	border-color: #c15601
}
.feature-11:hover .feature-11-icon span {
	background: #c15601;
	color: #fff
}
.feature-12 span {
	font-size: 30px;
	color: #fff;
	margin-right: 20px;
	float: left
}
.feature-12 p {
	display: table
}
.features13 {
	padding-top: 50px;
	padding-right: 25px
}
.features13 p {
	z-index: 9;
	margin-top: 20px;
	margin-bottom: 20px
}
.features13 b, .features13.left b {
	position: absolute;
	z-index: 0;
	top: 83px;
	font-family: Lato, sans-serif
}
.features13 b {
	right: 19px;
	font-size: 72px;
	color: #dde1eb;
	line-height: 72px
}
.features13 ul {
	border-right: 6px solid #dde1eb;
	padding-right: 20px
}
.features13 li {
	color: #626262;
	font-size: 16px;
	line-height: 22px;
	padding: 2px 0 7px
}
.features13.left {
	padding-right: 0;
	padding-left: 25px
}
.features13.left b {
	left: 20px;
	font-size: 72px;
	color: #dde1eb;
	line-height: 72px
}
.features13.left ul {
	border-left: 6px solid #dde1eb;
	padding-left: 20px;
	border-right: 0;
	padding-right: 0
}
.features-14 span {
	color: #fff;
	font-size: 32px;
	margin-bottom: 25px;
	display: block
}
.feature-15 {
	background: #fff;
	padding: 40px 30px;
	transition: all .3s ease-in-out
}
.feature-15 .feature-15-icon {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 2px solid #c15601;
	margin: 0 auto
}
.feature-15 .feature-15-icon img {
	margin-top: 20px
}
.feature-15 .feature-15-content {
	margin-top: 30px
}
.feature-15 .feature-15-content h4 {
	margin-bottom: 30px
}
.feature-15 .feature-15-content p {
	margin-bottom: 0
}
.feature-15:hover {
	box-shadow: 0 0 30px 0 rgba(0,0,0,.1)
}
.feature-16 {
	padding: 0 85px
}
.feature-16 .feature-16-icon {
	display: table-cell
}
.feature-16 .feature-16-icon span {
	font-size: 30px;
	color: #c15601;
	margin-right: 20px
}
.feature-16 .feature-16-contant {
	display: table-cell;
	vertical-align: top
}
.feature-16 .feature-16-contant p {
	font-size: 13px
}
.feature-17 .feature-17-icon {
	border: 20px solid #2b2b2b;
	display: inline-block;
	border-radius: 50%;
	margin-bottom: 30px
}
.feature-17 .feature-17-icon span {
	border: 1px solid #393939;
	width: 130px;
	height: 130px;
	border-radius: 50%;
	line-height: 130px;
	text-align: center;
	background: #323232;
	display: inline-block;
	font-size: 40px;
	color: #fff;
	box-shadow: 0 0 16px rgba(0,0,0,.1)
}
.feature-17 .feature-17-content p {
	font-size: 13px;
	color: #fff;
	padding: 0 20px
}
.feature-18 i {
	display: table-cell;
	float: left;
	margin-right: 15px;
	font-size: 24px
}
.feature-18 h5 {
	display: table-cell;
	margin-bottom: 20px;
	color: #c15601;
	line-height: 22px
}
.feature-18 p {
	margin-top: 20px;
	display: block
}
.feature-19 h1 {
	font-size: 60px;
	color: #323232
}
.feature-19 h3 {
	font-weight: 300;
	margin: 30px 0 20px
}
.feature-19 a {
	font-size: 14px;
	color: #c15601
}
.feature-19 a:hover {
	color: #323232
}
.feature-20 {
	background: #fff;
	padding: 40px 30px;
	-webkit-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}
.feature-20 .feature-20-icon span {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	display: inline-block;
	background: #eceff7;
	border: 0 solid #c15601;
	margin: 0 auto;
	line-height: 80px;
	font-size: 26px;
	color: #626262
}
.feature-20 .feature-20-content {
	margin-top: 30px
}
.feature-20 .feature-20-content h4 {
	margin-bottom: 30px;
	color: #c15601
}
.feature-20 .feature-20-content p {
	margin-bottom: 0
}
.feature-20:hover {
	box-shadow: 0 0 30px 0 rgba(0,0,0,.1)
}
.page-wrapper {
	overflow: hidden;
	background: #e5e3dc
}
.page-wrapper.boxed-layout, body.page-wrapper {
	overflow: inherit
}
.about, .our-blog .blog-box-image, .owl-carousel-2, .portfolio .portfolio-item, .why-choose-us, .wrapper {
	overflow: hidden
}
.custom-content b {
	font-weight: 500;
	font-size: 18px;
	display: block
}
.custom-content span {
	font-size: 15px;
	display: block;
	color: #626262
}
.custom-content li {
	font-size: 14px;
	line-height: 32px;
	color: #626262
}
.custom-content i {
	font-size: 14px;
	color: #c15601;
	padding-right: 10px
}
.custom-content .button {
	font-size: 14px
}
.custom-content .feature-12 span {
	font-size: 30px;
	color: #fff
}
.custom-content .feature-8 span {
	font-size: 30px
}
.custom-content-2 h1 {
	margin-bottom: 0;
	font-size: 2.2em
}
.custom-content-2 strong {
	font-size: 20px;
	font-weight: 500;
	display: block
}
.custom-content-2 p {
	color: #363636
}
.custom-content-2 i {
	padding-left: 10px
}
.custom-content-3 strong {
	font-weight: 500;
	display: block;
	color: #626262
}
.custom-content-3-info p {
	position: relative;
	z-index: 1;
	padding-left: 56px
}
.custom-content-3-info i {
	font-size: 113px;
	color: #eceff8;
	position: absolute;
	z-index: 0;
	left: 0
}
.custom-content-4 .social {
	float: right;
	display: table
}
.custom-content-4 .social li {
	display: inline-block
}
.custom-content-4 .social li a {
	display: block;
	background: #eceff7;
	color: #c15601;
	width: 35px;
	height: 35px;
	font-size: 15px;
	text-align: center;
	line-height: 35px;
	border-radius: 3px;
	margin-left: 5px
}
.custom-content-4 .social li a:hover {
	background: #c15601;
	color: #fff
}
.custom-content-4 .info {
	position: relative
}
.custom-content-4 .info i {
	position: absolute;
	left: 0;
	font-size: 126px;
	color: #eceff7;
	z-index: 0
}
.custom-content-4 .info p {
	position: relative;
	z-index: 9;
	padding-left: 64px;
	font-size: 16px;
	font-style: italic;
	line-height: 28px
}
.owl-custom .custom-content {
	margin-top: 0
}
.owl-custom .custom-content li {
	line-height: 32px
}
.owl-custom .owl-carousel-12.owl-carousel.owl-theme.owl-loaded {
	height: 430px
}
.owl-carousel-12 .owl-dots {
	display: block;
	position: relative;
	text-indent: inherit;
	width: 100%;
	cursor: pointer;
	text-align: center
}
.owl-carousel-12.owl-theme .owl-dots .owl-dot span {
	background: #d0d0d0;
	border-radius: 30px;
	display: block;
	height: 15px;
	margin: 0 7px;
	transition: opacity .2s ease 0s;
	width: 15px;
	cursor: pointer
}
.owl-carousel-12.owl-theme .owl-dots .owl-dot.active span, .owl-carousel-12.owl-theme .owl-dots .owl-dot:hover span {
	background: #c15601
}
.why-choose-us {
	-webkit-transform-style: preserve-3d
}
.owl-carousel .owl-item img, .owl-carousel-8.owl-carousel .owl-item img {
	transform-style: inherit
}
.custom-content-5 .section-title-1 p {
	font-size: 30px;
	padding: 0 100px;
	line-height: 40px
}
.custom-content-5 h4 {
	margin-bottom: 20px;
	color: #626262;
	font-size: 24px
}
.custom-content-5 .skills-2 li h5 {
	position: relative;
	font-style: italic;
	font-size: 18px
}
.custom-content-5 .skills-2 li {
	position: relative;
	margin-bottom: 0;
	font-size: 16px;
	width: 27%;
	float: left;
	margin-right: 70px
}
.action-box-3 .action-box-button .button.button-black, .action-box-button .button.button-black, .custom-content-5 .skills-2 li:last-child {
	margin-right: 0
}
.bar, .custom-content-5 .skills-2 .bar_container {
	position: absolute;
	left: 0;
	height: 7px;
	border-radius: 0;
	content: "";
	top: 30px
}
.custom-content-5 .skills-2 .bar_container {
	bottom: -8px;
	width: 100%;
	background-color: #eceff8;
	text-align: right
}
.custom-content-5 .skills-2 .bar {
	top: 0;
	background: #c15601!important
}
.custom-content-5 .skills-2 .pct {
	position: absolute;
	top: -75px;
	left: 0;
	right: inherit;
	opacity: 0;
	transition: opacity .3s linear;
	color: #323232!important;
	font-size: 26px
}
.custom-content-6 span {
	font-style: italic
}
.custom-content-6 h2 {
	font-weight: 800;
	line-height: 40px;
	margin: 15px 0 20px
}
.custom-content-6 .right-slide img {
	float: left;
	margin-left: 0
}
.custom-content-6 .left-slide {
	padding: 139px 30px
}
.custom-content-7 p, .custom-content-8 #tabs {
	padding: 0 100px
}
.custom-content-8 .ripple {
	display: none
}
.custom-content-8 #tabs ul.tabs li.active, .custom-content-8 #tabs ul.tabs li.active:focus, .custom-content-8 #tabs ul.tabs li:hover {
	background: 0 0;
	color: #c15601
}
.custom-content-8 #tabs .tabs {
	margin-bottom: 30px
}
.custom-content-8 #tabs .tabs li span {
	padding-right: 10px
}
.custom-content-8 #tabs .tabs li {
	font-size: 22px;
	font-weight: 300;
	padding: 10px 50px;
	color: #626262
}
.custom-content-8 #tabs .tabcontent h4 {
	font-weight: 300;
	margin-bottom: 20px;
	text-transform: capitalize
}
.custom-content-10 h2, .custom-content-9 h2 {
	font-weight: 300
}
.custom-content-10 span {
	display: block;
	font-weight: 300
}
.custom-content-11-info {
	padding: 0 100px
}
.custom-content-11 span {
	color: #c15601;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 2px
}
.custom-content-11 h2 {
	font-weight: 300;
	margin: 30px 0;
	line-height: 40px
}
.custom-content-11 a.button span {
	text-transform: inherit;
	letter-spacing: inherit
}
.custom-content-12 .skills-2 li {
	position: relative;
	margin-bottom: 55px;
	font-size: 16px
}
.custom-content-12 .skills-2 li:last-child {
	margin-bottom: 0
}
.bar, .custom-content-12 .skills-2 .bar_container {
	position: absolute;
	left: 0;
	height: 7px;
	border-radius: 3px;
	content: "";
	top: 30px
}
.custom-content-12 .skills-2 .bar_container {
	bottom: -8px;
	width: 100%;
	background-color: #eceff8;
	text-align: right
}
.custom-content-12 .skills-2 .bar {
	top: 0;
	background: #c15601!important
}
.custom-content-12 .skills-2 .pct {
	position: absolute;
	top: -30px;
	right: 0;
	opacity: 0;
	transition: opacity .3s linear;
	color: #323232!important
}
.horizontal-images.content-scrolling ul, .vertical-images.content-scrolling ul {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden
}
.horizontal-images.content-scrolling li {
	width: auto;
	float: left
}
.horizontal-images.content-scrolling li img {
	width: 100%
}
.portfolio .portfolio-item {
	width: 470px;
	position: relative
}
.portfolio .portfolio-item .portfolio-caption {
	background: rgba(0,0,0,.7);
	width: 100%;
	height: 100%;
	padding: 30px;
	position: absolute;
	top: -100px;
	color: #fff
}
.portfolio .portfolio-item .portfolio-caption a {
	display: inline-block;
	color: #fff;
	font-size: 18px
}
.portfolio .portfolio-item .portfolio-caption a span {
	font-size: 15px;
	padding: 10px 0 20px;
	display: block;
	text-transform: capitalize
}
.portfolio .portfolio-item .portfolio-caption a:hover, .portfolio-caption a span:hover {
	color: #c15601
}
.portfolio .portfolio-item .portfolio-overlay a {
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #626262;
	border-radius: 50%;
	margin-right: 10px;
	padding-left: 0;
	text-align: center;
	line-height: 38px
}
.portfolio .portfolio-item .portfolio-overlay a i {
	font-size: 16px
}
.portfolio .portfolio-item .portfolio-overlay a:hover {
	background: #c15601;
	color: #fff
}
.portfolio-main {
	position: relative
}
.portfolio-main .button-border-white span {
	font-size: 14px;
	padding: 7px 12px
}
.owl-carousel-8.owl-carousel {
	z-index: inherit
}
.owl-carousel-8 .item img {
	width: 100%
}
.owl-carousel-8 .owl-nav {
	display: block;
	position: relative;
	text-indent: inherit;
	width: 100%;
	cursor: pointer
}
.owl-carousel-8 .owl-nav .owl-next, .owl-carousel-8 .owl-nav .owl-prev {
	position: absolute;
	width: auto;
	display: block;
	cursor: pointer;
	text-indent: inherit
}
.owl-carousel-8 .owl-nav .owl-prev {
	left: 37%
}
.owl-carousel-8 .owl-nav .owl-next {
	right: 63%
}
.owl-carousel-8 .owl-nav .owl-prev i {
	padding-left: 3px
}
.owl-carousel-8 .owl-nav i {
	font-weight: 400;
	font-size: 18px;
	color: #fff;
	text-align: center;
	line-height: 28px;
	margin: 0 7px;
	-ms-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.blog-box .border, a.blog-btn i {
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s
}
.owl-carousel-8 .owl-nav i:hover {
	color: #363636
}
.owl-carousel-8 .owl-controls {
	position: relative;
	top: -45px;
	z-index: 10
}
.portfolio-info {
	display: block;
	background: #c15601;
	width: 20%;
	left: 20%;
	position: absolute;
	z-index: 9;
	padding: 30px 25px 20px;
	height: 100%
}
.blog-box, .blog-info, a.blog-btn {
	position: relative
}
.portfolio-info h3 {
	font-size: 24px
}
.portfolio-info p {
	margin-top: 20px;
	color: #fff;
	line-height: 22px
}
.blog-box {
	padding: 33px 20px 0;
	background: #eceff7;
	z-index: 1
}
.blog-info {
	z-index: 9
}
.blog-info span {
	color: #626262;
	font-size: 14px;
	margin-right: 6px
}
.blog-info i {
	padding-right: 3px;
	color: #c15601
}
.blog-info p {
	padding: 25px 0 20px
}
a.blog-btn {
	text-align: center;
	font-size: 14px;
	color: #c15601;
	display: block;
	padding-bottom: 12px;
	z-index: 1
}
a.blog-btn i {
	margin-left: 10px;
	-ms-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.blog-box .blog-box-img, .blog-box .border {
	bottom: 0;
	-ms-transition: all .3s ease-out 0s;
	position: absolute;
	z-index: 0;
	left: 0
}
.blog-box .border {
	background: #c15601;
	height: 0;
	width: 100%;
	transition: all .3s ease-out 0s
}
.blog-box .blog-box-img, .blog-box.active .blog-box-img, .blog-box:hover .blog-box-img {
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s
}
.blog-box .blog-box-img {
	opacity: 0;
	right: 0;
	top: 0;
	transition: all .3s ease-out 0s;
	background-size: cover;
	background-position: center center
}
.blog-box.active .blog-box-img, .blog-box:hover .blog-box-img {
	opacity: 1;
	-ms-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.blog-box.active a.blog-btn, .blog-box.active h4, .blog-box.active i, .blog-box.active p, .blog-box.active span, .blog-box:hover a.blog-btn, .blog-box:hover h4, .blog-box:hover i, .blog-box:hover p, .blog-box:hover span {
	color: #fff
}
.blog-box.active .border, .blog-box:hover .border {
	height: 45px
}
.blog-box.active .blog-box-img:before, .blog-box:hover .blog-box-img:before {
	background: rgba(0,0,0,.6);
	color: #fff;
	content: "";
	display: inline-block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1
}
.owl-carousel-3 .owl-nav {
	display: inline-block;
	text-align: center;
	position: relative;
	text-indent: inherit;
	margin-top: 25px;
	width: 100%;
	cursor: pointer
}
.owl-carousel-3 .owl-nav .owl-next, .owl-carousel-3 .owl-nav .owl-prev {
	display: inline-block;
	cursor: pointer
}
.owl-carousel-3 .owl-nav .owl-next i {
	padding-left: 3px
}
.our-blog .container-fluid, .our-blog .container-fluid .row [class*=col-] {
	padding-left: 0!important;
	padding-right: 0!important
}
.owl-carousel-3 .owl-nav i {
	font-weight: 400;
	font-size: 20px;
	color: #626262;
	background: #eceff7;
	text-align: center;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 28px;
	margin: 0 3px;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
#video-background, .blog-box-image .portfolio-item, .our-blog .blog-box-image img {
	width: 100%
}
.owl-carousel-3 .owl-nav i:hover {
	background: #c15601;
	color: #fff
}
.our-blog .container-fluid .row {
	margin-left: 0!important;
	margin-right: 0!important
}
.our-blog .blog-box-image {
	max-height: 300px;
	display: block;
	position: relative
}
.our-blog .blog-box-info {
	background: #c15601;
	padding: 35px 40px;
	max-height: 300px
}
.our-blog .portfolio-item .portfolio-caption {
	background: rgba(0,0,0,.7);
	width: 100%;
	height: 100%;
	padding: 30px;
	position: absolute;
	top: -100px;
	color: #fff
}
.blog-box-image .portfolio-caption a {
	font-size: 20px;
	color: #fff
}
.blog-box-image .portfolio-caption a:hover {
	color: #c15601
}
.blog-box-image .portfolio-caption .port-tag li {
	display: inline-block;
	margin-top: 20px;
	margin-left: 10px
}
.blog-box-image .portfolio-caption .port-tag li a {
	display: block;
	font-size: 14px;
	color: #fff
}
.blog-box-image .portfolio-caption .port-tag li a:hover, .blog-box-image .portfolio-caption .port-tag li i {
	color: #c15601
}
.blog-box-image .portfolio-caption .button-border span {
	font-size: 14px;
	padding: 7px 15px;
	margin-top: 30px
}
.blog-box-image .portfolio-caption .button-border span:hover {
	color: #fff
}
#video-background {
	height: 850px;
	position: relative;
	z-index: 0
}
.testimonial-info h3 {
	padding: 20px 20px 26px;
	margin-bottom: 0;
	font-size: 39px;
	border: 2px solid #fff;
	color: #fff;
	width: 35%;
	line-height: 50px
}
.testimonial-info h4 {
	padding: 25px 0 0;
	margin-bottom: 15px;
	font-size: 30px;
	color: #fff
}
.testimonial-info a, .testimonial-info p {
	padding: 10px 0;
	margin-bottom: 0;
	font-size: 16px;
	color: #fff
}
.testimonial-info strong {
	font-weight: 500;
	font-size: 16px;
	color: #fff
}
.testimonial-info span {
	font-weight: 400;
	color: #fff
}
.owl-carousel-4 .owl-nav {
	display: block;
	position: absolute;
	text-indent: inherit;
	left: 0;
	width: 100%;
	cursor: pointer;
	top: 4px
}
.owl-carousel-4 .owl-nav .owl-next, .owl-carousel-4 .owl-nav .owl-prev {
	width: auto;
	cursor: pointer;
	display: block;
	position: absolute;
	text-indent: inherit
}
.owl-carousel-4 .owl-nav .owl-prev {
	left: 56%
}
.owl-carousel-4 .owl-nav .owl-next {
	right: 56%
}
.owl-carousel-4.owl-nav .owl-prev i {
	padding-left: 3px
}
.owl-carousel-4 .owl-nav i {
	font-weight: 400;
	font-size: 17px;
	color: #626262;
	background: 0 0;
	text-align: center;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 28px;
	margin: 30px 3px 0;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s;
	display: none
}
.owl-carousel-4 .owl-nav i:hover {
	background: 0 0;
	color: #c15601
}
.owl-carousel-2 .owl-nav {
	display: block;
	position: absolute;
	text-indent: inherit;
	top: 46%;
	left: 0;
	width: 100%;
	cursor: pointer
}
.owl-carousel-2 .owl-nav .owl-next, .owl-carousel-2 .owl-nav .owl-prev {
	display: block;
	width: auto;
	position: absolute;
	cursor: pointer;
	text-indent: inherit
}
.owl-carousel-2 .owl-nav .owl-prev {
	left: -44px;
	-webkit-transition: opacity .3s ease 0s, left .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, left .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, left .3s ease 0s;
	-o-transition: opacity .3s ease 0s, left .3s ease 0s;
	transition: opacity .3s ease 0s, left .3s ease 0s
}
.owl-carousel-2 .owl-nav .owl-next {
	right: -44px;
	-webkit-transition: opacity .3s ease 0s, right .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, right .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, right .3s ease 0s;
	-o-transition: opacity .3s ease 0s, right .3s ease 0s;
	transition: opacity .3s ease 0s, right .3s ease 0s
}
.owl-carousel-2 .owl-nav i {
	font-weight: 400;
	font-size: 20px;
	color: #626262;
	background: rgba(255,255,255,.7);
	padding: 12px;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.owl-carousel-2 .owl-nav i:hover {
	color: #626262;
	background: rgba(255,255,255,1)
}
.who-we-are-left:hover .owl-carousel-2 .owl-nav .owl-prev {
	left: 0
}
.who-we-are-left:hover .owl-carousel-2 .owl-nav .owl-next {
	right: 0
}
.who-we-are .who-we-are-left img {
	width: 100%
}
.who-we-are-right h4 {
	margin-top: 0;
	font-size: 25px
}
.who-we-are-right a {
	font-weight: 600;
	color: #c15601
}
.who-we-are-right-button a.button i {
	padding: 13px 10px;
	width: 46px;
	font-size: 14px
}
.who-we-are-right-button a.button span {
	font-size: 13px;
	padding: 11px 15px
}
ul.who-we-are-about i {
	color: #c15601;
	padding-right: 10px
}
ul.who-we-are-about li {
	font-size: 14px;
	line-height: 30px
}
.tooltip-s {
	position: relative;
	float: right
}
.tooltip-s .tooltip-inner {
	background-color: transparent;
	padding: 5px 15px;
	color: #363636;
	font-weight: 700;
	font-size: 13px
}
.popOver+.tooltip-s .tooltip-arrow {
	border: none
}
.progress {
	border-radius: 0;
	overflow: visible;
	height: 10px;
	margin-bottom: 30px;
	background: #eceff7;
	box-shadow: none
}
.our-activities .skills-2 b {
	font-weight: 300;
	font-size: 16px;
	display: inline-block;
	margin-bottom: 5px;
	text-transform: capitalize
}
.accordion .accordion-title {
	font-size: 16px;
	color: #000
}
.accordion .accordion-title a {
	background: 0 0;
	border: 1px solid #eceff7;
	display: block;
	position: relative;
	color: #626262;
	margin-top: -1px;
	text-decoration: none;
	padding: 11px 60px;
	font-weight: 400;
	-webkit-transition: all .27s cubic-bezier(0, 0, .58, 1);
	-moz-transition: all .27s cubic-bezier(0, 0, .58, 1);
	-o-transition: all .27s cubic-bezier(0, 0, .58, 1);
	-ms-transition: all .27s cubic-bezier(0, 0, .58, 1);
	transition: all .27s cubic-bezier(0, 0, .58, 1)
}
.accordion .accordion-title a:hover {
	text-decoration: none;
	background-color: transparent;
	color: #c15601
}
.accordion .accordion-title a.active {
	color: #fff;
	background-color: #c15601;
	cursor: default;
	border-color: #c15601
}
.accordion .accordion-title a:after {
	content: "\f067";
	width: 20px;
	height: 20px;
	margin-top: -8px;
	position: absolute;
	top: 50%;
	left: 20px;
	font-family: FontAwesome;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #626262;
	-webkit-font-smoothing: antialiased;
	-webkit-transition: all .27s cubic-bezier(0, 0, .58, 1);
	-moz-transition: all .27s cubic-bezier(0, 0, .58, 1);
	-o-transition: all .27s cubic-bezier(0, 0, .58, 1);
	-ms-transition: all .27s cubic-bezier(0, 0, .58, 1);
	transition: all .27s cubic-bezier(0, 0, .58, 1)
}
.accordion .accordion-title a.active:after, .accordion .accordion-title a.active:hover:after {
	content: "\f068";
	color: #fff
}
.accordion .accordion-title a:hover:after {
	color: #c15601
}
.accordion-content {
	padding: 15px 20px;
	border: 1px solid #eceff7
}
.action-box {
	background: #c15601;
	padding: 48px 0 40px;
	position: relatfive
}
.action-box-button a.button i {
	padding: 13px 10px;
	width: 46px;
	font-size: 14px
}
.action-box-button a.button span {
	font-size: 13px;
	padding: 11px 12px
}
.action-box-2 {
	padding: 60px 0 45px
}
.action-box-3 {
	padding: 0;
	position: relative;
	background: #e5e3dc
}
.action-box-3:before {
	content: "\f0de";
	font-family: FontAwesome;
	top: -35px;
	left: 20%;
	width: 0;
	height: 0;
	font-size: 70px;
	position: relative;
	z-index: 1;
	color: #e5e3dc;
	float: left
}
.feature-mob, .mob-scroll {
	display: inline-block;
	height: 630px;
	position: relative
}
.action-box-3 h3 {
	line-height: 36px
}
.action-box-3 .action-box-button a.button i {
	padding: 13px 10px;
	width: 46px;
	font-size: 14px
}
.action-box-3 .action-box-button a.button span {
	font-size: 13px;
	padding: 11px 12px
}
.feature-main .container-fluid, .feature-main .container-fluid .row [class*=col-] {
	padding-left: 0!important;
	padding-right: 0!important
}
.feature-main .container-fluid .row {
	margin-left: 0!important;
	margin-right: 0!important
}
.feature-mob {
	background: url(../images/feature/05.png) no-repeat;
	width: 100%
}
.mob-scroll {
	width: 282px
}
.mob-scroll:hover .feature-mob-scroll {
	background-position: center 100%!important
}
.feature-mob .feature-mob-scroll {
	background-position: center 0;
	background-repeat: no-repeat;
	background-size: cover;
	height: 448px;
	left: 30px;
	position: absolute;
	top: 58px;
	width: 252px;
	transition: all 5s ease-out 0s;
	-webkit-transition: all 5s ease-out 0s;
	-o-transition: all 5s ease-out 0s;
	-moz-transition: all 5s ease-out 0s;
	-ms-transition: all 5s ease-out 0s
}
.key-features-device {
	position: absolute;
	left: -260px;
	top: -20px
}
.dropping-words {
	display: inline;
	text-indent: 10px
}
.dropping-words-1 span {
	position: absolute;
	opacity: 0;
	text-transform: capitalize;
	height: 45px;
	overflow: hidden;
	color: #c15601;
	-webkit-transform-origin: 10% 75%;
	transform-origin: 10% 75%;
	-webkit-animation: rotateWord 18s linear infinite 0s;
	-ms-animation: rotateWord 18s linear infinite 0s;
	-moz-animation: rotateWord 18s linear infinite 0s;
	-o-animation: rotateWord 18s linear infinite 0s;
	animation: rotateWord 18s linear infinite 0s
}
.awesome-features, .feature-item, .feature-preview {
	position: relative
}
.dropping-words span:nth-child(2) {
	-webkit-animation-delay: 3s;
	-ms-animation-delay: 3s;
	-o-animation-delay: 3s;
	-moz-animation-delay: 3s;
	animation-delay: 3s
}
.dropping-words span:nth-child(3) {
	-webkit-animation-delay: 6s;
	-ms-animation-delay: 6s;
	-o-animation-delay: 6s;
	-moz-animation-delay: 6s;
	animation-delay: 6s
}
.dropping-words span:nth-child(4) {
	-webkit-animation-delay: 9s;
	-ms-animation-delay: 9s;
	-o-animation-delay: 9s;
	-moz-animation-delay: 9s;
	animation-delay: 9s
}
.dropping-words span:nth-child(5) {
	-webkit-animation-delay: 12s;
	-ms-animation-delay: 12s;
	-o-animation-delay: 12s;
	-moz-animation-delay: 12s;
	animation-delay: 12s
}
.dropping-words span:nth-child(6) {
	-webkit-animation-delay: 15s;
	-ms-animation-delay: 15s;
	-o-animation-delay: 15s;
	-moz-animation-delay: 15s;
	animation-delay: 15s
}
@-webkit-keyframes rotateWord {
0%, 100%, 80% {
opacity:0
}
5% {
opacity:1
}
17% {
opacity:1;
-webkit-transform:rotate(0)
}
19% {
opacity:1;
-webkit-transform:rotate(98deg)
}
21% {
opacity:1;
-webkit-transform:rotate(86deg)
}
23% {
opacity:1;
-webkit-transform:translateY(85px) rotate(83deg)
}
25% {
opacity:0;
-webkit-transform:translateY(170px) rotate(80deg)
}
}
@-ms-keyframes rotateWord {
17%, 19%, 21%, 23%, 5% {
opacity:1
}
0%, 100%, 25%, 80% {
opacity:0
}
17% {
-ms-transform:rotate(0)
}
19% {
-ms-transform:rotate(98deg)
}
21% {
-ms-transform:rotate(86deg)
}
23% {
-ms-transform:translateY(85px) rotate(83deg)
}
25% {
-ms-transform:translateY(170px) rotate(80deg)
}
}
@-o-keyframes rotateWord {
0%, 100%, 80% {
opacity:0
}
5% {
opacity:1
}
17% {
opacity:1;
-o-transform:rotate(0)
}
19% {
opacity:1;
-o-transform:rotate(98deg)
}
21% {
opacity:1;
-o-transform:rotate(86deg)
}
23% {
opacity:1;
-o-transform:translateY(85px) rotate(83deg)
}
25% {
opacity:0;
-o-transform:translateY(170px) rotate(80deg)
}
}
@-moz-keyframes rotateWord {
0%, 100%, 80% {
opacity:0
}
5% {
opacity:1
}
17% {
opacity:1;
-moz-transform:rotate(0)
}
19% {
opacity:1;
-moz-transform:rotate(98deg)
}
21% {
opacity:1;
-moz-transform:rotate(86deg)
}
23% {
opacity:1;
-moz-transform:translateY(85px) rotate(83deg)
}
25% {
opacity:0;
-moz-transform:translateY(170px) rotate(80deg)
}
}
@keyframes rotateWord {
0%, 100%, 80% {
opacity:0
}
5% {
opacity:1
}
17% {
opacity:1;
-webkit-transform:rotate(0);
transform:rotate(0)
}
19% {
opacity:1;
-webkit-transform:rotate(98deg);
transform:rotate(98deg)
}
21% {
opacity:1;
-webkit-transform:rotate(86deg);
transform:rotate(86deg)
}
23% {
opacity:1;
-webkit-transform:translateY(85px) rotate(83deg);
transform:translateY(85px) rotate(83deg)
}
25% {
opacity:0;
-webkit-transform:translateY(170px) rotate(80deg);
transform:translateY(170px) rotate(80deg)
}
}
.feature-item {
	clear: both;
	zoom: 1
}
.feature-item:after, .feature-item:before {
	content: "";
	display: table
}
.feature-item:after {
	clear: both
}
.feature-details {
	float: right;
	width: 40%;
	padding-left: 20px
}
.feature-annotations, .feature-item img {
	width: 100%;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out
}
.feature-item:first-child .feature-details {
	background: url(../images/try.html) bottom left no-repeat;
	padding-bottom: 60px
}
.feature-details h2 {
	color: #498EA5;
	margin-top: 0;
	text-shadow: 1px 1px 1px rgba(255,255,255,.5);
	padding-bottom: 10px;
	box-shadow: 0 1px 0 #DFDEDC, 0 2px 0 rgba(255,255,255,.5)
}
.feature-details p {
	color: #999;
	text-shadow: 1px 1px 1px rgba(255,255,255,.8);
	line-height: 22px
}
.feature-details p a {
	font-weight: 700;
	color: #498EA5
}
.feature-details p a:hover {
	color: #2A3344
}
.feature-item-left .feature-details {
	float: left;
	padding-right: 20px;
	padding-left: 0;
	text-align: right
}
.feature-item img {
	margin: 0 auto;
	display: block;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	filter: alpha(opacity=80);
	transition: all .3s ease-in-out
}
.feature-annotations, .feature-annotations span {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	position: absolute;
	opacity: 0
}
.feature-annotations {
	height: 100%;
	top: 0;
	left: 0;
	filter: alpha(opacity=0);
	z-index: 5;
	-webkit-transform: scale(.8);
	-moz-transform: scale(.8);
	-o-transform: scale(.8);
	-ms-transform: scale(.8);
	transform: scale(.8);
	transition: all .3s ease-in-out
}
.feature-annotations span {
	display: block;
	padding: 12px 8px 0;
	text-align: center;
	font-size: 16px;
	font-style: italic;
	filter: alpha(opacity=0);
	-webkit-transform: scale(1.3);
	-moz-transform: scale(1.3);
	-o-transform: scale(1.3);
	-ms-transform: scale(1.3);
	transform: scale(1.3);
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}
.feature-annotations span:after {
	background: url(../images/001.png) no-repeat;
	content: "";
	height: 73px;
	left: 100%;
	position: absolute;
	top: 50%;
	width: 190px
}
.feature-annotations span:nth-child(1) {
	top: 5%;
	left: 5%
}
.feature-annotations span:nth-child(2) {
	top: 20%;
	left: -13%
}
.feature-annotations span:nth-child(3) {
	top: 7%;
	right: 2%
}
.feature-annotations span:nth-child(4) {
	top: 53%;
	right: -8%
}
.feature-annotations span:nth-child(5) {
	bottom: 25%;
	left: -4%
}
.feature-annotations span:nth-child(6) {
	top: -6%;
	left: 0;
	right: 0
}
.feature-item:nth-child(2) .feature-annotations span:nth-child(1) {
	top: 12%;
	left: 37%
}
.feature-item:nth-child(2) .feature-annotations span:nth-child(2) {
	top: 32%;
	left: -5%
}
.feature-item:nth-child(2) .feature-annotations span:nth-child(3) {
	top: 40%;
	right: -5%
}
.feature-annotations span:nth-child(3):after, .feature-annotations span:nth-child(4):after, .feature-item:nth-child(2) .feature-annotations span:nth-child(1):after {
	left: auto;
	right: 100%;
	background-image: url(../images/002.png)
}
.feature-item:nth-child(1) .feature-annotations span:nth-child(6):after {
	left: 50%;
	background-image: url(../images/003.png);
	margin-top: 25px;
	height: 150px
}
input.feature-toggle {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	z-index: 100;
	border: none;
	cursor: pointer
}
input.feature-toggle:checked+img {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=99)";
	filter: alpha(opacity=99);
	opacity: 1;
	-webkit-transform: scale(.8);
	-moz-transform: scale(.8);
	-o-transform: scale(.8);
	-ms-transform: scale(.8);
	transform: scale(.8)
}
input.feature-toggle:hover+img {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=99)";
	filter: alpha(opacity=99);
	opacity: 1
}
input.feature-toggle:checked~.feature-annotations, input.feature-toggle:checked~.feature-annotations span {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=99)";
	filter: alpha(opacity=99);
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1)
}
input.feature-toggle:checked~.feature-annotations span:nth-child(1) {
	-webkit-transition-delay: .3s;
	-moz-transition-delay: .3s;
	-o-transition-delay: .3s;
	-ms-transition-delay: .3s;
	transition-delay: .3s
}
input.feature-toggle:checked~.feature-annotations span:nth-child(2) {
	-webkit-transition-delay: .6s;
	-moz-transition-delay: .6s;
	-o-transition-delay: .6s;
	-ms-transition-delay: .6s;
	transition-delay: .6s
}
input.feature-toggle:checked~.feature-annotations span:nth-child(3) {
	-webkit-transition-delay: .8s;
	-moz-transition-delay: .8s;
	-o-transition-delay: .8s;
	-ms-transition-delay: .8s;
	transition-delay: .8s
}
input.feature-toggle:checked~.feature-annotations span:nth-child(4) {
	-webkit-transition-delay: 1s;
	-moz-transition-delay: 1s;
	-o-transition-delay: 1s;
	-ms-transition-delay: 1s;
	transition-delay: 1s
}
input.feature-toggle:checked~.feature-annotations span:nth-child(5) {
	-webkit-transition-delay: 1.2s;
	-moz-transition-delay: 1.2s;
	-o-transition-delay: 1.2s;
	-ms-transition-delay: 1.2s;
	transition-delay: 1.2s
}
input.feature-toggle:checked~.feature-annotations span:nth-child(6) {
	-webkit-transition-delay: 1.2s;
	-moz-transition-delay: 1.2s;
	-o-transition-delay: 1.2s;
	-ms-transition-delay: 1.2s;
	transition-delay: 1.4s
}
@media screen and (max-width:730px) {
.feature-item .feature-details, .feature-preview {
	float: none;
	width: 100%;
	padding: 0;
	text-align: left
}
.feature-annotations span {
	font-size: 11px
}
}


@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
	.partipate-response {

		float:inherit;
		position: relative;
		top: -23px;

	}

}
.awesome-features-device {
	position: relative;
	width: 100%
}
.awesome-features-device img {
	position: absolute;
	left: -250px;
	top: 50px;
	display: block
}
.key-features-2 .features-device .row [class*=col-] {
	padding-left: 0!important;
	padding-right: 0!important
}
.key-features-2 .features-device {
	margin-top: 310px;
	position: relative;
	z-index: 0
}
.key-features-2 .features-device img.device-1 {
	position: absolute;
	bottom: 0;
	bottom: -130px;
	left: 163px;
	z-index: 1;
	transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out
}
.key-features-2 .features-device img.device-2 {
	position: absolute;
	bottom: -80px;
	z-index: 1;
	left: 120px;
	transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out
}
.key-features-2 .features-device img.device-3 {
	position: absolute;
	bottom: -100px;
	z-index: 0;
	transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out
}
.key-features-2 .features-device img.device-4 {
	position: absolute;
	bottom: -160px;
	left: -140px;
	transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out
}
.news .meta ul li, .news .news-img {
	-webkit-transition: all .6s ease-out 0s;
	-moz-transition: all .6s ease-out 0s;
	-ms-transition: all .6s ease-out 0s;
	-o-transition: all .6s ease-out 0s
}
.key-features-2 .features-device img.device-1:hover {
	bottom: -110px
}
.key-features-2 .features-device img.device-2:hover {
	bottom: -60px
}
.key-features-2 .features-device img.device-3:hover {
	bottom: -80px
}
.key-features-2 .features-device img.device-4:hover {
	bottom: -140px
}
.service-image-holder {
	position: relative;
	height: 496px
}
.service-image-holder .section-title-2 {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.services-text-box {
	background: #c15601;
	padding: 90px 70px 40px 90px
}
.counter-main b {
	font-size: 38px;
	display: block;
	color: #fff;
	font-family: Lato, sans-serif;
	font-weight: 700;
	margin: 10px 0
}
.counter-main h5, .news-letter p {
	margin-bottom: 0
}
.counter-main span {
	font-size: 40px;
	color: #fff
}
.creativity h1 {
	line-height: 65px;
	text-transform: uppercase;
	font-size: 50px;
	font-weight: 700
}
.creativity p {
	font-size: 30px
}
.creativity .button {
	display: inline-block;
	float: none
}
.news-letter {
	padding: 50px 0
}
.news-letter .section-field {
	position: relative;
	display: block
}
.news-letter .section-field i {
	position: absolute;
	left: 0;
	padding: 15px;
	font-size: 14px;
	color: #363636
}
.news-letter .section-field input {
	padding-left: 35px;
	color: #363636;
	background: 0 0;
	border: 1px solid #363636;
	width: 80%;
	border-radius: 3px
}
.social-full li a, .social-full li a.fb {
	background: #3a5897
}
.news-letter .section-field input:focus {
	border: 1px solid #c15601
}
.social-full .container-fluid {
	padding: 0
}
.social-full li {
	display: inline-block;
	float: left;
	width: 12.5%
}
.social-full li a {
	height: 80px;
	display: block;
	text-align: center;
	line-height: 80px;
	color: #fff;
	font-size: 30px
}
.social-full li a.fb:hover {
	box-shadow: 250px 0 0 #1e3667 inset
}
.social-full li a.twitter {
	background: #41d1da
}
.social-full li a.twitter:hover {
	box-shadow: 250px 0 0 #249da5 inset
}
.social-full li a.pinterest {
	background: #c3222b
}
.social-full li a.pinterest:hover {
	box-shadow: 250px 0 0 #9d141c inset
}
.social-full li a.vimeo {
	background: #41b2dc
}
.social-full li a.vimeo:hover {
	box-shadow: 250px 0 0 #2288ae inset
}
.social-full li a.dribbble {
	background: #dd4b80
}
.social-full li a.dribbble:hover {
	box-shadow: 250px 0 0 #9e234f inset
}
.social-full li a.linkedin {
	background: #0274b3
}
.social-full li a.linkedin:hover {
	box-shadow: 250px 0 0 #025482 inset
}
.social-full li a.rss {
	background: #f90
}
.social-full li a.rss:hover {
	box-shadow: 250px 0 0 #c57805 inset
}
.social-full li a.behance {
	background: #005cff
}
.social-full li a.behance:hover {
	box-shadow: 250px 0 0 #0046c3 inset
}
.news {
	background: #eceff7;
	padding: 50px 60px 50px 40px;
	position: relative;
	z-index: 0
}
.news .meta ul li {
	display: inline-block;
	font-size: 14px;
	transition: all .6s ease-out 0s
}
.news .meta a {
	display: block;
	font-size: 26px;
	font-weight: 300;
	color: #626262;
	margin-top: 10px
}
.news .button {
	margin-top: 25px
}
.news .button a {
	text-transform: capitalize;
	font-size: 15px;
	color: #363636;
	position: relative
}
.news .button i {
	opacity: 0;
	position: absolute;
	left: 105px;
	margin-top: 0;
	top: 5px;
	transition: all .6s ease-in-out;
	-webkit-transition: all .6s ease-in-out;
	-ms-transition: all .6s ease-in-out;
	-moz-transition: all .6s ease-in-out;
	-o-transition: all .6s ease-in-out;
	font-size: 12px
}
.news .button a:hover {
	color: #c15601
}
.news .button a:hover i {
	left: 120px;
	opacity: 1;
	color: #c15601
}
.news .news-img {
	bottom: 0;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: all .6s ease-out 0s;
	z-index: 0;
	background-size: cover;
	background-position: center center
}
.news.active .news-img, .news:hover .news-img {
	opacity: 1
}
.news-content {
	position: relative;
	z-index: 9
}
.news.active:before, .news:hover:before {
	background: rgba(0,0,0,0);
	color: #fff;
	content: "";
	display: inline-block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
	-webkit-transition: all .6s ease-out 0s;
	-moz-transition: all .6s ease-out 0s;
	-ms-transition: all .6s ease-out 0s;
	-o-transition: all .6s ease-out 0s;
	transition: all .6s ease-out 0s
}
.news.active .button a, .news.active .meta a, .news.active .meta li, .news:hover .button a, .news:hover .meta a, .news:hover .meta li, .news:hover .meta span {
	color: #fff
}
.news:hover .button a:hover, .news:hover .meta a:hover {
	color: #c15601
}
.latest-news .all-post a {
	color: #c15601;
	font-size: 14px
}
.latest-news .all-post a i {
	padding-left: 10px
}
.latest-news .all-post a:hover {
	color: #323232
}
.experience h1 {
	color: #282828;
	font-size: 180px;
	line-height: 150px;
	font-weight: 700
}
.experience span {
	color: #c15601;
	display: block;
	margin: 10px 0 25px;
	font-size: 14px
}
.experience p {
	padding-right: 30px
}
.experience .owl-theme .owl-dots {
	text-align: center;
	margin-top: 15px
}
.experience .owl-theme .owl-dots .owl-dot span {
	background: 0 0;
	border: 3px solid #282828;
	border-radius: 0;
	width: 20px;
	height: 20px;
	cursor: pointer;
	margin-top: 15px;
	margin-bottom: 0
}
.experience .owl-theme .owl-dots .owl-dot.active span, .experience .owl-theme .owl-dots .owl-dot:hover span {
	background: #282828;
	border-color: #282828
}
.inner-intro {
	height: 410px;
	display: block
}
.inner-intro .container {
	display: table;
	height: 100%;
	position: relative
}
.inner-intro h1, .inner-intro span {
	text-transform: capitalize
}
.intro-title {
	display: table-cell;
	vertical-align: bottom
}
.intro-title p {
	font-size: 18px;
	text-transform: capitalize
}
ul.page-breadcrumb li span {
	color: #929292;
	font-size: 14px;
	text-transform: capitalize
}
ul.page-breadcrumb {
	margin-left: -40px;
    margin-top: 20px;
}
ul.page-breadcrumb li {
	color: #ccc;
    display: inline-block;
 	font-size: 13px;
    list-style: outside none none;
    margin-right: 20px;
    text-transform: uppercase;
}
ul.page-breadcrumb li i {
	font-size: 13px;
	padding: 0 7px
}
ul.page-breadcrumb li a {
	color: #808080;
	font-size: 13px;
	text-transform: capitalize
}
ul.page-breadcrumb li a:hover {
	color: #c15601
}
.sidebar-widget p {
	margin-bottom: 0
}
.widget-search {
	position: relative
}
.widget-search input {
	padding-right: 30px;
	color: #626262;
	height: 45px;
	border-color: #ccc
}
.widget-search i {
	position: absolute;
	right: 0;
	padding: 16px 12px;
	font-size: 14px;
	color: #626262;
	cursor: pointer
}
.widget-search input:focus {
	border-color: #df6708!important
}
.widget-menu {
	position: relative
}
.widget-menu ul li a {
	font-size: 14px;
	display: block;
	color: #626262;
	position: relative
}
.widget-menu i {
	padding-top: 4px;
	position: absolute;
	right: 18px;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out
}
.widget-menu ul li a span {
	padding: 5px 10px 5px 0;
	display: block;
	font-size: 14px
}
.widget-menu ul li a span:hover {
	text-decoration: none;
	color: #c15601
}
/*.fnt-12:hover, .footer-hedding li a:hover, .footer_copy a:hover {
	text-decoration: underline!important
}*/
.widget-menu ul li.active {
	border-bottom: none
}
.widget-menu ul li.hover a {
	color: #c15601
}
.widget-menu ul li.hover a span {
	background: 0 0;
	color: #c15601
}
.widget-menu ul ul li a span {
	color: #626262
}
.widget-menu ul ul li a span:hover {
	color: #c15601
}
.widget-menu ul ul {
	display: none;
	padding-left: 20px
}
.widget-menu ul ul a {
	display: block;
	color: #626262;
	font-size: 14px
}
.widget-menu ul ul a:hover {
	color: #a24800
}
.sidebar-widget .recent-post {
	float: left;
	width: 100%
}
.sidebar-widget .recent-post-info span {
	padding-top: 4px
}
.sidebar-widget .recent-post-info a {
	color: #626262
}
.sidebar-widget .recent-post-info a:hover, .sidebar-widget .recent-post-info i {
	color: #c15601
}
.sidebar-widget .recent-post:last-child {
	margin-bottom: 40px!important
}
.widget-archives li {
	display: block;
	margin-bottom: 13px
}
.widget-archives li a {
	font-size: 14px;
	color: #626262;
	line-height: 32px;
	display: block
}
.widget-archives li i {
	font-size: 16px;
	line-height: 17px;
	padding-left: 2px;
	margin-right: 20px;
	border: 1px solid #626262;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	text-align: center
}
.widget-archives li a:hover {
	color: #c15601
}
.widget-archives li a:hover i {
	border-color: #c15601;
	color: #c15601
}
.sidebar-widget .tags li a:hover {
	background: #c15601;
	border-color: #c15601;
	color: #fff
}
.owl-carousel-7 img {
	width: 100%
}
.owl-carousel-7 .owl-nav {
	display: block;
	position: absolute;
	text-indent: inherit;
	top: -50px;
	left: 0;
	width: 100%;
	cursor: pointer
}
.owl-carousel-7 .owl-nav .owl-next, .owl-carousel-7 .owl-nav .owl-prev {
	display: block;
	width: auto;
	position: absolute;
	cursor: pointer;
	text-indent: inherit
}
.owl-carousel-7 .owl-nav .owl-prev {
	right: 0;
	-webkit-transition: opacity .3s ease 0s, left .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, left .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, left .3s ease 0s;
	-o-transition: opacity .3s ease 0s, left .3s ease 0s;
	transition: opacity .3s ease 0s, left .3s ease 0s
}
.owl-carousel-7 .owl-nav .owl-next {
	right: 30px;
	-webkit-transition: opacity .3s ease 0s, right .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, right .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, right .3s ease 0s;
	-o-transition: opacity .3s ease 0s, right .3s ease 0s;
	transition: opacity .3s ease 0s, right .3s ease 0s
}
.owl-carousel-7 .owl-nav i {
	font-weight: 400;
	font-size: 20px;
	color: #626262;
	background: #eceff7;
	padding: 5px 10px;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.owl-carousel-7 .owl-nav i:hover {
	color: #fff;
	background: #c15601
}
.related-work .portfolio-item {
	width: 100%
}
.related-work .portfolio-item .portfolio-overlay {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.isotope-filters {
	display: table;
	margin: 0 auto 50px;
	text-align: center
}
.isotope-filters button {
	margin: 4px;
	cursor: pointer;
	color: #626262;
	padding: 5px 18px;
	font-size: 16px;
	border-radius: 3px;
	background: 0 0;
	border: 1px solid #626262;
	text-transform: capitalize;
	transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out
}
.isotope-filters button:focus {
	outline: 0;
	outline-offset: 0
}
.isotope-filters button.active, .isotope-filters button:hover {
	background: #c15601;
	color: #fff;
	border-color: #c15601
}
.portfolio-block .portfolio-item {
	position: relative;
	overflow: hidden
}
.portfolio-block .portfolio-item .portfolio-caption {
	background: rgba(0,0,0,.7);
	width: 100%;
	height: 100%;
	padding: 30px;
	position: absolute;
	top: -100px;
	color: #fff
}
.portfolio-block .portfolio-item .portfolio-caption a {
	display: inline-block;
	color: #fff;
	font-size: 18px
}
.portfolio-block .portfolio-item .portfolio-caption a span {
	font-size: 15px;
	padding: 10px 0 20px;
	display: block;
	color: #c15601;
	text-transform: capitalize
}
.portfolio-block .portfolio-item .portfolio-caption a:hover, .portfolio-caption a span:hover {
	color: #c15601
}
.portfolio-block .portfolio-item .portfolio-overlay a {
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #626262;
	border-radius: 50%;
	padding-left: 0;
	text-align: center;
	line-height: 38px
}
.portfolio-block .portfolio-item .portfolio-overlay a i {
	font-size: 16px
}
.portfolio-block .portfolio-item .portfolio-overlay a:hover {
	background: #c15601;
	color: #fff
}
.portfolio-block .grid-item {
	position: relative;
	height: auto;
	margin-bottom: 40px;
	width: 100%
}
.portfolio-block .grid-item img {
	width: 100%
}
.portfolio-block ul.port-tag li {
	display: inline-block
}
.portfolio-block ul.port-tag li a {
	display: block;
	font-size: 14px;
	color: #626262;
	padding-right: 5px
}
.portfolio-block ul.port-tag li a:hover {
	color: #c15601
}
.portfolio-block ul.list-style li {
	display: block
}
.portfolio-block ul.list-style li a {
	font-size: 14px;
	color: #626262;
	padding-right: 5px
}
.portfolio-block ul.list-style li i {
	padding-right: 10px
}
.portfolio-block ul.list-style li a:hover {
	color: #c15601
}
.portfolio-block .portfolio-item {
	width: 100%
}
.portfolio-block .portfolio-item .portfolio-overlay {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.portfolio-sidebar.portfolio-block a.title {
	background: 0 0;
	width: 100%;
	color: #fff;
	padding-left: 0
}
.portfolio-sidebar.portfolio-block a.title:hover {
	background: 0 0!important;
	color: #c15601
}
.portfolio-sidebar.portfolio-block ul.port-tag {
	margin-bottom: 10px
}
.portfolio-sidebar.portfolio-block ul.port-tag li a {
	background: 0 0;
	color: #fff;
	margin-right: 20px
}
.portfolio-sidebar.portfolio-block ul.port-tag li a:hover {
	background: 0 0;
	color: #c15601
}
.portfolio-sidebar .button-group.filters-button-group {
	margin-bottom: 42px
}
.portfolio-fullwidth.portfolio-block.columns-2 .grid-item {
	margin-bottom: 0;
	width: 50%
}
.portfolio-fullwidth.portfolio-block.columns-2 a.title {
	background: 0 0!important;
	width: 100%;
	color: #fff
}
.portfolio-fullwidth.portfolio-block.columns-2 a.title:hover {
	background: 0 0!important;
	width: 100%;
	color: #c15601
}
.portfolio-fullwidth.portfolio-block.columns-2 ul.port-tag {
	margin-bottom: 10px
}
.portfolio-fullwidth.portfolio-block.columns-2 ul.port-tag li a {
	background: 0 0;
	color: #fff;
	margin-right: 20px
}
.portfolio-fullwidth.portfolio-block.columns-2 ul.port-tag li a:hover {
	background: 0 0;
	color: #c15601
}
.portfolio-fullwidth.columns-2 .button-group.filters-button-group {
	margin-bottom: 42px
}
.portfolio-sidebar.portfolio-block.columns-2 .grid-item {
	margin-bottom: 0;
	width: 50%
}
.portfolio-sidebar.portfolio-block.columns-2 .portfolio-item .portfolio-overlay {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.portfolio-sidebar.portfolio-block.columns-3 .grid-item {
	margin-bottom: 0;
	width: 33.33%
}
.portfolio-sidebar.portfolio-block.columns-3 .portfolio-item .portfolio-overlay {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.portfolio-fullwidth.portfolio-block.columns-3 .grid-item {
	margin-bottom: 0;
	width: 33.33%
}
.portfolio-fullwidth.portfolio-block.columns-3 a.title {
	background: 0 0!important;
	width: 100%;
	color: #fff
}
.portfolio-fullwidth.portfolio-block.columns-3 a.title:hover {
	background: 0 0;
	width: 100%;
	color: #c15601
}
.portfolio-fullwidth.portfolio-block.columns-3 ul.port-tag {
	margin-bottom: 10px
}
.portfolio-fullwidth.portfolio-block.columns-3 ul.port-tag li a {
	background: 0 0;
	color: #fff;
	margin-right: 20px
}
.portfolio-fullwidth.portfolio-block.columns-3 ul.port-tag li a:hover {
	background: 0 0;
	color: #c15601
}
.portfolio-fullwidth.columns-3 .button-group.filters-button-group {
	margin-bottom: 42px
}
.portfolio-sidebar.portfolio-block.columns-4 .grid-item {
	margin-bottom: 0;
	width: 25%
}
.portfolio-sidebar.portfolio-block.columns-4 .portfolio-item .portfolio-overlay {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.portfolio-sidebar.portfolio-block.columns-4 ul.port-tag {
	margin-bottom: 3px
}
.portfolio-sidebar.portfolio-block.columns-4 ul.port-tag li a {
	font-size: 13px
}
.portfolio-sidebar.portfolio-block.columns-4 .portfolio-overlay a {
	height: 30px;
	line-height: 26px;
	margin-left: 0;
	padding-left: 0;
	text-align: center;
	width: 30px
}
.portfolio-sidebar.portfolio-block.columns-4 a.title {
	margin-left: 0;
	font-size: 14px;
	background: 0 0;
	width: 100%;
	height: 100%
}
.portfolio-sidebar.portfolio-block.columns-4 .portfolio-overlay i {
	font-size: 12px
}
.portfolio-fullwidth.portfolio-block.columns-4 .grid-item {
	margin-bottom: 0;
	width: 25%
}
.portfolio-fullwidth.portfolio-block.columns-4 a.title {
	background: 0 0!important;
	width: 100%;
	color: #fff
}
.portfolio-fullwidth.portfolio-block.columns-4 a.title:hover {
	background: 0 0;
	width: 100%;
	color: #c15601
}
.portfolio-fullwidth.portfolio-block.columns-4 ul.port-tag {
	margin-bottom: 10px
}
.portfolio-fullwidth.portfolio-block.columns-4 ul.port-tag li a {
	background: 0 0;
	color: #fff;
	margin-right: 20px
}
.portfolio-fullwidth.portfolio-block.columns-4 ul.port-tag li a:hover {
	background: 0 0;
	color: #c15601
}
.portfolio-fullwidth.columns-4 .button-group.filters-button-group {
	margin-bottom: 42px
}
.packetry .item {
	float: left;
	width: 25%;
	height: auto!important;
	padding: 0;
	margin: 0;
	border: 0 solid transparent
}
.packetry .item.width2 {
	width: 50%
}
.packetry .item img {
	width: 100%
}
.packetry .portfolio-item .portfolio-overlay {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.packetry .portfolio-item .portfolio-overlay a.title {
	background: 0 0;
	width: 100%;
	color: #fff
}
.packetry .portfolio-item .portfolio-overlay a.title:hover {
	color: #c15601;
	background: 0 0
}
.packetry .portfolio-item .portfolio-overlay ul.port-tag {
	margin-bottom: 5px
}
.packetry .portfolio-item .portfolio-overlay ul.port-tag li {
	display: inline-block;
	margin: 0 6px
}
.packetry .portfolio-item .portfolio-overlay ul.port-tag li a {
	background: 0 0;
	color: #fff;
	margin-right: 10px
}
.packetry .portfolio-item .portfolio-overlay ul.port-tag li a:hover {
	color: #c15601
}
.packetry .portfolio-item {
	width: 100%;
	position: relative;
	overflow: hidden
}
.packetry .portfolio-item .portfolio-caption {
	background: rgba(0,0,0,.7);
	width: 100%;
	height: 100%;
	padding: 30px;
	position: absolute;
	top: -100px;
	color: #fff
}
.packetry .portfolio-item .portfolio-caption a {
	display: inline-block;
	color: #fff;
	font-size: 18px
}
.packetry .portfolio-item .portfolio-caption a span {
	font-size: 15px;
	padding: 10px 0 20px;
	display: block;
	color: #c15601;
	text-transform: capitalize
}
.packetry .portfolio-item .portfolio-caption a:hover, .portfolio-caption a span:hover {
	color: #c15601
}
.packetry .portfolio-item .portfolio-overlay a {
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #626262;
	border-radius: 50%;
	padding-left: 0;
	text-align: center;
	line-height: 38px
}
.packetry .portfolio-item .portfolio-overlay a i {
	font-size: 16px
}
.packetry .portfolio-item .portfolio-overlay a:hover {
	background: #c15601;
	color: #fff
}
.isotope-filters-2 {
	display: table;
	margin: 0 auto 50px
}
.isotope-filters-2 button {
	margin: 2px;
	cursor: pointer;
	color: #626262;
	padding: 5px 18px;
	font-size: 16px;
	border-radius: 3px;
	background: 0 0;
	border: 1px solid #626262;
	text-transform: capitalize;
	transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out
}
.isotope-filters-2 button:focus {
	outline: 0;
	outline-offset: 0
}
.isotope-filters-2 button.active, .isotope-filters-2 button:hover {
	background: #c15601;
	color: #fff;
	border-color: #c15601
}
.masonry .grid-sizer, .masonry.columns-2 .masonry-item {
	width: 50%;
	padding: 5px
}
.masonry .masonry-item {
	float: left
}
.masonry .masonry-item img {
	display: block;
	width: 100%
}
.masonry .portfolio-item .portfolio-overlay .portfolio-item {
	width: 100%
}
.masonry .portfolio-item .portfolio-overlay {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.masonry .portfolio-item .portfolio-overlay a.title {
	background: 0 0;
	width: 100%;
	color: #fff
}
.masonry .portfolio-item .portfolio-overlay a.title:hover {
	color: #c15601;
	background: 0 0
}
.masonry .portfolio-item .portfolio-overlay ul.port-tag {
	margin-bottom: 5px
}
.masonry .portfolio-item .portfolio-overlay ul.port-tag li {
	display: inline-block;
	margin: 0 6px
}
.masonry .portfolio-item .portfolio-overlay ul.port-tag li a {
	background: 0 0;
	color: #fff;
	margin-right: 20px
}
.masonry .portfolio-item .portfolio-overlay ul.port-tag li a:hover {
	color: #c15601
}
.masonry.columns-3 .masonry-item {
	width: 33.333%;
	padding: 5px
}
.masonry.full-screen .masonry-item {
	width: 20%;
	padding: 5px
}
.masonry .portfolio-item {
	width: 100%;
	position: relative;
	overflow: hidden
}
.masonry .portfolio-item .portfolio-caption {
	background: rgba(0,0,0,.7);
	width: 100%;
	height: 100%;
	padding: 30px;
	position: absolute;
	top: -100px;
	color: #fff
}
.masonry .portfolio-item .portfolio-caption a {
	display: inline-block;
	color: #fff;
	font-size: 18px
}
.masonry .portfolio-item .portfolio-caption a span {
	font-size: 15px;
	padding: 10px 0 20px;
	display: block;
	color: #c15601;
	text-transform: capitalize
}
.masonry .portfolio-item .portfolio-caption a:hover, .portfolio-caption a span:hover {
	color: #c15601
}
.masonry .portfolio-item .portfolio-overlay a {
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #626262;
	border-radius: 50%;
	padding-left: 0;
	text-align: center;
	line-height: 38px
}
.masonry .portfolio-item .portfolio-overlay a i {
	font-size: 16px
}
.masonry .portfolio-item .portfolio-overlay a:hover {
	background: #c15601;
	color: #fff
}
.masonry.columns-4 .masonry-item {
	width: 20%;
	padding: 5px
}
.masonry.columns-4 ul.port-tag {
	margin-bottom: 3px
}
.masonry.columns-4 ul.port-tag li a {
	font-size: 13px
}
.masonry.columns-4 .portfolio-overlay a {
	height: 30px;
	line-height: 26px;
	margin-left: 0;
	padding-left: 0;
	text-align: center;
	width: 30px
}
.masonry.columns-4 a.title {
	margin-left: 0;
	font-size: 14px;
	background: 0 0;
	width: 100%;
	height: 100%
}
.masonry.columns-4 .portfolio-overlay i {
	font-size: 12px
}
.masonry-main .container-fluid {
	padding: 0
}
.full-screen.columns-2 .grid-item {
	width: 50%;
	padding: 0
}
.full-screen.columns-3 .grid-item {
	width: 33.333%;
	padding: 0
}
.full-screen.columns-4 .grid-item {
	width: 25%;
	padding: 0
}
.full-screen.columns-5 .grid-item {
	width: 20%;
	padding: 0
}
.full-screen .grid-item {
	float: left
}
.full-screen .grid-item img {
	display: block;
	width: 100%
}
.full-screen .portfolio-item {
	width: 100%;
	position: relative;
	overflow: hidden
}
.full-screen .portfolio-item .portfolio-overlay {
	display: inline-block;
	left: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	width: 100%
}
.full-screen a.title {
	background: 0 0;
	width: 100%;
	color: #c15601
}
.full-screen .portfolio-item .portfolio-overlay ul.port-tag {
	margin-bottom: 5px
}
.full-screen .portfolio-item .portfolio-overlay ul.port-tag li {
	display: inline-block;
	margin: 0 6px
}
.full-screen .portfolio-item .portfolio-overlay ul.port-tag li a {
	background: 0 0;
	color: #fff;
	margin-right: 20px
}
.full-screen .portfolio-item .portfolio-overlay ul.port-tag li a:hover {
	color: #c15601
}
.full-screen .portfolio-item .portfolio-caption {
	background: rgba(0,0,0,.7);
	width: 100%;
	height: 100%;
	padding: 30px;
	position: absolute;
	top: -100px;
	color: #fff
}
.full-screen .portfolio-item .portfolio-caption a.title {
	margin-left: 0;
	background: 0 0;
	width: 100%;
	height: 100%;
	font-size: 18px;
	color: #fff
}
.full-screen .portfolio-item .portfolio-caption a.title:hover {
	color: #c15601;
	background: 0 0
}
.full-screen .portfolio-item .portfolio-caption a span {
	font-size: 15px;
	padding: 10px 0 20px;
	display: block;
	color: #c15601;
	text-transform: capitalize
}
.blog-single blockquote.entry-quote p, .full-screen .portfolio-item .portfolio-overlay a i {
	font-size: 16px
}
.full-screen .portfolio-item .portfolio-caption a:hover, .portfolio-caption a span:hover {
	color: #c15601
}
.full-screen .portfolio-item .portfolio-overlay a {
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #626262;
	border-radius: 50%;
	padding-left: 0;
	text-align: center;
	line-height: 40px
}
.full-screen .portfolio-item .portfolio-overlay a:hover {
	background: #c15601;
	color: #fff
}
.blog-single .tags-2 ul {
	display: inline-block
}
.owl-carousel-13 img {
	width: 100%
}
.owl-carousel-13 .owl-nav {
	display: block;
	position: absolute;
	text-indent: inherit;
	top: -40px;
	left: 0;
	width: 100%;
	cursor: pointer
}
.owl-carousel-13 .owl-nav .owl-next, .owl-carousel-13 .owl-nav .owl-prev {
	position: absolute;
	width: auto;
	cursor: pointer;
	display: block;
	text-indent: inherit
}
.owl-carousel-13 .owl-nav .owl-prev {
	right: 0;
	-webkit-transition: opacity .3s ease 0s, left .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, left .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, left .3s ease 0s;
	-o-transition: opacity .3s ease 0s, left .3s ease 0s;
	transition: opacity .3s ease 0s, left .3s ease 0s
}
.owl-carousel-13 .owl-nav .owl-next {
	right: 30px;
	-webkit-transition: opacity .3s ease 0s, right .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, right .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, right .3s ease 0s;
	-o-transition: opacity .3s ease 0s, right .3s ease 0s;
	transition: opacity .3s ease 0s, right .3s ease 0s
}
.owl-carousel-13 .owl-nav i {
	font-weight: 400;
	font-size: 20px;
	color: #626262;
	background: #eceff7;
	padding: 5px 10px;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.owl-carousel-13 .owl-nav i:hover {
	color: #fff;
	background: #c15601
}
.blog .portfolio-item {
	width: 100%;
	position: relative;
	overflow: hidden
}
.blog .portfolio-item .portfolio-caption {
	background: rgba(0,0,0,.7);
	width: 100%;
	height: 100%;
	padding: 30px;
	position: absolute;
	top: -100px;
	color: #fff
}
.blog .portfolio-item .portfolio-overlay a {
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #626262;
	border-radius: 50%;
	margin-right: 10px;
	padding-left: 0;
	text-align: center;
	line-height: 38px
}
.blog .portfolio-item .portfolio-overlay a i {
	font-size: 16px
}
.blog .portfolio-item .portfolio-overlay a:hover {
	background: #c15601;
	color: #fff
}
.comments-1 .comments-photo {
	width: 106px;
	height: auto;
	float: left;
	border: 5px solid #eceff7;
	margin-right: 20px
}
.comments-1 .comments-photo img, .owl-carousel-6 img {
	width: 100%
}
.comments-1 .comments-info {
	display: table;
	background: #eceff7;
	padding: 20px;
	margin-bottom: 20px
}
.comments-1 .comments-info h4 {
	display: inline-block
}
.comments-1 .comments-info span {
	font-size: 13px;
	color: #626262
}
.comments-1 .comments-info a {
	color: #c15601
}
.comments-1 .comments-info a:hover {
	color: #363636
}
.comments-1.comments-2 {
	padding-left: 127px
}
.comments-1.comments-2 .comments-info {
	background: #fff;
	margin-bottom: 0;
	padding: 20px 20px 10px
}
.owl-carousel-6 {
	overflow: hidden
}
.owl-carousel-6 .owl-nav {
	display: block;
	position: absolute;
	text-indent: inherit;
	top: 45%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	left: 0;
	width: 100%;
	cursor: pointer
}
.owl-carousel-6 .owl-nav .owl-next, .owl-carousel-6 .owl-nav .owl-prev {
	display: block;
	text-indent: inherit;
	width: auto;
	position: absolute;
	cursor: pointer
}
.owl-carousel-6 .owl-nav .owl-prev {
	left: -44px;
	-webkit-transition: opacity .3s ease 0s, left .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, left .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, left .3s ease 0s;
	-o-transition: opacity .3s ease 0s, left .3s ease 0s;
	transition: opacity .3s ease 0s, left .3s ease 0s
}
.owl-carousel-6 .owl-nav .owl-next {
	right: -44px;
	-webkit-transition: opacity .3s ease 0s, right .3s ease 0s;
	-moz-transition: opacity .3s ease 0s, right .3s ease 0s;
	-ms-transition: opacity .3s ease 0s, right .3s ease 0s;
	-o-transition: opacity .3s ease 0s, right .3s ease 0s;
	transition: opacity .3s ease 0s, right .3s ease 0s
}
.owl-carousel-6 .owl-nav i {
	font-weight: 400;
	font-size: 20px;
	color: #626262;
	background: rgba(255,255,255,.7);
	padding: 8px;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.owl-carousel-6 .owl-nav i:hover {
	color: #626262;
	background: rgba(255,255,255,1)
}
.blog-entry-slider:hover .owl-carousel-6 .owl-nav .owl-prev {
	left: 0
}
.blog-entry-slider:hover .owl-carousel-6 .owl-nav .owl-next {
	right: 0
}
.blog-entry-slider .owl-carousel .owl-stage-outer {
	overflow: hidden
}
.blog-entry {
	margin: 0
}
.blog-entry p {
	font-size: 13px;
	line-height: 18px
}
.blog-entry h5 {
	margin-bottom: 5px
}
.entry-title i {
	color: #c15601;
	float: left;
	margin-right: 20px;
	font-size: 30px;
	line-height: 33px
}
.entry-title h3 {
	display: table
}
.entry-title h4 {
	display: table-cell;
	line-height: 30px
}
.blog.blog-grid .entry-title i {
	font-size: 24px
}
.entry-meta a {
	font-size: 14px;
	margin-right: 10px;
	color: #626262
}
.entry-meta a:hover, .entry-meta i {
	color: #c15601
}
.entry-meta i {
	font-size: 14px;
	padding-right: 3px
}
.entry-content p {
	margin-bottom: 0
}
.blog-entry-image .portfolio-item {
	width: 100%
}
.blog-entry-image .portfolio-item .portfolio-overlay {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	left: 0;
	display: inline-block;
	text-align: center;
	width: 100%;
	z-index: 2
}
.blog-entry-grid .grid-post li {
	float: left;
	width: 50%;
	border-right: 4px solid #fff;
	border-bottom: 4px solid #fff
}
.blog-entry-grid .grid-post li .portfolio-item, .blog-entry-grid .grid-post li img {
	width: 100%
}
.blog-entry-grid .grid-post li .portfolio-item .portfolio-overlay {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	left: 0;
	display: inline-block;
	text-align: center;
	width: 100%;
	z-index: 2
}
.js-video {
	height: 0;
	padding-top: 25px;
	padding-bottom: 53.5%;
	margin-bottom: 10px;
	position: relative;
	overflow: hidden
}
.js-video.widescreen {
	padding-bottom: 34.34%
}
.js-video.vimeo {
	padding-top: 0
}
.js-video embed, .js-video iframe, .js-video object, .js-video video {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute
}
blockquote.entry-quote {
	border: none
}
.entry-quote {
	background: #eceff7;
	padding: 25px
}
.entry-quote i {
	float: left;
	font-size: 30px;
	color: #bfc5d6;
	margin-top: 10px;
	margin-right: 20px
}
.entry-quote p {
	display: table;
	font-size: 18px;
	text-shadow: 1px 1px 0 #fff;
	line-height: 35px;
	vertical-align: top;
	font-style: italic
}
.quote-author a {
	color: #df6708
}
.quote-author a:hover {
	color: #c15601
}
.pagination {
    display: inline-block;
    margin: 20px 0;
    padding-left: 0;
}
.pagination li a {
	font-size: 15px;
	font-weight: 500;
	margin: 0 5px;
	color: #626262;
	border-radius: 3px
}
.pagination>li>a, .pagination>li>span {
	background-color: #fff;
       border: 1px solid #ddd;
       color: #f46a1a;
       float: left;
       line-height: 1.42857;
       margin-left: -6px;
       padding: 6px 12px;
       position: relative;
       text-decoration: none;
}
.pagination li a:focus, .pagination li a:hover, .pagination li span:focus, .pagination li span:hover, .pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
	background-color: #c15601;
	border-color: #c15601;
	color: #fff;
	z-index:auto;
}
#one+label:after, #one+label:before, #two+label:after, #two+label:before, .remember-checkbox a {
	color: #c15601
}
.register-form .section-field {
	display: block
}
.register-form .field-widget {
	position: relative
}
.register-form .field-widget i {
	position: absolute;
	left: 0;
	padding: 13px;
	color: #c15601
}
.register-form .field-widget input, .register-form .field-widget select {
	width: 100%;
	margin-bottom: 20px;
	padding-left: 10px
}
.register-form .field-widget input.gui-input {
	padding-left: 100px
}
.register-form .field-widget .button {
	background: #d9dde8;
	height: 41px;
	left: 0;
	line-height: 42px;
	margin: 2px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 80px
}
.remember-checkbox label {
	position: relative;
	padding-left: 30px;
	font-size: 14px;
	cursor: pointer
}
/*
	## Gulp issue fix for create-group page
.remember-checkbox label:before, label:after {
	font-family: FontAwesome;
	font-size: 17px;
	position: absolute;
	top: -3px;
	left: 0;
	padding-left: 2px
}
*/
.remember-checkbox label:before {
	border: 2px solid #df6708;
	content: "";
	height: 20px;
	margin-top: 3px;
	width: 20px
}
.remember-checkbox label:after {
	content: '\f00c';
	max-width: 0;
	overflow: hidden;
	opacity: .5;
	transition: all .35s;
	-webkit-transition: all .35s;
	-ms-transition: all .35s;
	-o-transition: all .35s
}
.remember-checkbox input[type=checkbox] {
	display: none
}
.login-2-form .section-field, .login-3-form .section-field {
	display: block
}
.remember-checkbox input[type=checkbox]:checked+label:after {
	max-width: 25px;
	opacity: 1
}
#three+label:after, #three+label:before {
	color: #389338
}
#four+label:after, #four+label:before {
	color: #389393
}
#five+label:after, #five+label:before {
	color: #383893
}
#six+label:after, #six+label:before {
	color: #933893
}
.register-2 .register-form input {
	background: rgba(0,0,0,.5);
	border: none;
	color: #fff
}
.register-2 .register-form input:focus, .register-2 .register-form input:hover {
	background: rgba(0,0,0,1);
	border: none;
	color: #fff
}
.register-2 .register-form label {
	color: #fff;
	margin-bottom: 10px
}
.register-2 .register-form input.email {
	padding-left: 42px
}
.login-2-form {
	border-bottom: 1px solid #e2e5ed
}
.login-2-form .field-widget {
	position: relative
}
.login-2-form .field-widget i {
	position: absolute;
	left: 0;
	padding: 13px;
	color: #c15601
}
.login-2-form .field-widget input {
	margin-bottom: 20px;
	padding-left: 10px
}
.login-2-form .field-widget select {
	width: 100%;
	margin-bottom: 20px
}
.login-2-form .field-widget textarea {
	width: 100%;
	margin-bottom: 30px;
	padding-left: 38px
}
.login-2-form .section-field .remember-checkbox a {
	color: #626262;
	font-size: 14px
}
.login-2-form .section-field .remember-checkbox a:hover {
	color: #c15601
}
.login-3-form .section-field label {
	color: #fff
}
.login-3-form .field-widget {
	position: relative
}
.login-3-form .field-widget i {
	position: absolute;
	left: 0;
	padding: 13px;
	color: #c15601
}
.login-3-form .field-widget input {
	margin-bottom: 20px;
	padding-left: 38px;
	background: rgba(0,0,0,.5);
	border: none;
	color: #fff
}
.login-3-form .field-widget input:focus, .login-3-form .field-widget input:hover {
	background: rgba(0,0,0,1)
}
.login-3-form .field-widget select {
	width: 100%;
	margin-bottom: 20px
}
.login-3-form .field-widget textarea {
	width: 100%;
	margin-bottom: 30px;
	padding-left: 38px
}
.login-3-form .section-field .remember-checkbox a {
	color: #fff;
	font-size: 14px
}
.login-3-form .section-field .remember-checkbox a:hover {
	color: #c15601
}
.login-3 .login-with {
	border: none
}
.login-3 .login-with h3 {
	font-size: 25px;
	color: #fff;
	display: inline-block
}
.login-3 .login-with a {
	font-size: 15px;
	border-radius: 50%;
	border: 1px solid #fff;
	background: 0 0;
	color: #fff;
	width: 40px;
	height: 40px;
	display: inline-block;
	text-align: center;
	line-height: 38px;
	margin-left: 5px
}
.login-3 .login-with a:hover {
	background: #c15601;
	color: #fff;
	border-color: #c15601
}
.tabcontent {
	text-align: center;
	margin-bottom: 20px
}
.built-tab ul.tabs {
	background: url(../images/device/device-7.png) no-repeat;
	width: 420px;
	height: 420px;
	position: relative;
	float: right
}
.built-tab .tabs li {
	background: #fff;
	border-radius: 50%;
	float: left;
	height: 60px;
	width: 60px;
	padding: 5px;
	border: 5px solid #eceff8;
	cursor: pointer;
	transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out
}
.built-tab #tabs .tabs li {
	position: absolute;
	padding: 5px 0 0 5px
}
.page-sidebar .page-content ul li i, .privacy-policy ul li i, .sitemap h4 i, .sitemap ul li i, .terms-and-conditions ul li i {
	padding-right: 10px
}
.built-tab .tabs li span {
	background: #c15601;
	border-radius: 50%;
	display: block;
	color: #fff;
	height: 40px;
	width: 40px;
	line-height: 22px;
	text-align: center;
	padding: 10px
}
.built-tab .tabs li.active, .built-tab .tabs li:hover {
	border-color: #c15601;
	background: 0 0
}
.built-tab .tabs li.css3 {
	position: absolute;
	top: 70px;
	left: 0
}
.built-tab .tabs li.support {
	position: absolute;
	top: 170px;
	left: 86px
}
.built-tab .tabs li.customization {
	position: absolute;
	top: 70px;
	right: 47px
}
.built-tab .tabs li.update {
	position: absolute;
	bottom: 110px;
	right: 66px
}
.built-tab .tabcontent {
	text-align: left
}
.built-tab #tabs ul.tabs li, .built-tab #tabs ul.tabs li.active, .built-tab #tabs ul.tabs li.active:focus, .built-tab #tabs ul.tabs li:hover {
	background: #fff
}
.built-tab #tabs ul.tabs li span.ripple.rippleEffect {
	display: none
}
.clients-partner .clients-logo li {
	width: 20%;
	float: left
}
.sitemap h4 {
	margin-bottom: 25px;
	text-transform: capitalize
}
.sitemap ul li {
	display: block;
	line-height: 34px
}
.page-sidebar .page-content ul li, .privacy-policy ul li, .terms-and-conditions ul li {
	line-height: 30px;
	font-size: 14px
}
.sitemap ul li a {
	font-size: 14px;
	color: #626262;
	text-transform: capitalize
}
.sitemap ul li a:hover {
	color: #c15601
}
.page-sidebar .page-content h3 {
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
	margin-bottom: 20px
}
.footer {
	background: #191919
}
.footer-logo img {
	width: 65%
}
.addresss-info li {
	font-size: 14px;
	color: #fff;
	margin-bottom: 10px
}
.addresss-info i {
	color: #c15601;
	padding-right: 5px;
	float: left;
	display: table-cell;
	width: 30px;
	line-height: 23px;
	font-size: 17px
}
.addresss-info p {
	display: table;
	color: #fff;
	margin-bottom: 0
}
.footer-gallery li, .social-feed li {
	margin-bottom: 5px;
	margin-right: 5px;
	list-style: none;
	float: left
}
.recent-post-image {
	float: left;
	display: table
}
.recent-post-image img {
	vertical-align: middle;
	width: 60px;
	height: 50px
}
.social-feed li, .social-feed li img {
	width: 70px;
	height: 70px
}
.recent-post-info {
	display: table-cell;
	padding-left: 8px
}
.recent-post-info a {
	color: #fff;
	font-size: 14px;
	vertical-align: top
}
.recent-post-info a:hover {
	color: #c15601
}
.recent-post-info span {
	color: #6d6d6d;
	font-size: 11px;
	display: table;
	padding-top: 5px
}
.footer-Newsletter, .footer-gallery, .social-feed, ul.footer-instagram-images li {
	display: inline-block
}
.recent-post-info i {
	color: #6d6d6d;
	font-size: 14px;
	padding-right: 5px
}
.footer-gallery li, .footer-gallery li img {
	width: 70px
}
.footer-useful-link.footer-hedding ul {
	margin-top: -6px
}
.footer-Newsletter .form-control {
	border-left: 0;
	height: 40px;
	font-size: 13px;
	color: #fff
}
.footer-Newsletter .form-control:focus {
	border: 1px solid #2f2f2f;
	border-left: 0
}
.footer-useful-link ul li {
	list-style: none
}
.footer-useful-link ul li a {
	color: #fff;
	font-size: 14px;
	vertical-align: top;
	display: block;
	line-height: 26px
}
.footer-useful-link ul li a:hover, .twitter-feed li p a {
	color: #c15601
}
.twitter-feed li {
	display: block
}
.twitter-feed li i {
	font-size: 15px;
	color: #c15601;
	float: left;
	margin-right: 15px;
	margin-top: 6px
}
.twitter-feed li p {
	font-size: 14px;
	display: table;
	vertical-align: top
}
.tags ul li, .twitter-feed li p a {
	display: inline-block
}
.twitter-feed.text-white li a, .twitter-feed.text-white li a:hover {
	color: #363636
}
.twitter-feed.text-white li i {
	color: #fff
}
.tags.footer-hedding h4 {
	margin-bottom: 20px
}
.tags ul li a {
	display: block;
	color: #6f6f6f;
	border: 1px solid #2f2f2f;
	background: 0 0;
	border-radius: 13px;
	padding: 5px 10px;
	margin-bottom: 10px;
	font-size: 12px;
	text-transform: capitalize
}
.tags ul li a:hover {
	background: 0 0;
	color: #c15601;
	border-color: #c15601
}
.footer-widget {
	padding: 20px 15px 0
}
.footer-widget a, .footer-widget a:hover {
	color: #c15601
}
.footer-widget-social {
	text-align: right;
	margin-top: -10px
}
.footer-widget-social li {
	display: inline-block
}
.footer-widget-social a i {
	display: inline-block;
	color: #545454;
	width: 30px;
	height: 45px;
	text-align: center;
	line-height: 47px;
	font-size: 14px
}
.footer-widget-social .tooltip, .footer-widget-social [data-tooltip] {
	position: relative;
	cursor: pointer
}
.footer-widget-social .tooltip:after, .footer-widget-social .tooltip:before, .footer-widget-social [data-tooltip]:after, .footer-widget-social [data-tooltip]:before {
	position: absolute;
	visibility: hidden;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-o-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-moz-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(Opacity=0);
	opacity: 0;
	-webkit-transition: opacity .2s ease-in-out, visibility .2s ease-in-out, -webkit-transform .2s cubic-bezier(.71, 1.7, .77, 1.24);
	-moz-transition: opacity .2s ease-in-out, visibility .2s ease-in-out, -moz-transform .2s cubic-bezier(.71, 1.7, .77, 1.24);
	-o-transition: opacity .2s ease-in-out, visibility .2s ease-in-out, -o-transform .2s cubic-bezier(.71, 1.7, .77, 1.24);
	-ms-transition: opacity .2s ease-in-out, visibility .2s ease-in-out, -ms-transform .2s cubic-bezier(.71, 1.7, .77, 1.24);
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out, transform .2s cubic-bezier(.71, 1.7, .77, 1.24);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	pointer-events: none
}
.footer-widget-social .tooltip:focus:after, .footer-widget-social .tooltip:focus:before, .footer-widget-social .tooltip:hover:after, .footer-widget-social .tooltip:hover:before, .footer-widget-social [data-tooltip]:focus:after, .footer-widget-social [data-tooltip]:focus:before, .footer-widget-social [data-tooltip]:hover:after, .footer-widget-social [data-tooltip]:hover:before {
	visibility: visible;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-o-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-moz-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	-webkit-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(Opacity=100);
	opacity: 1
}
.footer-widget-social .tooltip:before, .footer-widget-social [data-tooltip]:before {
	z-index: 1001;
	border: 6px solid transparent;
	background: 0 0;
	content: ""
}
.footer-widget-social .tooltip:after, .footer-widget-social [data-tooltip]:after {
	z-index: 1000;
	padding: 8px;
	width: 100px;
	text-align: center;
	background-color: #c15601;
	color: #fff;
	content: attr(data-tooltip);
	font-size: 14px;
	line-height: 1.2;
	border-radius: 3px
}
.footer-widget-social .tooltip-top:after, .footer-widget-social .tooltip-top:before, .footer-widget-social .tooltip:after, .footer-widget-social .tooltip:before, .footer-widget-social [data-tooltip]:after, .footer-widget-social [data-tooltip]:before {
	bottom: 100%;
	left: 50%
}
.footer-widget-social .tooltip-top:before, .footer-widget-social .tooltip:before, .footer-widget-social [data-tooltip]:before {
	margin-left: -6px;
	margin-bottom: -12px;
	border-top-color: #c15601
}
.footer-widget-social .tooltip-top:after, .footer-widget-social .tooltip:after, .footer-widget-social [data-tooltip]:after {
	margin-left: -50px
}
.footer-widget-social .tooltip-top:focus:after, .footer-widget-social .tooltip-top:focus:before, .footer-widget-social .tooltip-top:hover:after, .footer-widget-social .tooltip-top:hover:before, .footer-widget-social .tooltip:focus:after, .footer-widget-social .tooltip:focus:before, .footer-widget-social .tooltip:hover:after, .footer-widget-social .tooltip:hover:before, .footer-widget-social [data-tooltip]:focus:after, .footer-widget-social [data-tooltip]:focus:before, .footer-widget-social [data-tooltip]:hover:after, .footer-widget-social [data-tooltip]:hover:before {
	-webkit-transform: translateY(-25px);
	-o-transform: translateY(-25px);
	-ms-transform: translateY(-25px);
	-moz-transform: translateY(-25px);
	transform: translateY(-25px)
}
.footer-2 {
	background: #c15601;
	position: inherit
}
.footer-2 .footer-Newsletter {
	margin-top: 0
}
.footer-2 .input-group-btn button.btn {
	background: #fff;
	border: none;
	padding: 10px 12px
}
.footer-2 .input-group-btn button:hover {
	background: #363636
}
.footer-2 .input-group.divcenter .form-control, .footer-2 .tags ul li a {
	background: #008fb8;
	border: none
}
.footer-2 .input-group.divcenter .form-control:focus {
	box-shadow: none
}
.footer-2 .divcenter .input-group-addon {
	border: none;
	background: #008fb8;
	color: #fff
}
.footer-2 .divcenter .input-group-addon i {
	color: #fff
}
.footer-2 .input-group.divcenter .form-control::-moz-placeholder {
color:#fff
}
.footer-2 .recent-post-info {
	padding-left: 0;
	display: block;
	margin-bottom: 15px
}
.footer-2 .recent-post-info a:hover {
	color: #363636
}
.footer-2 .recent-post-info span {
	padding-top: 0;
	color: #323232;
	font-size: 14px;
	line-height: 5px;
	display: block;
	margin-top: 5px
}
.footer-2 .tags ul li a {
	color: #fff;
	padding: 5px 12px
}
.footer-2 .tags ul li a:hover {
	border: none;
	background: #fff;
	color: #c15601
}
.footer-2 .footer-widget-social li a span:after, .footer-2 .footer-widget-social li a span:before, .footer-widget-social li a span:after {
	color: #fff;
	border-top-color: #fff
}
.footer-2 .footer-widget-social li a span {
	color: #c15601;
	background: #fff
}
.footer-2 .tooltip:after, .footer-2 [data-tooltip]:after {
	z-index: 1000;
	padding: 8px;
	width: 100px;
	text-align: center;
	background-color: #fff;
	color: #c15601;
	content: attr(data-tooltip);
	font-size: 14px;
	line-height: 1.2;
	border-radius: 3px
}
.footer-2 .tooltip-top:before, .footer-2 .tooltip:before, .footer-2 [data-tooltip]:before {
	margin-left: -6px;
	margin-bottom: -12px;
	border-top-color: #fff
}
.footer-3 {
	background: #e5e3dc
}
.footer-3 p {
	color: #545454;
	margin-top: 10px;
}
.footer-3 .recent-post-info a {
	color: #626262
}
.footer-3 .recent-post-info a:hover {
	color: #c15601
}
.footer-3 .recent-post-info {
	padding-left: 0
}
.footer-3 .recent-post-info span {
	color: #c15601;
	padding-top: 0;
	line-height: 10px
}
.footer-3 .tags ul li a {
	background: #fff;
	border: 1px solid #363636;
	color: #363636
}
.footer-3 .tags ul li a:hover {
	background: #c15601;
	border: 1px solid #c15601;
	color: #fff
}
.footer-3 .input-group.divcenter .form-control {
	background: #fff;
	border: 1px solid #c15601;
	border-left: 0;
	color: #626262;
	box-shadow: none
}
.footer-3 .divcenter .input-group-addon {
	background: #fff;
	border: 1px solid #c15601;
	border-right: 0
}
.footer-3 .divcenter .input-group-addon i {
	color: #c15601
}
.footer-3 .input-group-btn button {
	background: #c15601;
	color: #fff;
	border-color: #c15601
}
.footer-3 .input-group-btn button:hover {
	background: #363636;
	color: #fff
}
.footer-3 .input-group.divcenter .form-control::-moz-placeholder {
color:#363636
}
.footer-3 .tooltip:after, .footer-3 [data-tooltip]:after {
	z-index: 1000;
	padding: 8px;
	width: 100px;
	text-align: center;
	background-color: #ca5c06;
	color: #fff;
	content: attr(data-tooltip);
	font-size: 14px;
	line-height: 1.2;
	border-radius: 3px
}
.footer-3 .tooltip-top:before, .footer-3 .tooltip:before, .footer-3 [data-tooltip]:before {
	margin-left: -6px;
	margin-bottom: -12px;
	border-top-color: #ca5c06
}
.contact-6 .feature-8, .footer-5 .footer-logo p {
	margin-bottom: 0
}
.footer-3 .footer-widget p {
	color: #545454
}
.footer-4 .get-in-touch {
	background: url(../images/map.png) 0 45px no-repeat
}
.footer-4 .get-in-touch p {
	display: table
}
.footer-4 .get-in-touch i {
	float: left;
	color: #c15601;
	margin-right: 15px;
	line-height: 29px
}
.footer-4 {
	background: #191919
}
.footer-4 .office-hours p {
	color: #fff
}
.footer-4 .tags ul li a {
	background: 0 0;
	border: 1px solid #6f6f6f
}
.footer-4 .tags ul li a:hover {
	background: #c15601;
	border: 1px solid #c15601;
	color: #fff
}
.footer-5 {
	bottom: 0;
	left: 0;
	z-index: -1;
	right: 0;
	background: #191919
}
.contact-6 {
	background: #c15601;
	padding: 50px 0
}
.footer-6 img.footer-logo, .footer-7 img.footer-logo {
	margin-bottom: 30px;
	height: 40px
}
.contact-6 .feature-8-title {
	background: #0097c3;
	color: #fff;
	width: 70px;
	height: 70px;
	text-align: center;
	border-radius: 50%
}
.footer-6, .footer-7 {
	background: #323232
}
.contact-6 .feature-8-title span {
	color: #fff;
	font-size: 25px;
	line-height: 70px
}
.contact-6 .feature-8-info p {
	font-size: 15px
}
.contact-6 .feature-8-info b {
	font-size: 15px;
	color: #191919;
	font-weight: 400
}
.footer-6 {
	-webkit-box-shadow: inset 0 20px 50px -10px rgba(0,0,0,.8);
	-moz-box-shadow: inset 0 20px 50px -10px rgba(0,0,0,.8);
	box-shadow: inset 0 20px 50px -10px rgba(0,0,0,.8)
}
.footer-7 .footer-about {
	margin-bottom: 30px
}
.footer-7 .footer-about p {
	padding: 0 100px;
	color: #6f6f6f
}
.footer-7 .footer-about ul.social li {
	display: inline-block
}
.footer-7 .footer-about ul.social li a {
	font-size: 14px;
	color: #6f6f6f;
	background: #282828;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	display: block;
	border-radius: 3px;
	margin: 0 3px
}
.footer-7 .footer-about ul.social li a:hover {
	background: #c15601;
	color: #fff
}
.footer-7 .footer-bottom p {
	font-size: 13px;
	color: ##6f6f6f
}
.footer-8 .get-in-touch p {
	display: table;
	color: #323232
}
.footer-8 .get-in-touch i {
	float: left;
	color: #323232;
	margin-right: 15px;
	line-height: 29px
}
.footer-8 .twitter-feed li p {
	color: #323232
}
.footer-8 .tags ul li a {
	background: 0 0;
	border: 1px solid #323232;
	color: #323232
}
.footer-8 .tags ul li a:hover {
	background: #323232;
	border: 1px solid #323232;
	color: #fff
}
.invite_btn, .invite_input {
	border: 1px solid #919191!important
}
@media (max-width:1199px) {
.boxed-layout .move-carousel, .wide-layout .move-carousel {
	height: 100vh;
	top: 0;
	padding: 0;
	transform: none
}
.move-carousel .owl-carousel {
	position: relative;
	top: 50%;
	padding: 0 0 0 35px;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%)
}
.testimonial-info h3 {
	font-size: 40px;
	width: 30%
}
}
.login-add {
	line-height: 69px
}
.footer-hedding li {
	line-height: 18px
}
.footer-hedding li a {
	font-size: 14px;
	color: #585858
}
.footer_copy, .footer_copy a {
	font-size: 16px;
	text-transform: uppercase
}
.invite_btn {
	width: 100%
}
.invite_input {
	padding: 15px 20px!important
}
.invite_btn span {
	padding: 10px 20px!important;
	color: #545454!important;
	width: 100%!important;
	text-align: center
}
.border-bottom {
	border-bottom: 1px solid #919191;
	margin: 20px 0
}
.about-col-3, .about-col-9, .why-we-col-9 {
	background: #fff;
	border: 1px solid #cfcfcf;
	box-shadow: 0 1px 1px rgba(0,0,0,.1)
}
.email_margin {
	margin-bottom: -20px;
	padding-top: 25px
}
.footer_copy {
	line-height: 8px;
	color: #545454!important;
	margin-top: 0px !important;
}
.footer_copy a {
	color: #545454
}
.footer2 {
	font-size: 12px;
	color: #000!important
}
.dot_span:before {
	content: "·";
	padding: 0 6px
}
.about-col-9 {
	width: 62.7%;
	margin: 0 1% 0 1.5%;
	min-height: 1080px
}
.why-we-col-9 {
	width: 62.7%;
	margin: 0 35.8% 0 1.5%
}
.about-col-3 {
	width: 33%;
	margin-left: .8%;
	min-height: 1080px
}
.grp_name {
	border-bottom: 1px solid #eee
}
.event-col-3, .event-col-6 {
	background: #fff;
	border: 1px solid #cfcfcf;
	box-shadow: 0 1px 1px rgba(0,0,0,.1)
}
.event-col-6 {
    margin: 0 1.5%;
    min-height: 550px;
   /*  width: 50%; */
}
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
	.event-col-6 {
    	margin: 0 0;
	}
}
.event-col-3 {
    margin-right: -1%;
    /* width: 32%; */
}
.event-col-left-3 {
    margin-left: -2.7%;
    width: 17%;
}
.event-col-3-custom {
    margin-right: -1%;
	background: #fff;
	width :32%;
 	border: 1px solid #cfcfcf;
 	box-shadow: 0 1px 1px rgba(0,0,0,.1)
}
@media only screen and (device-width: 768px) {
	.event-col-3-custom {
		width: 32%;
	    margin-left: -20px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
	.event-col-3-custom {
		width: 100%;
	    margin-left: -20px;
	}
}
.volunt-h5 {
	margin-left: -10px
}
.event-li li {
	width: 100%;
	float: left
}
.event-li li span {
	padding-top: 10px!important
}
.event-li i {
	float: left;
	position: relative;
	padding-top: 8px;
	color: #df6708;
	margin-left: 19px;
	font-size: 20px;
	margin-right: -5px
}
.owos-footer ul{
	padding: 0;
}
.ftn25 {
	font-size: 25px!important
}
.mt-5 {
	margin-top: 5px!important
}
.search-top {
	width: 40.7%;
	float: left;
	margin-left: 7.3%;
	margin-right: 5%
}
.user-part-login {
	width: 29%;
	float: right;
	margin-top: 12px
}
.top-input input {
	border: 1px solid #fff!important;
	background: #fff!important;
	margin: 12px 0
}
.user-img {
	color: #fff;
	float: left;
	width: 65%;
	line-height: 45px
}
.user-img img {
	margin: 5px 15px 0 0!important;
	width: auto;
	height: auto
}
.user-setting {
	float: right;
	width: 35%;
	margin-top: -12px
}
.user-setting i {
	color: #fff;
	margin-left: 15px;
	font-size: 25px
}
.b-bottom {
	border-bottom: 1px solid #ccc
}
.creat-btn {
	float: right
}
.create-btn {
	border: 2px solid #df6708;
	color: #333;
	font-size: 14px;
	padding: 5px 10px
}
.radio1 {
	float: left;
	width: 100px;
	margin-left: -5px!important
}
.radio2 {
	float: left;
	width: 20%!important;
	margin-right: 20%!important
}
.radio3 {
	float: right;
	width: 60%;
	margin-top: 17px
}
.btn-file {
	background: #eceff8;
	border: none;
	height: 45px;
	line-height: 32px;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	text-align: left;
	width: 100%
}
.border-1, .border-2 {
	background: #fff
}
.border-1, .border-1:focus, .border-2, .border-2:focus {
	border: 1px solid #c4c4c4!important
}
.btn-file input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	font-size: 100px;
	text-align: right;
	filter: alpha(opacity=0);
	opacity: 0;
	outline: 0;
	background: #fff;
	cursor: inherit;
	display: block;
	bottom: 30px
}
.right0 {
	padding-right: 0!important
}
.left0 {
	padding-left: 0!important
}
.register-col-7 {
	width: 52.7%;
	margin: 0 5% 0 1.5%
}
.register-col-5 {
	width: 39%;
	margin-left: .8%;
	margin-bottom: 80px
}
.fnt-30 {
	font-size: 40px;
	margin-right: 10px;
	color: #e8ac57
}
.border-2 {
	height: 30px
}
.blank-30-col {
	float: left;
	margin-left: 7.3%;
	margin-right: 5%;
	width: 30.7%
}
.login-panel-col {
	float: right;
	width: 39%;
	margin-top: 12px
}
.fnt-12 {
	font-size: 13px;
	margin-bottom: 7px!important
}
.fnt-12:hover {
	color: #fff
}
.login_btn2 {
	padding: 2px 10px!important;
	height: 30px;
	background: #a93e00!important
}
.border-1:focus, .border-2:focus {
	background: #fff;
	color: #000
}
.user-h4-text {
	color: #fff;
	font-size: 25px;
	padding-top: 210px
}
.profile-img-add {
	margin-top: 115px;
	border: 5px solid #fff
}
.profile_big-bg {
	background: url(../images/08.jpg) no-repeat;
	width: 100%;
	height: 250px;
	margin-bottom: 50px
}
.profile-img-bg {
	float: left;
	position: relative;
	margin-top: -30px;
	width: 100%;
	cursor: pointer
}
.profile-img-bg:hover {
	background: rgba(0,0,0,.5)
}
.c-mar {
	margin: -2px 0 0 15px;
	padding: 5px;
	float: left;
	color: #fff
}
.italic-fnt {
	font-size: 18px;
	font-style: italic
}
@media screen and (min-width:320px) and (max-width:769px) {
#video-background {
	height: 450px!important;
	width: 100%!important
}
.testimonial-info{
	position: absolute !important;
	top:1px !important;
}
.testimonial-info h3 {
	padding:5px;
	margin-bottom: 0;
	font-size: 18px;
	border: 2px solid #fff;
	color: #fff;
	width: 50%;
}
.testimonial-info h4 {
	padding: 10px 0 0;
	margin-bottom: 5px;
	font-size: 15px;
	color: #fff
}
.testimonial-info a, .testimonial-info p {
	padding: 5px 0;
	margin-bottom: 0;
	font-size: 8px;
	color: #fff
}
.testimonial-info strong {
	font-weight: 200;
	font-size: 10px;
	color: #fff
}
.testimonial-info span {
	font-weight: 200;
	color: #fff
}


}
.login_email_font{
	font-size: 17px;
}
.search-top {
	width: 40.7%;
	float: left;
	margin-left: 7.3%;
	margin-right: 5%;
}
.user-part-login {
	width: 29%;
	float: right;
	margin-top: 12px;
}
.top-input input {
	border: 1px solid #fff !important;
	background: #fff !important;
	margin: 12px 0px;
}
.user-img {
	color: #fff;
	float: left;
	width: 65%;
	line-height: 45px;
}
.user-img img {
	margin-right: 15px !important;
	width: auto;
	height: auto
}
.user-setting {
	float: right;
	width: 35%;
	margin-top: -12px
}
.user-setting i {
	color: #fff;
	margin-left: 15px;
	font-size: 25px;
}
.b-bottom {
	border-bottom: 1px solid #ccc;
}
.creat-btn {
	float: right;
}
.create-btn {
	border: 2px solid #df6708;
	color: #333;
	font-size: 14px;
	padding: 5px 10px;
}
.emial_holder {
	padding-left: 0px;
}

.emial_holder3 {
	padding-left: 0px;
	margin-top: 20px;
}
.emial_holder4 {
	padding-right: 0px;
	margin-top: 20px;
}
.joinuslink {
	margin-top: 0px !important;
	color: #df6708 !important;
}
.loginuparrow {
	margin-top: -32px;
	color: #fff;
	float: right;
}
.error {
	color: #df6708
}
.footer-link-ul {
	margin: -8px 0px 0px 0px;
}
.emial_holder {
	background: #fff !important;
	padding-left: 12px !important;
}
.emial_holder:focus {
	border-color: #8d98a9 !important;
}
.signup-p {
	margin-top: 90px;
}
.awarness-h3 {
	margin-bottom: -10px;
	font-size:18px;
}
.logout a {
	color: #fff!important;
	font-size: 16px;
	text-transform: uppercase;
	padding: 13px 15px !important
}
.login-margin {
	margin-right: -33px;
}

.owl-height {
    -webkit-transition: height .5s ease-in-out;
    -moz-transition: height .5s ease-in-out;
    -ms-transition: height .5s ease-in-out;
    -o-transition: height .5s ease-in-out;
    transition: height .5s ease-in-out
}

.owl-carousel {
    display: none;
    width: 100%;
    position: relative;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.mfp-container:before, .mfp-figure:after {
    content: ''
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-controls .owl-dot, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-nav .owl-prev {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel .owl-refresh .owl-item {
    display: none
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 120px
}

.owl-carousel .owl-item img {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.circle-mark
{
	top: -1px !important;
    left: 92% !important;
}
.resetpwdcard
{
	padding: 5px 10px 20px 10px !important;
}
.reset-btm-text
{
	padding: 0px 10px 0px 10px !important;
	margin-top: -45px !important;
}
.usersettings-width {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: #f2f4f7;
	border: 1px solid #dfe3e9;
	width:215px!important;
}
.chat_volunteer_event
{
	margin-right:50px;
	font-size:23px;
	cursor:pointer;
}
.event-no-login-message{
	color:red;
	margin-left:40px;
}
.chat-box .chatbox-group-participants {
	font-size: 10px;
	text-transform: lowercase;
}
.pd-l-r-0 {
	padding-left: 0px;
	padding-right: 0px;
}
.mar-btm{

	margin-bottom: -10px;
	padding-left: 0;
}

.formyoutubelabel
{
	font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
font-size: 12px;
letter-spacing: .3px;
color: #7f8fa4;
margin-bottom: 6px;
line-height: 22px;
}
.WYM-error-msg
{
	color:#a94442!important;
}
.col-xs-12 {
  max-width: 100%;

   display: flex;
}
.col-xs-left {
  float: left;
  display: inline-block !important;
  width: 25%;

padding: 8px;
  background-color: white;
  margin-right: 7px;
}
.col-xs-right {
  float: right;
  display: inline-block !important;
  width: 18.5%;
  padding: 8px;
  background-color: white;
}
.footer-header
{
  font-family: 'Georgia',sans-serif;
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
}
.col-xs-6 {
  float: none !important;
  display: inline-block !important;
  width: 80%;
  padding: 8px;
  background-color: white;
  margin-right: 7px;
}
.form-agenda-listing {
    font-weight: 600;
    text-align: left;
    padding: 20px;
}
.thumb1
{
  width: 500px;
}
.col-xs-width-50 {
  float: none !important;
  display: inline-block !important;
  width: 49%;
  padding: 8px;
  height: 420px;
}


i.fa-arrow-left
{
  cursor: pointer;
}
i.fa-arrow-right
{
  cursor: pointer;
}
.carousel-caption-position {
  /* bottom: -20px!important; */
  left: 0px!important;
  right:0px!important;
}
.img-responsive
{
	height: 180px;
	width: 200px;
}
.WYM-form-title
{
  font-size: 27px;
}
.wym-banner
{
	height: 300px;
  width: 100%;
}
.wym-banner-div
{
	padding-left: 0px!important;
	padding-right: 0px!important;
}
@media screen and (max-width:720px)
{
    .wym-banner
    {
        height: 150px;
    }
}
@media screen and (max-width: 420px)
{
  .wym-banner
  {
      height: 100px;
  }
}
@media screen and (max-width: 290px)
{
  .wym-banner
  {
      height: 75px;
  }
}
@media screen and (max-width:720px)
{
	.bio-description {
    padding-top:20px;
	}
}
.custom-height-355
{
  height: 250px!important;
}
.left-video-prev, .right-video-prev
{

  width:0px;
  margin-left:15px;
  padding-top: 100px;
  font-size:2.5em;
}
.left-video-next, .right-video-next
{
  width:0px;
  margin-left:440px;
  padding-top: 100px;
  font-size:2.5em;
}
.custom-width-video
{
	height: 250px!important;
  width: 500px;
}
.carousel-control .glyphicon-circle-arrow-left, . {
  left: 40;
}
@media screen and (min-width:290px) and (max-width:420px) {
        .custom-height-355,.custom-width-video
        {
	  			width: 290px;
          height: 150px!important;
        }
        .carousel-caption-style
        {
	        width: 290px;
          align: center;
          height: 150px!important;
        }
        .left-video-prev, .right-video-prev
        {
					width:0px;
					margin-left: 15px;
					padding-top:55px;
					font-size: 2.5em;
				}
				.left-video-next, .right-video-next
				{
					width:0px;
					margin-left:240px;
					padding-top: 55px;
					font-size: 2.5em;
       }
}
@media screen and (min-width:421px) and (max-width:720px) {
        .custom-height-355,.custom-width-video
        {
						width: 295px;
            height: 150px;
        }
        .carousel-caption-style
        {
            width: 300px;
            height: 150px;
            align: center;
        }
				.left-video-next, .right-video-next
				{
       			width:0px;
            margin-left: 220px;
            padding-top:100px;
            font-size: 2.5em;
				}
}
#table-wrapper {
  position:relative;
}
#table-scroll {
  height:200px;
  overflow:auto;
  margin-top:10px;
	margin-bottom: 10px;
}

.wrapper {
  overflow:hidden;
  margin-top: 20px;
  margin-bottom: 10px;
}

.wrapper div {
   min-height: 150px;
}
#left-carousel {
  float:left;
  width:50%;
}
#right-carousel {
  overflow:hidden;
}

@media screen and (max-width: 400px) {
   #left-carousel {
    float: none;
    margin-right:0;
    width:auto;
    border:0;
  }
}
.form-template-bgcolor{
	background-color:#fff;
}
.form-template-media-style {
	background-color:black;
	opacity: .5;
}
.carousel-caption-style {
	margin-bottom:-96px;
	left:0px;
	right:0px
}
/*style for terms and condition fancy box */

.term_color{
	background:#f8fafc;
	font-size: 13px;
	position: relative;
	top: -11px;
	left: 18px;
	color: #7f8fa4;
}
.terms_disabled{
	color: rgba(70, 22, 57, 0.28) !important;
}
.term_color a{

	color:blue;
	text-decoration:underline;
}

#divForm {
	width: 700px;
	height: 400px;
	padding: 0;
	margin: 0;
}
.term_ad{
	/*padding:20px 38px;*/
	margin-right: 44px;
	margin-left: 44px;
	margin-top: 5px;
	font-size: 25px;
	color: #fff!important;
	background-color: #de6926;
	border: 1px solid #b84808;

}
.terms_bottom_chng
{
	margin-bottom: 6px !important;
	padding: 3px;
}
.check_box_change{
	width: 15px;
	height: 15px;
	margin-left: -6px !important;
}
.scroll_content{
	background-color:white;
	padding: 5px;
	text-align: justify;

}
.scroll_header{
	position: fixed;
	height: 50px;
	background-color: #a4430c;
}
.scroll_header p {

	width: 685px;
	text-align: center;
	color:#fff;
	line-height: 50px;
	margin-bottom: 0;
	padding-left: 5px;
}
.scroll_footer{
	background-color: #eee;
	height: 50px;
	border: 1px solid rgba(58, 50, 70, 0.37);
	text-align: center;
}
#divForm .scroll_content{
	overflow-y: scroll;
	width: 700px;
  height: 350px;
}
.check_terms a{
	color:black;
	font-size: 20px;
}
.check_terms{
	padding-bottom: 25px;
}
#divForm .fancybox-close-small {
	display: none;
}
.top_hgt{
	top:25px;
}

.font_term{
	font-size: 16px
}

.event-terms-6{
	margin-top: -14px;
	border-top: none;
	min-height: 51px;
	margin-left: -5px;
	width: 52.8%;
}

.check_terms .col_apt{
	border: 1px solid darkgrey;
	margin-left: 22px;
	margin-right: 22px;
	background: #e06a26;
	color: white !important;
}
.check_terms .col_apted{
	background: darkgrey;
	color: white !important;
}
.mce-btn-group:not(:first-child) {
	border-left: 0 !important;
	margin-left: 0 !important;
}
.mce-btn-group .mce-btn {
	border-width: 1px;
	margin: 0!important;
}
@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
	.terms_responsive_show {
		position: relative;
		top: -5px;
		left: 16px;
		width: 93%;
	}
	.user_pro_pad {
		padding-left: 0 !important;
	}
	.user_profile_align {
		padding-right: 0 !important;
	}
	.user-profile-buttons .right-col {
		padding-right: 10px !important;
		padding-left: 10px !important;
	}
	.user-profile-buttons .left-col {
		 padding-right: 10px !important;
		padding-left: 10px !important;
	}
	.mar-l-0-ann{
		margin-left: 0 !important;
	}
	.ann_field_res{
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.announcements .datepicker{
		padding-left: 0 !important;
	}
}
.hyper_links_dec{
	color:blue;
	text-decoration: underline;
}
.smallgroup {
    height: 200px;
    overflow:hidden;
		margin-bottom:10px !important;
}
.biggroup {
    height: auto;
}


.profile_edit_col{
	background-color: rgb(218, 94, 34) !important;
	color: white !important;
}
.profile_edit_col i.fa{
	color: white !important;
}


.page-bg-clr{
	background-color: #ffffff;
	padding: 10px;
	border: 1px solid #ced0da;
}

ul.page-bg-clr{
	margin-left: 0px !important;
	margin-top: 0px !important;
}

ul.event-breadcrum-style{
	margin-left:-17px !important;
}
.pwdchange {
  width: 450px;
  margin: 0 auto;
  padding-top: 94px;
}
.bg-none-impt{
	background-image:none !important;
}



/* style for scroll-top faq for */
.scrollToTop{
	width:100px;
	height:130px;
	padding:10px;
	text-align:center;
	font-weight: bold;
	color: #444;
	text-decoration: none;
	position:fixed;
	bottom:0px;
	right:40px;
	display:none;
	outline: none;
}

.scrollToTop:focus{
	outline: none;
}

.scrollToTop i{
	color: white;
	font-size: 25px;
	background-color:#e06a26;
}
.scrollToTop i.fa {
	display: inline-block;
	border-radius: 60px;
	box-shadow: 0px 0px 2px #888;
	padding: 0.5em 0.6em;

}
.scrollToTop i.fa:hover{
	background-color: #e06a26 ;
	color: white;
}
.subject-info-box-1,
.subject-info-box-2 {
    float: left;
    width: 45%;
    height: 300px;
}
.subject-info-arrows {
    float: left;
    width: 10%;
}
#lstBox1,
#lstBox2 {
  height: 300px;
}
.selectize-control .selectize-input{
	width: 93%;
}
.page_left_no_circle{
	width:93px;
	float:left;
}

/*style for pagecontact */
.hght_auto{
	height: auto;
}
.form_widht{
	width: 100%;
}
.ul_page_reposive li{
	width: auto !important;
	float: left;
}
.cont_resp{
	display: inline-block;
	width: 100%;
}
.sub_btn_pad{
	padding: 5px 16px !important;
}
.bnt_unsub{
	background-color:grey !important;
}
.sub_txt_back{
	background-color:#efe2e2 !important;
	color: #e06a26;
}

/*Tool tip for css */
[tooltip]:before {
	position : absolute;
	content : attr(tooltip);
	opacity : 0;
}
[tooltip]:hover:before {
	opacity : 1;
	font-size: 12px;
	top: -10px;
	left: 17px;
	background: #0A246A;
	color: white;
}
[tooltip]:not([tooltip-persistent]):before {
	pointer-events: none;
}
div.mce-fullscreen {
	z-index: 1050;
}
.alert-custom
{
	padding-left: 10px;
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 25px;
	margin-bottom: 20px;
}
.alert-custom-bg
{
	background-color: #0D8409;
	color:#fff;
}
#announcement-header
{
	height:30px!important;
}
