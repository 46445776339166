.left-sidebar-brandimage img {
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 25px
}

.left-sidebar-menu .badge {
    background: #de6926;
}

.sidebar-form {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 35px
}

.sidebar-form .form-control {
    background: #222c3c;
    border: 1px solid #313d4f;
    color: #7f8fa4;
    border-right-width: 0;
    border-top-width: 0;
    background-color: transparent;
    border-left-width: 0;
}

.sidebar-form .input-group-btn .btn,
.sidebar-form .input-group-btn a {
    background: #222c3c;
    border-color: #313d4f!important;
    color: #7f8fa4!important;
    border-left-width: 0;
    border-top-width: 0;
    background-color: transparent;
    border-right-width: 0;
    padding-bottom: 7px
}

.user-details-sidebar {
    padding-top: 35px;
    padding-left: 30px;
    padding-right: 30px
}

.user-details-sidebar .img-60 {
    margin-bottom: 17px
}

.user-details-sidebar .user-name {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize
}

.user-details-sidebar .user-list-group {
    margin-top: 10px
}

.user-details-sidebar .user-list-group .list-group-item {
    background-color: transparent;
    border-width: 0;
    padding-left: 0;
    padding-right: 0;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #7f8fa4;
    text-transform: uppercase
}

.user-details-sidebar .user-list-group .list-group-item .pull-right {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #fff
}

.user-details-sidebar .btn {
    margin-bottom: 8px
}

.following-group {
    margin-top: 15px
}

.following-group .group-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase
}

.following-group .group-heading .btn-setting {
    margin-top: 0
}

.following-group .list-group-item {
    background-color: transparent;
    border-width: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 18px
}

.following-group .list-group-item .media .media-left {
    padding-right: 12px
}

.following-group .list-group-item .media .media-body .media-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    text-decoration: none;
    margin-bottom: -2px
}

.following-group .list-group-item .media .media-body .active-ago {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    vertical-align: middle
}

.following-group .list-group-item .media .media-body .text-muted {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    font-weight: 400;
    color: #7f8fa4
}

.chat-list {
    position: fixed;
    top: 60px;
    right: 0;
    height: 100%;
    background-color: #fafbfc;
    border-left: 1px solid #e6eaee;
    z-index: 9
}

@media (max-width:1325px) {
    .chat-list {
        display: none
    }
}

.chat-list .left-sidebar.owos {
    width: 240px;
    display: table
}

@media (max-width:1660px) {
    .chat-list .left-sidebar.owos {
        transition: all .3s ease!important;
        margin-right: -183px
    }
    .chat-list .left-sidebar.owos .following-group {
        transition: all .3s ease!important
    }
    .chat-list .left-sidebar.owos:hover {
        transition: all .3s ease!important;
        margin-right: 0
    }
    .chat-list .left-sidebar.owos:hover .following-group {
        transition: all .3s ease!important
    }
}

.chat-list .left-sidebar.owos .following-group {
    margin-top: -10px;
    height: 1000px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 30px;
}

.chat-list .left-sidebar.owos .following-group .list-group-item {
    padding: 0
}

.chat-list .left-sidebar.owos .following-group .list-group-item a {
    padding: 10px 15px;
    display: block
}

.chat-list .left-sidebar.owos .following-group .list-group-item a:hover {
    background-color: #eff3f6
}

.chat-list .left-sidebar.owos .following-group .media-left img {
    width: 30px;
    height: 30px;
}

.chat-list .left-sidebar.owos .following-group .media-body {
    vertical-align: middle
}

.chat-list .left-sidebar.owos .following-group .media-body .active-ago {
    margin-top: 5px
}

.chat-list .left-sidebar.owos .following-group .media-heading {
    width: 120px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #354052
}

.chat-list .left-sidebar.owos .following-group .group-heading {
    padding: 15px;
    padding-top: 26px;
    color: #354052;
    display: none
}

.chat-list .left-sidebar.owos .following-group .group-heading a {
    display: inline;
    padding: 0;
    background-color: transparent
}

.chat-list .left-sidebar.owos .following-group .group-heading a:hover {
    background-color: transparent
}

.chat-box {
    width: 260px;
    height: 304px;
    position: fixed;
    background-color: #fff;
    z-index: 9;
    bottom: 0;
    right: 255px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19)
}

.chat-box .chat-box-header {
    height: 30px;
    background-color: #de6926;
    border-bottom: 1px solid #e6eaee;
}

.chat-box .chat-box-header:hover {
    background-color: #c34e0b
}

.chat-box .chat-box-header .side-left {
    text-transform: capitalize;
    line-height: 28px;
    padding-left: 10px
}

.chat-box .chat-box-header .side-left a {
    color: #fff;
    text-decoration: none
}

.chat-box .chat-box-header .list-inline {
    margin-bottom: 0;
    padding-right: 5px
}

.chat-box .chat-box-header .list-inline li a i {
    line-height: 28px;
    color: #fff
}

.chat-box .chat-conversation {
    padding: 10px;
    height: 235px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-left: 1px solid #e6eaee;
    border-right: 1px solid #e6eaee
}

.chat-box .chat-conversation .message-conversation {
    padding-bottom: 18px
}

.chat-box .chat-conversation .message-conversation .media {
    position: relative
}

.chat-box .chat-conversation .message-conversation .media .media-left {
    padding-right: 4px
}

.chat-box .chat-conversation .message-conversation .media .media-left img {
    width: 28px;
    height: 28px;
}

.chat-box .chat-conversation .message-conversation .media .media-body .post-text {
    color: #354052;
    font-size: 12px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    line-height: 15px;
    border: 1px solid #e6eaee;
    padding: 5px 8px;
    padding-right: 15px;
    background-color: #fafbfc;
    margin-bottom: 0;
    display: inline-block;
    direction: ltr;
}

.chat-box .chat-conversation .message-conversation.current-user .media {
    direction: rtl
}

.chat-box .chat-conversation .message-conversation.current-user .media .media-left {
    padding-right: 0
}

.chat-box .chat-conversation .message-conversation.current-user .media .media-body {
    text-align: right
}

.chat-box .chat-conversation .message-conversation.current-user .media .media-body .post-text {
    text-align: left;
    padding-left: 15px;
    margin-right: 4px;
    padding-right: 4px
}

.chat-box .message-input {
    border-top: 1px solid #e6eaee;
    margin-top: 5px;
    border: 1px solid #e6eaee
}

.chat-box .message-input .form-group {
    margin-bottom: 4px
}

.chat-box .message-input .form-group .form-control {
    border-width: 0;
    font-size: 12px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding-top: 4px
}

.chat-box .message-input .list-inline {
    margin-left: 0;
    padding-left: 5px
}

.chat-box .message-input .list-inline li:first-child {
    padding-left: 10px
}

.chat-box .message-input .list-inline li a i {
    font-size: 16px;
    color: #9fa9ba
}

.chat-box:nth-child(2) {
    margin-right: 270px
}

.chat-box:nth-child(3) {
    margin-right: 540px
}

.chat-box:nth-child(4) {
    margin-right: 810px
}

.chat-box:nth-child(5) {
    margin-right: 1080px
}

.chat-box:nth-child(6) {
    margin-right: 1350px
}

.chat-box-small {
    height: 28px
}

.chat-box-small .chat-conversation,
.chat-box-small .message-input {
    display: none
}
.group-heading { 
    display:block !important;
}