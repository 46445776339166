.panel-default {
    background: #fff;
    border: 1px solid #e6eaee;
    box-shadow: none
}

.panel-default .panel-heading {
        color: #354052;

}
.panel-default .panel-heading .panel-title {
    color: #354052;
     font-size: 16px;
     font-weight: 600;
     line-height: 28px;
     text-transform: capitalize;
}

.panel-default .panel-heading .panel-title a {
    color: #bd5011;
    font-size: 12px;
    text-decoration: none!important
}

.panel-default .panel-heading .panel-title a:hover {
    color: #009bd6
}

.panel-default .panel-heading.no-bg {
    background: none;
    border-color: transparent
}

.panel-default .panel-body.nopadding-top-bottom {
    padding: 0 15px
}

.panel-default .panel-body.nopadding,
.panel-default .panel-heading.nopadding {
    padding: 0
}

.panel-default .panel-body.bg-grey {
    background-color: #eff3f6
}

.nav-tabs {
    border-bottom-width: 0
}

.nav-tabs li a {
    background: #fff;
    border: 1px solid #e6eaee;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #354052;
    font-size: 14px;
    margin-bottom: -1px;
    text-transform: capitalize;
    padding-left: 20px;
    padding-right: 20px
}

.nav-tabs li a:hover {
    border-bottom-width: 0
}

.nav-tabs li a:active,
.nav-tabs li a:focus,
.nav-tabs li a:hover {
    background: #edf2f7
}

.nav-tabs li.active a {
    border-top: 1px solid #e6eaee!important;
    border-left: 1px solid #e6eaee!important;
    border-right: 1px solid #e6eaee!important;
    background-image: none;
    line-height: 26px;
    color: #354052;
    margin-top: -5px
}

.tab-content {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #e6eaee;
    box-shadow: none!important
}
/*
* Style for dynamic table inside tab-content div for displaying in event about
*/
.tab-content table{
    max-width: 583px !important;
}
.panel>.panel-body+.table {
    border-top: 1px solid #e6eaee
}

.table {
    background-color: #fff
}

.table>thead>tr>th {
    text-transform: capitalize;
    color: #7f8fa4;
    background-color: #f5f8fa;
    border-top: 1px solid #e6eaee;
    border-bottom: 1px solid #e6eaee
}

.table>thead>tr>th .sort {
    float: right;
    margin-right: 10px
}

.table>thead>tr>th:first-child {
    padding-left: 20px
}

.table>tbody>tr>td {
    color: #354052;
    vertical-align: middle;
    border-bottom: 1px solid #e6eaee
}

.table>tbody>tr>td .text-muted {
    font-weight: 400
}

.table>tbody>tr>td:first-child {
    padding-left: 20px
}

.table-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 24px;
    color: #354052
}

.table-heading .sub-text {
    color: #7f8fa4;
    font-size: 14px;
    margin-left: 8px;
    border-left: 1px solid #e6eaee;
    padding-left: 8px
}


/* above is doubt full */


/**/


/*

********************************************************************************************************************************
********************************************************************************************************************************

*/

.navbar {
    min-height: 50px!important
}

.navbar.owos {
    /*Override for Homepage*/
    /*border-bottom: 1px solid #e6eaee;*/
    border-bottom: medium none;
    height: 70px;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.navbar-brand.owos {
    padding-top: 6px;
    height: 50px;
}

.no-bg {
    background-color: #fff;
}

.post-addon {
    border-top: 1px solid #e6eaee;
    border-bottom: 1px solid transparent;
    vertical-align: middle
}

.post-addon .post-addon-icon {
    display: inline-block;
    float: left;
    border-right: 1px solid #d3d4d5;
    margin-top: 10px
}

.post-addon .post-addon-icon i {
    color: #9fa9ba
}

.post-addon .post-addon-icon .fa-map-marker {
    padding-left: 13px;
    padding-right: 13px
}

.post-addon .post-addon-icon .fa-music {
    padding-right: 13px
}

.post-addon .post-addon-icon .fa-music.fa-spinner,
.post-addon i {
    padding-right: 10px
}

.post-addon i {
    padding-left: 10px;
    padding-bottom: 2px;
    font-size: 13px;
    color: #354052;
    font-weight: 400
}

.post-addon .form-group {
    padding-left: 36px;
    margin-bottom: 0
}

.post-addon .form-group .form-control {
    background-color: #fff!important;
    border-width: 0
}

.post-addon .form-group .youtube-text {
    border: 1px solid transparent;
    box-shadow: none;
    outline: none;
    float: right;
    padding-left: 8px;
    color: #354052;
    font-size: 13px;
    font-weight: 400
}

.post-addon .form-group .selectize-control {
    border: 0;
    background-color: transparent!important
}

.post-addon .form-group .selectize-control .selectize-input {
    border: 0;
    padding-left: 0;
    padding-top: 8px;
    padding-bottom: 0
}

.post-addon .form-group .selectize-control .selectize-input input {
    color: #354052
}

.post-addon .form-group .selectize-control .selectize-input input::-webkit-input-placeholder {
    color: #c3c6cb
}

.post-addon .form-group .selectize-control .selectize-input input:-moz-placeholder,
.post-addon .form-group .selectize-control .selectize-input input::-moz-placeholder {
    color: #c3c6cb
}

.post-addon .form-group .selectize-control .selectize-input input:-ms-input-placeholder {
    color: #c3c6cb
}

.post-addon .form-group .selectize-control .selectize-input.focus {
    outline: none;
    box-shadow: none
}

.post-addon .form-group .selectize-control .selectize-dropdown {
    padding-left: 0;
    border-color: #e6eaee
}

.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown {
    background-color: #fff;
    padding-top: 15px;
    padding-left: 15px
}

.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-left img {
    border-radius: 0
}

.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-body {
    max-height: 24px;
    overflow: hidden
}

.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-body .media-heading {
    margin-bottom: 0;
    color: #354052;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 600
}

.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-body p {
    color: #7f8fa4;
    font-size: 12px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    height: 17px;
    overflow: hidden;
    margin-bottom: 10px;
    line-height: 15px
}
.group_header_page{
    max-height: 284px !important;
}
.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown.active {
    background-color: #fafbfc
}

.emoticons-wrapper {
    margin-bottom: 0;
    border-top: 1px solid #e6eaee;
    width: 100%;
    display: none
}

.emoticons-wrapper a {
    margin-left: 8px
}

.emoticons-wrapper a img {
    padding: 7px 2px 7px 3px
}

.images-selected {
    padding: 5px 10px;
    border-top: 1px solid #e6eaee;
    font-size: 13px;
    color: #898f9c;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

#post-image-holder img {
    width: auto!important;
    height: 60px!important;
    margin-right: 5px;
    padding-left: 13px
}

.post-image-holder a {
    width: 32.5%;
    max-height: 100px;
    margin-bottom: 10px;
    display: inline-block;
    background-size: cover!important;
    overflow: hidden
}

.post-image-holder.single-image {
    max-height: inherit!important;
    height: auto
}

.post-image-holder.single-image a {
    max-height: inherit;
    height: auto;
    width: 100%
}

.post-image-holder.single-image a img {
    max-height: inherit!important;
    height: auto;
    width: 100%
}

.soundcloud-results,
.user-results {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll
}

.soundcloud-results img,
.user-results img {
    width: 30px;
    height: 30px
}

.soundcloud-wrapper {
    height: 166px;
    margin-top: 10px;
    overflow: hidden
}

.selectize-input {
    min-width: 260px
}

.big-search-dropdown {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 12px
}

.big-search-dropdown img {
    width: 38px;
    height: auto
}

.big-search-dropdown h4 {
    font-size: 14px;
    font-weight: 600
}

.selectize-dropdown-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px
}

.youtube-iframe {
    margin-bottom: -5px
}

.youtube-iframe iframe {
    width: 100%;
    height: 360px
}

.img-rectangle {
    width: 40px;
    height: 40px;
}

.announcement p {
    line-height: 20px;
    font-size: 13px
}

.announcement h3 {
    margin-top: 0;
    margin-bottom: 5px
}

.mCustomScrollbar .mCSB_inside>.mCSB_container {
    margin-right: 0
}

.verified-badge {
    width: 20px;
    height: 20px;
    background: #2ab314;
    border-radius: 50%;
    padding: 3px;
    line-height: 15px;
    vertical-align: text-bottom;
    margin-left: 10px;
    color: #fff;
    display: inline-block
}

.verified-badge.verified-small {
    width: 17px;
    height: 17px;
    padding: 2px;
    line-height: 13px
}

.verified-badge.verified-medium {
    padding: 2px;
    margin-left: 0
}

.owos-logo {
    height: auto;
    margin-top: 8px;
}

a:active,
a:focus,
a:hover {
    text-decoration: none
}

.media-heading a {
    color: #354052
}

.padd-left300 {
    padding-left: 18.4%;
    margin-top: 21px;
}

.widget-left-panel h3 {
    /*border-left: 5px solid #de6926;*/
    height: 40px;
    padding-left: 13px;
    margin-bottom: -1px;
}

.bg-remove {
    border: none !important;
    background: none !important
}

blockquote {
    font-family: Georgia, serif;
    font-size: 15px;
    font-style: italic;
    width: 500px;
    margin: 0.25em 0;
    padding: 0.25em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;
}

blockquote:before {
    display: block;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #df6708;
}

blockquote cite {
    color: #df6708;
    font-size: 14px;
    display: block;
    margin-top: 5px;
}

blockquote cite:before {
    content: "\2014 \2009";
}

.owos-event-widget ul {
    margin-left: -58px;
    margin-top: 0px;
}

.owos-event-widget li {
    list-style: none;
    margin-bottom: 15px;
}

.owos-event-widget li a {
    color: #333;
    padding-left: 20px;
    font-size: 18px;
}

.owos-event-widget li a:hover {
    color: #df6708;
    text-decoration: underline;
}

.owos-event-widget {
    margin-top: 0px;
}


.mb--10 {
    margin-bottom: -10px !important;
}

.owos-event-widget p {
    line-height: 24px;
}

.owos-event-widget h2 {
    text-transform: uppercase;
    margin-top: 0px;
}

.right-pad0 {
    padding-right: 0px !important;
}

.left-pad0 {
    padding-left: 0px !important;
}

.right-pad3 {
    padding-right: 3px !important;
}

.left-pad2 {
    padding-left: 2px !important;
}

.height165 {
    height: 165px !important;
}

.height30{
    height: 30px !important;
}
.owos-footer-main {
    margin-top: 25px;
}

.owos-footer h3 {
    font-size: 18px;
    text-transform: uppercase;
}

.owos-footer li {
    list-style: none;
}

.footer-link-ul {
    margin: -8px 0px 0px -40px;
}

.owos-footer li a {
    color: #333;
    font-size: 14px;
}

.owos-footer li a:hover {
    text-decoration: underline;
}

/*.footer_copy a::before {
	content: "·";
	padding: 0 10px;
	cursor:none;
}*/

.copyright span {
    color: #333;
    float: left;
    font-size: 14px;
    margin-top: -7px;
}

.footer-widget-social li {
    float: left;
    margin-right: 20px;
}

.footer_copy2 {
    font-size: 14px;
    font-weight: normal !important;
}

.bg-empty {
    background: none !important;
    border: none !important
}

.saiconnect-h3 {
    border-bottom: 1px solid #fff;
    color: #df6708;
    font-size: 25px;
    margin-top: 40px;
    padding-bottom: 23px;
}

.fnt-50 {
    color: #df6708;
    font-size: 50px;
}

.saiconnect-text {
    font-size: -20px;
}

.saiconnect-heading1 {
    color: #df6708;
    font-size: 30px;
}

.saiconnect-heading2 {
    font-size: 20px;
}

.padd-20 {
    padding: 20px 20px 30px 20px !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    background: #fff !important;
}

.padd-30 {
    padding: 0px!important;
    margin-top: 0px !important;
}

.login-padd {
    padding: 250px 20px 150px 20px!important;
}

.owos-login-old {
    padding: 20px!important;
}

.mt--30 {
    margin-top: -32px;
}

.volunter-btn {
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: 18px;
    color: #333;
    margin-top: 13px;
}

.volunter-btn:hover {
    color: #df6708;
    text-decoration: underline;
}

.orange-bg {
    color: #df6708
}

.contribute-vlt a {
    color: #df6708 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    font-weight: bold !important
}

.contribute-vlt a:hover {
    color: #fff !important;
}

.contribute-vlt {
    border-left: 1px solid #e6eaee;
    border-right: none !important;
}

.owos-event-form {
    width: 213px !important;
}

.owos-event-form2 {
    width: 533px !important;
}

.owos-event-form-btn {
    width: 100px !important;
    margin-left: 0px !important;
}

.owos-name-event {
    font-weight: bold;
    font-size: 18px;
    padding-left: 15px;
    margin: 20px 0px 5px 0px;
}

.owos-media-brd {
    border-bottom: 1px solid #e6eaee;
    margin: 10px 15px !important;
    padding: 5px 5px 20px 5px;
}

.owos-media-brd:first-child {
    margin-top: -10px !important;
}

.owos-media-dropdown {
    float: right;
    margin-top: 5px;
}

.owos-media-dropdown2 {
    float: right;
    margin-top: -83px;
}

.owos-media-dropdown2 ul {
    left: -150px;
}

.owos-media-dropdown ul {
    left: -148px;
}

.owos-media-flag {
    float: right;
    margin-right: 20px;
    margin-top: -40px;
}

.invite-left0 {
    padding-left: 0px;
}

.invite-right0 {
    padding-left: 0px;
}

.owos-login-input {
    height: 30px;
    margin-top: 15px;
}
.owos-login-input-min-height {
    height: 30px;
    width: 180px;
}
.owos-login-btn {
    height: 28px;
    margin-top: 15px;
    border: 2px solid #fff;
    background: none;
    color: #fff;
    font-size: 14px;
    padding: 0px 10px;
}

.owos-login-btn:hover {
    background: #863100;
}

.owos-forgot-a {
    color: #fff;
    font-size: 13px;
    float: left;
    margin-top: -13px
}

.owos-forgot-a:hover {
    text-decoration: underline;
    color: #fff;
}

.width200 {
    width: 303px !important;
}

.invite2-left0 {
    padding-left: 0px;
}

.invite3-padd0 {
    padding: 0px;
}

.owos-spin-add {
    top: -135px !important;
    margin-left: 452px !important;
    height: 31px !important;
    line-height: 28px !important;
    z-index: 1111;
    border-radius: 0 !important;
    width: 80px !important;
}

.delete-impact-img {
    float: left;
    margin-top: -100px
}

.upload-impact-img {
    float: left;
    margin-top: -10px;
    padding: 7px;
    border: 1px solid;
}

.upload-impact-img i {
    font-size: 24px;
    padding-left: 10px;
}

.impact-thumb h3 {
    color: #000;
    font-size: 14px;
    margin-top: 5px;
    line-height: 18px !important;
    margin-bottom: 5px;
}

.impact-thumb {
    background: #fff;
    border: none !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    min-height: 230px;
}

.impact-thumb:hover {
    background: #f4f7fa;
    border: #fbfbfb!important;
}

.impact-thumb img {
    min-height: 170px;
}

#bs-example-navbar-collapse-4{
    position: relative;
}

@media (min-width:1200px) {

    #bs-example-navbar-collapse-4 .login-errors {
        position: absolute;
        top: 58px;
        left: 13px;
        line-height: 12px;
        font-size: 12px;
        width: 224px;
    }
}
.login_pos_error_chg{
    position: absolute;
    left: -40px;
}
.account_lock_error_chg{
    position: absolute;
    left: -47px;
}
.login-errors {
    color: red;
   /* float: left; */
    /* font-size: 13px;
    margin-top: -12px;
    width: 100%; */
}

.login-errors-2 {
    color: #dd6826;
    font-size: 14px;
    font-weight: bold;
}

.error-block {
    background: url(../images/error-bg.png) no-repeat;
    background-position: center center;
    padding: 150px 50px;
    height: 450px;
    margin: 100px 0px;
}

.error-block h1 {
    font-size: 75px;
}

.error-block p {
    font-size: 24px;
    padding: 10px 130px;
}

.impact-pad0 {
    0px !important;
}

@media screen and (min-width:320px) and (max-width:768px) {
    video {
        width: 100% !important;
    }
    #video-background {
        height: 350px !important;
        background: #000;
    }
    .emial_holder {
        margin-bottom: 10px;
        padding: 0px 15px;
    }
    .emial_holder2 {
        padding: 0px 15px;
    }
    .emial_holder3 {
        padding: 0px;
    }
    .emial_holder4 {
        padding: 0px;
    }
    .email_margin {
        margin-bottom: 0px;
        padding-top: 10px;
    }
    .footer_copy {
        font-size: 14px !important;
        padding-top: 0px;
    }
    .footer_copy,
    .footer_copy a {
        font-size: 15px !important;
        margin-top: -5px;
    }
    .footer_copy2 {
        font-size: 13px !important;
    }
    .footer-widget-social {
        margin-top: 0px;
    }
    .register-col-5 {
        width: 97%;
    }
    .login-panel-col {
        width: 100%;
    }
    .event-col-left-3,
    .event-col-6,
    .event-col-3 {
        width: 100%;
        margin: 0px;
    }
    .event-col-left-3 {
        margin-top: 30px;
    }
    .invite-left0 {
        padding: 0px;
        margin-top: 20px;
    }
    .invite-right0 {
        padding: 0px;
        margin-top: 20px;
    }
    .footer_copy a::before {
        padding: 0 3px;
    }
    .footer_copy {
        margin-top: 15px;
    }
    .signup-p {
        margin: 10px 0px 0px 0px;
    }
    .width200 {
        width: 100% !important;
    }
    .owos-footer-main {
        margin-top: 10px;
    }
    .owos-footer h3 {
        margin-top: 10px;
    }
    .signup-p {
        margin-top: 10px !important;
    }
    .invite2-left0 {
        padding: 0px 15px;
    }
    .awarness-h3 {
        margin-top: 10px;
    }
    .owos-event-form {
        width: 100% !important;
    }
    .owos-event-form2 {
        width: 100% !important;
    }
    .logout a {
        line-height: 1px !important;
    }
    .bg-empty {
        margin-top: -40px;
    }
    .panel-heading.panel-settings {
        padding-left: 27px;
        padding-top: 24px;
    }
    blockquote {
        width: 100%;
    }
    .nav-tabs>li {
        width: 100%;
    }
    ul.page-breadcrumb li {
        width: 100%;
    }
    .responsive-login-owos {
        width: 113%;
        margin: 14px -20px 0 0 !important;
        padding: 15px 50px 26px 30px;
    }
    .mt-140,
    .mt-40,
    .mt-60,
    .mt-80,
    .mt-100 {
        margin-top: 10px;
    }
    .owos-login-btn {
        margin: 20px 0px 0px -100px;
    }
    .saiconnect-h3 {
        font-size: 20px;
        margin-top: 70px;
        margin-bottom: 10px;
    }
    .fnt-50 {
        font-size: 20px;
    }
    .login-block {
        padding-top: 30px
    }
    .saiconnect-heading1 {
        font-size: 22px;
    }
    .saiconnect-heading2 {
        font-size: 16px;
    }
    .login-bottom {
        padding: 7px 40px 0 0px
    }
    .padd-left300 {
        padding-left: 0px;
    }
    .error-block {
        padding: 50px;
        height: auto;
        margin: 30px;
        width: 100%
    }
    .error-block h1 {
        font-size: 30px;
    }
    .error-block p {
        font-size: 18px;
        padding: 10px 50px;
    }
}

.owos-rqst {
    float: right;
    margin: -50px 40px 0px 0px !important;
}

.owos-accept {
    margin-left: 18px;
}

.team-acpt {
    margin-right: 10px !important;
    margin-top: -49px !important;
}

.owosdropmenu2 {
    border: 1px solid #ccc !important;
    left: -90px !important;
    padding: 3px !important;
}

.owosdropmenu2 a {
    text-align: left !important;
}

.owosdropmenu2 a:active,
.owosdropmenu2 a:focus,
.owosdropmenu2 a:hover {
    background: none !important;
    color: #333 !important;
}

.owos-nb-btn:active,
.owos-nb-btn:focus,
.owos-nb-btn:hover {
    background: none !important;
    box-shadow: none !important;
}

.owos-nb-btn,
.open {
    background: none !important;
    box-shadow: none !important;
}

.owos-nb-btn {
    margin-top: 4px;
    border: none !important;
}

.owos-down-arrow {
    color: #859ab5 !important;
    font-size: 20px;
    float: right;
}
.m-0 {
	margin: 0!important
}
.p-0 {
	padding: 0!important
}
.mt-0 {
	margin-top: 0!important
}
.mt-10 {
	margin-top: 10px!important
}
.mt-15 {
	margin-top: 15px!important
}
.mt-20 {
	margin-top: 20px!important
}
.mt-30 {
	margin-top: 30px!important
}
.mt-40 {
	margin-top: 40px!important
}
.mt-50 {
	margin-top: 50px!important
}
.mt-60 {
	margin-top: 60px!important
}
.mt-70 {
	margin-top: 70px!important
}
.mt-80 {
	margin-top: 80px!important
}
.mt-90 {
	margin-top: 90px!important
}
.mt-100 {
	margin-top: 100px!important
}
.mt-110 {
	margin-top: 110px!important
}
.mt-120 {
	margin-top: 120px!important
}
.mt-130 {
	margin-top: 130px!important
}
.mt-140 {
	margin-top: 140px!important
}
.mt-150 {
	margin-top: 150px!important
}
.mr-0 {
	margin-right: 0!important
}
.mr-10 {
	margin-right: 10px!important
}
.mr-15 {
	margin-right: 15px!important
}
.mr-20 {
	margin-right: 20px!important
}
.mr-30 {
	margin-right: 30px!important
}
.mr-40 {
	margin-right: 40px!important
}
.mr-50 {
	margin-right: 50px!important
}
.mr-60 {
	margin-right: 60px!important
}
.mr-70 {
	margin-right: 70px!important
}
.mr-80 {
	margin-right: 80px!important
}
.mr-90 {
	margin-right: 90px!important
}
.mr-100 {
	margin-right: 100px!important
}
.mr-110 {
	margin-right: 110px!important
}
.mr-120 {
	margin-right: 120px!important
}
.mr-130 {
	margin-right: 130px!important
}
.mr-140 {
	margin-right: 140px!important
}
.mr-150 {
	margin-right: 150px!important
}
.mb-0 {
	margin-bottom: 0!important
}
.mb-5 {
	margin-bottom: 5px!important
}
.mb-10 {
	margin-bottom: 10px!important
}
.mb-15 {
	margin-bottom: 15px!important
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-30 {
	margin-bottom: 30px!important
}
.mb-40 {
	margin-bottom: 40px!important
}
.mb-50 {
	margin-bottom: 50px!important
}
.mb-60 {
	margin-bottom: 60px!important
}
.mb-70 {
	margin-bottom: 70px!important
}
.mb-80 {
	margin-bottom: 80px!important
}
.mb-90 {
	margin-bottom: 90px!important
}
.mb-100 {
	margin-bottom: 100px!important
}
.mb-110 {
	margin-bottom: 110px!important
}
.mb-120 {
	margin-bottom: 120px!important
}
.mb-130 {
	margin-bottom: 130px!important
}
.mb-140 {
	margin-bottom: 140px!important
}
.mb-150 {
	margin-bottom: 150px!important
}
.ml-0 {
	margin-left: 0!important
}
.ml-10 {
	margin-left: 10px!important
}
.ml-15 {
	margin-left: 15px!important
}
.ml-20 {
	margin-left: 20px!important
}
.ml-30 {
	margin-left: 30px!important
}
.ml-40 {
	margin-left: 40px!important
}
.ml-50 {
	margin-left: 50px!important
}
.ml-60 {
	margin-left: 60px!important
}
.ml-70 {
	margin-left: 70px!important
}
.ml-80 {
	margin-left: 80px!important
}
.ml-90 {
	margin-left: 90px!important
}
.ml-100 {
	margin-left: 100px!important
}
.ml-110 {
	margin-left: 110px!important
}
.ml-120 {
	margin-left: 120px!important
}
.ml-130 {
	margin-left: 130px!important
}
.ml-140 {
	margin-left: 140px!important
}
.ml-150 {
	margin-left: 150px!important
}
.pt-0 {
	padding-top: 0!important
}
.pt-10 {
	padding-top: 10px!important
}
.pt-15 {
	padding-top: 15px!important
}
.pt-20 {
	padding-top: 20px!important
}
.pt-30 {
	padding-top: 30px!important
}
.pt-40 {
	padding-top: 40px!important
}
.pt-50 {
	padding-top: 50px!important
}
.pt-60 {
	padding-top: 60px!important
}
.pt-70 {
	padding-top: 70px!important
}
.pt-80 {
	padding-top: 80px!important
}
.pt-90 {
	padding-top: 90px!important
}
.pt-100 {
	padding-top: 100px!important
}
.pt-110 {
	padding-top: 110px!important
}
.pt-120 {
	padding-top: 120px!important
}
.pt-130 {
	padding-top: 130px!important
}
.pt-140 {
	padding-top: 140px!important
}
.pt-150 {
	padding-top: 150px!important
}
.pr-0 {
	padding-right: 0!important
}
.pr-10 {
	padding-right: 10px!important
}
.pr-15 {
	padding-right: 15px!important
}
.pr-20 {
	padding-right: 20px!important
}
.pr-30 {
	padding-right: 30px!important
}
.pr-40 {
	padding-right: 40px!important
}
.pr-50 {
	padding-right: 50px!important
}
.pr-60 {
	padding-right: 60px!important
}
.pr-70 {
	padding-right: 70px!important
}
.pr-80 {
	padding-right: 80px!important
}
.pr-90 {
	padding-right: 90px!important
}
.pr-100 {
	padding-right: 100px!important
}
.pr-110 {
	padding-right: 110px!important
}
.pr-120 {
	padding-right: 120px!important
}
.pr-130 {
	padding-right: 130px!important
}
.pr-140 {
	padding-right: 140px!important
}
.pb-0 {
	padding-bottom: 0!important
}
.pb-10 {
	padding-bottom: 10px!important
}
.pb-15 {
	padding-bottom: 15px!important
}
.pb-20 {
	padding-bottom: 20px!important
}
.pb-30 {
	padding-bottom: 30px!important
}
.pb-40 {
	padding-bottom: 40px!important
}
.pb-50 {
	padding-bottom: 50px!important
}
.pb-60 {
	padding-bottom: 60px!important
}
.pb-70 {
	padding-bottom: 70px!important
}
.pb-80 {
	padding-bottom: 80px!important
}
.pb-90 {
	padding-bottom: 90px!important
}
.pb-100 {
	padding-bottom: 100px!important
}
.pb-110 {
	padding-bottom: 110px!important
}
.pb-120 {
	padding-bottom: 120px!important
}
.pb-130 {
	padding-bottom: 130px!important
}
.pb-140 {
	padding-bottom: 140px!important
}
.pb-150 {
	padding-bottom: 150px!important
}
.pl-0 {
	padding-left: 0!important
}
.pl-10 {
	padding-left: 10px!important
}
.pl-15 {
	padding-left: 15px!important
}
.pl-20 {
	padding-left: 20px!important
}
.pl-30 {
	padding-left: 30px!important
}
.pl-35 {
	padding-left: 35px!important
}
.pl-40 {
	padding-left: 40px!important
}
.pl-50 {
	padding-left: 50px!important
}
.pl-60 {
	padding-left: 60px!important
}
.pl-70 {
	padding-left: 70px!important
}
.pl-80 {
	padding-left: 80px!important
}
.pl-90 {
	padding-left: 90px!important
}
.pl-100 {
	padding-left: 100px!important
}
.pl-110 {
	padding-left: 110px!important
}
.pl-120 {
	padding-left: 120px!important
}
.pl-130 {
	padding-left: 130px!important
}
.pl-140 {
	padding-left: 140px!important
}
.pl-150 {
	padding-left: 150px!important
}
.border-none{
    border: none !important;
}
.panel-heading.panel-settings {
    padding-left: 27px;
    padding-top: 24px;
}

.panel-heading.panel-settings .panel-title {
    font-family: Helvetica Neue,sans-serif,Helvetica,Arial;
    font-size: 24px;
    font-weight: 400;
}
.event-h5 {
	border-left: 5px solid #df6708;
	line-height: 40px;
	margin-left: -5px;
    padding: 10px 0;
}
.emial_holder2 {
    background: #e5e3dc none repeat scroll 0 0;
    border: 2px solid #494949;
    color: #333;
    height: 49px;
    width: 100%;
}
#invite_email_btn {
    background: #e5e3dc none repeat scroll 0 0;
    border: 2px solid #494949;
    color: #333;
    height: 49px;
    width: 233px;
    font-size: 15px;
}

.emial_holder {
    background: #fff none repeat scroll 0 0 !important;
    padding-left: 12px !important;
}
.emial_holder {
    padding-left: 0;
}
.emial_holder {
    border: 1px solid #494949;
    height: 50px;
    width: 100%;
}
.audit-p {
    margin-left: 20px;
    word-wrap:break-word;
}

.width_wel{
    width: 1140px;
    margin-right: 0px;
}

.wid_full{
    width:100%
}
.readmore{
  float: right;
   margin: -10px auto;
   padding: 1px 13px 0px 0px;
   background: #df6708;
   color:white;
}
.pgn-in-show{
    display:none;
}
.event-dt-hgt h3{
    height:27px;
}
@media (max-width: 1200px) {
   .event-dt-hgt h3{
    height: auto;
  }
}
.inpt_widht_sz{
    width:50px ;
    color:#e06a26 ;
}

.event_left_align{
    margin-left: -13px;
}
.event_about_align{
    margin-top: -20px;
}

.event_impct__align{
    margin-top: 20px;
}
.event_time_zn_align{
    font-size: 12px;
    font-weight:normal!important;
}
.event_stas_size{
    font-size: 12px!important;
}
.event_fa_ic_size{
    font-size: 12px!important;
    margin-right: 0!important;
}
.event_time_pad_align{
    padding-right:10px !important;
}
/* owos-605-announcement-changes*/
#announcement-header-register{
    background: #ccc;
    padding: 0px 25px 0px 0px;
    margin-top:-10px;
    height: 33px !important;

}
 #announcement-header-register .arrow_box {
    position: relative;
    background: #009933;
     z-index: 500;
     padding: 5px 5px;
      color: #fff;
      float: left;
}
 #announcement-header-register .arrow_box:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
/*    border-color: rgba(204, 204, 204, 0);*/
    border-left-color: #009933;
    border-width: 10px;
    margin-top: -10px;
      }
#announcement-header {
        background: #ccc;
          padding: 0px 25px 0px 0px;
          margin-top:-3px;
          height: 33px !important;
      }
 #announcement-header .arrow_box {
   position: relative;
   background: #009933;
    z-index: 500;
    padding: 5px 5px;
     color: #fff;
     float: left;
}
 #announcement-header .arrow_box:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
/*    border-color: rgba(204, 204, 204, 0);*/
    border-left-color: #009933;
    border-width: 10px;
    margin-top: -10px;
      }
     .announcementbox {
         display: flex;
         margin: 5px 25px 0px 30px;
         color: #000;
         text-align: left;
         min-height:25px !important;

      }
/*style for projec name in event-left*/
.project_name_srt{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.user_profile_align{
    padding-left: 0px;
    margin-left: 0px !important;
}
.user_pro_pad{
    padding-right: 0px;
}
#globevideolaunch
{
  margin: auto;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0%;
  visibility: visible;
  display: none;
  width: 1905px;
  height: auto;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
#globevideopreload
{
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-image: none;
}
.closeoverride{
  margin : 0 -500px 0px 0px;
}
.closeoverideheader{
    font-size:25px;
    color:red
  }

/*
For announcement responsive have to call this class name
*/
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
.announcement-header-mbl {
          width:652px !important;
          text-size:8px;
          font-size: smaller;
          height: 25px !important;
      }
      .arrow_box{
        font-size: 10px;
      }
      #announcement-header{
        height:25px !important;
      }
      #announcement-header-register{
        height:25px !important;
      }
      .announcementbox{
        display: flex;
        margin: 0px 30px 0px 0px;
        color: #000;
        text-align: justify;
        font-size: 10px;
        min-height:33px !important;
      }
      .closeoverideheader {
        margin: 7px -50px;
    }
    .owos-rqst {
       float: right;
       margin: 5px 40px 0px 0px !important;
   }
    }

.btn-size-resp{
    width: 95% !important;
    margin-left: 11px;
}

@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
    .btn-size-resp {
        width: 92% !important;
    }
    .pad-l-r-6{
        padding-right: 6px !important;
        padding-left: 6px !important;
    }
}

.readmore:hover{
    color: white;
}
.mce-notification-error{
    width: 35% !important;
}
@media (min-width:1200px) {
    .ml-15-per {
        margin-left: 15% !important;
    }
}
.user_pro_quote_align div p {
    padding: 0 !important;
}



@media screen and (min-device-width: 320px) and (max-device-width: 768px) {

    .recat_mob_res .g-recaptcha iframe {
        width: 200px !important;
        overflow: hidden;
        border-right: 1px solid #eee;
    }
}

.error_pos .login_pos_error_chg{
    position: static;
}
.pd-l-r-5{
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.overflow_email_name{
    letter-spacing: .26px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: auto;
    max-width: 271px;
}


/*resposive of welcome page*/
@media screen and (min-device-width: 320px) and (max-device-width: 768px) {

    .h3_resp p{
        font-size:  14px !important;
        width:295px;
        padding:0 !important;
    }
    .h3_resp{
        padding: 2px !important;
    }
    .h4_resp{
        line-height: 4px !important;
        font-size: 12px !important;
    }

    .wel_info_resp p {
        line-height: 8px !important;
        font-size: 7px !important;
    }
    ul.event_bread_crumb_res li {
        width:auto !important;
        margin-right: 0 !important;
    }
    .custom_organiser_search{
        width:276px !important;
    }
    .event_pending_res{
        display: block;
    }

    .myprogress_resp{
        display: inline-block;
    }
    .post_event_form_resp{
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
    .mar-l-0-res{
        margin-left: 0 !important;
    }
    .font-16-res{
        font-size: 16px !important;
    }
}

.custom_organiser_search{
    width:600px;
    overflow:auto;
    resize:vertical
}
