.privacy-question {
    padding-left: 16px;
    padding-right: 20px;
    padding-bottom: 20px
}

.privacy-question .list-group .list-group-item {
    padding: 10px 0;
    border-left: transparent;
    border-right: transparent;
}

.privacy-question .list-group .list-group-item .form-group {
    width: 100%
}

.privacy-question .list-group .list-group-item .form-group label {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 0;
    padding-top: 8px
}

.notification_read_all{

    color: #f68f53;
}

.notification_read_vue{
    color:#7f8fa4;
}

.cursor_none{
    cursor: default;
}
.privacy-question .list-group .list-group-item .form-group .form-control {
    border: 1px solid #ced0da;
    float: right;
    width: 130px
}

.privacy-question .list-group .list-group-item:first-child {
    border-top: transparent;
    padding-top: 16px
}

.privacy-question .btn-success {
    padding: 7px 12.5px
}

.footer-description .owos-terms {
    padding-bottom: 10px;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

@media (max-width:440px) {
    .footer-description .owos-terms {
        font-size: 11px
    }
}

.footer-description .owos-terms a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #249af2;
    text-transform: capitalize;
    font-weight: 400;
    vertical-align: middle
}

@media (max-width:440px) {
    .footer-description .owos-terms a {
        font-size: 11px
    }
}

.footer-description .owos-terms:nth-child(1) {
    border-top: 1px solid #e6eaee;
    padding-top: 24px
}

.list-group.owos-group {
    margin-bottom: 20px
}

.list-group.owos-group .list-group-item {
    padding: 6px 15px!important
}

.list-group.owos-group .list-group-item .badge {
    margin-top: 9px
}

.list-group.owos-group .list-group-item:hover .owos-icon {
    background: #e26b26
}

.list-group.owos-group .list-group-item:hover .owos-icon i {
    color: #fff
}

.list-group.owos-group .list-icon.owos-icon {
    background: #e8eaf1
}

.list-group.owos-group .list-icon.owos-icon i {
    color: #8d909f
}

.list-group.owos-group .list-icon.owos-icon:hover {
    background: #e26b26
}

.list-group.owos-group .list-icon.owos-icon:hover i {
    color: #fff
}

.list-group.owos-group .list-icon.active {
    background: #e26b26 none repeat scroll 0 0;
}

.list-group.owos-group .list-icon.active i {
    color: #fff
}

.panel-heading.panel-settings {
    padding-top: 24px;
    padding-left: 27px
}

.panel-heading.panel-settings .panel-title {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 24px;
    font-weight: 400
}

.owos-form {
    padding: 29px 32px 20px
}

.owos-form .form-control {
    padding-right: 35px!important
}

.owos-form .help-block {
    padding: 10px 0 0
}

.owos-form .avatar-details .avatar-pic {
    display: inline-block;
    padding-left: 17px
}

.owos-form .avatar-details .avatar-pic .avatar-name {
    font-size: 14px;
    color: #354052;
    text-transform: capitalize;
    margin: 0
}

.owos-form .avatar-details .avatar-pic .avatar-size {
    font-size: 12px;
    color: #7f8fa4;
    font-weight: 400;
    margin: 0
}

.owos-form .form-group .input-group.gender-select {
    width: 100%
}

.owos-form .form-group .input-group.gender-select .form-control {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
}

.owos-form .form-group .input-group.datepicker {
    padding: 0
}

.owos-form .form-group .input-group.datepicker .calendar-addon {
    color: #c5d0de;
    font-size: 16px
}

.owos-form .form-group .input-group.datepicker .form-control {
    background: #fff;
    border-right: none;
    border-left: none
}

.owos-security {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 28px
}

.owos-security .btn-save-change {
    padding-top: 22px
}

.affiliate-settings .affiliate-link {
    text-align: center;
    padding-bottom: 20px
}

.affiliate-settings .affiliate-link .link-heading {
    color: #354052;
    margin: 0;
    padding-bottom: 7px
}

.affiliate-settings .affiliate-link .link-heading,
.affiliate-settings .affiliate-link a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 'Source Sans Pro', sans-serif;
    font-weight: 400
}

.affiliate-settings .affiliate-link a {
    color: #789a6a
}

.affiliate-settings .affiliate-link .affiliate-buttons {
    padding-top: 8px
}

.affiliate-settings .affiliate-link .affiliate-buttons .btn-default {
    padding: 6px 15px;
    color: #354052;
    margin-right: 17px
}

.affiliate-settings .affiliate-link .affiliate-buttons .btn-default i {
    color: #3b5998;
    font-size: 20px;
    padding-right: 9px
}

.affiliate-settings .affiliate-link .affiliate-buttons .btn-default.btn-twitter {
    padding: 6px 25px
}

.affiliate-settings .affiliate-link .affiliate-buttons .btn-default.btn-twitter i {
    color: #5ea9dd;
    font-size: 20px
}

.affiliate-settings .affliate-followers .list-group-item {
    border-left: none;
    border-right: none;
    padding: 15px 0
}

.affiliate-settings .affliate-followers .list-group-item .connect-link a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    font-weight: 400
}

.affiliate-settings .affliate-followers .list-group-item .connect-link a img {
    width: 40px;
    height: 40px;
}

.affiliate-settings .affliate-followers .list-group-item .connect-link a .name {
    padding-left: 20px
}

.affiliate-settings .affliate-followers .list-group-item .connect-mail .btn-default {
    padding: 7px 17px
}

.affiliate-settings .affliate-followers .list-group-item .connect-mail .btn-default i {
    color: #1b92ec;
    font-size: 14px;
    padding-right: 9px
}

.startpeep-services {
    padding-left: 13px;
    padding-right: 13px
}

.startpeep-services .list-group-item {
    border-left: none;
    border-right: none;
    padding: 15px 0
}

.startpeep-services .list-group-item .connect-list .connect-link a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: .5px;
    text-transform: capitalize
}

.startpeep-services .list-group-item .connect-list .connect-link a img {
    width: 16px;
    height: 16px;
    margin-right: 10px
}

.startpeep-services .list-group-item .connect-list .connect-mail a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 400;
    text-decoration: none
}

.startpeep-services .list-group-item .connect-list .connect-mail .btn-cross {
    padding: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #f3f5f6;
    border: 1px solid #d6d7e0;
    line-height: 13px;
    box-shadow: none;
    margin-left: 9px;
    vertical-align: text-bottom
}

.startpeep-services .list-group-item .connect-list .connect-mail .btn-cross i {
    color: #b2b3bf;
    font-size: 11px
}

.startpeep-services .list-group-item .connect-list .connect-mail .btn-success {
    color: #fff
}

.startpeep-services li:nth-child(1) {
    border-top: none
}

.startpeep-services li:nth-child(2) {
    padding: 7px 0
}

.startpeep-services li:nth-child(2) .connect-link {
    margin-top: 7px
}

.startpeep-services li:nth-child(5) {
    border-bottom: none
}

.startpeep-services li:nth-child(4) {
    padding: 7px 0
}

.startpeep-services li:nth-child(4) .connect-link {
    margin-top: 7px
}

.accout-deactivate {
    position: relative;
    text-align: center
}

.accout-deactivate i {
    color: rgba(231, 104, 118, .07);
    font-size: 150px
}

.accout-deactivate .delete-text {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #354052;
    font-weight: 400
}

.delete-btn {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 8px
}

.delete-btn .btn-danger {
    background: #e76877;
    border: 1px solid #e76877
}

.list-group.page-likes {
    padding-left: 13px;
    padding-right: 13px
}

.list-group.page-likes .list-group-item {
    border-left: none;
    border-right: none;
    padding: 15px 0
}

.list-group.page-likes .list-group-item .connect-link a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: .5px;
    text-transform: capitalize
}

.list-group.page-likes .list-group-item .connect-link a img {
    width: 40px;
    height: 40px;
    margin-right: 20px
}

.list-group.page-likes .list-group-item .pull-right {
    padding-top: 5px
}

.list-group.page-likes .list-group-item .pull-right .btn-primary {
    color: #fff
}

.list-group.page-likes .list-group-item .pull-right .btn-primary i {
    color: #fff;
    padding-right: 12px
}

.list-group.page-likes li:nth-child(1) {
    border-top: 0
}

.list-group.page-likes li:nth-child(5) {
    border-bottom: 0
}

.privacy-question .calendar-switch {
    padding-right: 0;
    margin-right: 0;
    padding-top: 5px
}

.list-group li:last-child {
    border-bottom: none
}

.mini-profile .widget-bg img {
    border-radius: 0
}

.mini-profile .avatar-mail,
.mini-profile .avatar-name {
    margin-top: -8px!important
}

.mini-profile .avatar-mail a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #7f8fa4;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none
}

.mini-profile .activity-list {
    margin-top: 17px
}

.mini-profile .activity-list .activity-count {
    text-align: center
}

.footer-description {
    padding-top: 100px
}

.footer-description .owos-terms a {
    color: #354052;
    text-decoration: none
}

.footer-description .owos-terms a:hover {
    color: #de6926
}

.footer-description .owos-languages span {
    color: #354052
}

.footer-description .owos-languages a {
    color: #354052;
    text-decoration: none
}

.footer-description .owos-languages a:hover {
    color: #de6926
}

.owos-form .radio label input {
    margin-top: 0
}

.owos-form .radio p {
    text-transform: none
}

.widget-left-panel .active .menu-btn i {
    color: #333!important
}

.owos-form .form-group .youtube-input-group .youtube-btn {
    background: #cd201f;
    border-color: #cd201f
}

.owos-form .form-group .youtube-input-group .youtube-btn i {
    color: #fff
}

.owos-form .form-group .instagram-input-group .instagram-btn {
    background: #f56040;
    border-color: #f56040
}

.owos-form .form-group .instagram-input-group .instagram-btn i {
    color: #fff
}

.owos-form .form-group .dribbble-input-group .dribbble-btn {
    background: #ea4c89;
    border-color: #ea4c89
}

.owos-form .form-group .dribbble-input-group .dribbble-btn i {
    color: #fff
}

.owos-form .form-group .linkedin-input-group .linkedin-btn {
    background: #0077b5;
    border-color: #0077b5
}

.owos-form .form-group .linkedin-input-group .linkedin-btn i {
    color: #fff
}

.owos-form .privacy-question {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0
}

.owos-form .privacy-question .list-group-item .form-group {
    margin-bottom: 0
}

.timeline .manage-table td {
    font-weight: 300
}

.timeline .manage-table td li {
    padding-left: 0;
    padding-right: 0
}

.timeline .manage-table td li a {
    font-weight: 300;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    padding-left: 4px
}

.timeline .manage-table td:nth-child(3) {
    padding-left: 0
}

.timeline .manage-table td:nth-child(5) {
    width: 100px
}

.theme-block {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    border-bottom: 1px solid red
}

.theme-block .theme-picture .theme-img {
    float: left
}

.theme-block .theme-picture .theme-img img {
    width: 50px;
    height: 50px;
}

.theme-block .theme-picture .theme-header {
    float: left
}

.theme-block .theme-picture .theme-header .theme-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    margin-bottom: 0
}

.theme-block .theme-picture .theme-header h3:nth-child(1) {
    margin-top: -8px
}

.theme-block .theme-picture .theme-header h3:nth-child(2) {
    margin-top: -10px
}

.theme-block .theme-picture .theme-header h3:nth-child(3) {
    margin-top: -8px
}

.theme-passage {
    padding-left: 27px;
    padding-top: 17px
}

.theme-passage p {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    margin-bottom: 0
}

.form-horizontal {
    padding-top: 29px
}

.form-horizontal .form-group {
    margin-left: 12px;
    margin-right: 12px
}

.form-horizontal .form-group:nth-child(1) {
    border-bottom: 1px solid #e6eaee;
    padding-bottom: 37px;
    margin-bottom: 21px
}

.form-horizontal .btn-success {
    padding: 6.5px 28px
}

.page-header.news-heading {
    margin: 9px 0 17px;
    padding-left: 35px
}

.announcements .datepicker {
    padding-left: 19px!important;
    padding-right: 19px!important
}

.announcements .datepicker .form-control {
    background: #f2f4f7;
    border-left: 0;
    border-right: 0
}

.announcements .form-group:nth-child(1) {
    padding-bottom: 0;
    border-bottom: none
}

.announcements .form-group label {
    padding-left: 0;
    padding-right: 0
}

.announcements .form-group .upload-form input {
    margin-top: 3px
}

.announcements .form-group .upload-form input:focus {
    outline: none
}

.table.annual-statistics tr td:first-child {
    width: 200px;
    font-size: 15px
}

.table.annual-statistics tr td {
    width: 150px;
    border-top: transparent;
    padding-bottom: 18px;
    padding-top: 18px
}

.table.annual-statistics tr td .widget-sales {
    background-color: #f5f5f5;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid #e6eaee
}

.table.annual-statistics tr td .widget-sales .no-of-sales {
    padding-bottom: 0;
    padding-top: 6px;
    font-size: 25px
}

.table.annual-statistics tr td .widget-sales .sales {
    padding-bottom: 10px
}

.table.annual-statistics tr td .widget-sales .sales a {
    text-transform: uppercase;
    font-size: 12px
}

.table.annual-statistics tr:last-child td {
    border-bottom: 0
}

@media (max-width:700px) {
    .table.annual-statistics tr td {
        width: 0
    }
    .table.annual-statistics tr td .widget-sales {
        padding-left: 20px;
        padding-right: 20px
    }
}

.edit-form .form-group {
    padding-left: 29px;
    padding-right: 29px
}

.edit-form .pull-right {
    padding-right: 29px
}

@media (max-width:620px) {
    .heading-list li a {
        font-size: 15px;
        padding: 33px 5px 8px 0
    }
    .heading-list li a span {
        font-size: 10px
    }
}

.avatar-body {
    width: 78px;
    height: 70px;
    background-color: #fafbfc;
    border: 1px solid #e6eaee;
    margin-left: 30px;
    margin-top: 9px;
    margin-bottom: 9px;
    display: inline-block
}

.avatar-body img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    float: left;
    margin-left: -31px;
    margin-top: -1px
}

.avatar-body .image-icon {
    padding-left: 51px;
    padding-top: 6px
}

.avatar-body .image-icon a {
    border-bottom: 1px solid #e6eaee;
    padding-bottom: 8px
}

.avatar-body .image-icon a i {
    color: #c8d2df;
    font-size: 16px
}

.avatar-body .image-icon.delete-icon {
    padding-top: 15px
}

.avatar-body .image-icon.delete-icon a {
    border-bottom: transparent
}

.avatar-body .image-icon.delete-icon a i {
    font-size: 20px
}

.pencil-icon {
    background: #de6926;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
}

.pencil-icon i {
    color: #fff!important;
    font-size: 14px;
    line-height: 26px;
    margin-left: 1px
}

.trash-icon {
    background: #e76877;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
}

.trash-icon i {
    color: #fff!important;
    font-size: 13px;
    line-height: 27px
}

.email-icon {
    background: #9FE8DD;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
}
@media (max-width:600px) {
    .privacy-question .list-group .list-group-item .form-group {
        margin-bottom: 8px
    }
    .privacy-question .list-group .list-group-item .form-group label {
        padding-top: 0;
        padding-bottom: 15px
    }
    .privacy-question .list-group .list-group-item .form-group .form-control {
        float: none
    }
}

.settings-block {
    text-transform: capitalize;
    background: #2ea1f8;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    letter-spacing: .4px
}

.settings-block .close-modal i {
    color: #70bbfb
}

.settings-list {
    background-color: #222c3c;
    margin-left: 0;
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.settings-list li {
    margin-left: 30px;
    margin-right: 30px;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 3px solid transparent
}

.settings-list li:first-child {
    margin-left: 10px
}

.settings-list li:last-child {
    margin-right: 7px
}

.settings-list li a {
    color: #848c98;
    padding: 0;
    padding-top: 14px;
    padding-bottom: 11px;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 400
}

.settings-list li.active {
    border-bottom: 3px solid #2ea1f8
}

.settings-list li.active a {
    color: #fff
}

.settings-content {
    padding: 0;
    margin-bottom: 0;
    border: 0
}

.list-group {
    margin-bottom: 0
}

.list-group .list-group-item {
    padding: 16px 30px;
    cursor: pointer;
    font-size: 14px;
    color: #354052;
    border-left: 0;
    border-right: 0
}

.list-group .list-group-item .text-toggle {
    opacity: .6;
    color: #354052
}

.list-group .list-group-item .text-selected {
    opacity: 1;
    color: #354052
}

.settings-footer {
    padding: 12px 30px
}

.settings-footer .btn-send {
    background: #39b54a;
    border: 1px solid #249533;
    color: #fff;
    font-size: 14px;
    font-family: Source Sans Pro, sans-serif
}

.btn-custom {
    float: right
}

.side-left {
    float: left
}

.side-right {
    float: right
}
