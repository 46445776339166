.product-image img {
    width: 100%;
    height: auto;
}

.related-box {
    padding: 10px
}
.one-title {
    width: 220px !important;
    display: inline-block;
    overflow: hidden;
    padding-left: 2px;
}
.related-box .productivity-heading h3 {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px
}

.related-box .productivity-sub {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #7f8fa4
}

.related-box .productivity-text {
    margin-top: 6px;
    border-bottom: 1px solid #e6eaee;
    margin-bottom: 15px
}

.related-box .productivity-text p {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    margin-bottom: 13px
}

.related-box .btn-buy-class {
    text-transform: none;
    margin-top: 2px;
    padding: 7px 18px
}

.mini-card {
    padding: 7px 5px 4px
}

.mini-card .media-actions {
    float: right
}

.mini-card .media-actions li:last-child {
    padding-right: 10px
}

.mini-card .media-actions li a {
    color: #ccd3da;
    font-size: 16px
}

.mini-card .media-actions li .dropdown-menu {
    right: -20px;
    left: auto
}

.mini-card .media-left {
    padding-right: 20px
}

.mini-card .media,
.mini-card .media-body {
    overflow: visible
}

.mini-card .media-body .media-heading {
    text-transform: capitalize;
    margin-bottom: 1px;
    font-size: 16px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #354052
}

.mini-card .media-body .designation {
    font-size: 12px;
    color: #7f8fa4;
    line-height: 19px
}

.mini-card .image-gallery {
    margin-top: 20px
}

.mini-card .image-gallery .image-row {
    margin-left: -5px;
    margin-right: -5px
}

.mini-card .image-gallery .image-col {
    padding-left: 5px;
    padding-right: 5px
}

.mini-card .image-gallery .image-col img {
    width: 100%;
    height: auto;
}

.btn-setting {
    float: right;
    margin-top: 5px
}

.btn-setting a i {
    font-size: 18px;
    color: #c5d0de
}

.btn-setting .dropdown-menu {
    left: auto;
    right: 0
}

.skill-col {
    border-left: 1px solid #e6eaee
}

.list-group-item.skill {
    border: 0;
    padding-top: 5px
}

.list-group-item.skill:first-child {
    padding-top: 17px
}

.list-group-item.skill:last-child {
    padding-bottom: 22px
}

.list-group-item.skill .left-side {
    float: left;
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
    font-weight: 600
}

.list-group-item.skill .left-side span {
    display: block;
    font-size: 18px;
    color: #354052;
    margin-top: -5px
}

.list-group-item.skill .right-side {
    margin-left: 100px;
    position: relative;
    margin-top: 15px
}

.list-group-item.skill .right-side .progress-tool {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    border: 2px solid #fff;
    top: 50%;
    margin-top: -18px;
    left: -19px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 30px
}

.list-group-item.skill .right-side .progress {
    margin-bottom: 0;
    height: 14px;
    background-color: #e2e7ee;
}

.list-group-item.skill .right-side .progress .progress-bar {
    border-radius: 0
}

.list-group-item.skill .right-side .blue {
    background: #de6926
}

.list-group-item.skill .right-side .purple {
    background: #9d90e4
}

.list-group-item.skill .right-side .green {
    background: #2bb415
}

.list-group-item.skill .right-side .yellow {
    background: #fdc018
}

.list-group-item.skill .right-side .voilet {
    background: #445c83
}

.skills-list .list-group-item {
    border: 0;
    border-top: 1px solid #e6eaee;
    margin-left: 15px;
    margin-right: 15px;
    text-transform: capitalize;
    padding: 10px 0;
    font-size: 14px;
    color: #354052
}

.skills-list .list-group-item span {
    float: right;
    color: #7f8fa4;
    font-size: 14px
}

.skills-list .list-group-item:first-child {
    border-top: 0
}

.skills-list .list-group-item:last-child {
    padding-bottom: 22px
}

.skill-panel .panel-heading {
    height: auto;
    padding-top: 17px;
    padding-bottom: 6px;
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
    font-weight: 600
}

.media.post-list {
    border-bottom: 1px solid #e3e8ef;
    padding: 15px;
    padding-left: 0;
    margin: 0;
    overflow: visible
}

.media.post-list .post-time {
    float: right;
    font-size: 14px;
    color: #7f8fa4;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 600
}

.media.post-list .media-body {
    padding-left: 5px;
    overflow: visible
}

.media.post-list .media-body .media-heading {
    margin-bottom: 0
}

.media.post-list .media-body .media-heading a {
    color: #354052;
    font-size: 16px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-decoration: none;
    text-transform: capitalize
}

.media.post-list .media-body .media-heading .text-muted {
    margin-left: 5px;
    color: #7f8fa4;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize
}

.media.post-list .media-body .post-text {
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

.media.post-list .media-body .post-pictures {
    margin-top: 12px;
    margin-bottom: 15px
}

.media.post-list .media-body .post-pictures .post-row {
    margin-left: -5px;
    margin-right: -3px
}

.media.post-list .media-body .post-pictures .post-row .image-col {
    padding-left: 5px;
    padding-right: 5px
}

.media.post-list .media-body .post-pictures .post-row .image-col img {
    width: 100%;
    height: auto
}

.media.post-list .media-body .post-actions {
    margin-top: 5px
}

.media.post-list .media-body .post-actions .leftside-list {
    float: left;
    margin-bottom: 0
}

.media.post-list .media-body .post-actions .leftside-list li {
    padding-left: 15px;
    padding-right: 15px
}

.media.post-list .media-body .post-actions .leftside-list li a {
    text-decoration: none;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #7f8fa4
}

.media.post-list .media-body .post-actions .leftside-list li a span {
    font-size: 16px;
    color: #aeb6c5;
    margin-right: 3px
}

.media.post-list .media-body .post-actions .leftside-list li.active .favourite,
.media.post-list .media-body .post-actions .leftside-list li.active .favourite span,
.media.post-list .media-body .post-actions .leftside-list li a span .fa-heart {
    color: #df0c0c
}

.media.post-list .media-body .post-actions .leftside-list li.active .share,
.media.post-list .media-body .post-actions .leftside-list li.active .share span {
    color: #1bb934
}

.media.post-list .media-body .post-actions .rightside-action {
    float: right;
    margin-top: 4px;
    margin-right: 5px
}

.media.post-list .media-body .post-actions .rightside-action i {
    font-size: 16px;
    color: #ccd3da
}

.media.post-list .media-body .post-actions .rightside-action .dropdown-menu {
    left: auto;
    right: 0
}

.cover-section {
    padding: 10px;
    text-align: center
}

.cover-section .cover-image {
    overflow: hidden;
    height: 120px;
    width: 100%;
    position: relative
}

.cover-section .cover-image img {
    width: 100%;
    height: auto
}

.cover-section .user-avatar {
    position: absolute;
    top: 50px;
    left: 50%;
    margin-left: -78px
}

.cover-section .user-avatar img {
    height: 156px;
    width: 156px
}

.cover-section .user-name {
    padding-top: 91px
}

.cover-section .user-name a {
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 24px;
    color: #354052;
    text-decoration: none
}

.cover-section .user-name .user-details-list {
    margin-top: 5px
}

.cover-section .user-name .user-details-list li {
    line-height: 15px
}

.cover-section .user-name .user-details-list li a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4
}

.cover-section .user-name .user-details-list li:first-child {
    border-right: 2px solid #7f8fa4;
    margin-right: -4px
}

.user-information-section {
    padding: 30px;
    padding-top: 6px
}

.user-information-section .detail-box {
    border-top: 1px solid #e6eaee;
    padding-top: 22px
}

.user-information-section .detail-box .user-identity {
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 12px;
    color: #7f8fa4
}

.user-information-section .detail-box .user-detail {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #354052;
    line-height: 19px;
    margin-bottom: 8px;
    margin-top: 1px
}

.user-information-section .detail-box .user-bio {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052;
    line-height: 19px;
    margin-bottom: 15px;
    margin-top: 5px
}

.user-information-section .detail-box .paid-status {
    margin-top: 5px
}

.user-information-section .detail-box .paid-status .progress {
    height: 4px;
    margin-right: 79px;
    background-color: #dfe4ee;
    border-radius: 18.62px;
    margin-bottom: 17px
}

.user-information-section .detail-box .paid-status .progress .progress-bar {
    background-color: #1a91eb;
    border-radius: 18.62px
}

.user-information-section .detail-box .paid-status .paid-money {
    float: right;
    margin-top: -28px;
    font-size: 14px;
    color: #354052;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 600
}

.user-information-section .detail-box .paid-status .paid-money span {
    color: #1a91eb
}

.user-information-section .detail-box .skills-list {
    margin-top: 5px;
    margin-bottom: 0
}

.user-information-section .detail-box .skills-list li {
    padding-left: 3px;
    padding-right: 3px
}

.user-information-section .detail-box .skills-list li:first-child {
    padding-left: 0
}

.user-information-section .detail-box .skills-list li:last-child {
    padding-right: 0
}

.user-information-section .detail-box .skills-list li .label {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase
}

.products-table {
    margin-bottom: 0
}

.products-table tr .bullet {
    width: 14px;
    height: 14px;
    background-color: red;
    border-radius: 50%;
    margin-top: -3px
}

.products-table tr .light-blue {
    background-color: #2297f0
}

.products-table tr .dark-blue {
    background: #337bbf
}

.products-table tr:first-child td {
    border-top: 0
}

.products-table tr td {
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    padding-top: 13px!important;
    padding-bottom: 17px!important
}

.products-table tr td a {
    color: #354052;
    text-decoration: none
}

.products-table tr td span {
    display: block;
    font-weight: 400;
    color: #7f8fa4;
    margin-top: 3px
}

.products-table tr td .target {
    float: left
}

.products-table tr td .count {
    float: right;
    color: #354052;
    font-weight: 600
}

.products-table tr td .progress {
    margin: 0;
    height: 4px;
    margin-top: 11px;
    margin-bottom: 5px;
    background-color: #dfe4ee;
}

.products-table tr td .progress .progress-bar {
    background-color: #1a91eb;
}

.heading-list {
    margin: 0;
    padding: 0
}

.heading-list li {
    line-height: 23px
}

.heading-list li:first-child a {
    padding-left: 30px
}

.heading-list li a {
    padding: 27px 30px 20px 25px;
    text-decoration: none;
    font-family: Source Sans Pro, sans-serif
}

.heading-list li a span {
    font-size: 14px;
    color: #7f8fa4;
    margin-left: 10px
}

.heading-list .divider {
    border-left: 1px solid #e6eaee;
    margin-top: 30px;
    height: 35px
}

.heading-list li.active,
.heading-list li:hover {
    border-bottom: 3px solid #e06a26
}

.heading-list li.active a,
.heading-list li:hover a {
    color: #354052!important
}

.messages-page .panel-heading.user-pages {
    border-bottom-width: 0!important
}

.messages-page .panel-body .message-col-4 {
    padding-right: 0;
    border-right: 1px solid #e6eaee
}

.messages-page .panel-body .message-col-4 ul {
    margin-bottom: 0
}

.messages-page .panel-body .message-col-4 .media,
.messages-page .panel-body .message-col-4 .media .media-body {
    overflow: hidden
}

.messages-page .panel-body .message-col-4 .input-group {
    padding: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dfe3e9!important;
    margin-bottom: 0
}

.messages-page .panel-body .message-col-4 .input-group .form-control {
    height: 36px;
    border-right: 0;
    box-shadow: none;
    outline: none;
    border-color: #dfe3e9;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0
}

.messages-page .panel-body .message-col-4 .input-group .input-group-btn .btn {
    height: 36px;
    border-left: 0;
    background: none;
    box-shadow: none;
    outline: none;
    border-color: #dfe3e9;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.messages-page .panel-body .message-col-4 .input-group .input-group-btn .btn i {
    color: #7f8fa4;
    font-size: 16px
}

.messages-page .panel-body .message-col-8 {
    padding-left: 0
}

.messages-page .panel-body .message-col-8 .conversation .left-side {
    float: left;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    font-size: 18px;
    color: #354052;
    padding-top: 21px;
    padding-left: 20px
}

.messages-page .panel-body .message-col-8 .conversation .left-side .chat-status {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #1bb934;
    display: inline-block;
    margin-left: 4px
}

.messages-page .panel-body .message-col-8 .conversation .right-side {
    float: right;
    padding-top: 19px
}

.messages-page .panel-body .message-col-8 .conversation .right-side .primary-list>li {
    padding-left: 20px;
    padding-right: 18px
}

.messages-page .panel-body .message-col-8 .conversation .right-side ul {
    margin-bottom: 0;
    margin-top: 0
}

.messages-page .panel-body .message-col-8 .conversation .right-side .pagination li a {
    background: #f2f4f7;
    border: 1px solid #ced0da
}

.messages-page .panel-body .message-col-8 .conversation .right-side .pagination li a i {
    color: #7f8fa4;
    font-size: 18px;
    vertical-align: middle
}

.messages-page .panel-body .message-col-8 .conversation .right-side .pagination.latest li a i {
    color: #ced0da
}

.messages-page .panel-body .message-col-8 .conversation .right-side .divider {
    background: #e6eaee;
    width: 1px;
    height: 34px;
    padding: 0!important;
    vertical-align: top;
    margin-left: 4px
}

.messages-page .panel-body .message-col-8 .conversation .right-side .page-number {
    vertical-align: top;
    margin-top: 8px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #7f8fa4;
    font-weight: 600
}


/*  @TODO below height needs to be converted to percentage  */

.messages-page .panel-body .coversations-thread {
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px
}

.messages-page .panel-body .message-conversation {
    border-bottom: 0;
    padding: 0 30px 0 15px
}

.messages-page .panel-body .message-conversation .media.post-list {
    border-bottom: 0;
    padding-bottom: 0;
    padding-top: 10px
}

.messages-page .panel-body .message-conversation .post-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052;
    line-height: 19px
}

.messages-page .panel-body .message-conversation:first-child {
    padding-top: 5px
}

.messages-page .panel-body .message-conversation .media-body {
    padding-left: 10px
}

.messages-page .panel-body .message-conversation .media-body .media-heading a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px
}

.messages-page .panel-body .message-conversation .media-body .media-heading .text-muted {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-left: 11px;
    float: right
}

.messages-page .panel-body .message-conversation .media-body .media-heading .text-muted .timeago {
    font-size: 11px
}

.messages-page .panel-body .message-conversation .media-body .post-text {
    font-family: Source Sans Pro, sans-serif
}

.messages-page .panel-body .message-conversation .post-pictures {
    margin-bottom: 0;
    padding-top: 5px;
    padding-left: 90px;
    padding-right: 30px
}

.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col {
    position: relative;
    padding-left: 7px;
    padding-right: 7px
}

.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col .img-holder {
    background-color: #000;
}

.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col .img-holder img {
    width: 100%;
    height: auto
}

.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col .img-search {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: hsla(0, 0%, 100%, .3);
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: 50%;
    margin-left: -25px;
    text-align: center;
    line-height: 55px;
    display: none
}

.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col .img-search i {
    color: #fff;
    font-size: 20px
}

.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col:hover img {
    opacity: .5
}

.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col:hover .img-search {
    display: block
}

.messages-page .panel-body .message-holder {
    padding: 15px 5px 15px 15px;
    position: relative;
    border-bottom: 1px solid #e6eaee
}

.messages-page .panel-body .message-holder:hover {
    background-color: #fafbfc
}

.messages-page .panel-body .message-holder .show-conversation {
    text-decoration: none
}

.messages-page .panel-body .message-holder:last-child {
    border-bottom: 1px solid transparent
}

.messages-page .panel-body .message-holder .media.post-list {
    border-bottom-width: 0;
    padding-top: 0;
    padding-bottom: 0
}

.messages-page .panel-body .message-holder .media.post-list .post-time {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize
}

.messages-page .panel-body .message-holder .media.post-list .media-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    display: block;
    height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #0af
}

.messages-page .panel-body .message-holder .media.post-list .media-heading .chat-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0af;
    margin-right: 5px;
    display: inline-block
}

.messages-page .panel-body .message-holder .media.post-list .media-heading .text-muted {
    display: block;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-top: -4px
}

.messages-page .panel-body .message-holder .media.post-list .media-heading span {
    margin-left: 0
}

.messages-page .panel-body .message-holder .post-text {
    padding-left: 0;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 13px;
    color: #354052;
    font-weight: 400;
    max-height: 42px;
    overflow: hidden;
    width: 180px;
    text-overflow: ellipsis;
    line-height: 20px;
    position: relative
}

.messages-page .panel-body .unseen-messages {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #de6926;
    color: #fff;
    text-align: center;
    line-height: 24px;
    display: none;
    position: absolute;
    bottom: 27px;
    right: 20px
}

.messages-page .panel-body .directing-div {
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0
}

.messages-page .panel-body .message-holder.active {
    border-left: 3px solid #e06a26;
    position: relative
}

.messages-page .panel-body .message-holder.active .media-heading a {
    color: #0af
}

.messages-page .panel-body .message-holder.unseen-message .unseen-messages {
    display: inline-block
}

.messages-page .panel-body .message-divider {
    border-bottom: 1px solid #e3e8ef;
    position: relative;
    margin-top: 26px;
    margin-bottom: 6px
}

.messages-page .panel-body .message-divider .day {
    padding: 0 13px 0 14px;
    height: 52px;
    background-color: red;
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #7f8fa4;
    line-height: 52px;
    text-align: center;
    position: absolute;
    top: -26px;
    left: 50%;
    margin-left: -43px;
    background-color: #fff
}

.messages-page .panel-body .message-divider.blue {
    border-bottom: 1px solid #1a91eb
}

.messages-page .panel-body .message-divider.blue .day {
    color: #1a91eb
}

.messages-page .panel-body .new-message {
    padding: 14px 30px 20px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #fff
}

.messages-page .panel-body .new-message .input-group-btn .btn {
    height: 56px;
    width: 56px;
    line-height: 18px;
    border: 1px solid #ced0da;
    box-shadow: none;
    outline: none;
    font-size: 30px;
    padding: 0;
    padding-bottom: 4px;
    color: #fff
}

.messages-page .panel-body .new-message .input-group-btn .btn i {
    font-size: 20px
}

.messages-page .panel-body .new-message .form-control {
    height: 56px;
    padding-top: 10px
}

.messages-page .panel-body .new-message .form-control:focus {
    border: 1px solid #dfe3e9
}

.messages-page .panel-body .message-box {
    border-top: 1px solid #e6eaee;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    margin: 0!important;
    width: 100%
}

.messages-page .panel-body .message-box .message-col-4 {
    padding-left: 0
}

.messages-page .panel-body .message-box .message-col-4 .coversations-list {
    height: 597px;
    overflow-y: scroll;
    overflow-x: hidden
}

.panel-body.ongoing-projects {
    padding: 3px 30px 15px 29px
}

.panel-body.ongoing-projects .table-heading {
    text-transform: capitalize;
    float: left
}

.panel-body.ongoing-projects .projects-download {
    float: right;
    margin-top: 27px
}

.panel-body.ongoing-projects .projects-download i {
    color: #afb4bb;
    font-size: 16px
}

.tabel-panel {
    margin: -20px
}

.table.course-table {
    margin: 0
}

.table.course-table thead tr th {
    padding-top: 15px;
    padding-bottom: 20px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    background-color: #fff
}

.table.course-table thead tr th:first-child {
    padding-left: 30px
}

.table.course-table tbody tr td {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    text-transform: capitalize
}

.table.course-table tbody tr td:first-child {
    padding-left: 30px
}

.table.course-table tbody tr td a {
    color: #354052;
    text-decoration: none
}

.table.course-table tbody tr td .percent {
    float: left;
    vertical-align: middle;
    margin-right: 11px
}

.table.course-table tbody tr td .progress {
    height: 4px;
    margin-top: 7px;
    margin-bottom: 0;
    background-color: #dfe4ee;
}

.table.course-table tbody tr td .progress .progress-bar {
    background-color: #1a91eb;
}

.table.course-table tbody tr td ul {
    margin-bottom: 0
}

.table.course-table tbody tr td ul li a i {
    color: #c5d0de;
    font-size: 16px
}

.table.course-table tbody tr td ul li .dropdown-menu {
    left: auto;
    right: 0
}

.table.course-table tbody tr .status .status-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    margin-right: 9px;
    vertical-align: text-top;
    margin-top: 3px
}

.table.course-table tbody tr .status.blue .status-circle {
    border: 2px solid #1a91eb
}

.table.course-table tbody tr .status.green .status-circle {
    border: 2px solid #84b800
}

.table.course-table tbody tr .status.purple .status-circle {
    border: 2px solid #bf8fe1
}

.table.course-table tbody tr .status.red .status-circle {
    border: 2px solid#F86B67
}

.table.course-table tbody tr .report a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4
}

.table.course-table tbody tr .report a i {
    color: #c5d0de;
    font-size: 16px;
    margin-right: 8px
}

.table.course-table tbody tr .regular-font {
    font-weight: 400
}

.image-detail-page .heading {
    font-weight: 400!important
}

.image-detail-page .heading,
.image-detail-page .user-details .user-name,
.image-detail-page .user-details .user-role {
    font-family: Source Sans Pro, sans-serif
}

.image-detail-page .image-detail-section {
    background-color: #eff3f6
}

.image-detail-page .image-detail-section .images-block {
    padding: 15px 15px 4px
}

.image-detail-page .image-detail-section .images-block .col-md-3 {
    padding-top: 15px;
    padding-bottom: 15px
}

.imagedetail-cover {
    width: 100%;
    height: 380px;
    padding: 0
}

.imagedetail-cover .cover-pic {
    width: 100%;
    height: 320px;
    background: #000;
    position: relative
}

.imagedetail-cover .cover-pic img {
    width: 100%;
    height: 100%
}

.imagedetail-cover .cover-pic .label {
    color: #fff;
    padding: 6px 12px;
    position: absolute;
    top: 21px;
    right: 20px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px
}

.imagedetail-cover .cover-pic .bottom-cover-pic {
    position: absolute;
    bottom: 0;
    width: 100%
}

.imagedetail-cover .cover-pic .bottom-cover-pic .leftside {
    float: left;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 26px;
    color: #fff;
    text-transform: capitalize;
    margin-left: 15px;
    margin-top: 26px
}

.imagedetail-cover .cover-pic .bottom-cover-pic .rightside {
    float: right;
    text-align: right;
    margin-right: 15px
}

.imagedetail-cover .cover-pic .bottom-cover-pic .rightside span {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: uppercase;
    font-size: 12px;
    color: #fff
}

.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list {
    margin-bottom: 19px;
    margin-top: 2px
}

.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li {
    padding-left: 2px;
    padding-right: 2px
}

.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li:first-child {
    margin-right: 12px
}

.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li .btn-success {
    font-size: 18px;
    color: #fff
}

.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li .btn-friends {
    width: 40px;
    padding: 0;
    height: 40px;
    border-radius: 50%
}

.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li .btn-friends img {
    width: 100%;
    height: 100%
}

.navbar-tabs {
    background-color: #fff;
    margin-bottom: 20px
}

.navbar-tabs .btn-group {
    float: left;
    margin: 15px 20px 0 15px
}

.navbar-tabs .btn-group .btn {
    line-height: 18px
}

.navbar-tabs .btn-group .btn i {
    font-size: 16px;
    color: #ced0da
}

.navbar-tabs .left-list {
    margin: 0;
    margin-left: 10px
}

.navbar-tabs .left-list li {
    border-right: 1px solid #dfe3e9;
    margin-top: 15px;
    padding: 5px 14px 3px
}

.navbar-tabs .left-list li a i {
    color: #ced0da;
    font-size: 25px
}

.navbar-tabs .left-list li:first-child {
    padding-left: 8px
}

.navbar-tabs .left-list li:last-child {
    border-right: 0
}

.navbar-tabs .left-list li .nstSlider {
    height: 2px;
    background-color: #ced0da;
    width: 96px;
    margin-top: -6px
}

.navbar-tabs .left-list li .nstSlider .leftGrip {
    background: #de6926;
    height: 10px;
    width: 10px;
    bottom: -4px
}

.navbar-tabs .mid-list {
    margin: 0
}

.navbar-tabs .mid-list li a {
    padding: 19px 15px 18px;
    text-decoration: none;
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4
}

.navbar-tabs .mid-list li.active {
    border-bottom: 3px solid #1a91eb
}

.navbar-tabs .mid-list li.active a {
    color: #354052
}

.navbar-tabs .btn-newproject {
    margin-top: 15px;
    margin-right: 15px
}

.navbar-tabs .input-group {
    padding-right: 15px;
    padding-top: 15px;
    margin-bottom: 0;
    width: 270px;
    float: right
}

.navbar-tabs .input-group .form-control {
    height: 36px;
    border-right: 0;
    box-shadow: none;
    outline: none;
    border-color: #dfe3e9;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.navbar-tabs .input-group .input-group-btn .btn {
    height: 36px;
    border-left: 0;
    background: none;
    box-shadow: none;
    outline: none;
    border-color: #dfe3e9;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.navbar-tabs .input-group .input-group-btn .btn i {
    color: #7f8fa4;
    font-size: 16px
}

.navbar-tabs.navbars .btn-group {
    margin-top: 19px;
    margin-bottom: 19px
}

.navbar-tabs.navbars .left-list {
    margin-top: 5px
}

.navbar-tabs.navbars .mid-list {
    margin-top: 7px
}

.navbar-tabs.navbars .mid-list li a {
    padding: 19px 15px 21px
}

.navbar-tabs.navbars .btn-newproject {
    margin-top: 17px
}

.video-detail .panel-heading {
    height: auto
}

.video-detail .post-list {
    margin-top: -6px;
    margin-bottom: 0
}

.video-detail .post-list li {
    border-bottom: 3px solid transparent;
    transition: .5s ease
}

.video-detail .post-list li:first-child {
    margin-left: 27px;
    padding-left: 7px
}

.video-detail .post-list li:first-child a {
    padding-left: 0
}

.video-detail .post-list li a {
    padding: 10px 12px 11px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    text-decoration: none;
    text-transform: capitalize
}

.video-detail .post-list li.active,
.video-detail .post-list li:hover {
    border-bottom: 3px solid #1a91eb
}

.video-detail .post-list li.active a,
.video-detail .post-list li:hover a {
    color: #354052
}

.video-detail .video-details {
    border-top: 1px solid #e6eaee;
    padding-left: 28px
}

.video-detail .video-details .left-col {
    border-right: 1px solid #e6eaee
}

.video-detail .video-details .right-col {
    padding-left: 24px
}

.video-detail .video-details .right-col p {
    margin-top: 2px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052
}

.video-detail .video-details .right-col .btn-success {
    padding: 7px 19px;
    margin-top: 10px;
    margin-bottom: 21px
}

.video-detail .video-details .right-col .btn-success span {
    margin: 0;
    color: #fff
}

.video-detail .video-details .sub-heading {
    padding-top: 15px;
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052
}

.video-detail .video-details .source-list {
    margin-bottom: 23px
}

.video-detail .video-details .source-list li {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052;
    line-height: 26px
}

.video-detail .video-details .source-list li:first-child {
    margin-top: 7px
}

.video-detail .video-details .source-list li i {
    color: #f86b67;
    font-size: 16px;
    margin-right: 10px
}

.panel-body.video-panel .media {
    border-bottom: 0;
    padding-bottom: 5px
}

.panel-body.video-panel .media.forum {
    padding: 30px 30px 20px
}

.panel-body.video-panel .time {
    float: right;
    margin-right: -5px
}

.panel-body.video-panel .views-count {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4
}

.panel-body.video-panel .views-count i {
    color: #c5d0de;
    font-size: 16px;
    margin-right: 10px
}

.panel-body.video-panel .media-body .media-heading span {
    margin-left: 0
}

.panel-body.video-panel .media-body .media-heading .border {
    border-left: 2px solid #7f8fa4;
    margin-left: 5px;
    margin-right: 10px
}

.panel-body.video-panel .media-body .media-heading .text-muted {
    display: block;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.panel-body.video-panel .media-body .media-heading .text-muted .author a {
    color: #1a91eb;
    font-size: 'Source Sans Pro', sans-serif
}

.panel-body.video-panel .media-body .media-heading .text-muted.forum {
    line-height: 40px;
    margin-left: 0
}

.panel-body.video-panel .post-text {
    margin-top: 15px;
    font-family: Source Sans Pro, sans-serif
}

.panel-body.video-panel .post-text.forum {
    margin-top: 4px;
    margin-bottom: 20px
}

.panel-body.video-panel .label-holder {
    margin-top: 12px
}

.panel-body.video-panel .label-holder .label {
    font-size: 10px;
    padding: 3px 8px 3px 9px;
    font-family: Source Sans Pro, sans-serif
}

.panel-body.video-panel .query-details {
    padding: 10px 15px 0 28px
}

.panel-body.video-panel .query-details .ans-cnt {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-top: 5px;
    text-transform: capitalize;
    font-size: 16px;
    color: #354052
}

.panel-body.video-panel .query-details .query-list li a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
}

.panel-body.video-panel .query-details .query-list li.active a {
    color: #354052;
    border-bottom: 3px solid #1a91eb
}

.panel-body.video-panel .divider {
    border-bottom: 1px solid #e6eaee
}

.panel-body.video-panel .media.answers {
    padding: 13px 30px
}

.panel-body.video-panel .media.answers .post-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding-left: 5px;
    margin-top: 10px;
    margin-bottom: 19px
}

.panel-body.video-panel .margin-top {
    margin-top: 5px
}

.panel-body.video-panel .reply-section {
    padding: 0 30px
}

.panel-body.video-panel .reply-section label {
    text-transform: capitalize;
    font-size: 14px
}

.panel-body.video-panel .reply-section p {
    margin-top: 21px;
    margin-bottom: 10px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #7f8fa4
}

.panel-body.video-panel .reply-section p a {
    color: #1a91eb
}

.panel-body.video-panel .likes-count .btn-group-vertical .btn {
    box-shadow: none;
    outline: none;
    border: 1px solid #ced0da;
    text-shadow: none;
    margin-top: 0
}

.panel-body.video-panel .likes-count .btn-group-vertical .btn i {
    line-height: 6px;
    font-size: 16px;
    color: #ced0da
}

.panel-body.video-panel .likes-count .btn-group-vertical .btn:first-child {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #f2f4f7;
    padding-bottom: 0;
    padding-top: 4px;
    line-height: 10px
}

.panel-body.video-panel .likes-count .btn-group-vertical .btn:last-child {
    background: #fff;
    color: red;
    border-width: 0;
    padding-top: 0
}

.panel-body.video-panel .likes-count .btn-group-vertical .btn-counter {
    padding-top: 1px;
    border-top-width: 0;
    background: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    line-height: 17px;
    color: #354052;
    cursor: default
}

.adding-product {
    margin-bottom: 20px
}

.adding-product .left-side {
    float: left
}

.adding-product .btn-primary {
    font-weight: 600
}

.adding-product .btn-primary span {
    margin-right: 12px;
    font-size: 16px;
    color: #fff
}

.adding-new-product {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px
}

.adding-new-product label {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
    margin-bottom: 7px
}

.adding-new-product input.form-control {
    height: 36px
}

.rightside-box {
    padding-left: 15px
}

.publish-section {
    padding-top: 3px
}

.publish-section label {
    width: 100%
}

.publish-section .form-group {
    margin-bottom: 14px
}

.publish-section .form-group .input-group {
    float: left;
    width: 141px;
    margin-bottom: 0
}

.publish-section .form-group .input-group .form-control {
    width: 105px
}

.publish-section .btn-success {
    color: #fff;
    padding: 7px 14px;
    margin-top: 24px
}

@media (max-width:401px) {
    .publish-section .btn-success {
        padding: 7px 13px
    }
}

.border-right {
    border-right: 1px solid #e6eaee
}

@media (max-width:991px) {
    .border-right {
        border-right: 0
    }
}

.existing-products .left-col {
    padding-right: 5px
}

.existing-products .right-col {
    padding-left: 5px
}

@media (max-width:991px) {
    .existing-products .clear-fix {
        clear: both
    }
}

.existing-products .product-text {
    margin-top: 13px;
    margin-bottom: 25px
}

.news-help-block {
    border: 1px solid #e6eaee;
}

.help-block {
    background-color: #fff;
    margin: 0;
    padding: 24px 30px 26px;
}

.help-block p {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052;
    margin-bottom: 5px
}

.help-block a {
    color: #1a91eb
}

.news-block {
    background-color: #fafbfc;
    padding: 30px;
    padding-bottom: 0;
}

.news-block,
.news-block .news-holder {
    border-bottom: 1px solid #e6eaee
}

.news-block .news-holder:last-child {
    border-bottom: 0;
    margin-top: 18px
}

.news-block .news-holder .news-heading {
    margin-bottom: 8px
}

.news-block .news-holder .news-heading .left {
    float: left;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    text-transform: capitalize
}

.news-block .news-holder .news-heading .left img {
    width: 16px;
    height: 16px;
    margin-right: 9px;
    margin-top: -3px
}

.news-block .news-holder .news-heading .right {
    float: right
}

.news-block .news-holder .news-heading .right i {
    color: #aeb6c5;
    font-size: 16px
}

.news-block .news-holder .news-text {
    padding-bottom: 15px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #7f8fa4
}

.place-detail {
    background-color: #fff
}

.placedetail-cover {
    position: relative
}

.placedetail-cover,
.placedetail-cover .cover-pic {
    width: 100%;
    height: 415px
}

.placedetail-cover .cover-pic img {
    width: 100%;
    height: 100%
}

.placedetail-cover .overlay {
    width: 100%;
    height: 415px;
    background: #000;
    opacity: .5;
    position: absolute;
    top: 0
}

.placedetail-cover .cover-content {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%
}

.placedetail-cover .cover-content .left {
    float: left
}

.placedetail-cover .cover-content .left .media .btn-count {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .2);
    border: 2px solid #fff;
    padding: 0;
    line-height: 34px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 18px;
    margin-top: 5px;
    color: #fff
}

.placedetail-cover .cover-content .left .media .media-body {
    width: auto
}

.placedetail-cover .cover-content .left .media .media-body .media-heading a {
    font-size: 40px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    font-size: 30px;
    color: #fff;
    text-decoration: none
}

.placedetail-cover .cover-content .left .media .media-body .media-heading .text-muted {
    display: block;
    text-align: left;
    text-transform: uppercase;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 700;
    font-size: 12px;
    color: #bfc7d8;
    margin-left: 3px
}

.placedetail-cover .cover-content .right {
    float: right
}

.placedetail-cover .cover-content .right ul {
    margin: 0;
    margin-right: 15px
}

.placedetail-cover .cover-content .right ul li {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px
}

.placedetail-cover .cover-content .right ul li .btn {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    margin-right: 14px;
    padding: 0;
    line-height: 65px
}

.placedetail-cover .cover-content .right ul li .btn i {
    font-size: 25px;
    color: #fff
}

.placedetail-cover .cover-content .right ul li .btn-favourite {
    background: #dc893e
}

.place-detail-section {
    border-top: 1px solid #e6eaee;
    margin-top: 6px;
    margin-bottom: 69px
}

.place-detail-section p {
    margin-top: 18px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052
}

.place-detail-section img {
    width: 257px;
    height: 160px
}

.place-detail-section .sub-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #7f8fa4;
    text-transform: uppercase;
    margin-top: 24px;
    margin-bottom: 17px
}

.placedetail-list {
    margin: 0;
    text-align: center;
    margin-top: 10px
}

.placedetail-list li .image-hover .image-holder img {
    width: 156px;
    height: 100px;
}

.details-aboutplace .sub-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #7f8fa4;
    text-transform: uppercase;
    margin-top: 27px;
    margin-bottom: 17px
}

.details-aboutplace .social-list li {
    padding-left: 2px;
    padding-right: 2px
}

.details-aboutplace .social-list li .btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #bfc7d8;
    background-color: transparent;
    padding: 0;
    line-height: 34px
}

.details-aboutplace .social-list li .btn i {
    color: #bfc7d8;
    font-size: 16px
}

.details-aboutplace .list-friends li {
    padding-left: 2px;
    padding-right: 2px
}

.details-aboutplace .list-friends li .btn-friends {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0
}

.details-aboutplace .list-friends li .btn-friends img {
    width: 100%;
    height: 100%;
    margin-right: 0
}

.details-aboutplace .weather-list li {
    padding-left: 10px;
    padding-right: 10px;
    color: #4e4e5b;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 36px
}

.details-aboutplace .weather-list li img {
    width: 44px;
    height: 44px;
    margin-right: 12px;
    vertical-align: top
}

.invoice-panel {
    border: 0;
    border-left: 4px solid #1a91eb;
}

.invoice-panel .panel-heading {
    border-color: #e6eaee
}

.teamlead-info {
    float: left;
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    min-width: 230px
}

.teamlead-info .user-img {
    float: left;
    margin-right: 12px;
    position: relative
}

.teamlead-info .user-img .status-list {
    position: absolute;
    margin-bottom: 0;
    left: 40px;
    bottom: -5px
}

.teamlead-info .user-img .status-list li {
    padding: 0;
    margin-right: -8px
}

.teamlead-info .user-img .status-list li .status-circle {
    display: block;
    height: 14px;
    width: 14px;
    border-radius: 50%
}

.teamlead-info .user-img .status-list li .status-circle,
.teamlead-info .user-img .status-list li .status-circle.light-blue {
    background: #de6926
}

.teamlead-info .user-img .status-list li .status-circle.yellow {
    background: #f8cd51
}

.teamlead-info .user-img .status-list li .status-circle.purple {
    background: #7d5be2
}

.teamlead-info .user-img .status-list li .status-circle.red {
    background: #f75954
}

.teamlead-info .user-img .status-list li .status-circle.green {
    background: #5dda47
}

.teamlead-info .user-img .status-list li .status-circle.blue {
    background: #3278bb
}

.teamlead-info .user-details {
    margin-left: 37px;
    padding-left: 40px;
    margin-top: 2px
}

.teamlead-info .user-details .user-name {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    text-transform: capitalize
}

.teamlead-info .user-details .user-name a {
    color: #354052;
    text-decoration: none
}

.teamlead-info .user-details .user-role {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    font-size: #354052;
    color: #7f8fa4
}

.teamlead-statisctics {
    float: right;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 20px
}

@media (max-width:700px) {
    .teamlead-statisctics {
        float: none
    }
}

.teamlead-statisctics .detail-list {
    margin-bottom: 0;
    float: left;
    margin-top: 1px
}

.teamlead-statisctics .detail-list li {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #354052;
    padding-left: 24px;
    padding-right: 24px
}

@media (max-width:768px) {
    .teamlead-statisctics .detail-list li {
        padding-left: 8px;
        padding-right: 8px
    }
    .teamlead-statisctics .detail-list li:first-child {
        padding-left: 20px
    }
    .teamlead-statisctics .detail-list li:last-child {
        padding-right: 0
    }
}

.teamlead-statisctics .detail-list li .text-muted {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    text-transform: capitalize;
    display: block
}

.teamlead-statisctics .btn-collapsable {
    float: right;
    background-color: transparent;
    margin: 9px 8px 0 9px;
    box-shadow: none
}

@media (max-width:666px) {
    .teamlead-statisctics .btn-collapsable {
        margin-left: 0;
        margin-right: 0;
        float: none
    }
}

.teamlead-statisctics .btn-collapsable .fa {
    color: #ced0da;
    font-size: 20px
}

.teamlead-statisctics .btn-collapsable:focus {
    color: transparent
}

.red.invoice-panel {
    border-left: 4px solid #dd3b1e
}

.orange.invoice-panel {
    border-left: 4px solid #ff7800
}

.green.invoice-panel {
    border-left: 4px solid #41c72a
}

.yellow.invoice-panel {
    border-left: 4px solid #fac634
}

.timeline .timeline-panel .panel-heading {
    height: auto;
    padding: 20px;
    border-bottom: 1px solid #e6eaee
}

.timeline .timeline-panel .panel-heading .media.main {
    margin-top: 0
}

.timeline .timeline-panel .panel-heading .media.main .media-left {
    padding-left: 4px;
    padding-right: 15px
}

.timeline .timeline-panel .panel-heading .media.main .media-body {
    padding-left: 15px
}

.timeline .timeline-panel .panel-heading .media.main .media-body .media-heading {
    text-transform: capitalize;
    margin-top: 1px
}

.timeline .timeline-panel .panel-heading .media.main .media-body .media-heading a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #354052
}

.timeline .timeline-panel .panel-heading .media.main .media-body .media-heading .text-muted {
    display: block;
    line-height: 17px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4
}

.timeline .timeline-panel .panel-body {
    border-radius: 0
}

.timeline .timeline-panel .panel-body .list-group {
    margin-bottom: 0;
}

.timeline .timeline-panel .panel-body .list-group .list-group-item {
    padding-left: 20px;
    padding-right: 25px;
    border-top: 1px solid #e6eaee;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    background-color: #fafbfc
}

.timeline .timeline-panel .panel-body .list-group .list-group-item:first-child {
    border-top: 0
}

.timeline .event-panel .panel-heading {
    height: auto;
    padding-left: 20px;
    padding-right: 25px;
    border-bottom: 1px solid #e6eaee
}

.timeline .event-panel .panel-heading .event-details {
    line-height: 20px
}

.timeline .event-panel .panel-heading .event.fs-16 {
    font-size: 16px;
    margin-right: 10px
}

.timeline .event-panel .panel-heading .comments-list {
    line-height: 20px;
    margin-bottom: 0
}

.timeline .event-panel .panel-heading .comments-list li {
    margin-top: 8px
}

.timeline .event-panel .panel-form {
    padding-left: 23px;
    padding-top: 6px;
    padding-bottom: 8px
}

.timeline .event-panel .panel-form .checkbox-inline input[type=checkbox],
.timeline .event-panel .panel-form .checkbox input[type=checkbox],
.timeline .event-panel .panel-form .radio-inline input[type=radio],
.timeline .event-panel .panel-form .radio input[type=radio] {
    margin-left: -23px;
    margin-top: 2px
}

.timeline .event-panel .panel-form label {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052;
    text-transform: none
}

.timeline .event-panel .panel-form .fa {
    color: #7c8ca5;
    font-size: 16px;
    margin-left: 6px;
    margin-right: 5px
}

.timeline .event-panel .panel-form .check-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4
}

.timeline .event-panel,
.timeline .event-panel .panel-heading,
.timeline .timeline-panel,
.timeline .timeline-panel .panel-heading {
    position: relative
}

.timeline .event-panel .panel-heading .btn-timeline-staging,
.timeline .timeline-panel .panel-heading .btn-timeline-staging {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    position: absolute;
    background-color: red;
    margin-top: -17px;
    top: 50%;
    left: -50px;
    border: 0!important;
    padding: 0;
    line-height: 34px
}

.timeline .event-panel .panel-heading .btn-timeline-staging i,
.timeline .timeline-panel .panel-heading .btn-timeline-staging i {
    font-size: 16px
}

.timeline .event-panel .panel-heading .btn-purple,
.timeline .timeline-panel .panel-heading .btn-purple {
    background: #7d5be2
}

.timeline .event-panel .panel-heading:after,
.timeline .event-panel .panel-heading:before,
.timeline .timeline-panel .panel-heading:after,
.timeline .timeline-panel .panel-heading:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid
}

.timeline .event-panel .panel-heading:after,
.timeline .timeline-panel .panel-heading:after {
    border-color: transparent #fff transparent transparent;
    border-width: 7px;
    margin-top: -7px;
    left: -15px
}

.timeline .event-panel .panel-heading:before,
.timeline .timeline-panel .panel-heading:before {
    border-color: transparent #e6eaee transparent transparent;
    border-width: 8px;
    margin-top: -8px;
    left: -17px
}

.timeline .events-details {
    float: left;
    line-height: 36px
}

.timeline .event-time {
    color: #7f8fa4;
    margin-right: 18px
}

.timeline .event,
.timeline .event-time {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px
}

.timeline .event {
    color: #354052
}

.timeline .event img {
    margin-right: 13px
}

.timeline .timeline-comments-list {
    float: right;
    line-height: 36px;
    min-width: 100px
}

.timeline .timeline-comments-list li {
    vertical-align: middle
}

.timeline .timeline-comments-list li:last-child {
    float: right;
    margin-top: 3px
}

.timeline .timeline-comments-list li a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4
}

.timeline .timeline-comments-list li i {
    margin-right: 3px;
    font-size: 16px;
    color: #7f8fa4
}

.login-block {
    width: 450px;
    margin: 0 auto;
    padding-top: 150px
}

@media (max-width:500px) {
    .login-block {
        width: 340px
    }
    .login-block .social-connect li {
        padding-right: 6px;
        padding-left: 6px
    }
    .login-block .social-connect li .btn-social {
        height: 50px;
        width: 50px;
        padding: 0
    }
    .login-block .social-connect li .btn-social .social-circle {
        width: 28px;
        height: 28px;
        margin-top: 10px;
        line-height: 31px
    }
    .login-block .social-connect li .btn-social .social-circle i {
        font-size: 15px
    }
    .login-block .problem-login {
        font-size: 11px
    }
}

@media (max-width:500px) and (max-width:350px) {
    .login-block {
        width: 300px
    }
    .login-block .social-connect {
        padding-left: 0px;
        padding-right: 0px
    }
    .login-block .social-connect li {
        padding-right: 2px;
        padding-left: 2px
    }
}

.login-head {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 24px;
    color: #354052;
    text-align: left;
    position: relative;
    padding: 20px 0x;
}

.login-head .header-circle {
    background: #be4c0a;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    position: absolute;
    top: -35px;
    left: 50%;
    margin-left: -35px;
    text-align: center;
    line-height: 65px
}

.login-head .header-circle i {
    color: #fff;
    direction: rtl
}

.login-bottom {
    padding: 7px 0px
}

.login-bottom .form-group label {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: none;
    font-size: 14px;
    color: #7f8fa4;
    margin-top: 2px;
    margin-bottom: 8px
}

.login-bottom .form-group .form-control {
    height: 46px;
    background: #f8fafc
}

.login-bottom .btn-success {
    width: 100%;
    text-transform: none;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    background: #e26b26;
    border: 1px solid #be4c0a;
    color: #fff;
    margin-top: 2px;
    padding: 12px
}

.divider-login {
    border-bottom: 4px solid #e6eaee;
    position: relative;
    margin: 20PX;
}

.divider-login .divider-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    background-color: #fff;
    width: 210px;
    text-align: center;
    left: 50%;
    margin-left: -105px;
    margin-top: -10px;
    position: absolute
}

.social-connect {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 0;
    padding-left: 0px;
    padding-right: 0px
}

.social-connect li {
    padding-left: 0px;
    padding-right: 0px
}

.social-connect li:first-child {
    padding-left: 0
}

.social-connect li:last-child {
    padding-right: 0
}

.social-connect li .btn-social {
    height: 70px;
    width: 70px;
    background: red
}

.social-connect li .btn-social .social-circle {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #fff;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    line-height: 40px
}

.social-connect li .btn-social .social-circle i {
    color: green;
    font-size: 18px
}

.social-connect li .btn-social .social-circle .fa-twitter {
    color: #0af
}

.social-connect li .btn-social .social-circle .fa-facebook {
    color: #2492e4
}

.social-connect li .btn-social .social-circle .fa-google-plus {
    color: #d34836
}

.social-connect li .btn-social .social-circle .fa-linkedin {
    color: #4875b4
}

.social-connect li .btn-social.fb {
    background: #2492e4
}

.social-connect li .btn-social.tw {
    background: #4cc3ff
}

.social-connect li .btn-social.google-plus {
    background: #d34836 !important;
}

.social-connect li .btn-social.linkedin {
    background-color: #0077b5
}

.problem-login {
    margin-top: -25px;
    color: #333;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px
}

.problem-login a {
    color: #333;
    text-transform: capitalize;
    text-decoration: underline
}

.bg-dargrey {
    background-color: #fafbfc
}

.login-two {
    width: 350px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    margin-top: -175px
}

.login-two .login-bottom.small {
    position: relative;
    padding: 32px 30px 21px
}

.login-two .login-bottom.small .form-group {
    margin-bottom: 13px
}

.login-two .login-bottom.small .form-group .form-control {
    height: 36px
}

.login-two .login-bottom.small .form-group .mail-form {
    margin-bottom: -3px
}

.login-two .login-bottom.small .btn-success {
    margin-top: 8px;
    padding: 8px 12px 7px;
    text-transform: capitalize
}

.login-two .problem-login.small {
    text-align: center;
    background-color: #f8fafc;
    margin-top: 0;
    height: 49px;
    line-height: 49px;
    box-shadow: 0 -1px 0 0 #e7e9ed;
}

.login-two .problem-login.small a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4
}

.login-two .account-signup {
    padding-top: 15px;
    opacity: .5;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #fff
}

.login-two .account-signup a {
    color: #fff;
    text-transform: capitalize
}

.main-login,
body,
html {
    height: 100%
}

.main-login .row {
    height: 100%;
    margin: 0
}

.main-login .row .main-col {
    height: 100%;
    padding: 0
}

.main-login .row .main-col .left-col {
    height: 100%;
    background-color: #212531;
    padding: 0
}

.main-login .row .main-col .left-col .left-box {
    height: 100%;
    padding-right: 0
}

.main-login .row .main-col .left-col .left-box .black-box {
    position: relative;
    width: 100%;
    background: #1e212c;
    border: 1px solid #242835;
    top: 50%;
    padding: 50px;
    margin-top: -101px
}

.main-login .row .main-col .left-col .left-box .black-box .header-circle {
    left: 50px;
    margin-left: 0
}

.main-login .row .main-col .left-col .left-box .black-box .heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 26px;
    color: #fff
}

.main-login .row .main-col .left-col .left-box .black-box .box-text {
    opacity: .6;
    margin-top: 7px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    line-height: 28px
}

.main-login .row .main-col .right-col {
    height: 100%;
    background: #2ea1f8 padding: 0
}

.main-login .row .main-col .right-col .right-box {
    position: relative;
    top: 50%;
    margin-top: -99px;
    padding-left: 0
}

.main-login .row .main-col .right-col .right-box .login-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    padding-left: 40px;
    font-size: 26px;
    color: #fff;
    position: absolute;
    top: -55px
}

.main-login .row .main-col .right-col .right-box .white-box {
    background: #fff;
    padding: 30px 70px 20px 40px;
}

.main-login .row .main-col .right-col .right-box .white-box .form-group label {
    text-transform: none
}

.main-login .row .main-col .right-col .right-box .white-box .form-group .form-control {
    height: 36px;
    background: #f8fafc;
    margin-top: 1px
}

.main-login .row .main-col .right-col .right-box .white-box .mail-form {
    margin-bottom: 9px
}

.main-login .row .main-col .right-col .right-box .signin-divison {
    padding-left: 40px;
    position: absolute;
    bottom: -60px
}

.main-login .row .main-col .right-col .right-box .signin-divison .btn-signin {
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #fff;
    background: #1f5480;
    padding: 8px 35px
}

.main-login .row .main-col .right-col .right-box .signin-divison span a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color: #fff;
    margin-left: 21px;
    vertical-align: middle
}

.main-login .row .main-col .footer-section {
    opacity: .5;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    position: absolute;
    bottom: 26px
}

.main-login .row .main-col .footer-section ul {
    margin-bottom: 0
}

.main-login .row .main-col .footer-section ul li {
    padding-left: 0;
    padding-right: 0
}

.main-login .row .main-col .footer-section ul li:last-child a {
    border-right: 0
}

.main-login .row .main-col .footer-section ul li a {
    padding-right: 10px;
    padding-left: 8px;
    border-right: 1px solid #e6eaee;
    text-transform: capitalize;
    color: #fff
}

.main-login .row .main-col .footer-section.right {
    right: 30px
}

.main-login .row .main-col .footer-section.left {
    left: 46px
}

.main-login .row .main-col .footer-section.left a {
    padding-right: 10px;
    color: #fff;
    text-transform: capitalize
}

.no-border {
    border-width: 0!important
}

.bg-lightgrey {
    background-color: #fafbfc
}

.no-padding {
    padding: 0!important
}

.nopadding-top-bottom {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.no-paddingbottom {
    padding-bottom: 0
}

.no-texttransform {
    text-transform: none!important
}

.btn-edit-details {
    width: 40px;
    height: 40px;
    background: #f4f7f9 border: 1px solid #e7eaee;
    position: absolute;
    border-radius: 50%;
    top: -10px;
    color: #354052;
    font-size: 18px;
    right: 2px
}

.nav.nav-pills li.active a,
.nav.nav-pills li:hover a {
    background-color: transparent
}

.tab-content.nopadding {
    border: 0;
    margin: 0;
    padding: 0
}

.panel-heading.user-pages {
    border-bottom: 1px solid #e6eaee!important;
    height: auto;
    background-color: #fff
}

.panel-heading.user-pages.navbars .btn-downloadreport {
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 24px;
    padding: 7px 12px
}

.panel-heading.user-pages.navbars .divider {
    border-top: 1px solid red;
    margin-left: 14px;
    margin-right: 14px;
    border-top: 1px solid #e6eaee
}

.panel-heading.user-pages.navbars .performance-list li {
    padding-left: 13px;
    padding-right: 13px
}

.panel-heading.user-pages.navbars .performance-list li a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    font-size: 16px;
    color: #7f8fa4;
    padding: 16px 0 21px
}

.panel-heading.user-pages.navbars .performance-list li.active a {
    color: #354052;
    border-bottom: 3px solid #e06a26;
}

.panel-heading.user-pages.navbars .datepicker-list {
    margin-top: 6px
}

.panel-heading.user-pages.navbars .datepicker-list li {
    vertical-align: middle;
    padding-left: 4px;
    padding-right: 4px
}

.panel-heading.user-pages.navbars .datepicker-list .date-separator {
    width: 8px;
    height: 2px;
    background: #c5d0de
}

.panel-heading.user-pages.navbars .datepicker-list .form-group {
    margin-bottom: 0
}

.panel-heading.user-pages.navbars .datepicker-list .form-group .input-group {
    width: 140px;
    margin-bottom: 0
}

.panel-heading.user-pages.navbars .datepicker-list .form-group .form-control {
    background: #f2f4f7 border: 1px solid #ced0da;
    border-left: 0;
    border-right: 0;
    height: 36px;
    padding-left: 6px;
    text-transform: capitalize;
    padding-right: 0
}

.panel-heading.user-pages.navbars .datepicker-list .form-group .input-group-addon {
    background: #f2f4f7 box-shadow: none;
    height: 36px;
    border-color: #ced0da
}

.panel-heading.user-pages.navbars .datepicker-list .form-group .input-group-addon:focus {
    border-color: #ced0da
}

.panel-heading.user-pages.navbars .datepicker-list .form-group .calendar-addon {
    padding-right: 6px
}

.panel-heading.user-pages.navbars .datepicker-list .form-group .angle-addon {
    border-left: 0;
    padding-left: 2px
}

.invoice-filters {
    padding-left: 30px;
    padding-right: 30px
}

.invoice-filters .select-forms .form-group {
    display: inline;
    margin-right: 7px;
    margin-top: 14px;
    margin-bottom: 14px
}

.invoice-filters .select-forms .form-group select.form-control {
    min-width: 140px;
    text-transform: capitalize;
    height: 36px
}

.invoice-filters .navbar-form {
    margin-top: 14px;
    margin-bottom: 0
}

.page-heading {
    float: left;
    padding: 12px 12px 14px
}

.user-info-bk {
    float: right;
    margin-top: 5px
}

.user-info-bk .user-img {
    position: relative;
    float: left;
    margin-right: 7px
}

.user-info-bk .user-img .status {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    bottom: -1px;
    right: 3px
}

.user-info-bk .user-img .status .status-circle {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 3px solid #38a76a;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    left: 50%;
    margin-left: -7px
}

.user-info-bk .user-details {
    float: right;
    margin-left: 8px
}

.user-info-bk .user-details .user-name {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    margin-top: 2px;
    text-transform: capitalize
}

.user-info-bk .user-details .user-name a {
    color: #1a91eb;
    text-decoration: none
}

.user-info-bk .user-details .user-role {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    font-size: 14px;
    color: #7f8fa4;
    margin-top: 4px
}

.user-info-bk .user-details .dropdown {
    float: right;
    margin-right: 1px
}

.user-info-bk .user-details .dropdown .btn {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    margin-left: 20px;
    margin-top: 8px
}

.user-info-bk .user-details .dropdown .btn i {
    font-size: 16px;
    color: #a1a7af
}

.user-info-bk .user-details .dropdown .dropdown-menu {
    left: auto;
    right: 0
}

.friends-list {
    margin-top: -10px;
    height: 45px
}

.friends-list li {
    margin-left: 5px
}

.friends-list li:first-child {
    margin-left: 0
}

.friends-list li a {
    position: absolute
}

.friends-list li .btn-more-users {
    background: #be4c0a;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;
    line-height: 29px;
    font-size: 14px;
    color: #fff
}

.header-circle {
    background: #be4c0a;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    position: absolute;
    top: -35px;
    left: 50%;
    margin-left: -35px;
    text-align: center;
    line-height: 65px
}

.header-circle i {
    color: #fff;
    direction: rtl;
    font-size: 20px
}

.dark-bg {
    background-color: #212531
}

.image-hover .image-holder {
    position: relative;
    background-color: #28303c;
    display: block;
}

.image-hover .image-holder img {
    border-radius: 0
}

.image-hover .image-holder .search {
    position: absolute;
    height: 50px;
    width: 50px;
    background-color: #fff;
    opacity: .3;
    border-radius: 50%;
    left: 50%;
    margin-left: -25px;
    top: 50%;
    margin-top: -25px;
    display: none
}

.image-hover .image-holder .fa {
    color: #fff;
    font-size: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -9px;
    display: none
}

.image-hover:hover .image-holder img {
    opacity: .25
}

.image-hover:hover .image-holder .fa,
.image-hover:hover .image-holder .search {
    display: block
}

.upload-box {
    padding-right: 15px
}

.upload-box select.form-control {
    height: 36px
}

.upload-box .image-details {
    padding-top: 8px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 13px;
    color: #7f8fa4
}

.upload-box .image-details i {
    color: #c5d0de;
    font-size: 16px;
    margin-right: 3px
}

.drop-box {
    padding-right: 0
}

.drop-box a {
    text-decoration: none
}

.drop-box a .outer-box {
    padding: 6px;
    height: 162px;
    border: 1px solid #e6eaee;
}

.drop-box a .outer-box .inner-box {
    border: 3px dashed #ced0da;
    width: 100%;
    height: 100%;
    text-decoration: none
}

.drop-box a .outer-box .inner-box i {
    color: #e2e3e9;
    font-size: 40px;
    margin-top: 31px
}

.drop-box a .outer-box .inner-box .cloud-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    text-decoration: none;
    width: 220px;
    margin: 0 auto
}

@media (max-width:460px) {
    .drop-box a .outer-box .inner-box .cloud-text {
        width: auto;
        font-size: 10px
    }
}

@media (min-width:991px) and (max-width:1560px) {
    .drop-box a .outer-box .inner-box .cloud-text {
        width: auto;
        font-size: 10px
    }
}

.drag-drop-box {
    margin-bottom: 8px
}

.selected-box {
    padding-left: 5px;
    max-height: 162px;
    overflow: auto
}

.selected-box .selected-image {
    margin-bottom: 6px;
    width: 73px;
    height: 50px
}

.selected-box .selected-image:last-child {
    margin-bottom: 0
}

.selected-box .selected-image img {
    width: 100%;
    height: 100%;
}

.selected-box .selected-image.single {
    width: 162px;
    height: 162px
}

.selected-box .selected-image.single img {
    height: 100%;
    width: 100%
}

.table.existing-products-table {
    border-radius: 0
}

.table.existing-products-table .actions-list {
    padding-right: 0
}

.table.existing-products-table thead th {
    background-color: #fff;
    height: 50px;
    padding-bottom: 16px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px
}

.table.existing-products-table thead th:first-child {
    border-top-left-radius: 4px;
    padding-left: 18px
}

.table.existing-products-table thead th:nth-child(2) {
    padding-left: 0
}

.table.existing-products-table thead th:last-child {
    border-top-right-radius: 4px;
    padding-right: 18px
}

.table.existing-products-table tbody tr td {
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px
}

.table.existing-products-table tbody tr td:first-child {
    padding-left: 10px;
    border-top: 0;
    border-bottom: 0
}

.table.existing-products-table tbody tr td:nth-child(2) {
    padding-left: 0
}

.table.existing-products-table tbody tr td:last-child {
    padding-right: 10px;
    border-top: 0;
    border-bottom: 0
}

.table.existing-products-table tbody tr td img {
    width: 60px;
    height: 40px;
}

.table.existing-products-table tbody tr td p {
    color: #354052;
    line-height: 20px
}

.table.existing-products-table tbody tr td a {
    color: #354052;
    text-decoration: none
}

.table.existing-products-table tbody tr td ul {
    margin-bottom: 0
}

.table.existing-products-table tbody tr td ul li a i {
    color: #c8d2df;
    font-size: 16px
}

.table.existing-products-table.owos tbody tr td img {
    width: 40px!important
}

.table.panel-table {
    margin-bottom: 0
}

.table.panel-table thead tr th {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px
}

.table.panel-table thead tr th .sort {
    margin-top: 5px;
    float: none;
    margin-left: 10px
}

.table.panel-table thead tr th.active {
    color: #1a91eb
}

.table.panel-table tbody tr td {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    font-size: 14px;
    position: relative
}

.table.panel-table tbody tr td .friends-list li {
    padding-right: 8px
}

.table.panel-table tbody tr td .label {
    padding: 4px 8px 3px;
    font-size: 12px;
    color: #fff
}

.table.panel-table tbody tr td .btn-default {
    border: 1px solid #ced0da;
    color: #354052;
    padding: 7px 14px
}

.table.panel-table tbody tr td .btn-default i {
    margin-left: 22px
}

.table.projects-table th {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 600
}

.table.projects-table th i {
    margin-top: 4px
}

.table.projects-table th:first-child {
    padding-left: 30px
}

.table.projects-table tbody tr td {
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    position: relative
}

.table.projects-table tbody tr td a {
    color: #354052;
    text-decoration: none
}

.table.projects-table tbody tr td:first-child {
    padding-left: 30px
}

.table.projects-table tbody tr td .overdue {
    color: #d35847
}

.table.projects-table tbody tr td .media .media-left {
    padding-right: 16px
}

.table.projects-table tbody tr td .media .media-body {
    max-width: 150px
}

.table.projects-table tbody tr td .media .media-body .media-heading {
    margin-bottom: 0;
    line-height: 16px!important
}

.table.projects-table tbody tr td .media .media-body .media-heading a {
    text-decoration: none;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px
}

.table.projects-table tbody tr td .media .media-body .media-heading .text-muted {
    display: block;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 12px;
    margin-top: 3px
}

.table.projects-table tbody tr td .btn-default {
    box-shadow: none;
    color: #354052;
    position: relative;
    padding: 7px 13px 8px 12px
}

.table.projects-table tbody tr td .btn-default i {
    color: #a8aab7;
    font-size: 16px;
    margin-left: 22px
}

.table.projects-table tbody tr .status {
    font-size: 12px
}

.table.projects-table tbody tr .status .status-circle {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 9px;
    vertical-align: middle
}

.table.projects-table tbody tr .status.blue .status-circle {
    border: 3px solid #19a5d6
}

.table.projects-table tbody tr .status.gold .status-circle {
    border: 3px solid #da932c
}

.table.projects-table tbody tr .status.maroon .status-circle {
    border: 3px solid #636363
}

.table.projects-table tbody tr .status.green .status-circle {
    border: 3px solid #66b92e
}

.table.projects-table tbody tr.overdue td:first-child {
    border-left: 3px solid #d35847
}

.table.apps-table {
    margin-bottom: 0
}

.table.apps-table tbody tr td:first-child,
.table.apps-table tbody tr th:first-child,
.table.apps-table thead tr td:first-child,
.table.apps-table thead tr th:first-child {
    padding-left: 30px
}

.table.apps-table tbody td {
    text-transform: capitalize
}

.table.apps-table tbody td img {
    width: 58px;
    height: 58px;
    margin-right: 15px
}

.table.apps-table tbody td .app-details {
    display: inline-block;
    vertical-align: middle
}

.table.apps-table tbody td .app-details .app-name {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px
}

.table.apps-table tbody td .app-details .app-name a {
    color: #354052;
    text-decoration: none
}

.table.apps-table tbody td .text-secondary {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 12px;
    color: #7f8fa4
}

.table.apps-table tbody td .label {
    padding: 5px 10px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    text-transform: uppercase
}

.table.apps-table tbody td ul {
    margin-bottom: 0
}

.table.apps-table tbody td ul li {
    padding-left: 4px;
    padding-right: 4px;
}

.table.apps-table tbody td ul li a i {
    color: #bbc5d5;
    font-size: 12px;
}

.table.apps-table tbody td ul li a .fa-play-circle-o {
    display: none
}

.table.apps-table tbody td ul li.active a i {
    color: #1bb934
}

.table.apps-table tbody td ul li.active a .fa-play-circle-o {
    display: inline-block
}

.table.apps-table tbody td ul li.active a .fa-pause-circle-o {
    display: none
}

.table.apps-table.owos tbody tr img {
    width: 40px!important;
    height: 40px!important;
}

.label {
    text-transform: uppercase;
}

.label.label-info {
    background: #18a5d6
}

.label.label-warning {
    background: #da932c
}

.label.label-success {
    background: #1bb934
}

.label.label-default {
    background-color: #9299a7
}

.label.label-primary {
    background-color: #0474c8
}

.label.label-danger {
    background-color: #f86b67
}

.img-46 {
    width: 46px;
    height: 46px
}

.img-radius {
    border-radius: 0
}

.img-50 {
    width: 50px;
    height: 50px
}

.img-30 {
    width: 30px;
    height: 30px;
    border: 1px solid #fff
}

.img-32 {
    width: 32px;
    height: 32px;
    border: 1px solid #fff
}

.img-36 {
    width: 36px;
    height: 36px;
    border: 1px solid #fff
}

.img-38 {
    width: 38px;
    height: 38px;
    border: 1px solid #fff
}

.img-40 {
    width: 40px;
    height: 40px;
    border: 1px solid #fff
}

.header-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 24px;
    text-transform: capitalize;
    color: #354052
}

#mce_0_ifr {
    height: 82px!important
}

#mce_0_ifr,
.mce-edit-area {
    border-radius: 0
}

.mce-toolbar-grp {
    background: #f2f4f7!important;
}

.mce-tinymce {
    border: 1px solid #ced0da!important;
    margin-bottom: 13px!important
}

.mce-btn.mce-active,
.mce-btn.mce-active:hover {
    background-color: #dbdbdb !important;
    border-color: #ccc !important
}

.mce-btn button {
    padding: 4px 5px!important;
    margin-left: 0!important
}

@media (max-width:500px) {
    .mce-btn button {
        padding-left: 4px 5px!important
    }
}

.mce-ico {
    color: #aaa!important;
    font-size: 16px!important
}

.mce-btn-group:not(:first-child) {
    border-left: 0!important;
    margin-left: 30px!important
}

.mce-caret {
    border-top: 4px solid #aaa!important
}

.post-impact-images-uploaded {
	height: 200px;
	width: 200px;
}