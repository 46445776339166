.panel-dark {
    background-color: #222c3c;
}

.sort-panel {
    border: 0
}

.sort-panel .search-form-box {
    padding: 5px
}

.sort-panel .search-form-box form .radio-inline {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    margin-top: -13px;
    font-family: Source Sans Pro, sans-serif!important;
    letter-spacing: .6px;
    text-transform: capitalize
}

.sort-panel .search-form-box form .radio-inline .select {
    margin-top: 0
}

.sort-panel .search-form-box form .sort-form {
    margin-bottom: 10px
}

.sort-panel .search-form-box form .sort-form .sort-check {
    margin: 0
}

.sort-panel .search-form-box form .sort-form .sort-check .sort-select {
    font-size: 14px;
    color: #fff;
    text-transform: none;
    font-weight: 400
}

.sort-panel .search-form-box form .sort-form .sort-radio {
    margin: 0
}

.sort-panel .search-form-box form .sort-form .sort-radio .sort-check {
    font-size: 14px;
    margin-right: 17px;
    color: #fff;
    text-transform: none;
    font-weight: 400
}

.sort-panel .search-form-box form .form-group .selection {
    margin-bottom: 0
}

.sort-panel .search-form-box form .form-group .search-group {
    margin-bottom: 1px;
    font-size: 14px;
    font-family: Source Sans Pro, sans-serif!important
}

.sort-panel .search-form-box form .form-group .search-group .form-icon {
    height: 36px;
    border-color: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
    border-right: 0
}

.sort-panel .search-form-box form .form-group .search-group .form-icon i {
    color: #bbc5d5
}

.sort-panel .search-form-box form .form-group .search-group .search-form {
    height: 36px;
    border-color: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0;
    border-left: 0;
    background: #fff
}

.sort-panel .search-form-box .select-option {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    font-family: Source Sans Pro, sans-serif!important;
    text-transform: capitalize;
    letter-spacing: .6px;
    cursor: pointer
}

.sort-panel .search-form-box .select-option input[type=checkbox] {
    margin-right: 9px
}

.sort-panel .search-form-box .btn-primary {
    height: 36px;
    width: 100%;
    margin-top: 3px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 600
}

.sort-panel .search-form-box .btn-primary i {
    font-size: 16px;
    margin-right: 7px;
    color: #fff
}

.sort-panel .search-form-box .btn-primary span {
    color: #fff;
    letter-spacing: .6px;
    font-family: Source Sans Pro, sans-serif
}

.revenue-panel {
    padding-bottom: 0
}

.revenue-block {
    padding-right: 14px;
    padding-left: 14px;
    background-color: #fff
}

.revenue-block .monthly-revenue {
    position: relative
}

.revenue-block .monthly-revenue .revenue-head {
    color: #354052;
    font-size: 24px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    margin-top: 10px
}

.revenue-block .monthly-revenue .btn-month {
    margin-top: 7px;
    background: #f2f4f7;
    border: 1px solid #ced0da;
    text-transform: capitalize;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 6px 8px 7px 16px;
    font-weight: 400;
    margin-right: 3px;
    color: #354052
}

.revenue-block .monthly-revenue .btn-month span {
    margin-left: 8px
}

.revenue-block .monthly-revenue .btn-month span i {
    color: #a8aab7
}

.revenue-block .monthly-revenue .month-dropdown {
    right: 0;
    top: 40px;
    left: auto
}

.revenue-block .revenue-spending .revenue {
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    letter-spacing: .6px;
    font-weight: 400;
    margin-top: 15px
}

.revenue-block .revenue-spending .revenue .income {
    font-size: 18px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #354052;
    font-weight: 400;
    display: block
}

.revenue-block .revenue_progress {
    margin-top: 8px;
    height: 36px;
    background: #445c83
}

.revenue-block .revenue_progress .progress-bar {
    background: #2bb415;
}

.revenue-block .budget-spending {
    border-top: 1px solid #e6eaee;
    padding-top: 14px
}

.revenue-block .budget-spending .budget {
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #7f8fa4;
    text-transform: capitalize;
    letter-spacing: .6px;
    font-weight: 400
}

.revenue-block .budget-spending .budget .income {
    font-size: 18px;
    display: block;
    color: #354052;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

.revenue-block .budget_progress {
    margin-top: 8px;
    height: 36px;
    background: #e2e7ee
}

.revenue-block .budget_progress .budget-bar {
    background: #de6926;
}

.last-month-income {
    background-color: #fafbfc;
    border-top: 1px solid #e6eaee
}

.previous-month-details {
    padding: 13px 15px 20px
}

.previous-month-details .previous-month {
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    letter-spacing: .6px;
    font-weight: 400;
    margin-top: 4px
}

.previous-month-details .previous-month-income {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-top: 5px;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.previous-month-details .previous-month-income .month_income {
    font-size: 14px;
    color: #354052;
    text-transform: capitalize;
    letter-spacing: .6px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.previous-month-details .previous-month-income .income-details {
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
    letter-spacing: .6px;
    font-weight: 400;
    text-align: center
}

.previous-month-details .exmp {
    width: 100%
}

.previous-month-details .last-month-progress {
    height: 8px;
    margin-bottom: 10px;
    margin-top: -6px;
    background: #445c83
}

.previous-month-details .last-month-progress .previous-month-bar {
    background: #de6926;
}

.previous-month-details .last-month-budget-progress {
    height: 8px;
    margin-bottom: 10px;
    background: #e2e7ee;
    margin-top: -4px
}

.previous-month-details .last-month-budget-progress .previous-month-bar {
    background: #de6926;
}

.product-sales-panel {
    padding: 27px 30px 30px;
    border-top: 3px solid #1a91eb
}

.product-sales-panel .product-sales .sales-header {
    color: #354052;
    font-size: 24px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 300;
    margin-top: -5px;
    margin-bottom: 22px;
    text-transform: capitalize
}

.product-sales-panel .product-sales .product-sales-list {
    border-bottom: 1px solid #e6eaee;
    margin-top: 11px
}

.product-sales-panel .product-sales .product-sales-list li {
    margin-top: -11px
}

.product-sales-panel .product-sales .product-sales-list li:last-child .progress {
    margin-bottom: 15px
}

.product-sales-panel .product-sales .product-sales-list .product-name {
    font-size: 14px;
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-bottom: 7px;
    color: #354052
}

.product-sales-panel .product-sales .product-sales-list .product-name .circle-primary {
    height: 10px;
    width: 10px;
    float: left;
    border: 2px solid #1aa6d6;
    border-radius: 50%;
    margin-top: 2px
}

.product-sales-panel .product-sales .product-sales-list .product-name .circle-purple {
    height: 10px;
    width: 10px;
    float: left;
    border: 2px solid #8e76e4;
    border-radius: 50%;
    margin-top: 2px
}

.product-sales-panel .product-sales .product-sales-list .product-name .circle-success {
    height: 10px;
    width: 10px;
    float: left;
    border: 2px solid #49c834;
    border-radius: 50%;
    margin-top: 2px
}

.product-sales-panel .product-sales .product-sales-list .product-name .circle-yellow {
    height: 10px;
    width: 10px;
    float: left;
    border: 2px solid #fac942;
    border-radius: 50%;
    margin-top: 2px
}

.product-sales-panel .product-sales .product-sales-list .product-name span {
    margin-left: 9px
}

.product-sales-panel .product-sales .product-sales-list .sales-count {
    color: #7f8fa4;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-top: 10px
}

.product-sales-panel .product-sales .product-sales-list .progress {
    height: 18px;
    background: #e2e7ee;
    margin-top: 3px
}

.product-sales-panel .product-sales .product-sales-list .progress .progress-primary {
    background: #de6926;
}

.product-sales-panel .product-sales .product-sales-list .progress .progress-purple {
    background: #9d90e4;
}

.product-sales-panel .product-sales .product-sales-list .progress .progress-success {
    background: #2bb415;
}

.product-sales-panel .product-sales .product-sales-list .progress .progress-yellow {
    background: #fdc018;
}

.product-sales-panel .product-sales .sales-range {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -5px
}

.product-sales-panel .product-sales .sales-range li {
    text-align: justify;
    color: #354052;
    font-size: 12px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.customer-section {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.customer-section .customers-panel {
    padding: 29px 29px 30px
}

.customer-section .customers-panel .customer-block .customer-head {
    font-size: 20px;
    color: #354052;
    font-weight: 400;
    margin-top: -9px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    letter-spacing: .6px
}

.customer-section .customers-panel .customer-block .customers-count {
    font-size: 30px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #354052;
    font-weight: 400;
    margin-top: -13px
}

.customer-section .customers-panel .customer-block .customers-percentage {
    color: #3fc529;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -27px;
    font-family: Source Sans Pro, sans-serif
}

.customer-section .customers-panel .customer-block .customers-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin: 1px -20px 0
}

.customer-section .customers-panel .customer-block .customers-list li {
    text-align: justify
}

.customer-section .customers-panel .customer-block .customers-list li .circle-new {
    border: 2px solid #1aa6d6;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.customer-section .customers-panel .customer-block .customers-list li .circle-return {
    border: 2px solid #40557d;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.customer-section .customers-panel .customer-block .customers-list li .circle-referal {
    border: 2px solid #fac942;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.customer-section .customers-panel .customer-block .customers-list li span {
    font-size: 14px;
    color: #354052
}

.customer-referrals {
    background-color: #fafbfc;
    padding: 15px 29px 9px
}

.customer-referrals h2 {
    font-size: 30px;
    margin-top: 0;
    font-weight: 400;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #354052
}

.customer-referrals .status {
    color: #7f8fa4;
    font-size: 12px;
    margin-top: -9px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: uppercase;
    letter-spacing: .6px
}

.customer-referrals .progress {
    background: #e2e7ee;
    height: 8px;
    margin-top: 8px
}

.customer-referrals .progress .progress-primary {
    background: #de6926;
}

.customer-referrals .progress .progress-yellow {
    background: #fdc018;
}

.spending-panel {
    padding: 30px
}

.spending-panel .spending-block .spending-head {
    font-size: 24px;
    color: #354052;
    font-weight: 400;
    margin-top: -6px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    letter-spacing: .6px
}

.spending-panel .spending-block .spenders-count {
    font-size: 24px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #1a91eb;
    font-weight: 400;
    margin-top: -9px
}

.spending-panel .spending-block #char {
    margin-top: -21px
}

.spending-panel .spending-block .spending-progress-table {
    margin-top: 10px;
    margin-bottom: -11px
}

.spending-panel .spending-block .spending-progress-table .spend-for {
    font-size: 16px;
    color: #354052;
    padding-top: 7px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize
}

.spending-panel .spending-block .spending-progress-table .spend-for .food-share {
    border: 2px solid #40557d;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.spending-panel .spending-block .spending-progress-table .spend-for .family-share {
    border: 2px solid #fac942;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.spending-panel .spending-block .spending-progress-table .spend-for .company-share {
    border: 2px solid #1aa6d6;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.spending-panel .spending-block .spending-progress-table .spend-for .stocks-share {
    border: 2px solid #f0b499;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.spending-panel .spending-block .spending-progress-table .spend-for .travel-share {
    border: 2px solid #72c4b9;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.spending-panel .spending-block .spending-progress-table .spend-for .other-share {
    border: 2px solid #e06a26;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 5px
}

.spending-panel .spending-block .spending-progress-table .spent-percentage {
    color: #7f8fa4;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-left: 13px;
    font-weight: 400;
    float: left;
    margin-bottom: 7px
}

.daily-sales-section {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.daily-sales-section .sales-panel {
    padding: 29px 29px 30px
}

.daily-sales-section .sales-panel .sales-block .sales-head {
    font-size: 20px;
    color: #354052;
    font-weight: 400;
    margin-top: -9px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    letter-spacing: .6px
}

.daily-sales-section .sales-panel .sales-block .sales-count {
    font-size: 30px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #354052;
    font-weight: 400;
    margin-top: -13px
}

.daily-sales-section .sales-panel .sales-block .sales-percentage {
    color: #3fc529;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -27px;
    font-family: Source Sans Pro, sans-serif
}

.daily-sales-section .sales-panel .sales-block .daily-sales-progress .progress-bar-vertical {
    width: 3px;
    min-height: 100px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-right: 5px;
    background: transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    float: left
}

.daily-sales-section .sales-panel .sales-block .daily-sales-progress .progress-bar-vertical .progress-bar {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 0;
    transition: height .6s ease
}