.user-follow .btn {
    padding: 6px 15px
}

.user-follow .media-heading {
    font-size: 16px;
    margin-bottom: 0;
    display: inline-block
}

.user-follow.owos .media-left img {
    width: 40px;
    height: 40px;
}

.user-follow.owos .media-body .media-heading {
    max-width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

@media (max-width:398px) {
    .user-follow.owos .media-body .media-heading {
        max-width: 120px
    }
}

@media (max-width:350px) {
    .user-follow.owos .media-body .media-heading {
        max-width: 110px
    }
}

@media (max-width:398px) {
    .user-follow.owos .media-body .btn.unfollow {
        padding: 4px 7px
    }
}