.progress {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    background: #b84c0e;
    height: 12px;
    box-shadow: none
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #b84c0e;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    transition: width .6s ease;
    box-shadow: none;
    background-color: #0090d9;
    background-image: none;
    transition: all 1s cubic-bezier(.785, .135, .15, .86);
    transition-timing-function: cubic-bezier(.785, .135, .15, .86)
}

.progress-bar-success {
    background: #b84c0e;
    background-repeat: repeat-x;
}

.progress-bar-info {
    background-color: #b84c0e;
    background-image: none
}

.progress-bar-warning {
    background: #b84c0e;
    background-repeat: repeat-x;
    background-color: #b84c0e;
    background-image: none
}

.progress-bar-danger {
    background: #b84c0e;
}

.progress-mini {
    height: 4px!important
}

.progress-mini .progress-bar {
    border-radius: 0px
}