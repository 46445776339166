.carousel-caption-text-shadow {
    text-shadow: 2px 4px 2px rgba(0, 0, 0, .6)
}

.imgword {
border: none;
height: auto;
max-width: 100%;
width: auto;
}
.custom-col-lg-6
{
padding-left: 0px;
padding-right: 0px;
}
.fullwidth
{
width: 100%;

}

.vid-list-container {
   width: 91%;
   overflow: hidden;
   margin-left:4%;
   padding-bottom: 20px;
}

.vid-list {
   width: 5344px;
   position: relative;
   top:0;
   left: 0;
}

.vid-item {
   display: block;
   width: 260px;
   height: 178px;
   float: left;
   margin: 0;
   padding: 5px;
}

.thumb {

   overflow:hidden;
   height: 150px;
}

.thumb img {
   display: block;
   width:auto;
   height: 151px;
   margin: auto;
}

.vid-item .desc {
   color: #21A1D2;
   font-size: 1em;
   margin-top:3px;
    text-align:center;
}

.vid-item:hover {
   background: #eee;
   cursor: pointer;
}

.arrows {
   position:relative;
   width: 98%;
}

.arrow-left {
   color: #fff;
   position: absolute;
   background: #777;
   padding: 15px;
   left: -25px;
   top: -130px;
   z-index: 99;
   cursor: pointer;
}

.arrow-right {
   color: #fff;
   position: absolute;
   background: #777;
   padding: 15px;
   right: -25px;
   top: -130px;
   z-index:100;
   cursor: pointer;
}

.arrow-left:hover {
   background: #CC181E;
}

.arrow-right:hover {
   background: #CC181E;
}
.img-responsive-impact
{
  width : 635px !important;
  height: 300px !important;
}
.impact-thumb-img {

   overflow:hidden;
   height: 150px;
}

.impact-thumb-img img {
   display: block;
   width:250px;
   height: 151px;
   margin: auto;
}
