/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

.datepicker {
    padding: 4px;
    direction: ltr
}

.datepicker-inline {
    width: 220px
}

.datepicker.datepicker-rtl {
    direction: rtl
}

.datepicker.datepicker-rtl table tr td span {
    float: right
}

.datepicker-dropdown {
    top: 0;
    left: 0
}

.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, .2);
    position: absolute
}

.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute
}

.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px
}

.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px
}

.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px
}

.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px
}

.datepicker-dropdown.datepicker-orient-top:before {
    top: -7px
}

.datepicker-dropdown.datepicker-orient-top:after {
    top: -6px
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff
}

.datepicker>div {
    display: none
}

.datepicker.days div.datepicker-days,
.datepicker.months div.datepicker-months,
.datepicker.years div.datepicker-years {
    display: block
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.datepicker td,
.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border: none
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent
}

.datepicker table tr td.day:hover {
    background: #eee;
    cursor: pointer
}

.datepicker table tr td.new,
.datepicker table tr td.old {
    color: #999
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    background: none;
    color: #999;
    cursor: default
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
    background-color: #fde19a;
    background: #fdd49a;
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    color: #000
}

.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today[disabled] {
    background-color: #fdf59a
}

.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover:active {
    background-color: #fbf069\9
}

.datepicker table tr td.today:hover:hover {
    color: #000
}

.datepicker table tr td.today.active:hover {
    color: #fff
}

.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range:hover {
    background: #eee;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today:hover {
    background-color: #f3d17a;
    background: #f3c17a;
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
    border-color: #f3e97a #f3e97a #edde34;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    border-radius: 0
}

.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today.disabled:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today[disabled] {
    background-color: #f3e97a
}

.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover:active {
    background-color: #efe24b\9
}

.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover {
    background-color: #9e9e9e;
    background: #b3b3b3;
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
    border-color: gray gray #595959;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25)
}

.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected.disabled:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected[disabled] {
    background-color: gray
}

.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover:active {
    background-color: #666\9
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
    background-color: #006dcc;
    background: #08c;
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #04c #04c #002a80;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25)
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled] {
    background-color: #04c
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover:active {
    background-color: #039\9
}

.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
}

.datepicker table tr td span:hover {
    background: #eee
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
    background: none;
    color: #999;
    cursor: default
}

.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
    background-color: #006dcc;
    background: #08c;
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #04c #04c #002a80;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25)
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled] {
    background-color: #04c
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
    background-color: #039\9
}

.datepicker table tr td span.new,
.datepicker table tr td span.old {
    color: #999
}

.datepicker th.datepicker-switch {
    width: 145px
}

.datepicker tfoot tr th,
.datepicker thead tr:first-child th {
    cursor: pointer
}

.datepicker tfoot tr th:hover,
.datepicker thead tr:first-child th:hover {
    background: #eee
}

.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle
}

.datepicker thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px
}

.input-daterange input {
    text-align: center
}

.input-daterange input:first-child {
    border-radius: 0px
}

.input-daterange input:last-child {
    border-radius: 0
}

.input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px
}