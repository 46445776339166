    .dropdown {
        position: relative;
        display: inline-block;
    }
    .miles-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        border-radius:5%;
        margin-left:-50px;
    }
    .miles-dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align:center;
    }
    .miles-dropdown-content a:hover {background-color: #f1f1f1}
    .location-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 300px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        border-radius:5%;
    }
    .location-dropdown-content input {
        text-decoration: none;
        display: block;
        width:280px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 10px;
        border-color:red;
    }
    .location-dropdown-content a:hover {background-color: #f1f1f1}
    .category-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 80%;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        border-radius:5%;
    }
    .category-dropdown-content div {
        text-decoration: none;
        display: block;
        width:280px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 10px;
        border-color:red;
    }
    .cat-dropdown{
        position: relative;
    }
    .category-dropdown-content a:hover {background-color: #f1f1f1}
    .show {display:block;}
    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid blue;
        border-bottom: 16px solid blue;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .location-content{
        padding-top:10px;
        margin-left: -18px;
    }
    .ellipse input{
        color:#e06a26
    }
    .page_header_response p{
        font-size: 36px;
    }
    .pwdchanges_msg{
        background-color: #dff0d8;
        border-color: #d6e9c6;
        color: #3c763d;
        padding: 15px;
        margin-bottom: 20px;
        border:1px solid transparent;
    }