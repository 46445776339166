body {
    background: #e5e3dc;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #354052;
    letter-spacing: .1px;
    overflow-x: hidden;
}

h1 {
    font-size: 28px;
    line-height: 36px!important
}

h2 {
    font-size: 24px;
    line-height: 30px!important
}

h3 {
    font-size: 22px
}

h3,
h4 {
    line-height: 28px!important
}

h4 {
    font-size: 19px;
    line-height: 24px!important
}

h4,
p {
    font-size: 14px
}

p {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #71787e;
    line-height: 24px
}

.striked {
    text-decoration: line-through
}

.no-margin {
    margin: 0!important
}

a {
    color: #bd5011
}