.mini-profile {
    padding: 10px
}

.mini-profile .background {
    position: relative
}

.mini-profile .background .widget-bg {
    width: 100%;
    height: 100px;
    overflow: hidden
}

.mini-profile .background .widget-bg img {
    width: 100%;
    height: auto
}

.mini-profile .background .label {
    padding: 6.5px 12px;
    background: rgba(49, 53, 62, .25);
    font-size: 12px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    margin-top: 10px;
    margin-right: 10px;
    letter-spacing: .5px;
    position: absolute;
    top: 0;
    right: 0
}

.mini-profile .background .avatar-img {
    position: absolute;
    left: 8px;
    bottom: -45px;
    border-radius: 50%;
    text-align: -webkit-center;
    line-height: 82px
}

.mini-profile .background .avatar-img img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    border: 4px solid #fff
}

.mini-profile .avatar-profile .avatar-details {
    padding-left: 94px
}

.mini-profile .avatar-profile .avatar-details .avatar-name {
    margin: 0
}

.mini-profile .avatar-profile .avatar-details .avatar-name a {
    color: #354052;
    font-size: 16px;
    text-decoration: none;
    text-transform: capitalize
}

.mini-profile .avatar-profile .avatar-details .avatar-mail {
    color: #7f8fa4;
    font-size: 14px;
    margin-top: -4px
}

.mini-profile .social-links {
    padding-top: 6px;
    border-bottom: 1px solid #e6eaee;
    margin-left: 13px;
    margin-right: 13px
}

.mini-profile .address {
    padding-top: 10px
}

.mini-profile .address ul {
    margin-bottom: 4px;
    padding-left: 18px
}

.mini-profile .address ul li {
    line-height: 25px;
    font-size: 14px;
    color: #354052
}

.mini-profile .address ul li i {
    padding-right: 12px;
    color: #bbc5d5;
    font-size: 14px
}

.mini-profile .activity-list {
    margin-top: 13px;
    margin-bottom: 0;
    padding: 0 10px
}

.mini-profile .activity-list li {
    padding-left: 15px;
    padding-right: 15px
}

@media (max-width:350px) {
    .mini-profile .activity-list li {
        padding-left: 10px;
        padding-right: 10px
    }
}
.mini-profile .activity-list .activity-name {
    font-size: 12px;
    color: #354052;
    text-transform: uppercase;
    border: 0;
    text-align: left;
    padding-bottom: 0;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 600
}

.mini-profile .activity-list .activity-count {
    border: 0 none;
    padding-bottom: 5px;
    font-size: 22px;
    padding-top: 0;
    font-weight: 600
}

.mini-profile.dark-profile .background .avatar-img img {
    border: 4px solid #273142
}

.mini-profile.dark-profile .avatar-profile .avatar-details .avatar-name a {
    font-size: 16px;
    color: #fff
}

.mini-profile.dark-profile .avatar-profile .avatar-details .avatar-mail {
    font-size: 14px;
    color: #7c8ca5
}

.mini-profile.dark-profile .social-links {
    border-bottom: 1px solid #313d4f
}

.mini-profile.dark-profile .address li {
    font-size: 14px;
    color: #fff
}

.mini-profile.dark-profile .address li i {
    color: #bbc5d5
}

.mini-profile.widget-candidates .avatar-profile.avatar-candidates {
    border-bottom: 1px solid #f2f4f6;
    margin-right: 20px;
    margin-left: 11px
}

.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-details {
    padding-left: 80px
}

.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-details .avatar-mail i {
    padding-right: 5px;
    font-size: 15px
}

.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-icons {
    padding-top: 20px
}

.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-icons a i {
    color: #c8d2df;
    font-size: 20px
}

.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-icons a:nth-child(1) {
    padding-right: 6px
}

.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-icons .btn-setting.btn-candidate {
    margin-top: 0
}

.mini-profile.widget-candidates .avatar-prefers {
    padding-left: 12px;
    padding-right: 20px;
    padding-top: 14px
}

@media (max-width:415px) {
    .mini-profile.widget-candidates .avatar-prefers .avatar-message .btn-primary.btn-chat {
        padding: 7px 7.5px
    }
}

.mini-profile.widget-candidates .avatar-prefers .avatar-hire {
    padding-top: 10px;
    padding-right: 10px
}

.mini-profile.widget-candidates .avatar-prefers .avatar-hire .btn-tick {
    width: 18px;
    height: 18px;
    background: #2ab314;
    border-radius: 50%;
    padding: 0;
    line-height: 15px;
    vertical-align: text-bottom;
    margin-right: 5px
}

.mini-profile.widget-candidates .avatar-prefers .avatar-hire .btn-tick i {
    color: #fff;
    font-size: 12px
}

.mini-profile.widget-candidates .avatar-prefers .avatar-hire a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #2fb818;
    font-weight: 400;
    text-decoration: none
}

@media (max-width:415px) {
    .mini-profile.widget-candidates .avatar-prefers .avatar-hire.recent-contact {
        padding-right: 0
    }
}

.mini-profile.widget-candidates .avatar-prefers .avatar-hire.recent-contact a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #335980;
    font-weight: 400
}

.mini-profile.widget-candidates .avatar-prefers .avatar-hire.recent-contact a i {
    color: #384b78;
    font-size: 18px;
    padding-right: 5px
}

.mini-profile.widget-candidates .avatar-contact {
    padding-top: 24px;
    padding-bottom: 11px
}

.mini-profile.widget-candidates .avatar-contact a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #c5d0de;
    text-decoration: none;
    font-weight: 400;
    letter-spacing: .5px
}

.mini-profile.widget-candidates .avatar-contact a i {
    color: #c5d0de;
    font-size: 18px;
    padding-right: 10px
}

.panel-body.panel-post {
    padding: 0;
    border: 1px solid #e6eaee;
}

.my-post .form-group .form-control {
    padding: 11px 18px
}

.my-post .post-block {
    background: #fafbfc;
    padding: 12px;
}

.my-post .post-block .left .btn-image {
    padding: 7px 9.5px;
    margin-right: 10px
}

.my-post .post-block .left a {
    font-size: 14px;
    color: #1a91eb;
    text-decoration: none;
    border-left: 1px solid #e6eaee;
    padding-top: 19px;
    padding-bottom: 23px
}

.my-post .post-block .left a i {
    color: #1a91eb;
    padding-right: 15px;
    padding-left: 20px
}

@media (max-width:450px) {
    .my-post .post-block .left a {
        font-size: 12px
    }
    .my-post .post-block .left a i {
        padding-right: 5px;
        padding-left: 11px
    }
}

.my-post .post-block .right .btn-success.btn-post {
    padding: 7px 16px
}

.widget-overview.my-post .form-group:after,
.widget-overview.my-post .form-group:before {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    width: 0;
    height: 0;
    border-style: solid
}

.widget-overview.my-post .form-group:after {
    border-color: transparent transparent #fff;
    border-width: 7px;
    margin-top: -5px;
    left: 40px
}

.widget-overview.my-post .form-group:before {
    border-color: transparent transparent #dfe3e9;
    border-width: 8px;
    margin-top: -8px;
    left: 39px
}

.widget-overview.my-post .btn-primary.btn-image {
    margin-right: 4px
}

.widget-jobs .job-heading {
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 3px;
    font-size: 16px;
    color: #354052;
    font-weight: 600
}

.widget-jobs .job-process {
    border-bottom: 1px solid #e6eaee;
    margin-left: 14px;
    margin-right: 24px;
    padding-bottom: 20px
}

.widget-jobs .job-process .avilable-jobs {
    font-size: 14px;
    color: #354052;
    padding-top: 15px;
    font-weight: 600
}

@media (max-width:990px) {
    .widget-jobs .job-process .avilable-jobs {
        padding-bottom: 10px
    }
}

.widget-jobs .job-process .choose-jobs .btn-group .btn-green {
    background-color: #fafbfc;
    padding: 11px 0;
    box-shadow: none;
    font-size: 14px;
    color: #354052;
    border: 1px solid #e7e9ed
}

.widget-jobs .job-process .choose-jobs .btn-group .btn-green.active {
    background: #39b54a;
    color: #fff;
    border: 1px solid #249533
}

.widget-jobs .job-checking {
    padding-top: 16px;
    margin-left: 14px
}

.widget-jobs .job-checking .employee-review,
.widget-jobs .job-checking .question {
    font-size: 14px;
    color: #354052;
    line-height: 19px;
    font-weight: 600
}

.widget-jobs .job-checking .employee-review {
    padding-left: 14px;
    padding-right: 44px;
    letter-spacing: .2px
}

.widget-recently .table {
    margin-bottom: 0;
    width: 100%
}

.widget-recently .table tr td:first-child {
    padding-left: 15px;
    padding-bottom: 14px;
    padding-top: 14px
}

.widget-recently .table tr td {
    border-top: 0
}

.widget-recently .table tr td a {
    text-decoration: none;
    font-size: 16px;
    color: #354052
}

.widget-recently .table tr td a i {
    padding-right: 7px;
    color: #f86b67
}

.widget-recently .table tr td i.fa-file-text {
    color: #0af
}

.widget-recently .table tr td .doc-icon {
    padding-right: 7px;
    margin-top: -5px
}

.widget-recently .table tr td .files-edit {
    margin-bottom: 1px;
    padding-left: 42px
}

.widget-recently .table tr td .files-edit li {
    font-size: 14px;
    color: #7f8fa4
}

.widget-recently .table tr td .files-edit li:first-child {
    border-right: 1px solid #7f8fa4;
    padding-right: 8px
}

.widget-recently .table tr:last-child td {
    border-bottom-width: 0
}

.widget-pictures {
    padding-top: 16px;
    padding-bottom: 12px
}

.widget-pictures .picture {
    font-size: 16px;
    color: #354052;
    padding-bottom: 11px;
    padding-left: 17px
}

.widget-pictures .btn-setting.btn-picture {
    padding-right: 18px
}

.widget-pictures .btn-setting.btn-picture a i {
    color: #ced0da
}

.widget-pictures .best-pictures {
    padding-left: 4px;
    padding-right: 4px
}

.widget-pictures .best-pictures img {
    width: 100%;
    height: auto
}

.widget-pictures .best-pictures .row {
    margin-right: 0;
    margin-left: 0
}

.widget-pictures .best-pictures .row .left-pic {
    padding-right: 5px;
    margin-bottom: 9px
}

.widget-pictures .best-pictures .row .right-pic {
    padding-left: 5px;
    margin-bottom: 9px
}

.panel-default .widget-frequently {
    background-color: #fafbfc;
    border: 1px solid #e6eaee;
    padding: 0 26px 3px 20px
}

.panel-default .widget-frequently .question-tips {
    font-size: 16px;
    color: #354052;
    padding-bottom: 11px;
    padding-top: 11px
}

.panel-default .widget-frequently .question-tips i {
    color: #354052;
    padding-right: 8px
}

.panel-default .widget-frequently .question-passage {
    border-bottom: 1px solid #e6eaee
}

.panel-default .widget-frequently .question-passage p {
    font-size: 14px;
    color: #7f8fa4;
    line-height: 23px;
    font-weight: 400
}

.panel-default .contact-support {
    padding-top: 14px;
    padding-bottom: 14px;
    border: 1px solid #e6eaee;
    background-color: #fff;
    text-align: center
}

.panel-default .contact-support a {
    font-size: 14px;
    color: #1a91eb;
    font-weight: 600
}

.widget-card {
    padding: 10px
}

.widget-card .widget-card-bg img {
    width: 100%;
    height: auto
}

.widget-card .widget-card-project {
    padding-left: 10px
}

.widget-card .widget-card-project .bridge-text {
    padding-top: 7px
}

.widget-card .widget-card-project .bridge-text a {
    font-size: 16px;
    color: #354052;
    text-transform: capitalize;
    text-decoration: none
}

.widget-card .widget-card-project .upadate-project {
    font-size: 12px;
    color: #7f8fa4;
    font-weight: 400;
    letter-spacing: .3px;
    padding-top: 2px
}

.widget-card .widget-card-project .upadate-project span {
    font-weight: 600
}

.widget-card .widget-card-project .add-images {
    padding-top: 14px
}

.widget-card .widget-card-project .add-images ul li {
    padding-right: 1px;
    position: relative
}

.widget-card .widget-card-project .add-images ul li .btn-plus {
    width: 32px;
    height: 32px;
    background: #e8eaf1;
    border-radius: 50%;
    padding: 5px 6px;
    box-shadow: none
}

.widget-card .widget-card-project .add-images ul li .btn-plus i {
    color: #8d909f
}

.widget-card .widget-card-project .add-images ul li .btn-plus:hover {
    background: #37ac48
}

.widget-card .widget-card-project .add-images ul li .btn-plus:hover i {
    color: #fff
}

.widget-card .widget-card-project .add-images ul li .btn-plus.btn-num {
    background: #e8eaf1;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052
}

.widget-card .widget-card-project .add-images ul li .btn-plus.btn-num:hover {
    background: #37ac48;
    color: #fff
}

.widget-card .widget-card-project .add-images ul li a img {
    width: 32px;
    height: 32px;
    border-radius: 50%
}

.widget-card .widget-card-project .add-images ul li .tooltip {
    margin-top: -6px;
    margin-left: -1px
}

.widget-card .widget-card-project .add-images ul li .tooltip .tooltip-arrow {
    border-top-color: #353c48
}

.widget-card .widget-card-project .add-images ul li .tooltip .tooltip-inner {
    background: #353c48;
    width: 94px;
    height: 34px;
    line-height: 34px;
    padding: 0;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #fff
}

.widget-featured .background .widget-bg {
    height: 210px
}

.widget-featured .featured-design .us-design {
    padding-top: 7px;
    padding-left: 20px;
    float: left
}

.widget-featured .featured-design .us-design .design-heading {
    margin: 0
}

.widget-featured .featured-design .us-design .design-heading a {
    font-size: 16px;
    color: #354052;
    text-decoration: none
}

.widget-featured .featured-design .us-design .design-sub-heading {
    margin-top: -9px
}

.widget-featured .featured-design .us-design .design-sub-heading a {
    font-size: 12px;
    color: #7f8fa4;
    font-weight: 400;
    text-decoration: none;
    text-transform: capitalize
}

.widget-featured .featured-design .design-price {
    margin-top: 16px;
    margin-bottom: 17px;
    padding-right: 10px;
    float: right
}

.widget-featured .featured-design .design-price .btn {
    padding: 7px 13px;
    font-size: 14px;
    color: #fff;
    letter-spacing: .3px;
    text-transform: none
}

.widget-statistics .sales-increment {
    text-align: center;
    background: #f5f8fa;
    border-top: 1px solid #e6eaee;
    padding-top: 13px;
    padding-bottom: 15px
}

.widget-statistics .sales-increment .sales-icon {
    width: 15px;
    height: 15px;
    background: #1bb934;
    border-radius: 50%;
    margin: 0 auto;
    line-height: 11px;
    margin-right: 10px;
    padding: 0
}

.widget-statistics .sales-increment .sales-icon i {
    line-height: 11px;
    color: #fff
}

.widget-statistics .sales-persentage {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: #354052;
    font-weight: 600
}

.widget-card.widget-card-dark .bridge-text a {
    color: #fff
}

.widget-card.widget-card-dark .add-images ul li .btn-plus {
    background: #222c3c;
    border: 1px solid #313d4f
}

.widget-card.widget-card-dark .add-images ul li .btn-plus i {
    color: #7c8ca5
}

.panel-default.panel-blue {
    background-color: #de6926
}

.panel-default.panel-red {
    background-color: #d65b4a
}

.panel-default.panel-green {
    background-color: #66b92e
}

.panel-default.panel-yellow {
    background-color: #da932c
}

.widget-stats .company-profile {
    padding-left: 16px
}

@media (max-width:1235px) {
    .widget-stats .company-profile {
        padding-left: 7px
    }
}

.widget-stats .company-profile .company-projects {
    padding-top: 7px
}

.widget-stats .company-profile .company-projects a {
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    text-transform: capitalize
}

.widget-stats .company-profile .company-projects a:hover {
    opacity: .69
}

.widget-stats .company-profile .no-of-projects {
    font-size: 36px;
    color: #fff;
    font-weight: 400;
    padding-bottom: 14px
}

.widget-stats .sales-icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin-right: 8px
}

.widget-stats .sales-icon:hover {
    opacity: .2
}

.widget-stats .sales-icon .btn-arrow {
    padding: 1px 4px
}

.widget-stats .sales-icon .btn-arrow i {
    color: #fff
}

.widget-stats .stats-percentage {
    padding-left: 16px;
    padding-top: 11px;
    border-top: 1px solid #fff
}

.widget-stats .stats-percentage a {
    font-size: 12px;
    color: #fff;
    text-decoration: none
}

.widget-stats .stats-percentage a:hover {
    opacity: .69
}

.icon-boxes {
    padding: 50px 42px 38px;
    text-align: center
}

.icon-boxes .application-image img {
    width: 146px;
    height: 118px
}

.icon-boxes .app-files {
    padding-top: 16px;
    font-size: 16px;
    color: #354052;
    line-height: 22px;
    font-weight: 600
}

.icon-boxes .new-campaign {
    padding-top: 16px
}

.icon-boxes .new-campaign .app-btn {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #fff;
    letter-spacing: .5px;
    text-transform: none;
    padding: 7px 23.5px
}

.panel-heading.panel-calendar {
    padding: 15px 19px;
    color: #354052;
    font-weight: 400
}

.widget-calendar {
    padding-left: 19px
}

.widget-calendar .calendar-todo-list {
    padding-top: 20px;
    padding-bottom: 3px
}

.widget-calendar .calendar-todo-list ul li {
    line-height: 29px
}

.widget-calendar .calendar-todo-list ul li .square-shape {
    width: 16px;
    height: 16px;
    background-color: #00a8ff;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
    margin-top: -3px
}

.widget-calendar .calendar-todo-list ul li .square-shape.color-white {
    background: #d8dde5
}

.widget-calendar .calendar-todo-list ul li .square-shape.color-blue {
    background: #bf8fe1
}

.widget-calendar .calendar-todo-list ul li .square-shape.color-green {
    background: #1bb934
}

.widget-calendar .calendar-todo-list ul li .square-shape.color-orange {
    background: #ff7800
}

.widget-calendar .calendar-todo-list ul li .square-shape.color-dark {
    background: #2097d4
}

.widget-calendar .calendar-todo-list ul li a {
    text-decoration: none;
    font-size: 14px;
    color: #354052;
    font-weight: 400
}

.widget-calendar .import-head {
    font-size: 12px;
    color: #7f8fa4;
    font-weight: 400;
    text-transform: uppercase
}

.widget-calendar .calendar-import-list.calendar-todo-list {
    padding-top: 3px
}

.widget-calendar .calendar-import-list.calendar-todo-list .square-shape.color-orange {
    background: #f0b499
}

.widget-searched .input-group {
    border-bottom: 1px solid #e6eaee;
    padding: 10px 20px
}

.widget-searched .input-group .form-control {
    background: #fff;
    border: 1px solid #e6eaee;
    border-right: none;
    box-shadow: none;
    outline: 0;
    height: 36px
}

.widget-searched .input-group .btn-form {
    background: #fff;
    border: 1px solid #e6eaee;
    box-shadow: none;
    border-left: none;
    outline: none;
    height: 36px
}

.widget-searched .input-group .btn-form i {
    color: #a1a7af
}

.widget-searched .search-list {
    padding-left: 20px
}

.widget-searched .search-list li {
    line-height: 27px
}

.widget-searched .search-list li a {
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    text-decoration: none
}

.widget-searched .search-list li a i {
    color: #b2b3bf;
    padding-left: 6px
}

.calendar-visible .public-calendar {
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    text-transform: capitalize;
    padding-left: 27px
}

.calendar-visible .public-calendar span {
    color: #36af47
}

@media (max-width:1435px) {
    .calendar-visible .public-calendar {
        padding-left: 0
    }
}

.widget-left-panel .menu-heading {
    font-size: 12px;
    color: #9fa9ba;
    text-transform: uppercase;
    padding-left: 19px;
    padding-bottom: 4px;
    padding-top: 15px
}

.widget-left-panel .menu-list {
    padding-bottom: 3px
}

.widget-left-panel .menu-list li.active .menu-btn {
    color: #de6926;
    border-left: 5px solid #de6926;
}

.widget-left-panel .menu-list li .menu-btn {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 8px 18px;
    background-color: transparent;
    box-shadow: none;
    font-size: 16px;
    color: #354052;
    text-transform: capitalize;
    text-align: left;
    display: inline-block;
    width: 100%;
}

.widget-left-panel .menu-list li .menu-btn:hover {
    background-color: #dedede;
    color: #333
}

.widget-left-panel .menu-list li .menu-btn:hover i {
    color: #333
}

.widget-left-panel .categotry-list {
    padding-left: 19px
}

.widget-left-panel .categotry-list li {
    line-height: 33px
}

.widget-left-panel .categotry-list li .hash-icon {
    background-color: #de6926;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 19px;
    margin-right: 5px
}

.widget-left-panel .categotry-list li .hash-icon i {
    color: #fff;
    font-size: 11px
}

.widget-left-panel .categotry-list li .round-shape {
    height: 14px;
    width: 14px;
    background-color: #ff4b64;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -3px
}

.widget-left-panel .categotry-list li .round-shape.dark-circle {
    background-color: #bf8fe1
}

.widget-left-panel .categotry-list li .round-shape.blue-circle {
    background-color: #0af
}

.widget-left-panel .categotry-list li .round-shape.light-circle {
    background-color: #98d4e0
}

.widget-left-panel .categotry-list li .round-shape.green-circle {
    background-color: #33ad00
}

.widget-left-panel .categotry-list li .round-shape.white-circle {
    background-color: #8d909f;
    text-align: center;
    line-height: 0
}

.widget-left-panel .categotry-list li .round-shape.white-circle i {
    color: #fff;
    font-size: 10px
}

.widget-left-panel .categotry-list li a {
    color: #354052;
    font-size: 14px;
    text-transform: capitalize;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
    width: 79px;
    overflow: hidden;
    text-overflow: ellipsis
}

.widget-left-panel .categotry-list li a:hover {
    color: #de6926
}

.widget-events.widget-left-panel .menu-list li a i {
    font-size: 16px;
    padding-right: 10px;
    color: #333
}

.widget-events.widget-left-panel .menu-list li .event-circle {
    width: 20px;
    height: 20px;
    background-color: #ff7800;
    border-radius: 50%;
    text-align: center;
    line-height: 19px;
    display: inline-block;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    margin-left: 8px;
    /*	float: right;*/
    margin-top: 2px;
}

.widget-mountain .mountain-bg img {
    width: 100%;
    height: auto
}

.widget-mountain .mountain-bg .image-hover .image-holder.mountain-holder {
    border-radius: 0
}

.widget-mountain .image-details {
    text-align: center;
    padding-top: 18px;
    padding-bottom: 18px;
    background-color: #fff
}

.widget-mountain .image-details .image-name {
    font-size: 16px;
    color: #354052;
    text-transform: capitalize;
    letter-spacing: .3px
}

.widget-mountain .image-details .image-uploader {
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: .3px
}

.widget-mountain .image-details .image-uploader span {
    font-weight: 600
}

.widget-profile {
    padding-left: 29px;
    padding-right: 29px;
    padding-bottom: 40px
}

.widget-profile .profile-heading {
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: Source Sans Pro, sans-serif
}

.widget-profile .avatar-img {
    margin-top: 4px;
    float: left
}

.widget-profile .avatar-img img {
    width: 60px;
    height: 60px;
    border-radius: 50%
}

.widget-profile .form-group .avatar-details {
    padding-left: 23px
}

.widget-profile .form-group .account-name {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
    font-weight: 600
}

.widget-profile .form-group .account-form.form-area {
    height: auto
}

.widget-profile .form-group .account-form {
    height: 36px;
    font-family: Source Sans Pro, sans-serif
}

.widget-profile .form-group .profile-btn {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

.widget-profile .input-group-area .form-group {
    padding-right: 80px;
    margin-bottom: 0
}

.widget-profile .input-group-area .form-group .form-control {
    float: left;
    height: 36px
}

.widget-profile .input-group-area .form-group .account-name.account-password {
    padding-top: 18px
}

.widget-profile .input-group-area .btn-success {
    float: right;
    color: #fff;
    height: 36px;
    font-family: Source Sans Pro, sans-serif
}

.widget-account {
    padding: 24px 30px 9px 27px
}

.widget-account .profile-info {
    font-size: 16px;
    color: #354052;
    padding-bottom: 6px
}

.widget-account .form-horizontal .form-group {
    padding-bottom: 5px;
    padding-top: 10px;
    border-bottom: 1px solid #e6eaee;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0
}

.widget-account .form-horizontal .form-group .control-label {
    text-align: left;
    padding-left: 2px;
    font-size: 14px;
    color: #354052;
    text-transform: capitalize;
    padding-top: 12px
}

.widget-account .form-horizontal .form-group .form-control-static {
    font-size: 14px;
    color: #354052;
    font-weight: 600
}

.widget-account .form-horizontal .form-group .avatar-image {
    padding-left: 2px
}

.widget-account .form-horizontal .form-group .avatar-image .avatar-name {
    font-size: 14px;
    color: #354052;
    text-transform: capitalize;
    margin: 0;
    padding-top: 22px
}

.widget-account .form-horizontal .form-group .avatar-image .avatar-size {
    font-size: 12px;
    color: #7f8fa4;
    font-weight: 400;
    margin: 0
}

.widget-account .form-horizontal .form-group .avatar-body {
    width: 78px;
    height: 70px;
    background-color: #fafbfc;
    border: 1px solid #e6eaee;
    margin-left: 30px;
    margin-top: 9px;
    margin-bottom: 9px
}

.widget-account .form-horizontal .form-group .avatar-body img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    float: left;
    margin-left: -31px;
    margin-top: -1px
}

.widget-account .form-horizontal .form-group .avatar-body .image-icon {
    padding-left: 51px;
    padding-top: 6px
}

.widget-account .form-horizontal .form-group .avatar-body .image-icon a {
    border-bottom: 1px solid #e6eaee;
    padding-bottom: 8px
}

.widget-account .form-horizontal .form-group .avatar-body .image-icon a i {
    color: #c8d2df;
    font-size: 16px
}

.widget-account .form-horizontal .form-group .avatar-body .image-icon.delete-icon {
    padding-top: 15px
}

.widget-account .form-horizontal .form-group .avatar-body .image-icon.delete-icon a {
    border-bottom: transparent
}

.widget-account .form-horizontal .form-group .avatar-body .image-icon.delete-icon a i {
    font-size: 20px
}

.widget-account .form-horizontal .form-group.favorite {
    border-bottom: none
}

.widget-account .form-horizontal .form-group.favorite .control-label {
    padding: 12px 2px
}

.widget-pagination-horizantal .pagination {
    background: #fff;
}

.widget-pagination-horizantal .pagination li.active a {
    color: #354052;
    border: 1px solid #ddd;
    cursor: pointer;
    background-color: transparent
}

.widget-pagination-horizantal .pagination li a {
    background: transparent;
    font-size: 14px;
    color: #ced0da;
    font-family: Source Sans Pro, sans-serif
}

.widget-pagination-horizantal .pagination li a:hover {
    color: #354052
}

.widget-pagination .pagination li a {
    margin-right: 20px;
    background: #273142;
    border: 1px solid #313d4f;
    color: #ced0da
}

.widget-pagination .pagination .page-change a {
    background-color: transparent;
    border: transparent;
    cursor: default
}

.widget-post-block {
    padding: 15px 20px 10px
}

.widget-post-block .widget-post {
    overflow: inherit;
    padding-bottom: 12px
}

.widget-post-block .widget-post .post-dropdown {
    float: right
}

.widget-post-block .widget-post .post-dropdown .dropdown-toggle {
    background: transparent;
    border: none
}

.widget-post-block .widget-post .post-dropdown .dropdown-toggle i {
    color: #7f8fa4
}

.widget-post-block .widget-post .post-dropdown .dropdown-menu {
    right: 0;
    left: auto
}

.widget-post-block .widget-post .post-block .post-img {
    vertical-align: middle;
    padding-right: 13px
}

.widget-post-block .widget-post .post-block .post-img img {
    width: 40px;
    height: 40px;
    border-radius: 50%
}

.widget-post-block .widget-post .post-block .post-details .post-pictures {
    margin: 0
}

.widget-post-block .widget-post .post-block .post-details .post-pictures a {
    font-size: 14px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-decoration: none
}

.widget-post-block .widget-post .post-block .post-details .post-time {
    margin-top: -4px;
    margin-bottom: 0;
    font-size: 12px;
    color: #7f8fa4;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.widget-post-block .post-passage {
    padding-bottom: 15px
}

.widget-post-block .post-passage p {
    margin-bottom: 0;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #fff;
    line-height: 19px;
    font-weight: 400
}

.widget-post-block .post-images {
    border-bottom: 1px solid #313d4f
}

.widget-post-block .post-images .row .post-img-holder {
    position: relative;
    margin-bottom: 15px
}

.widget-post-block .post-images .row .post-img-holder:hover .overlay-container {
    position: absolute;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    top: 0;
    left: 0
}

.widget-post-block .post-images .row .post-img-holder:hover .overlay-container .overlay {
    background: #de6926;
    width: 100%;
    height: 100%;
    opacity: .5;
}

.widget-post-block .post-images .row .post-img-holder:hover .img-post-number {
    display: block
}

.widget-post-block .post-images .row .post-img-holder .small-img {
    width: 100%;
    height: auto
}

.widget-post-block .post-images .row .post-img-holder .img-post-number {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    display: none;
    margin-top: -17px
}

.widget-post-block .post-images .row .post-img-holder .img-post-number a {
    font-size: 24px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%
}

.widget-post-block .post-images .row .post-middle.post-img-holder,
.widget-post-block .post-images .row .post-middle.post-img-holder .overlay-container {
    padding-left: 0;
    padding-right: 0
}

.widget-post-block .post-images .row .post-middle.post-img-holder img {
    width: 100%;
    height: auto
}

.widget-post-block .post-users {
    position: relative;
    margin-top: 9px
}

.widget-post-block .post-users .post-icon a i {
    color: #aeb6c5;
    font-size: 14px;
    float: left;
    padding-top: 12px;
    padding-right: 6px
}

.widget-post-block .post-users .friends-list.users-category {
    float: left;
    margin-bottom: 0
}

.widget-post-block .post-users .friends-list.users-category li a img {
    width: 28px;
    height: 28px
}

.widget-post-block .post-users .friends-list.users-category li a i {
    color: #aeb6c5;
    font-size: 14px
}

.widget-post-block .post-users .friends-list.users-category li .btn-more-users.btn-user {
    width: 37px;
    height: 28px;
    line-height: 27px;
    font-size: 12px
}

.widget-post-block .post-users .comment-category {
    float: right;
    padding-top: 10px;
    margin-bottom: 0
}

.widget-post-block .post-users .comment-category li a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    text-decoration: none
}

.widget-post-block .post-users .comment-category li a i {
    color: #aeb6c5;
    font-size: 15px;
    padding-right: 6px
}

.sales-block {
    padding-top: 13px;
    padding-left: 30px;
    padding-right: 29px
}

.sales-block .sales-update {
    border-bottom: 1px solid #e6eaee;
    padding-bottom: 14px
}

.sales-block .sales-update .update-heading {
    font-size: 16px;
    color: #354052;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.sales-block .sales-update .show-sales .btn-sales {
    background-color: transparent;
    font-size: 12px;
    color: #1a91eb;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0;
    box-shadow: none
}

.sales-block .sales-dropdown .input-group {
    padding-top: 18px;
    padding-bottom: 20px;
    margin-bottom: 0
}

.sales-block .sales-dropdown .input-group .input-group-btn .btn-default {
    box-shadow: none;
    background: #f2f4f7;
    outline: 0;
    height: 36px;
    border: 1px solid #ced0da
}

.sales-block .sales-dropdown .input-group .input-group-btn .btn-default i {
    color: #ced0da
}

.sales-block .sales-dropdown .input-group .input-group-btn .btn-default.btn-form {
    border-left: none
}

.sales-block .sales-dropdown .input-group .input-group-btn .btn-default.btn-form i {
    color: #a8aab7
}

.sales-block .sales-dropdown .input-group .select-list {
    height: 36px;
    border: 1px solid #ced0da;
    background: #f2f4f7;
    border-right: none
}

.sales-block .sales-questions.news-block {
    padding: 0;
    border-bottom: 0;
    margin-top: 19px
}

.sales-block .sales-questions.news-block .news-holder {
    margin-top: 0;
    padding: 16px 18px
}

.sales-block .sales-questions.news-block .news-holder .news-heading {
    margin-bottom: 0;
    padding-bottom: 14px
}

.sales-block .sales-questions.news-block .news-holder .news-text {
    padding-bottom: 0
}

.sales-block .sales-profit .sales-currency {
    float: left;
    font-size: 24px;
    color: #354052;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.sales-block .sales-profit .sales-currency .sales-percent {
    border-left: 1px solid #e7e9ed;
    margin-left: 6px;
    padding-left: 8px;
    font-size: 24px;
    color: #299817;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.sales-block .sales-profit .total-sales {
    float: right;
    font-size: 14px;
    color: #7f8fa4;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    padding-top: 10px
}

.sales-block .sales-profit .sales-progress {
    background-color: #dfe4ee;
    height: 5px;
    margin-bottom: 0;
    margin-top: 11px
}

.sales-block .sales-profit .sales-progress .progress-bar {
    background-color: #38ba24;
}

.sales-block .sales-table {
    padding-top: 21px;
    padding-bottom: 19px
}

.sales-block .sales-table .table {
    background: transparent;
    margin-bottom: 0
}

.sales-block .sales-table .table tr td {
    padding: 0;
    border-top: 0;
    border-bottom: 0;
    font-size: 14px;
    color: #7f8fa4;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    line-height: 30px
}

.sales-block .sales-table .table tr td:last-child {
    padding-right: 84px;
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

@media (max-width:1410px) {
    .sales-block .sales-table .table tr td:last-child {
        padding-right: 0
    }
}

.sales-block .sales-links {
    padding-bottom: 21px
}

@media (max-width:1500px) {
    .sales-block .sales-links {
        text-align: center
    }
}

@media (max-width:1200px) {
    .sales-block .sales-links {
        text-align: justify
    }
}

@media (max-width:1090px) {
    .sales-block .sales-links {
        text-align: center
    }
}

@media (max-width:990px) {
    .sales-block .sales-links {
        text-align: justify
    }
}

@media (max-width:836px) {
    .sales-block .sales-links {
        text-align: center
    }
}

.sales-block .sales-links ul {
    margin-bottom: 0
}

.sales-block .sales-links ul li {
    padding-right: 0;
    margin-bottom: 5px
}

.sales-block .sales-links ul li .btn-chat.btn-success {
    padding: 7px 9px
}

.sales-block .sales-links ul li .btn-chat.btn-success i {
    color: #b0edb8
}

.sales-block .notice i {
    color: #c5d0de;
    float: left
}

.sales-block .notice .notice-passage {
    padding-left: 21px;
    padding-right: 0;
    padding-bottom: 12px;
    font-size: 13px;
    color: #7f8fa4;
    line-height: 19px;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

.widget-faq .faq-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #354052;
    font-weight: 400;
    padding-bottom: 6px;
    text-transform: uppercase
}

.widget-faq .faq-passage {
    padding-bottom: 6px
}

.widget-faq .faq-passage p {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0
}

.widget-faq .faq-passage p:nth-child(2) {
    padding-bottom: 5px
}

.widget-faq .faq-passage p:nth-child(3) {
    line-height: 18px;
    padding-bottom: 7px
}

.widget-faq .faq-vist a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #1a91eb;
    font-weight: 400;
    text-transform: capitalize
}

.widget-notifications {
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 11px
}

.widget-notifications .note-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #354052;
    font-weight: 400;
    padding-top: 25px;
    padding-bottom: 12px;
    letter-spacing: .5px
}

.widget-notifications .notification {
    border-bottom: 1px solid #e6eaee;
    padding-bottom: 10px;
    padding-top: 13px
}

.widget-notifications .notification .notification-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    font-weight: 400
}

.widget-notifications .notification .calendar-switch.notfication-switch {
    margin-top: 0;
    padding-right: 0
}

.widget-notifications .notification .calendar-switch.notfication-switch label {
    margin-bottom: 0
}

.widget-connect {
    padding-top: 22px;
    padding-bottom: 9px
}

.widget-connect .connect-header {
    padding-left: 30px;
    padding-bottom: 5px
}

.widget-connect .connect-list {
    border-bottom: 1px solid #e6eaee;
    padding-top: 12px;
    padding-bottom: 12px
}

.widget-connect .connect-list .connect-link {
    padding-left: 30px
}

.widget-connect .connect-list .connect-link a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    text-decoration: none
}

.widget-connect .connect-list .connect-link a img {
    width: 16px;
    height: 16px;
    margin-right: 10px
}

.widget-connect .connect-list .connect-mail {
    padding-right: 30px
}

.widget-connect .connect-list .connect-mail a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 400;
    text-decoration: none
}

.widget-connect .connect-list .connect-mail .btn-cross {
    padding: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #f3f5f6;
    border: 1px solid #d6d7e0;
    line-height: 13px;
    box-shadow: none;
    margin-left: 9px;
    vertical-align: text-bottom
}

.widget-connect .connect-list .connect-mail .btn-cross i {
    color: #b2b3bf;
    font-size: 11px
}

.widget-connect .connect-list .connect-mail .btn-chat.btn-connect {
    display: none;
    margin-right: 20px;
    color: #fff
}

.widget-bank-account .card-details {
    background-color: #fff;
    border: 2px solid #2da1f7;
    width: 254px;
    height: 152px;
    position: relative;
    bottom: 37px;
    float: left;
    margin-left: 20px
}

@media (max-width:1878px) {
    .widget-bank-account .card-details {
        float: none;
        margin: 0 auto
    }
}

.widget-bank-account .card-details .visa-form {
    padding-left: 28px
}

.widget-bank-account .card-details .visa-form img {
    margin-top: 22px;
    width: 70px;
    height: 46px
}

.widget-bank-account .card-details .visa-form .visa-password {
    padding-top: 9px
}

.widget-bank-account .card-details .visa-form .visa-password i {
    vertical-align: text-top;
    font-size: 13px;
    margin-top: 1px
}

.widget-bank-account .card-details .visa-form .visa-password span:nth-child(4),
.widget-bank-account .card-details .visa-form .visa-password span:nth-child(8) {
    padding-right: 5px
}

.widget-bank-account .card-details .visa-form .visa-password span {
    font-size: 20px
}

.widget-bank-account .card-details .visa-form .visa-password .account-no {
    vertical-align: super
}

.widget-bank-account .card-details .visa-form .valid-text {
    margin-top: -4px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 400;
    letter-spacing: .5px
}

.widget-bank-account .account-details {
    margin-top: 23px;
    float: right;
    padding-right: 46px
}

@media (max-width:1878px) {
    .widget-bank-account .account-details {
        float: none;
        text-align: center;
        margin-top: 0;
        padding-bottom: 20px;
        padding-right: 0
    }
}

.widget-bank-account .account-details h4 {
    margin: 0
}

.widget-bank-account .account-details .account-header {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 18px;
    color: #354052;
    font-weight: 400;
    text-transform: capitalize;
    padding-bottom: 15px;
    letter-spacing: .5px
}

.widget-bank-account .account-details .user-num {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 400;
    letter-spacing: .5px
}

.widget-bank-account .account-details .user-num span {
    color: #354052
}

.widget-lesson.related-lesson .purchased-friends {
    padding-top: 4px;
    margin-bottom: 8px
}

.widget-lesson.related-lesson .purchased-friends li {
    padding-right: 1px
}

.widget-new-lesson {
    padding: 15px 20px
}

.widget-new-lesson .light-room {
    font-size: 16px;
    color: #354052;
    text-transform: capitalize;
    padding-bottom: 5px
}

.widget-new-lesson .famous-text,
.widget-new-lesson .light-room {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    letter-spacing: .8px
}

.widget-new-lesson .famous-text {
    font-size: 12px;
    color: #7f8fa4;
    padding-bottom: 8px
}

.widget-new-lesson .lesson-pagination {
    margin: 0
}

.widget-new-lesson .lesson-pagination li a {
    background: #f2f4f7;
    border: 1px solid #ced0da;
    margin-right: 11px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #354052;
    font-weight: 400;
    padding: 8.5px 15px
}

.widget-new-lesson .lesson-pagination li a i {
    color: #ced0da;
    font-size: 14px
}

.widget-new-lesson .lesson-pagination li:nth-child(2) a {
    padding: 8.5px 37.5px;
    margin-right: 0
}

.widget-new-lesson .lesson-pagination li:nth-child(2) a i {
    padding-left: 16px
}

.social-links-list {
    margin-top: 10px
}

.social-links-list li {
    padding-right: 2px
}

.social-links-list li .btn {
    height: 38px;
    width: 38px;
    background-color: #ff3700;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    box-shadow: none
}

.social-links-list li .btn i {
    color: #fff;
    font-size: 14px
}

.social-links-list li .btn.btn-twitter {
    background-color: #1da1f2
}

.social-links-list li .btn.btn-behance {
    background-color: #187ac6
}

.social-links-list li .btn.btn-youtube {
    background-color: #cd201f
}

.social-links-list li .btn.btn-facebook {
    background-color: #3b5998
}

.social-links-list li .btn.btn-linkedin {
    background-color: #0077b5
}

.social-links-list li .btn.btn-dribbble {
    background-color: #ea4c89
}

.social-links-list li .btn.btn-instagram {
    background-color: #f56040
}

.social-links-list li:last-child {
    padding-top: 5px
}

.social-links-list.dark .btn.btn-linkedin,
.social-links-list.dark .btn.btn-youtube {
    background-color: #313d4f
}

.widget-sales {
    text-align: center;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 18px;
}

.widget-sales .no-of-sales {
    font-size: 30px;
    color: #354052;
    font-weight: 400;
    padding-bottom: 6px
}

.widget-sales .sales a {
    font-size: 12px;
    text-decoration: none
}

.tab-left-col {
    width: 100px;
    float: left
}

@media (max-width:992px) {
    .tab-left-col {
        width: 0;
        display: inline;
        float: none
    }
}

.tab-left-col .account-avatar {
    text-align: center
}

.tab-left-col .account-avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%
}

.tab-left-col .account-avatar .edit-text a {
    font-size: 14px;
    color: #354052;
    font-weight: 600
}

.tab-right-col {
    margin-left: 138px
}

@media (max-width:992px) {
    .tab-right-col {
        margin-left: 0
    }
}

.tab-right-col .account-details {
    border-right: 1px solid #dfe3e9;
    padding-right: 30px
}

.tab-right-col .account-details .form-group .account-name {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #7f8fa4;
    text-transform: capitalize;
    font-weight: 600
}

.tab-right-col .account-details .account-area {
    height: 0
}

.tab-right-col .account-details .account-form.form-area {
    height: auto
}

.tab-right-col .account-details .account-form {
    height: 36px;
    font-family: Source Sans Pro, sans-serif
}

@media (max-width:992px) {
    .tab-right-col .account-details {
        border-right: none;
        padding-right: 15px
    }
}

.tab-right-col .account-details.company-details {
    padding-right: 24px;
    border-right: none;
    padding-left: 30px
}

.tab-right-col .account-details.company-details .fb-btn {
    padding: 10px 10.5px
}

.tab-right-col .account-details.company-details .twitter-btn {
    padding: 10px 13px
}

@media (max-width:992px) {
    .tab-right-col .account-details.company-details {
        padding-left: 15px;
        padding-right: 15px
    }
}

.text-success {
    color: #1bb934!important
}

.text-info {
    color: #4695d9!important
}

.calendar-switch {
    margin-top: -3px;
    padding-right: 27px
}

.calendar-switch input.cmn-toggle-round:checked+label:after {
    margin-left: 24px;
    width: 20px;
    height: 20px;
    margin-top: 1px
}

.calendar-switch input.cmn-toggle-round:checked+label:before {
    background-color: #dfe1e5
}

.calendar-switch input.cmn-toggle-round+label:after {
    width: 20px;
    height: 20px;
    box-shadow: none;
    margin-left: 2px;
    margin-top: 1px
}

.calendar-switch input.cmn-toggle-round+label:before {
    background-color: #36af47
}

@media (max-width:1435px) {
    .calendar-switch {
        padding-right: 0
    }
}

.btn-chat {
    font-weight: 400;
    text-transform: none;
    letter-spacing: .5px;
    padding: 7px 14.5px;
    box-shadow: none
}

.btn-chat i {
    color: #86caff;
    padding-right: 10px;
    font-size: 15px
}

.panel-body.panel-account {
    max-height: 135px
}

@media (max-width:1878px) {
    .panel-body.panel-account {
        max-height: 299px
    }
}
