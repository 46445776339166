.form-group {
    margin-bottom: 18px
}

.input-group {
    margin-bottom: 10px
}

.conrol-label,label {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    letter-spacing: .3px;
    color: #7f8fa4;
    text-transform: uppercase;
    margin-bottom: 6px;
    line-height: 22px
}

.radio.disabled {
    color: #7f8fa4;
    opacity: .6
}

.text-muted {
    color: #7f8fa4;
    font-size: 12px
}

.input-group .input-group-addon {
    background: #fff;
    border-color: #dfe3e9;
    color: #7f8fa4
}

.input-group.facebook-input-group .input-group-addon {
    background: #288bd5;
    color: #fff;
    border-color: #137ece
}

.input-group.facebook-input-group .form-control:focus {
    border-left-color: transparent
}

.input-group.twitter-input-group .input-group-addon {
    background: #1a91ec;
    border-color: #2ea1f8;
    color: #fff
}

.form-control,textarea {
    border: 1px solid #dfe3e9;
    background: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #9a9fa8;
    line-height: 19px;
    box-shadow: none!important;
    padding-left: 12px;
    padding-right: 12px
}

.form-control:focus,textarea:focus {
    border-color: #b84c0e;
    outline: 0;
    color: #354052;
    box-shadow: none!important
}

.form-control::-webkit-input-placeholder,textarea::-webkit-input-placeholder {
    color: #c3c6cb
}

.form-control:-moz-placeholder,
.form-control::-moz-placeholder,textarea:-moz-placeholder,textarea::-moz-placeholder {
    color: #c3c6cb
}

.form-control:-ms-input-placeholder,textarea:-ms-input-placeholder {
    color: #c3c6cb
}

.has-success .form-control {
    background: url(../images/icons/check.png);
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 18px
}

.has-success .form-control,
.has-success .form-control:focus {
    border-color: #1bb934;
    color: #354052
}

.has-error .form-control {
    background: url(../images/icons/cross.png);
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 18px
}

.has-error .form-control,
.has-error .form-control:focus {
    border-color: #ed1c24;
    color: #354052
}

.has-warning .form-control {
    background: url(../images/icons/warning.png);
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 18px
}

.has-warning .form-control,
.has-warning .form-control:focus {
    border-color: #fecf39;
    color: #354052
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #f2f4f7;
    border: 1px solid #dfe3e9;
}

textarea.form-control {
    padding-top: 10px;
    resize: none
}

.btn {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    outline: none!important;
}

.btn img {
    width: 16px;
    height: 16px;
    margin-right: 4px
}

.btn-success {
    background-color: #de6926;
    border: 1px solid #b84808
}

.btn-success span {
    color: #71d37e;
    margin-right: 8px
}

.btn-success:active,
.btn-success:focus,
.btn-success:hover {
    border-color: #a4430c
}

.btn-default {
    background-color: #fff;
    border: 1px solid #e2e7ed;
    color: #7f8fa4;
    border-color: #ced0da
}

.btn-default span {
    color: #fff;
    margin-right: 8px
}

.btn-default:active,
.btn-default:focus,
.btn-default:hover {
    border-color: #dce1e9
}

.btn-default:hover {
    color: #7f8fa4;
    border-color: #bfc2cf
}

.btn-default span {
    color: #bbc3ce
}

.btn-primary {
    background-color: #97471a;
    border: 1px solid #1383d8;
    border-color: #97471a
}

.btn-primary span {
    color: #90cdfb;
    margin-right: 8px
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    border-color: #97471a
}

.btn-info {
    background-color: #94e1f1;
    border: 1px solid #46b8da
}

.btn-info span {
    color: #eefafd;
    margin-right: 8px
}

.btn-info:active,
.btn-info:focus,
.btn-info:hover {
    border-color: #b84808
}

.btn-danger {
    background-color: #f87c34;
    border: 1px solid #b0220b
}

.btn-danger span {
    color: #f29383;
    margin-right: 8px
}

.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover {
    border-color: #97471a
}

.btn-warning {
    background-color: #f8cf5d;
    border: 1px solid #f9b702
}

.btn-warning span {
    color: #fcecbf;
    margin-right: 8px
}

.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover {
    border-color: #efb002
}

.badge {
    min-width: 22px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 500;
    padding: 5px 8px;
    font-size: 13px;
    color: #fff
}

.badge.badge-primary {
    background: #de6926;
}

.badge.badge-danger {
    background: #c8270c;
}
