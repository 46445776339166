.form-left {
    margin-top: 15px;
    margin-bottom: 0
}

.form-left .form-control {
    margin-left: 0!important;
    border-left-width: 0;
    border-right-width: 1px!important;
    height: 30px!important;
    min-width: 360px
}
.color-font{
 color: #a94442;
}

.form-left .form-control:focus {
    outline: none!important;
    box-shadow: none!important
}

.form-left .form-control .selectize-input {
    height: 40px;
    min-height: 40px;
    max-width: 360px;
    border-color: #e6eaee;
    border-left-width: 0;
    padding-top: 5px;
    padding-left: 8px;
    background-color: #fff;
    margin-top: -7px;
}

.form-left .form-control .selectize-input:focus {
    outline: none;
    box-shadow: none
}

.form-left .form-control .selectize-input.focus {
    box-shadow: none
}

.form-left .form-control .selectize-dropdown {
    height: auto!important;
    background-color: #fff;
    border: 1px solid #e6eaee!important;
    border-top-width: 0;
    top: -7px!important
}

.form-left .form-control .selectize-dropdown .selectize-dropdown-content {
    padding-top: 0;
    padding-bottom: 0
}

@media (max-width:500px) {
    .form-left .form-control .selectize-dropdown .selectize-dropdown-content {
        max-width: 250px
    }
}

.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .optgroup-header {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052;
    letter-spacing: 0;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 6px;
    background-color: #fff
}

.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown {
    padding-left: 15px;
    padding-top: 4px
}

.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-left {
    padding-right: 15px
}

.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-left img {
    width: 40px;
    height: 40px;
}

.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-body .media-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    letter-spacing: 0;
    margin-bottom: 0;
    line-height: 19px
}

.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-body p {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #9a9fa8;
    letter-spacing: 0;
    max-height: 43px;
    margin-bottom: 0;
    height: 18px;
    overflow: hidden;
    width: 250px;
    line-height: 15px
}

.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown.active {
    background-color: #fafbfc
}

.form-left .input-group-btn .btn {
    border-left-width: 1px!important;
    border-right-width: 0;
    background: #fff!important;
    height: 40px!important;
    padding-top: 4px;
    padding-right: 6px;
    margin-top: -7px;
}

.notification-list {
    border-right: 1px solid #e6eaee
}

.notification-list>li {
    padding-left: 12px;
    padding-right: 12px
}

.notification-list>li:first-child {
    padding-left: 0
}

.notification-list>li:last-child {
    padding-right: 26px
}

.notification-list>li>a {
    padding-top: 23px;
    padding-bottom: 23px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    color: #fff;
}

@media (max-width:1010px) {
    .notification-list>li>a {
        padding-top: 10px;
        padding-bottom: 10px
    }
}

.notification-list>li>a i {
    font-size: 20px;
    color: #fff;
    letter-spacing: .46px;
    position: relative
}

.notification-list>li>a .count {
    width: 18px;
    height: 18px;
    background: #e76877;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    top: -10px;
    line-height: 18px;
    right: -3px;
    font-size: 12px;
    color: #fff;
    letter-spacing: .17px
}

.notification-list>li .small-screen {
    display: none;
    margin-left: 10px;
    text-transform: capitalize;
    color: #354052;
    font-size: 16px;
    font-family: Source Sans Pro, sans-serif
}

@media (max-width:1010px) {
    .notification-list>li .small-screen {
        display: inline-block
    }
}

@media (max-width:1325px) {
    .notification-list .chat-list-toggle {
        display: none
    }
}

@media (max-width:1325px) {
    .dropdown.message {
        padding-right: 26px
    }
}

.dropdown.message .dropdown-menu {
    width: 370px;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 2px solid #e06a26;
    margin-top: 0
}

@media (max-width:1010px) {
    .dropdown.message .dropdown-menu {
        left: 34px;
        position: static;
        border: 1px solid #e6eaee;
        width: 100%;
        border-top: 2px solid #e06a26;
        margin-bottom: 10px
    }
}

.dropdown.message .dropdown-menu .dropdown-menu-header {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #e6eaee
}

.dropdown.message .dropdown-menu .dropdown-menu-header .side-left {
    color: #354052;
    text-transform: capitalize;
    padding-top: 7px;
    padding-bottom: 9px;
    float: left
}

.dropdown.message .dropdown-menu .dropdown-menu-header .side-right {
    padding-top: 7px;
    padding-bottom: 9px;
    float: right
}

.dropdown.message .dropdown-menu .dropdown-menu-header .side-right li {
    padding-right: 6px;
    border-bottom-width: 0
}

.dropdown.message .dropdown-menu .dropdown-menu-header .side-right li:last-child {
    padding-right: 0
}

.dropdown.message .dropdown-menu .dropdown-menu-header .side-right a {
    color: #e06a26;
    text-transform: capitalize
}

.dropdown.message .dropdown-menu .dropdown-menu-footer {
    border-top: 1px solid #e6eaee;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 6px;
    background-color: #fafbfc
}

.dropdown.message .dropdown-menu .dropdown-menu-footer a {
    text-transform: capitalize
}

.dropdown.message .dropdown-menu .no-messages {
    text-align: center;
    border-bottom: 1px solid #e6eaee
}

.dropdown.message .dropdown-menu .no-messages i {
    padding-top: 40px;
    font-size: 100px;
    color: #7f8fa4;
    letter-spacing: 0
}

.dropdown.message .dropdown-menu .no-messages p {
    font-family: LucidaGrande;
    font-size: 18px;
    color: #7f8fa4;
    letter-spacing: 0;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 5px
}

.dropdown.message .dropdown-menu .dropdown-messages-list {
    height: 250px;
    overflow-x: hidden;
    overflow-y: scroll
}

.dropdown.message .dropdown-menu>ul li {
    border-bottom: 1px solid #e6eaee;
    padding-left: 15px;
    padding-right: 15px
}

.dropdown.message .dropdown-menu>ul li:last-child {
    border-bottom: 0
}

.dropdown.message .dropdown-menu>ul li .media .media-left img {
    width: 42px;
    height: 42px;
}

.dropdown.message .dropdown-menu>ul li .media .media-body {
    padding-left: 5px
}

.dropdown.message .dropdown-menu>ul li .media .media-body .media-heading {
    margin-bottom: 0
}

.dropdown.message .dropdown-menu>ul li .media .media-body .media-heading .message-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    letter-spacing: 0;
    text-transform: capitalize;
    text-decoration: none;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 175px;
    white-space: nowrap;
    overflow: hidden
}

.dropdown.message .dropdown-menu>ul li .media .media-body .media-heading .online-status {
    background: #39b54a;
    border: 1px solid #36af47;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 7px
}

.dropdown.message .dropdown-menu>ul li .media .media-body .media-heading .message-time {
    float: right;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-top: 1px;
    font-weight: 400;
    font-size: 11px;
    color: #7f8fa4;
    letter-spacing: 0
}

.dropdown.message .dropdown-menu>ul li .media .media-body .message-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #7f8fa4;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 0;
    height: 19px;
    overflow: hidden;
    width: 245px;
    white-space: nowrap;
    text-overflow: ellipsis
}

.dropdown.message .dropdown-menu li.inbox-message {
    padding-top: 10px;
    padding-bottom: 10px
}

.dropdown.message .dropdown-menu li.inbox-message>a {
    display: block;
    width: 100%;
    text-decoration: none
}

.dropdown.message .dropdown-menu li.inbox-message>a .media-heading {
    color: #354052;
    font-weight: 600
}

.dropdown.message .dropdown-menu li.inbox-message.active,
.dropdown.message .dropdown-menu li.inbox-message:hover {
    background-color: #fafbfc
}

@media (max-width:1325px) {
    .dropdown.message.notification {
        padding-right: 12px
    }
}

.dropdown.message.notification .dropdown-menu>ul li .media-left img {
    width: 40px;
    height: 40px
}

.dropdown.message.notification .dropdown-menu>ul li .media-heading .notification-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    display: block;
    line-height: 13px
}

.dropdown.message.notification .dropdown-menu>ul li .media-heading .message-time {
    display: block;
    margin-top: 0;
    float: left!important;
    letter-spacing: 0
}

.dropdown.message.notification .dropdown-menu>ul li .media-heading .message-time .notification-type {
    display: inline-block;
    background: #e06a26;
    border: 1px solid #e06a26;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 50%;
    line-height: 14px;
    margin-right: 7px
}

.dropdown.message.notification .dropdown-menu>ul li .media-heading .message-time .notification-type i {
    color: #fff;
    font-size: 10px
}

.user-image.dropdown.owos {
    padding-bottom: 16px;
    padding-top: 19px;
    min-width: 189px;
    max-width: 224px;
}

.user-image.dropdown.owos>a {
    margin-left: 26px
}

@media (max-width:1010px) {
    .user-image.dropdown.owos>a {
        margin-left: 31px
    }
}

.user-image.dropdown.owos>a .user-name {
    margin-left: 7px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 16px;
    color: #fff;
    letter-spacing: .26px;
    text-transform: capitalize;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: auto;
    max-width: 121px;

}

.user-image.dropdown.owos>a i {
    color: #fff;
    font-size: 16px;
    border-radius: 83px;
    margin-left: 10px
}

.user-image.dropdown.owos>a i.fa-angle-down {
    margin-right: 13px
}

.user-image.dropdown.owos .dropdown-menu {
    background-color: #fff;
    width: 100%;
    border: 1px solid #e6eaee;
    border-top: 2px solid #a4430c;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none
}

.user-image.dropdown.owos .dropdown-menu li {
    border-bottom: 1px solid #e6eaee
}

.user-image.dropdown.owos .dropdown-menu li:last-child {
    border-bottom-width: 0
}

.user-image.dropdown.owos .dropdown-menu li.active a,
.user-image.dropdown.owos .dropdown-menu li:hover a {
    background-color: #de6926!important;
    color: #fff
}

.user-image.dropdown.owos .dropdown-menu li.active i,
.user-image.dropdown.owos .dropdown-menu li:hover i {
    color: #fff
}

.user-image.dropdown.owos .dropdown-menu li a {
    margin-left: 0;
    padding: 10px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #354052;
    letter-spacing: 0;
    text-transform: capitalize
}

@media (max-width:1010px) {
    .user-image.dropdown.owos .dropdown-menu li a {
        padding-left: 20px
    }
}

.user-image.dropdown.owos .dropdown-menu li i {
    font-size: 18px;
    color: #7f8fa4;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 20px
}

@media (max-width:1010px) {
    .navbar-header {
        float: none
    }
    .navbar-toggle {
        display: block
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .1);
        margin-top: 9px;
        background-color: #e06a26;
        border-bottom: 1px solid #e6eaee;
        margin-left: -15px!important;
        margin-right: -15px!important
    }
    .navbar-collapse.collapse {
        display: none!important
    }
    .navbar-form {
        border-top-width: 0;
        border-bottom-width: 0;
        float: none!important
    }
    .navbar-form .input-group {
        width: 100%
    }
    .navbar-form .input-group .input-group-btn {
        width: 31px
    }
    .navbar-form .input-group .selectize-input {
        max-width: none
    }
    .navbar-form button.btn {
        margin-left: 0
    }
    .notification-list {
        border-right-width: 0
    }
    .notification-list li {
        display: block;
        padding-left: 35px
    }
    .notification-list li:first-child {
        padding-left: 35px
    }
    .navbar-collapse.collapse.in {
        display: block!important
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px
    }
    .navbar-nav>li {
        float: none
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px
    }
}

@media (max-width:1010px) and (max-width:500px) {
    .navbar-form .input-group .selectize-input {
        max-width: 400px
    }
}

@media (max-width:1010px) and (max-width:398px) {
    .navbar-form .input-group .selectize-input {
        max-width: 200px
    }
}

@media (max-width:1010px) and (max-width:768px) {
    .navbar-form {
        margin-left: 0;
        width: 100%
    }
    .navbar-form .selectize-control {
        width: 100%;
        min-width: 0
    }
}

.logout {
    font-size: 18px;
    color: #354052;
    padding-left: 10px;
    padding-bottom: 1px;
    margin-top: 7px
}

.logout a {
    color: #fff!important;
    font-size: 16px;
    text-transform: uppercase;
    padding: 13px 15px !important
}

.logout a:hover {
    text-decoration: underline !important;
}

.main-content,
.page-wrapper {
    padding-top: 80px
}

.panel-create .panel-heading {
    background: #fafbfc;
    font-size: 16px;
    color: #5b6b81;
    line-height: 21px;
    border-width: 0;
    border-bottom: 1px solid #e6eaee
}

.panel-create .panel-body {
    padding: 0;
    padding-bottom: 7px
}

.panel-create .panel-body textarea {
    border: 0;
    box-shadow: none;
    padding-left: 0;
    resize: none;
    font-size: 14px;
    padding: 6px 15px
}

.panel-create .panel-footer {
    background: #fafbfc;
    padding: 7px 15px;
    border-top-color: #e6eaee
}

.panel-create .panel-footer ul {
    margin: 0
}

.panel-create .panel-footer .left-list {
    float: left
}

.panel-create .panel-footer .left-list li {
    padding-left: 9px;
    padding-right: 9px
}

.panel-create .panel-footer .left-list li:first-child {
    padding-left: 0
}

@media (max-width:460px) {
    .panel-create .panel-footer .left-list li {
        padding-left: 5px;
        padding-right: 5px
    }
}

@media (max-width:400px) {
    .panel-create .panel-footer .left-list li {
        padding-left: 4px;
        padding-right: 4px
    }
}

.panel-create .panel-footer .left-list li a {
    color: #859ab5;
    font-size: 18px
}

@media (max-width:350px) {
    .panel-create .panel-footer .left-list li a {
        font-size: 15px
    }
}

.panel-create .panel-footer .right-list {
    float: right
}

.panel-create .panel-footer .right-list li {
    padding-left: 8px;
    padding-right: 8px
}

.panel-create .panel-footer .right-list li:last-child {
    padding-right: 0
}

@media (max-width:424px) {
    .panel-create .panel-footer .right-list li {
        padding-left: 0;
        padding-right: 4px
    }
}

@media (max-width:350px) {
    .panel-create .panel-footer .right-list li {
        padding-right: 1px
    }
}

.panel-create .panel-footer .right-list .create-album {
    color: #2298f1;
    font-size: 14px;
    text-decoration: none;
    vertical-align: middle
}

@media (max-width:424px) {
    .panel-create .panel-footer .right-list .create-album {
        font-size: 13px
    }
}

@media (max-width:350px) {
    .panel-create .panel-footer .right-list .create-album {
        font-size: 12px
    }
}

.panel-create .panel-footer .right-list .btn {
    padding: 2px 17px
}

@media (max-width:350px) {
    .panel-create .panel-footer .right-list .btn {
        padding: 2px 12px;
        font-size: 12px
    }
}

.panel-post {
    border-width: 0
}

.panel-post .panel-heading {
    padding: 14px 15px;
    height: auto;
    border: 1px solid #e6eaee!important
}

.panel-post .panel-heading .post-author .post-options {
    float: right;
    color: #859ab5;
    margin-top: 6px
}

.panel-post .panel-heading .post-author .post-options>ul>li>a {
    color: #859ab5;
    border-radius: 83px;
    font-size: 20px
}

.panel-post .panel-heading .post-author .post-options .dropdown-menu {
    right: -15px;
    left: auto
}

.panel-post .panel-heading .post-author .post-options .dropdown-menu li:hover a {
    background-color: #fafbfc
}

.panel-post .panel-heading .post-author .post-options .dropdown-menu li a {
    color: #333;
    font-size: 14px;
    font-family: Source Sans Pro, sans-serif
}

.panel-post .panel-heading .post-author .post-options .dropdown-menu li a i {
    width: 20px
}

.panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a {
    font-size: 14px
}

@media (max-width:500px) {
    .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 16px
    }
}

@media (max-width:350px) {
    .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a {
        font-size: 12px
    }
}

.panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a .small-text {
    font-weight: 400;
    font-size: 14px;
    color: #7f8fa4;
    display: block;
    padding-left: 22px
}

@media (max-width:500px) {
    .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a .small-text {
        font-size: 12px
    }
}

@media (max-width:350px) {
    .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a .small-text {
        font-size: 11px
    }
}

.panel-post .panel-heading .post-author .post-options .dropdown-menu li.active a {
    background-color: #fafbfc
}

.panel-post .panel-heading .post-author .user-avatar {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 10px
}

.panel-post .panel-heading .post-author .user-avatar img {
    width: 100%;
    height: 100%;
}

.panel-post .panel-heading .post-author .user-post-details {
    line-height: 21px
}

.panel-post .panel-heading .post-author .user-post-details ul {
    padding-left: 50px
}

.panel-post .panel-heading .post-author .user-post-details li {
    color: #859ab5;
    font-size: 16px;
    font-weight: 400
}

.panel-post .panel-heading .post-author .user-post-details li .user {
    text-transform: capitalize
}

.panel-post .panel-heading .post-author .user-post-details li .post-time {
    color: #354052;
    font-size: 12px;
    letter-spacing: 0;
    margin-right: 3px
}

.panel-post .panel-heading .post-author .user-post-details li:last-child {
    font-size: 14px
}

.panel-post .panel-heading .post-author .user-post-details li:last-child i {
    margin-left: 6px;
    margin-right: 2px
}

.panel-post .panel-heading .post-author .user-post-details li:last-child .post-place {
    text-transform: capitalize
}

.panel-post .panel-body {
    border: 1px solid #e6eaee;
    border-top-width: 0;
    padding-bottom: 7px
}

.panel-post .panel-body .text-wrapper p {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    font-weight: 400;
    color: #5b6b81;
    letter-spacing: .3px;
    line-height: 20px;
    margin-bottom: 0;
    word-break: break-word
}

.panel-post .panel-body .text-wrapper .post-image-holder {
    margin-top: 10px;
    max-height: 200px;
    overflow: hidden
}

.panel-post .panel-body .text-wrapper .post-image-holder img {
    max-width: 100%;
    max-height: 200px;
    margin-top: 3px;
    margin-right: 3px;
    margin-bottom: 3px;
}

.panel-post .panel-body iframe {
    width: 100%;
    margin-top: 10px;
    height: 273px
}

.panel-post .panel-body .actions-count {
    margin-top: 10px;
    margin-bottom: 0
}

.panel-post .panel-body .actions-count li a {
    text-decoration: none;
    font-size: 13px;
    text-transform: capitalize;
    color: #859ab5
}

@media (max-width:350px) {
    .panel-post .panel-body .actions-count li a {
        font-size: 12px
    }
}

.panel-post .panel-body .actions-count li a .count-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    padding: 0;
    background-color: #9fa9ba;
    line-height: 13px;
    margin-right: 3px;
    vertical-align: 2px
}

.panel-post .panel-body .actions-count li a .count-circle i {
    font-size: 10px;
    color: #fff
}

.panel-post .panel-body.image-post {
    padding: 0
}

.panel-post .panel-body.image-post .text-wrapper p {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px
}

.panel-post .panel-body.image-post .text-wrapper .post-image-holder {
    margin-top: 10px;
    max-height: 800px;
    overflow: hidden
}

.panel-post .panel-body.image-post .text-wrapper .post-image-holder a {
    width: 100%;
    height: auto;
    margin-bottom: 0
}

.panel-post .panel-body.image-post .text-wrapper .post-image-holder a img {
    width: 100%;
    height: auto;
    margin: 0
}

.panel-post .panel-body.image-post .actions-count {
    padding-left: 15px;
    padding-bottom: 7px
}

.panel-post .panel-footer.owos {
    background-color: #fff;
    border-top: 0;
    padding: 15px;
    border: 1px solid #e6eaee;
    border-top-width: 0
}

.panel-post .panel-footer.owos .footer-list {
    margin-bottom: 0
}

.panel-post .panel-footer.owos .footer-list li {
    padding-left: 14px;
    padding-right: 14px
}

@media (max-width:350px) {
    .panel-post .panel-footer.owos .footer-list li {
        padding-left: 10px;
        padding-right: 10px
    }
}

.panel-post .panel-footer.owos .footer-list li:first-child,
.panel-post .panel-footer.owos .footer-list li:nth-child(2) {
    padding-left: 5px
}

.panel-post .panel-footer.owos .footer-list li a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #859ab5;
    text-transform: capitalize
}

.panel-post .panel-footer.owos .footer-list li a i {
    font-size: 16px;
    color: #9fa9ba;
    letter-spacing: 0;
    margin-right: 4px
}

.panel-post .comments-section {
    width: 100%;
    height: auto;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #eff3f6
}

.panel-post .comments-section .comments-wrapper {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 26px;
    height: auto;
    background: #fcfcfc;
    border: 1px solid #e6eaee;
    border-top-width: 0
}

.panel-post .comments-section .comments-wrapper .comment-form {
    position: relative
}

.panel-post .comments-section .comments-wrapper .comment-form .meme-reply {
    position: absolute;
    top: 50%;
    margin-bottom: 0;
    margin-top: -9px;
    right: 10px
}

.panel-post .comments-section .comments-wrapper .comment-form .meme-reply li a i {
    font-size: 16px;
    color: #859ab5
}

.panel-post .comments-section .comments-wrapper .to-comment {
    padding-top: 16px
}

.panel-post .comments-section .comments-wrapper .to-comment.comment-reply {
    margin-left: 45px;
    padding-top: 12px
}

.panel-post .comments-section .comments-wrapper .to-comment.comment-reply .commenter-avatar {
    width: 30px;
    height: 30px
}

.panel-post .comments-section .comments-wrapper .to-comment.comment-reply .comment-textfield {
    padding-left: 45px
}

.panel-post .comments-section .comments-wrapper .to-comment.comment-reply .comment-textfield .form-control {
    height: 30px;
    font-size: 13px
}

.panel-post .comments-section .comments-wrapper .commenter {
    margin-top: -3px;
    padding-left: 45px
}

.panel-post .comments-section .comments-wrapper .commenter-avatar {
    width: 36px;
    height: 36px;
    float: left;
    display: inline-block
}

.panel-post .comments-section .comments-wrapper .commenter-avatar img {
    width: 100%;
    height: 100%;
}

.panel-post .comments-section .comments-wrapper .comment-replies {
    margin-left: 45px
}

.panel-post .comments-section .comments-wrapper .comment-textfield {
    padding-left: 51px
}

.panel-post .comments-section .comments-wrapper .comment-textfield .form-control {
    padding-top: 8px;
    padding-right: 60px;
    padding-bottom: 8px
}

.panel-post .comments-section .comments-wrapper .comments {
    padding-top: 15px
}

.panel-post .comments-section .comments-wrapper .delete-comment {
    float: right;
    margin-right: 2px;
    font-size: 14px;
    color: #7f8fa4
}

.panel-post .comments-section .comments-wrapper .commenter-name a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #2298f1;
    letter-spacing: 0;
    text-transform: capitalize
}

.panel-post .comments-section .comments-wrapper .commenter-name .comment-description {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 13px;
    color: #5b6b81;
    margin-left: 8px;
    word-break: break-word
}

.panel-post .comments-section .comments-wrapper .comment-options {
    margin-top: 1px
}

.panel-post .comments-section .comments-wrapper .comment-options li {
    padding-left: 4px;
    padding-right: 4px;
    color: #859ab5
}

.panel-post .comments-section .comments-wrapper .comment-options li:first-child {
    padding-left: 5px
}

.panel-post .comments-section .comments-wrapper .comment-options li a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 12px;
    color: #859ab5;
    text-transform: capitalize
}

.panel-post .comments-section .comments-wrapper .comment-options li a i {
    margin-right: 3px
}

.panel-post .comments-section .comments-wrapper .replies-count {
    margin-top: 13px;
    width: 100px;
    display: block;
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    color: #9fa9ba;
    margin-left: 45px
}

.panel-post .comments-section .comments-wrapper .replies-count i {
    font-size: 16px;
    color: #9fa9ba;
    margin-right: 5px;
    vertical-align: -1px
}

.btn-follow {
    float: right;
    margin-top: 4px
}

.btn-follow .btn {
    padding: 4px 15px
}

.media-body.socialte-timeline .text-muted {
    display: block;
    font-size: 14px;
    margin-top: -4px
}

.timeline-cover-section {
    width: 100%;
    background-color: #000;
    position: relative;
    margin-bottom: 20px
}

@media (max-width:1200px) {
    .timeline-cover-section {
        margin-bottom: 70px
    }
}

.timeline-cover-section .timeline-cover {
    max-height: 320px;
    width: 100%;
    overflow: hidden;
    position: relative
}

@media (max-width:660px) {
    .timeline-cover-section .timeline-cover {
        min-height: 185px;
        width: auto
    }
}

.timeline-cover-section .timeline-cover:hover .btn-camera-cover {
    display: block
}

.timeline-cover-section .timeline-cover img {
    height: auto;
    width: 100%;
    opacity: .7
}

@media (max-width:660px) {
    .timeline-cover-section .timeline-cover img {
        min-height: 185px;
        width: auto
    }
}

.timeline-cover-section .timeline-cover .btn-camera-cover {
    width: 198px;
    height: 50px;
    background: rgba(0, 0, 0, .7);
    text-align: left;
    position: absolute;
    top: 8px;
    left: 20px;
    display: none
}

@media (max-width:560px) {
    .timeline-cover-section .timeline-cover .btn-camera-cover {
        width: 148px;
        height: 40px
    }
}

.timeline-cover-section .timeline-cover .btn-camera-cover i.fa {
    margin-right: 12px;
    font-size: 24px;
    color: #fff;
    letter-spacing: .56px;
    margin-top: 7px
}

@media (max-width:560px) {
    .timeline-cover-section .timeline-cover .btn-camera-cover i.fa {
        font-size: 15px;
        margin-right: 10px
    }
}

.timeline-cover-section .timeline-cover .btn-camera-cover .change-cover-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    text-transform: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: .37px;
    vertical-align: text-bottom
}

@media (max-width:560px) {
    .timeline-cover-section .timeline-cover .btn-camera-cover .change-cover-text {
        font-size: 14px
    }
}

.timeline-user-avtar {
    width: 150px;
    height: 150px;
    position: absolute;
    top: -120px;
    left: 20px;
    padding: 7px;
    background-color: #fff;
    border: 1px solid #e6eaee
}

@media (max-width:560px) {
    .timeline-user-avtar {
        width: 120px;
        height: 120px;
        top: -90px
    }
}

@media (max-width:350px) {
    .timeline-user-avtar {
        width: 100px;
        height: 100px;
        top: -70px
    }
}

.timeline-user-avtar img {
    width: 100%;
    height: 100%
}

.timeline-user-avtar:hover .chang-user-avatar {
    display: block
}

.event-avatar {
    width: 50px;
    height: 50px;
    background-color: transparent;
    position: absolute;
    top: -63px;
    left: 18px;
    border: 2px solid #fff;
    text-align: center
}

.event-avatar .event-month {
    color: #fff;
    text-transform: uppercase;
    margin-top: 1px;
    font-size: 12px;
    line-height: 16px
}

.event-avatar .event-date {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 3px
}

.chang-user-avatar {
    position: relative;
    display: none
}

.chang-user-avatar .btn-camera {
    background-color: rgba(0, 0, 0, .67);
    width: 100%;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff
}

@media (max-width:560px) {
    .chang-user-avatar .btn-camera {
        padding-left: 4px;
        font-size: 8px
    }
}

.chang-user-avatar .btn-camera .fa {
    font-size: 16px;
    margin-top: 3px;
    margin-right: 17px;
    vertical-align: middle
}

@media (max-width:560px) {
    .chang-user-avatar .btn-camera .fa {
        margin-right: 4px
    }
}

@media (max-width:350px) {
    .chang-user-avatar .btn-camera .fa {
        font-size: 13px;
        margin-top: 5px
    }
}

.chang-user-avatar .btn-camera .avatar-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    width: 78px;
    line-height: 10px;
    margin-top: -3px;
    vertical-align: top
}

@media (max-width:560px) {
    .chang-user-avatar .btn-camera .avatar-text {
        font-size: 11px
    }
}

@media (max-width:350px) {
    .chang-user-avatar .btn-camera .avatar-text {
        font-size: 8px
    }
}

.chang-user-avatar .btn-camera .avatar-text span {
    display: block;
    margin-top: -5px
}

@media (max-width:350px) {
    .chang-user-avatar .btn-camera .avatar-text span {
        margin-top: -7px
    }
}

.user-timeline-name {
    position: absolute;
    bottom: 22px;
    left: 193px
}

.user-timeline-name a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 24px;
    color: #fff;
    text-decoration: none;
    letter-spacing: .56px;
    text-transform: capitalize
}

@media (max-width:560px) {
    .user-timeline-name {
        bottom: 16px;
        left: 162px
    }
}

@media (max-width:350px) {
    .user-timeline-name {
        bottom: 9px;
        left: 130px
    }
    .user-timeline-name a {
        font-size: 22px
    }
}

.user-timeline-name.event {
    left: 90px;
    bottom: 19px
}

.status-button {
    position: absolute;
    top: 0;
    text-align: right;
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: none
}

@media (max-width:1200px) {
    .status-button {
        display: block
    }
}

.status-button .btn-status {
    background-color: #de6926;
    color: #fff;
    margin-top: 8px;
    margin-right: 15px
}

.user-avatar-progress {
    padding-top: 65px
}

.user-avatar-progress,
.user-cover-progress {
    background-color: rgba(0, 0, 0, .67);
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    color: #fff;
    text-align: center;
    width: 100%
}

.user-cover-progress {
    padding-top: 150px
}

.timeline-list {
    background-color: #fff;
    height: 52px;
    position: relative
}

.timeline-list>ul {
    margin-left: 170px;
    text-align: left;
    font-size: 0
}

.timeline-list>ul>li {
    border-right: 1px solid #e6eaee;
    padding-left: 0;
    padding-right: 0
}

.timeline-list>ul>li:hover>a {
    color: #fff;
    background: #e26b26;
}

.timeline-list>ul>li:last-child {
    border-right-width: 0;
    float: right;
    margin: 0px 10px 0px 0px;
}

.timeline-list>ul>li>a {
    display: block;
    padding: 16px 17px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    text-decoration: none;
    text-transform: capitalize;
    background: #fff
}

.timeline-list>ul>li>a i {
    font-size: 15px;
    vertical-align: bottom
}

.timeline-list>ul>li .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    border-width: 1px solid #e6eaee;
    border-top: 2px solid #df6708;
}

.timeline-list>ul>li .dropdown-menu li a {
    text-align: center;
    color: #354052
}

.timeline-list>ul>li .dropdown-menu li a i {
    margin-right: 10px
}

.timeline-list>ul>li .dropdown-menu li:hover a {
    color: #fff;
    background-color: #de6926
}

.timeline-list>ul .dropdown.open:hover>a {
    background: #fff;
    color: #354052
}

.timeline-list>ul li.active a {
    color: #fff;
    background: #de6926;
}

.timeline-list>ul li.largescreen-report {
    border-right: 1px solid transparent
}

@media (max-width:1200px) {
    .timeline-list>ul li.largescreen-report {
        display: none
    }
}

.timeline-list>ul li.smallscreen-report {
    margin-bottom: -60px;
    display: none
}

@media (max-width:1200px) {
    .timeline-list>ul li.smallscreen-report {
        display: block
    }
    .timeline-list>ul li.smallscreen-report a {
        text-align: center
    }
}

@media (max-width:1200px) {
    .timeline-list {
        height: auto;
        background-color: #eff3f6
    }
    .timeline-list>ul {
        margin-left: 0;
        display: none
    }
    .timeline-list>ul li {
        display: block;
        border-right: 0;
        margin-top: 10px;
        margin-bottom: 10px
    }
    .timeline-list>ul li:first-child {
        margin-top: 0;
        padding-top: 60px
    }
    .timeline-list>ul li:last-child {
        margin-bottom: -60px
    }
    .timeline-list>ul li>a {
        padding: 12px 16px
    }
    .timeline-list>ul li>a .top-list {
        text-align: center;
        display: block;
        margin: 0 auto
    }
    .timeline-list .report-dropdown li {
        margin-top: 1px;
        margin-bottom: 0
    }
    .timeline-list .report-dropdown li:first-child {
        padding-top: 0
    }
    .timeline-list .report-dropdown li a {
        padding: 8px 16px;
    }
}

.timeline-list.event>ul {
    margin-left: 0
}

.user-profile-buttons .left-col {
    padding-right: 10px
}

.user-profile-buttons .right-col {
    padding-left: 10px
}

@media (max-width:991px) {
    .user-profile-buttons .btn {
        padding: 9px 16px
    }
}

.user-profile-buttons .btn-profile {
    background-color: #fff;
    border: 1px solid #e6eaee;
    width: 100%;
    padding: 9px 12px;
    text-transform: capitalize;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052
}

@media (max-width:991px) {
    .user-profile-buttons .btn-profile {
        padding: 12px 17px
    }
}

.user-profile-buttons .btn-profile i.fa {
    font-size: 14px;
    color: #354052;
    letter-spacing: 0;
    margin-right: 10px
}

.user-profile-buttons .btn-profile.text-center {
    text-align: center
}

.mini-profile.owos .avatar-img img {
    width: 60px;
    height: 60px
}

.mini-profile.owos .activity-list {
    text-align: center;
    margin-top: 7px
}

.mini-profile.owos .avatar-details {
    padding-left: 80px
}

.mini-profile.owos .activity-name {
    color: #354052
}

.mini-profile.owos .activity-count {
    color: #7f8fa4
}

.user-tag-names {
    display: inline-block
}

.users-results-wrapper .user-results {
    margin-bottom: 0;
    border-top: 1px solid #e6eaee
}

.users-results-wrapper .user-results .user-result-item.list-group-item {
    background-color: #fff;
    color: #354052;
    border-width: 0;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10px;
    border-bottom: 2px solid #e6eaee
}

.users-results-wrapper .user-results .user-result-item.list-group-item:last-child {
    border-bottom-width: 1px
}

.users-results-wrapper .user-results .user-result-item.list-group-item:hover {
    background-color: #fafbfc
}

.users-results-wrapper .user-results .user-result-item.list-group-item img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    float: left;
    margin-right: 15px
}

.users-results-wrapper .user-results .user-result-item.list-group-item span {
    display: block;
    font-size: 12px;
    color: #7f8fa4
}

.typeahead.dropdown-menu {
    padding: 0;
    min-width: 250px;
    top: 114px
}

.typeahead.dropdown-menu li a {
    padding: 0;
    background-color: #fff;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-top: 5px
}

.typeahead.dropdown-menu li a img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    float: left
}

.typeahead.dropdown-menu li a .mention_name {
    color: #354052;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-left: 5px;
    font-size: 14px
}

.typeahead.dropdown-menu li a .mention_username {
    color: #7f8fa4;
    font-weight: 400;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    display: block;
    font-size: 12px;
    padding-left: 50px
}

.typeahead.dropdown-menu li.active a {
    background-color: #f46a1a
}

.typeahead.dropdown-menu li.active a .mention_name,
.typeahead.dropdown-menu li.active a .mention_username {
    color: #fff
}

select.form-control {
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url(../images/arrow-new.png) no-repeat 97% 50%;
    background-size: 16px
}

.event-suggested-users .holder,
.group-suggested-users .holder,
.page-suggested-users .holder {
    padding: 10px 15px
}

.event-suggested-users .holder .follower img,
.group-suggested-users .holder .follower img,
.page-suggested-users .holder .follower img {
    width: 40px;
    height: 40px;
    margin-right: 4px
}

.event-suggested-users .holder .follower span,
.group-suggested-users .holder .follower span,
.page-suggested-users .holder .follower span {
    color: #354052;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-left: 4px
}

.event-suggested-users .holder .follow-links,
.group-suggested-users .holder .follow-links,
.page-suggested-users .holder .follow-links {
    padding-top: 3px
}

.event-suggested-users .holder .follow-links .left-col .btn,
.group-suggested-users .holder .follow-links .left-col .btn,
.page-suggested-users .holder .follow-links .left-col .btn {
    margin-right: 5px
}

.page-likes .connect-list .follow-links {
    padding-top: 0!important
}

.page-likes .connect-list .follow-links .row {
    margin-left: 0;
    margin-right: 0
}

.page-likes .connect-list .follow-links .row .padding-5 {
    padding: 5px
}

.page-likes .connect-list .follow-links .row .padding-5 .btn {
    width: 100%
}

.page-likes .connect-list .follow-links .row .padding-5 .remove-pagemember {
    padding-left: 7px
}

.page-likes .connect-list .follow-links .remove-button {
    display: inline-block;
    width: 95px
}

.panel .panel-heading {
    height: auto
}

.holder {
    padding-left: 15px
}

.soundcloud-results-wrapper .soundcloud-results {
    margin-bottom: 0
}

.soundcloud-results-wrapper .soundcloud-results .list-group-item:first-child {
    border-radius: 0
}

.soundcloud-results-wrapper .soundcloud-results .list-group-item {
    border-width: 0;
    border-top: 1px solid #e6eaee;
    padding: 10px;
    color: #354052;
    font-size: 14px
}

.soundcloud-results-wrapper .soundcloud-results .list-group-item img {
    margin-right: 1px
}

.soundcloud-results-wrapper .soundcloud-results a.list-group-item:focus,
.soundcloud-results-wrapper .soundcloud-results a.list-group-item:hover,
.soundcloud-results-wrapper .soundcloud-results button.list-group-item:focus,
.soundcloud-results-wrapper .soundcloud-results button.list-group-item:hover {
    background-color: #fafbfc
}

.new-conversation {
    padding-left: 15px;
    padding-right: 15px
}

.new-conversation .selectize-dropdown {
    height: auto!important;
    background-color: #fff;
    border: 1px solid #e6eaee!important;
    border-top-width: 0
}

.new-conversation .selectize-dropdown .selectize-dropdown-content {
    padding-top: 0;
    padding-bottom: 0
}

.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown {
    padding-left: 15px;
    padding-top: 4px
}

.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-left {
    padding-right: 15px
}

.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-left img {
    width: 40px;
    height: 40px;
}

.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-body .media-heading {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 14px;
    color: #354052;
    letter-spacing: 0;
    margin-bottom: 0;
    line-height: 19px
}

.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-body p {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    color: #9a9fa8;
    letter-spacing: 0;
    max-height: 43px;
    margin-bottom: 0;
    height: 18px;
    overflow: hidden;
    width: 250px;
    line-height: 15px
}

.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown.active {
    background-color: #fafbfc
}

.form-control {
    -webkit-appearance: none!important
}

.form-group.required label:after {
    content: '*'!important;
    color: #e76877;
    font-size: 15px;
    margin-left: 3px
}

.signup-form .form-group label {
    text-transform: capitalize!important
}
.form-group.ann_start_end label:after {
    margin-left: 0 !important;
}
.signup-form .form-group .optional {
    font-size: 11px;
    color: #7f8fa4;
    margin-left: 2px
}

.pagination-holder {
    text-align: center;
    padding-top: 15px
}

.pagination-holder .pagination {
    margin: 0
}

.table.announcements-table tbody tr td .description {
    display: block;
    width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.table.announcements-table tbody tr td ul.list-inline {
    margin-bottom: 0;
    display:flex;
}

.table.announcements-table tbody tr td .announcement-status {
    padding: 2px 8px
}

.existing-products-table.owos tr:last-child td,
.table.announcements-table tbody tr:last-child td {
    border-bottom: 0
}

.announcement-title {
    padding-left: 13px;
    font-size: 14px;
    color: #354052;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 600
}

.announcement-title .expiry-date {
    font-weight: 400;
    font-size: 14px;
    padding-right: 10px;
    text-transform: none
}

.announcement-description {
    padding-left: 13px;
    padding-top: 15px;
    width: 400px
}

.announcement-actions {
    padding-top: 15px;
    text-align: right
}

.announcement-actions .view-by {
    display: block;
    padding-bottom: 10px;
    color: #354052;
    text-decoration: none;
    font-size: 12px
}

.announcement-actions .view-by i {
    font-size: 10px
}

.announcement-actions a {
    text-transform: capitalize
}

.btn.btn-success {
    color: #fff!important
}

.btn.btn-default {
    color: #333!important
}
.btn.btn-default-color {
    color: #fff !important;
    background-color: #f46a1a;
}


@media (max-width:700px) {
    .modal.fade.in {
        padding-right: 0!important
    }
}

.modal.fade.in .album-modal.modal-dialog,
.modal.fade.in .modal-likes.modal-dialog {
    margin: 30px auto
}

@media (max-width:510px) {
    .modal.fade.in .album-modal.modal-dialog,
    .modal.fade.in .modal-likes.modal-dialog {
        margin: 0 auto;
        width: 380px
    }
    .modal.fade.in .album-modal.modal-dialog .modal-content,
    .modal.fade.in .modal-likes.modal-dialog .modal-content {
        margin-top: 50px
    }
    .modal.fade.in .album-modal.modal-dialog .modal-content .album-body,
    .modal.fade.in .modal-likes.modal-dialog .modal-content .album-body {
        padding: 10px 16px
    }
    .modal.fade.in .album-modal.modal-dialog .modal-content .album-body .form-group,
    .modal.fade.in .modal-likes.modal-dialog .modal-content .album-body .form-group {
        margin-bottom: 10px
    }
}

@media (max-width:400px) {
    .modal.fade.in .album-modal.modal-dialog,
    .modal.fade.in .modal-likes.modal-dialog {
        width: 330px
    }
    .modal.fade.in .album-modal.modal-dialog .modal-content,
    .modal.fade.in .modal-likes.modal-dialog .modal-content {
        margin-top: 20px
    }
}

@media (max-width:350px) {
    .modal.fade.in .album-modal.modal-dialog,
    .modal.fade.in .modal-likes.modal-dialog {
        width: 300px
    }
}

.time-created {
    color: #7f8fa4
}

@media (max-width:1010px) {
    .largescreen-message {
        display: none!important
    }
}

.smallscreen-message {
    display: none!important
}

@media (max-width:1010px) {
    .smallscreen-message {
        display: block!important
    }
}

.dropdown-loading {
    text-align: center
}

.dropdown-loading i {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px
}

[v-cloak] {
    display: none
}

.tooltip-inner {
    white-space: nowrap
}

.installer-container {
    padding-top: 80px
}

.settings-content {
    min-height: 300px;
    padding-bottom: 60px
}

.settings-content p {
    text-align: center;
    font-size: 22px;
    padding-top: 100px
}

.settings-content .btn-installer {
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -45px
}

.settings-content .fa {
    font-size: 24px!important
}

.settings-content .fa.success {
    color: #1bb934
}

.settings-content .fa.error {
    color: #ed1c24
}

.installer-form {
    text-align: center
}

.installer-form .btn {
    margin: 10px 0
}

.input-group.input-group-focus .input-group-addon.addon-left {
    border: 1px solid #b84c0e;
    border-right-width: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out!important;
    transition-property: border-color, box-shadow!important;
    transition-duration: .15s, .15s!important;
    transition-timing-function: ease-in-out, ease-in-out!important;
    transition-delay: initial, initial!important
}

.input-group.input-group-focus .form-control {
    border-top: 1px solid #b84c0e;
    border-bottom: 1px solid #b84c0e
}

.input-group.input-group-focus .input-group-addon.addon-right {
    border: 1px solid #b84c0e;
    border-left-width: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out!important;
    transition-property: border-color, box-shadow!important;
    transition-duration: .15s, .15s!important;
    transition-timing-function: ease-in-out, ease-in-out!important;
    transition-delay: initial, initial!important
}

.badge-verification {
    padding-left: 10px;
    padding-top: 5px
}

.badge-verification a {
    display: block;
    position: relative
}

.badge-verification a .verified-badge {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -3px;
    left: -6px;
    line-height: 10px
}

.badge-verification a .verified-badge .fa {
    font-size: 11px
}

.list-details li {
    padding-top: 5px
}

.event-avatar.settings {
    width: 35px;
    height: 35px;
    top: auto;
    bottom: 10px
}

.event-avatar.settings .event-date {
    margin-top: 0;
    font-size: 13px
}

.event-avatar.settings .event-month {
    margin-top: 0;
    font-size: 10px;
    line-height: 9px
}

.avatar-details.event-settings {
    padding-left: 17px!important
}

.report-dropdown li {
    border-bottom: 1px solid #e6eaee
}

.report-dropdown li a {
    padding: 8px 20px
}

body.direction-rtl {
    direction: rtl
}

body.direction-rtl .navbar-form button.btn {
    margin-left: 0
}

body.direction-rtl .form-left .form-control {
    margin-left: 0!important;
    border-left-width: 1px solid #e6eaee!important;
    border-right-width: 0!important;
    max-width: 360px!important
}

@media (max-width:1010px) {
    body.direction-rtl .form-left .form-control {
        max-width: none!important
    }
}

body.direction-rtl .form-left .form-control .selectize-input {
    border-left-width: 1px;
    padding-right: 8px;
    border-right-width: 0!important
}

body.direction-rtl .form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown {
    padding-left: 0;
    padding-right: 15px
}

body.direction-rtl .form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-left {
    padding-right: 0;
    padding-left: 15px
}

body.direction-rtl .form-left .input-group-btn .btn {
    border-right-width: 1px!important;
    border-left-width: 0!important;
}

body.direction-rtl .notification-list {
    padding-right: 0
}

body.direction-rtl .notification-list>li {
    padding-left: 12px;
    padding-right: 12px
}

body.direction-rtl .notification-list>li:first-child {
    padding-left: 12px;
    padding-right: 24px
}

body.direction-rtl .notification-list>li:last-child {
    padding-right: 12px
}

body.direction-rtl .notification-list>li .small-screen {
    margin-right: 10px
}

@media (max-width:1010px) {
    body.direction-rtl .notification-list .smallscreen-message {
        padding-right: 26px
    }
}

@media (max-width:767px) {
    body.direction-rtl .notification-list .smallscreen-message {
        padding-right: 16px
    }
}

@media (max-width:1325px) {
    body.direction-rtl .dropdown.message {
        padding-right: 12px
    }
}

@media (max-width:1010px) {
    body.direction-rtl .dropdown.message .dropdown-menu {
        left: 34px;
        position: static
    }
}

body.direction-rtl .dropdown.message .dropdown-menu .dropdown-menu-header .side-left {
    float: right
}

body.direction-rtl .dropdown.message .dropdown-menu .dropdown-menu-header .side-right {
    float: left
}

body.direction-rtl .dropdown.message .dropdown-menu .dropdown-messages-list {
    padding-right: 0
}

body.direction-rtl .dropdown.message .dropdown-menu>ul li .media .media-left {
    padding-right: 0;
    padding-left: 10px
}

body.direction-rtl .dropdown.message .dropdown-menu>ul li .media .media-body {
    padding-left: 0;
    padding-right: 5px
}

body.direction-rtl .dropdown.message .dropdown-menu>ul li .media .media-body .media-heading {
    text-align: right
}

body.direction-rtl .dropdown.message .dropdown-menu>ul li .media .media-body .media-heading .online-status {
    margin-left: 0;
    margin-right: 7px
}

body.direction-rtl .dropdown.message .dropdown-menu>ul li .media .media-body .media-heading .message-time {
    float: left
}

body.direction-rtl .dropdown.message .dropdown-menu>ul li .media .media-body .message-text {
    text-align: right
}

@media (max-width:1325px) {
    body.direction-rtl .dropdown.message.notification {
        padding-right: 26px
    }
}

@media (max-width:767px) {
    body.direction-rtl .dropdown.message.notification {
        padding-right: 16px
    }
}

body.direction-rtl .dropdown.message.notification .dropdown-menu>ul li .media-heading .message-time {
    float: right!important
}

body.direction-rtl .dropdown.message.notification .dropdown-menu>ul li .media-heading .message-time .notification-type {
    text-align: center;
    margin-right: 7px
}

body.direction-rtl .dropdown.message.notification .dropdown-menu>ul li .media-heading .message-time .notification-type i {
    color: #fff;
    font-size: 10px
}

body.direction-rtl .user-image.dropdown.owos>a {
    margin-left: 24px
}

@media (max-width:1010px) {
    body.direction-rtl .user-image.dropdown.owos>a {
        margin-left: 31px;
        margin-right: 26px;
        margin-right: 16px
    }
}

body.direction-rtl .user-image.dropdown.owos>a .user-name {
    margin-right: 7px;
    margin-left: 7px
}

body.direction-rtl .user-image.dropdown.owos>a i {
    margin-left: 0
}

body.direction-rtl .user-image.dropdown.owos>a i.fa-angle-down {
    margin-right: 0
}

body.direction-rtl .user-image.dropdown.owos .dropdown-menu li a {
    margin-left: 0;
    text-align: right
}

@media (max-width:1010px) {
    body.direction-rtl .user-image.dropdown.owos .dropdown-menu li a {
        padding-left: 20px
    }
}

body.direction-rtl .user-image.dropdown.owos .dropdown-menu li i {
    margin-left: 20px;
    margin-right: 10px
}

@media (max-width:1010px) {
    body.direction-rtl .navbar-collapse .navbar-right {
        padding-right: 0
    }
}

body.direction-rtl .panel-create .panel-footer .left-list {
    float: right;
    padding-right: 0
}

body.direction-rtl .panel-create .panel-footer .left-list li:first-child {
    padding-right: 0;
    padding-left: 9px
}

body.direction-rtl .panel-create .panel-footer .right-list {
    float: left;
    padding-right: 0
}

body.direction-rtl .panel-create .panel-footer .right-list li {
    padding-left: 0
}

body.direction-rtl .post-addon .post-addon-icon {
    float: right!important;
    border-left: 1px solid #d3d4d5;
    border-right-width: 0!important
}

body.direction-rtl .post-addon .form-group {
    padding-left: 0!important;
    padding-right: 36px
}

body.direction-rtl .post-addon .form-group .selectize-control .selectize-input {
    padding-left: 12px!important;
    padding-right: 0;
    padding-top: 8px
}

body.direction-rtl .post-addon .form-group .selectize-control .selectize-dropdown {
    padding-left: 0
}

body.direction-rtl .post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown {
    padding-left: 15px;
    padding-right: 15px!important
}

body.direction-rtl .post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-left {
    padding-right: 0!important;
    padding-left: 10px
}

body.direction-rtl .smiley-pics-content {
    padding: 5px 15px 5px 0
}

body.direction-rtl .panel-post .panel-heading .post-author .post-options {
    float: left
}

body.direction-rtl .panel-post .panel-heading .post-author .post-options .dropdown-menu {
    left: -15px;
    right: auto
}

body.direction-rtl .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link {
    text-align: right
}

body.direction-rtl .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a .small-text {
    padding-right: 22px
}

body.direction-rtl .panel-post .panel-heading .post-author .user-avatar {
    float: right;
    margin-right: 0
}

body.direction-rtl .panel-post .panel-heading .post-author .user-post-details ul {
    padding-left: 0;
    padding-right: 50px
}

body.direction-rtl .panel-post .panel-body .text-wrapper .post-image-holder img {
    margin-right: 0;
    margin-left: 3px
}

body.direction-rtl .panel-post .panel-body .actions-count {
    padding-right: 0;
    margin-left: 0;
    margin-right: -5px
}

body.direction-rtl .panel-post .panel-body .actions-count li a .count-circle {
    margin-right: 0;
    margin-left: 3px
}

body.direction-rtl .panel-post .panel-footer.owos .footer-list {
    padding-right: 0;
    margin-left: 0;
    margin-right: -5px
}

body.direction-rtl .panel-post .panel-footer.owos .footer-list li:first-child,
body.direction-rtl .panel-post .panel-footer.owos .footer-list li:nth-child(2) {
    padding-left: 14px;
    padding-right: 5px
}

body.direction-rtl .panel-post .panel-footer.owos .footer-list li a i {
    margin-right: 0;
    margin-left: 4px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-form .meme-reply {
    left: 10px;
    right: auto
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .to-comment.comment-reply {
    margin-left: 0;
    margin-right: 45px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .to-comment.comment-reply .comment-textfield {
    padding-left: 0;
    padding-right: 45px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .main-comment {
    padding-right: 0
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .commenter {
    padding-left: 0;
    padding-right: 45px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .commenter-avatar {
    float: right
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-replies {
    margin-left: 0;
    margin-right: 45px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-replies .comment-replys {
    padding-right: 0
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-textfield {
    padding-left: 0;
    padding-right: 51px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-textfield .form-control {
    padding-right: 12px;
    padding-left: 60px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .delete-comment {
    float: left;
    margin-right: 0;
    margin-left: 2px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .commenter-name a {
    display: inline-block
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .commenter-name .comment-description {
    margin-right: 8px;
    margin-left: 0
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-options {
    padding-right: 0;
    margin-right: -5px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-options li {
    padding-left: 4px;
    padding-right: 4px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-options li:first-child {
    padding-left: 5px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-options li a i {
    margin-right: 0;
    margin-left: 3px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .replies-count {
    margin-top: 13px;
    margin-left: 0;
    margin-right: 45px
}

body.direction-rtl .panel-post .comments-section .comments-wrapper .replies-count i {
    margin-right: 0;
    margin-left: 5px
}

body.direction-rtl .btn-follow,
body.direction-rtl .close {
    float: left
}

body.direction-rtl .user-follow .media-left {
    padding-right: 0;
    padding-left: 10px
}

body.direction-rtl .timeline-cover-section .timeline-cover .btn-camera-cover {
    text-align: right;
    right: 20px;
    left: auto
}

body.direction-rtl .timeline-cover-section .timeline-cover .btn-camera-cover i.fa {
    margin-right: 0;
    margin-left: 12px
}

@media (max-width:560px) {
    body.direction-rtl .timeline-cover-section .timeline-cover .btn-camera-cover i.fa {
        margin-right: 0;
        margin-left: 10px
    }
}

body.direction-rtl .timeline-user-avtar {
    left: auto;
    right: 20px
}

body.direction-rtl .chang-user-avatar .btn-camera {
    left: auto;
    right: 0
}

@media (max-width:560px) {
    body.direction-rtl .chang-user-avatar .btn-camera {
        padding-left: 0;
        padding-right: 4px
    }
}

body.direction-rtl .chang-user-avatar .btn-camera .fa {
    margin-right: 0;
    margin-left: 17px;
    vertical-align: middle
}

@media (max-width:560px) {
    body.direction-rtl .chang-user-avatar .btn-camera .fa {
        margin-right: 0;
        margin-left: 4px
    }
}

body.direction-rtl .user-timeline-name {
    left: auto;
    right: 193px
}

@media (max-width:560px) {
    body.direction-rtl .user-timeline-name {
        left: auto;
        right: 162px
    }
}

@media (max-width:350px) {
    body.direction-rtl .user-timeline-name {
        right: 130px;
        left: auto
    }
}

body.direction-rtl .status-button {
    text-align: left
}

body.direction-rtl .status-button .btn-status {
    margin-right: 0;
    margin-left: 15px
}

body.direction-rtl .user-avatar-progress,
body.direction-rtl .user-cover-progress {
    left: auto;
    right: 0
}

body.direction-rtl .timeline-list>ul {
    padding-right: 0;
    margin-left: auto;
    margin-right: 170px;
    text-align: right
}

body.direction-rtl .timeline-list>ul>li:last-child {
    border-right-width: 1px
}

body.direction-rtl .timeline-list>ul>li:first-child {
    border-right-width: 0
}

body.direction-rtl .timeline-list>ul>li .dropdown-menu li a i {
    margin-right: 0;
    margin-left: 10px
}

body.direction-rtl .timeline-list>ul li.largescreen-report {
    border-right: 1px solid #e6eaee
}

@media (max-width:1200px) {
    body.direction-rtl .timeline-list>ul {
        margin-left: auto;
        margin-right: 0
    }
}

body.direction-rtl .typeahead.dropdown-menu {
    left: auto!important;
    right: 16px!important
}

body.direction-rtl .typeahead.dropdown-menu li a {
    padding-left: 15px;
    padding-right: 15px
}

body.direction-rtl .typeahead.dropdown-menu li a img {
    margin-right: 0;
    margin-left: 5px;
    float: right
}

body.direction-rtl .typeahead.dropdown-menu li a .mention_name {
    margin-left: 0;
    margin-right: 5px
}

body.direction-rtl .typeahead.dropdown-menu li a .mention_username {
    padding-left: 0;
    padding-right: 50px
}

body.direction-rtl .verified-badge {
    margin-right: 10px;
    margin-left: 0
}

body.direction-rtl .chat-conversation-list {
    padding-right: 0
}

body.direction-rtl .new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown {
    padding-left: 12px;
    padding-right: 15px
}

body.direction-rtl .new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-left {
    padding-right: 0;
    padding-left: 15px
}

body.direction-rtl .messages-page .panel-body .message-col-4 {
    border-right-width: 0;
    border-left: 1px solid #e6eaee
}

body.direction-rtl .chat-box .chat-box-header .side-left {
    padding-left: 0;
    padding-right: 10px
}

body.direction-rtl .chat-box .chat-box-header .list-inline {
    padding-right: 5px;
    margin-left: 0
}

body.direction-rtl .left-sidebar.owos .following-group {
    padding-right: 0
}

body.direction-rtl .left-sidebar.owos .following-group .list-group-item a .media-left {
    padding-left: 12px;
    padding-right: 0
}

@media (max-width:1660px) {
    body.direction-rtl .left-sidebar.owos {
        direction: ltr
    }
    body.direction-rtl .left-sidebar.owos .following-group {
        padding-right: 0
    }
    body.direction-rtl .left-sidebar.owos .following-group .list-group-item a .media-left {
        padding-left: 0;
        padding-right: 12px
    }
    body.direction-rtl .left-sidebar.owos:hover {
        direction: rtl
    }
    body.direction-rtl .left-sidebar.owos:hover .following-group {
        padding-right: 0
    }
    body.direction-rtl .left-sidebar.owos:hover .following-group .list-group-item a .media-left {
        padding-left: 12px;
        padding-right: 0
    }
}

body.direction-rtl select.form-control {
    direction: ltr!important;
    padding-right: 35px!important;
    padding-left: 12px!important
}

body.direction-rtl .message-conversation .media .media-body .post-text {
    margin-right: 4px
}

body.direction-rtl .event-avatar {
    left: auto;
    right: 18px
}

body.direction-rtl .user-timeline-name.event {
    left: auto;
    right: 90px
}

body.direction-rtl .timeline-list.event>ul {
    margin-right: 0
}

body.direction-rtl .avatar-details.event-settings {
    padding-left: 0!important;
    padding-right: 17px!important
}

body.direction-rtl .form_datetime {
    direction: ltr
}

body.direction-rtl .widget-left-panel .menu-list ul {
    padding-right: 0
}

body.direction-rtl .widget-left-panel .menu-list ul li .menu-btn {
    text-align: right
}

body.direction-rtl .widget-left-panel .menu-list ul li .menu-btn i {
    padding-left: 10px
}

body.direction-rtl .widget-left-panel .menu-list ul li .event-circle {
    float: left
}

body.direction-rtl .widget-left-panel .menu-heading {
    padding-right: 29px
}

body.direction-rtl .widget-left-panel .categotry-list ul {
    padding-right: 26px
}

body.direction-rtl .widget-left-panel .categotry-list ul li .hash-icon {
    margin-left: 5px
}

body.direction-rtl .mini-profile.owos .background .avatar-img {
    right: 8px;
    left: auto
}

body.direction-rtl .mini-profile.owos .avatar-profile .avatar-details {
    padding-right: 80px;
    padding-left: 0
}

body.direction-rtl .mini-profile .background .avatar-img {
    right: 8px;
    left: auto
}

body.direction-rtl .mini-profile .avatar-profile .avatar-details {
    padding-right: 94px;
    padding-left: 0
}

body.direction-rtl .user-follow .socialte-timeline .btn-follow {
    float: left;
    margin-top: 10px
}

body.direction-rtl .post-filters .mini-profile .avatar-profile .avatar-details {
    padding-right: 94px
}

body.direction-rtl .owos-group .list-group-item .list-icon {
    float: right
}

body.direction-rtl .owos-group .list-group-item .list-text {
    margin-right: 60px;
    margin-left: 0
}

body.direction-rtl .owos-form .facebook-input-group .input-group-addon {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

body.direction-rtl .owos-form .facebook-input-group .form-control {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

body.direction-rtl .owos-form .facebook-input-group .form-control:focus {
    border-left-color: #354052
}

body.direction-rtl .panel-heading.panel-settings {
    padding-right: 27px
}

body.direction-rtl .privacy-question .list-group {
    padding-right: 0
}

body.direction-rtl .privacy-question .list-group .list-group-item .form-control {
    float: left!important;
    padding-right: 25px
}

body.direction-rtl .affiliate-settings .affiliate-buttons a i {
    padding-left: 9px
}

body.direction-rtl .affiliate-settings .affliate-followers {
    padding-right: 0
}

body.direction-rtl .user-profile-buttons .btn-profile i {
    margin-left: 10px
}

body.direction-rtl .user-bio-block .bio-list {
    padding-right: 0
}

body.direction-rtl .user-bio-block .bio-list li i {
    margin-left: 5px
}

body.direction-rtl .side-left {
    float: right
}

body.direction-rtl .side-right {
    float: left
}

body.direction-rtl .page-likes .connect-list .connect-link {
    padding-right: 0
}

body.direction-rtl .page-likes .connect-list img {
    margin-left: 20px;
    margin-right: 0!important
}

body.direction-rtl .event-suggested-users .holder .side-left,
body.direction-rtl .group-suggested-users .holder .side-left,
body.direction-rtl .page-suggested-users .holder .side-left {
    padding-right: 0
}

body.direction-rtl .event-suggested-users .holder .side-left a img,
body.direction-rtl .group-suggested-users .holder .side-left a img,
body.direction-rtl .page-suggested-users .holder .side-left a img {
    margin-left: 4px
}

body.direction-rtl .event-suggested-users .holder .side-left a span,
body.direction-rtl .group-suggested-users .holder .side-left a span,
body.direction-rtl .page-suggested-users .holder .side-left a span {
    margin-right: 4px
}

body.direction-rtl .event-suggested-users .holder .side-right,
body.direction-rtl .group-suggested-users .holder .side-right,
body.direction-rtl .page-suggested-users .holder .side-right {
    padding-left: 0
}

body.direction-rtl .messages-page .page-heading {
    float: right
}

body.direction-rtl .messages-page .user-info-bk {
    float: left
}

body.direction-rtl .messages-page .message-box .input-group .form-control {
    border-left: 0!important;
    border-right: 1px solid #dfe3e9!important;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
    border-top-right-radius: 4px!important;
    border-bottom-right-radius: 4px!important
}

body.direction-rtl .messages-page .message-box .input-group .input-group-btn .btn {
    border-right: 0!important;
    border-left: 1px solid #dfe3e9!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    border-top-left-radius: 4px!important;
    border-bottom-left-radius: 4px!important
}

body.direction-rtl .messages-page .message-box .coversation-tree .left-side {
    float: right!important
}

body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread,
body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation,
body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation .post-list {
    padding-right: 0
}

body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation .post-list .media-left {
    padding-left: 10px;
    padding-right: 0
}

body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation .post-list .media-body {
    padding-right: 10px;
    padding-left: 0
}

body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation .post-list .media-body .media-heading .text-muted {
    float: left;
    margin-left: 11px;
    margin-right: 0
}

body.direction-rtl .messages-page .message-box .coversations-list {
    padding-right: 0
}

body.direction-rtl .messages-page .message-box .coversations-list .message-holder.active {
    border-right: 3px solid #e06a26!important;
    border-left: transparent
}

body.direction-rtl .messages-page .message-box .coversations-list .message-holder a .post-list {
    padding-right: 8px
}

body.direction-rtl .messages-page .message-box .coversations-list .message-holder a .post-list .media-left {
    padding-left: 10px;
    padding-right: 0
}

body.direction-rtl .messages-page .message-box .coversations-list .message-holder a .post-list .media-body {
    padding-right: 5px;
    padding-left: 0
}

body.direction-rtl .messages-page .new-message .form-control {
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0!important
}

body.direction-rtl .messages-page .new-message .input-group-btn .btn {
    border-radius: 0
}

body.direction-rtl .owos-form .form-group .radio label {
    margin-right: 20px
}

body.direction-rtl .owos-form .form-group .radio label input {
    margin-right: -20px
}

body.direction-rtl .widget-pictures .side-left {
    padding-right: 17px;
    padding-left: 0
}

body.direction-rtl .widget-pictures .side-right {
    padding-left: 17px
}

body.direction-rtl .owos-form .form-control {
    padding-left: 35px;
    padding-right: 12px!important
}

body.direction-rtl .btn-rtl {
    float: left
}

body.direction-rtl .announcement-container table thead tr th {
    text-align: right
}

body.direction-rtl .announcement-container table tbody tr td ul {
    padding-right: 0
}

body.direction-rtl .manage-table table thead tr th {
    text-align: right
}

body.direction-rtl .manage-table table tbody td ul {
    padding-right: 0
}
.res_mar_algn{
    margin-top: 3px !important;

}
.resp_about_group p,.resp_about_group ul ,.resp_about_group li{

    word-wrap: break-word;
}
#navbar-search {
    height: 40px !important;
    padding-top: 0!important;
    margin-top: -6px!important;
}

@media (max-width:1200px) {
    .event-col-left-3 {
        width: 100% !important;
    }

  }

.username_shorten{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: auto;
    max-width: 158px;
    display: block;
}

.event_blank_space{
    width: 184px;
    height:54px !important;
}

@media (min-width:1200px) {
    .navbar-search_flick {
        width: 215px !important;
    }
}
.event_head_align{
    margin-left: 8px;
}
.impact_event_head_align{
    margin-left: 4px;
}

.sai_quote_align div p {
    padding: 0 !important;
}

.width_67_per{
    width:67%;
}
.qr_code_aligned{
    margin:0px 280px;
}
@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
    .qr_code_aligned_resp{
        margin:0px 61px;
    }
    .reset_pwd_new{
        width: 66% !important;
    }
    .pnl_bdy_res{
        padding: 0 !important;
    }
    .adm_overflow_y{
        overflow-y: hidden;
        border: 1px solid #ddd;
        width: 98%;
    }

}

.alrt-dng {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

/*increase the size of checkbox*/
.rsvp_inc_checkbox{
    width:20px;
    height:20px;
}
