.txthalflength:focus
{
	border-color: #ed1c24;
    color: #354052;
}
.txthalflength
{
	width: 100px;
	outline:0;
	background: #fff none repeat scroll 0 0;
	border: 1px solid #dfe3e9;
	box-shadow: none !important;
	color: #9a9fa8;
	font-family: Helvetica Neue,sans-serif,Helvetica,Arial;
	font-size: 14px;
	line-height: 25px;
}
.recurring_freq,.Mode-Daily,.Mode-Weekly,.Mode-Monthly,
.Mode-Monthly-Repeat-Month,.Mode-Monthly-Repeat-Week,.Mode-Ends {
  display: none;
}
.recurring,.Mode-Ends{
	width: 600px;
}
.recurring {
	margin-left: 90px;
}
.switchpp {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 30px;
}

.switchpp input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top:20px !important;
  background-color: #C7C4C3;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  content: "";
  height: 25px;
  width: 26px;
  left: 3px;
  bottom: 3px;
  margin-top:20px !important;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #EE6013;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(80px);
  -ms-transform: translateX(80px);
  transform: translateX(80px);
}

/*------ ADDED CSS ---------*/
.on
{
  display: none;
}

.on, .off
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+ .slider .on
{display: block;}

input:checked + .slider .off
{display: none;}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 55%;}

	/* calendar CSS */
.weekdays {
    margin: 5px;
    padding: 2px 0;
    background-color: #ddd;
}

.weekdays li {
    display: inline-block;
    width: 13%;
    color: #666;
    text-align: center;
}

.days {
	margin: 5px;
    padding: 2px 0;
    background: #eee;
}

.days li {
    list-style-type: none;
    display: inline-block;
    width: 13%;
    text-align: center;
    margin-bottom: 2px;
    font-size:10px;
    color: #777;
}

.days li .active {
    padding: 3px;
    background: #1abc9c;
    color: white;
}

.days li .inactive {
    padding: 3px;
    background: #eee;
}
.days li .inactive:hover {
	cursor: pointer;
	font-weight: bold;
	font-size: 12px;
}

.days li .CalHeader {
    padding: 3px;
    background: #bd5011;
    color: white;
	font-weight: bold;
}

.event-recurringflag {
	margin-left: 22px!important;
	margin-top: 10px!important;
}
.eve-text-capatalize {
	text-transform: capitalize!important;
}
.repeat-label {
	width: 90px;
}
/* Add media queries for smaller screens */
@media screen and (max-width:720px) {
    .weekdays li, .days li {width: 13.1%;}
}

@media screen and (max-width: 420px) {
    .weekdays li, .days li {width: 12.5%;}
    .days li .active {padding: 2px;}
}

@media screen and (max-width: 290px) {
    .weekdays li, .days li {width: 12.2%;}
}

	/* calendar CSS */
.btn-skip {
  color: #de6926;
  border-color: #de6926;
  border:2px solid #de6926;
}

.btn-skip:hover {
    border-color: #985f0d;
}

.btn-accept {
  border-color: #2EAD2C;
  border:2px solid #2EAD2C;
	color:#2EAD2C;
	padding:3px;
}
.btn-accept:hover {
    background-color: #2EAD2C;
		color:#fff;
}
.btn-accept-selected {
    background-color: #2EAD2C;
		color:#fff;
}
.btn-decline {
  border-color: #bd5011;
  border:2px solid #bd5011;
	color:#bd5011;
	padding:3px;
}
.btn-decline:hover {
    background-color: #bd5011;
		color:#fff;
}
.btn-decline-selected {
    background-color: #bd5011;
		color:#fff;
}

 /* on off switch on volunteer participate tab */
.participate-onoff-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.participate-onoff-switch input {display:none;}

.participate-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.participate-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .participate-slider {
  background-color: #2EAD2C;
}


input:checked + .participate-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.participate-slider.participate-round {
  border-radius: 30px;
}

.participate-slider.participate-round:before {
  border-radius: 50%;
}
.color-accept {
	text-transform: capitalize;
	color: #2EAD2C;
}
.color-decline {
	text-transform: capitalize;
	color: #bd5011;
}
.color-pending {
	text-transform: capitalize;
	color: #FFA500;
}
.toggle-button-text {
	width: 120px;
	position: absolute;
}
.myProgress {
    width: 65%;
    background-color: grey;
    border-radius: 25px;
}
.myBar {
    width: 0%;
    height: 10px;
    background-color: green;
	  border-radius: 25px;
}
.btn-increase {
		color: #fff;
		background-color: #47a447;
		border-color: #398439;
		width: 50px;
}
.btn-decrease {
	color: #fff;
	background-color: #d9534f;
	border-color: #d43f3a;
	width: 75px;
}
.btn-increase {
		color: #fff;
		background-color: #47a447;
		border-color: #398439;
		width: 30px;
    height: 25px;
    padding-top:5px;
    text-align: center;
}
.btn-decrease {
	color: #fff;
	background-color: #d9534f;
	border-color: #d43f3a;
  width: 30px;
  height: 25px;
  padding-top:5px;
  text-align: center;
}

.owos-event-login-btn {
    height: 30px;
    margin-top: 5px;
    border: 2px solid #fff;
    background-color: #a4430c;
		color:#fff;
    font-size: 14px;
    padding: 0px 10px;
}

.owos-event-login-btn:hover {
    background: #863100;
}

.txthalflength:focus
{
	border-color: #ed1c24;
    color: #354052;
}
.txthalflength
{
	width: 100px;
	outline:0;
	background: #fff none repeat scroll 0 0;
	border: 1px solid #dfe3e9;
	box-shadow: none !important;
	color: #9a9fa8;
	font-family: Helvetica Neue,sans-serif,Helvetica,Arial;
	font-size: 14px;
	line-height: 25px;
}
.txthalflength-noborder:focus
{
	border: 1px solid #ed1c24;
}
.txthalflength-noborder
{
	width: 100px;
	outline:0;
	background: #fff none repeat scroll 0 0;
	border: 0px solid #dfe3e9;
	box-shadow: none !important;
	color: #9a9fa8;
	font-family: Helvetica Neue,sans-serif,Helvetica,Arial;
	font-size: 14px;
	line-height: 25px;
}

.no-display
{
	display: none;
}
.txthalf-half-length
{
	width: 50px;
	outline:0;
	background: #fff none repeat scroll 0 0;
	border: 1px solid #dfe3e9;
	box-shadow: none !important;
	color: #9a9fa8;
	font-family: Helvetica Neue,sans-serif,Helvetica,Arial;
	font-size: 14px;
	line-height: 25px;
}

.txthalf-half-length:focus
{
	border-color: #ed1c24;
    color: #354052;
}
.txthalf-half-length-noborder:focus
{
	border: 1px solid #ed1c24;
}
.txthalf-half-length-noborder
{
	width: 50px;
	outline:0;
	background: #fff none repeat scroll 0 0;
	border: 1px solid #dfe3e9;
	box-shadow: none !important;
	color: #9a9fa8;
	font-family: Helvetica Neue,sans-serif,Helvetica,Arial;
	font-size: 14px;
	line-height: 25px;
}

/* Below are volunteering page related styles */


.slider,
.slidervertical {
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	z-index: 100;
}
.slidershell {
	border: 0 none;
	position: relative;
	left: 0px;
	top: 0px;
	overflow: visible;
}

.slidertrack {
	border: 2px outset #666;
	border-radius: 8px;
	position: absolute;
}
.sliderfill {
	border: 2px solid #00767f;
	border-radius: 8px;
	position: absolute;
	opacity: 0.2;
	pointer-events: none;
	background: #00767f;
	background: linear-gradient(to left,#ff5808, #ee9);
}

.sliderthumb {
	width: 40px;
	height: 40px;
	background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/358203/thumb.png');
	background-size: 40px 40px;
	background-position: 0px 0px;
	background-repeat: no-repeat;
	background-color: transparent;
	position: absolute;
	left: 0px;
	top: 0px;
	border: 0 none;
	padding: 0px;
	margin: 0px;
	text-align: center;
	pointer-events: none;
}
/* .slidervalue can be styled as you see fit */

.slidervalue {
	width: 40px;
	height: 20px;
	line-height: 40px;
	color: #fff;
	font-family: helvetica, sans-serif;
	font-size: 18px;
	position: absolute;
	left: 0px;
	top: 0px;
	border: 0 none;
	padding: 0px;
	margin: 0px;
	text-align: center;
	pointer-events: none;
}
/* First we have to make transparent all the default elements of the range input.
		We use color:transparent to eliminate the default tick marks in IE. */

input[type=range]::-ms-track {
	width: 100%;
	height: 100%;
	-webkit-appearance: none;
	margin: 0px;
	padding: 0px;
	border: 0 none;
	background: transparent;
	color: transparent;
	overflow: visible;
}

input[type=range]::-moz-range-track {
	width: 100%;
	height: 100%;
	-moz-appearance: none;
	margin: 0px;
	padding: 0px;
	border: 0 none;
	background: transparent;
	color: transparent;
	overflow: visible;
}

input[type=range] {
	width: 100%;
	height: 0%;
	-webkit-appearance: none;
	margin: 0px;
	padding: 0px;
	border: 0 none;
	background: transparent;
	color: transparent;
	overflow: visible;
}
input[type=range].slidervertical {
	-webkit-appearance: slider-vertical;
	writing-mode: bt-lr;
	opacity: 0.01;
}

input[type=range]:focus::-webkit-slider-runnable-track {
	background: transparent;
	border: transparent;
}

input[type=range]:focus {
	outline: none;
}
input[type=range]::-ms-thumb {
	width: 40px;
	height: 40px;
	border-radius: 0px;
	border: 0 none;
	background: transparent;
}

input[type=range]::-moz-range-thumb {
	width: 40px;
	height: 40px;
	border-radius: 0px;
	border: 0 none;
	background: transparent;
}

input[type=range]::-webkit-slider-thumb {
	width: 40px;
	height: 40px;
	border-radius: 0px;
	border: 0 none;
	background: transparent;
	-webkit-appearance: none;
}
input[type=range]::-ms-fill-lower {
	background: transparent;
	border: 0 none;
}

input[type=range]::-ms-fill-upper {
	background: transparent;
	border: 0 none;
}

input[type=range]::-ms-tooltip {
	display: none;
}

/*
	 Range slider Css
*/
.btncustamize {
		display: inline-block;
		margin-bottom: 0;

		text-align: center;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		background-image: none;
		border: 1px solid transparent;
		white-space: nowrap;
	 padding: 5px;
		font-size: 14px;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
}
.itemsDesc {
 font-weight: bold;
 text-align: center;
 font-size: 12px;

}
.waiting-approval {
	font-size: 11px;
	font-weight: normal;
}
.social-text {
	font-size: 12px;
	font-weight: lighter;
}
.social-text-strong {
	font-size: 12px;
	font-weight: bold;
}
.social-text a {
	font-size: 12px;
	font-weight: lighter;
	color: #354052;
}
.bgwhite {
 background:#fff
}
.fullhouse {
	color:#C6C0C0;
	pointer-events: none;
}
.fontcolorcount {
	color: #bd5011;
}
.selectedtab {
	background: #de6926!important;
	color:#fff!important;
}
.form-control-customize {
	style=font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
	font-size: 12px;
	letter-spacing: .3px;
	color: #7f8fa4;
	margin-bottom: 6px;
	line-height: 22px;
}
.switchppYesNo {
	position: relative;
	display: inline-block;
	width: 65px;
	height: 25px;
}

.switchppYesNo input {display:none;}

.sliderYesNo {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #C7C4C3;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .sliderYesNo {
	background-color: #EE6013;
}

input:focus + .sliderYesNo {
	box-shadow: 0 0 1px #2196F3;
}
input:checked+ .sliderYesNo .on
{display: block;}

input:checked + .sliderYesNo .off
{display: none;}

.sliderYesNo.round {
	border-radius: 35px;
}
.label-only-required:after {
    content: '*'!important;
    color: #e76877;
    font-size: 15px;
    margin-left: 3px
}
.grayfade
{
  	color:#C6C0C0!important;
  	pointer-events: none;
}
.impact-thumb img {
	min-height:auto;
}
.impact-thumb{
    box-shadow:none;
    min-height: auto;
}
.txt-noborder
{
       width: 300px;
       outline:0;
       background: #fff none repeat scroll 0 0;
       border: 0px solid #dfe3e9;
       box-shadow: none !important;
       color: #9a9fa8;
       font-family: Helvetica Neue,sans-serif,Helvetica,Arial;
       font-size: 14px;
       line-height: 20px;
}
.txt-noborder:focus
{
       border: 0.5px solid #ed1c24;
}
.event-notify-members {
	margin-top: 0px !important;
}
span.new-user-no-link
{
	color: #fff;
	font-size: 13px;
	float: left;
	margin-top: -13px;
}
.delete-event-org
{
	    color: red!important;
}
.save-cust-name
{
		color:green!important;
}
.notifyEventUser
{
		background-color:#b84808;
		margin-left:6px;
		color:white;
		cursor:pointer;
}
.wym-swami-pic
{
	height:150px;
	width: 165px;
}
.wym-quotes
{
	overflow:auto;
	height:610px;
}
.event-copy-link-icon{
	font-size:23px;
	vertical-align:middle;
}
.btn-success1 {
		color: #000;
		background-color: white;
		border-color: #9e420e;
		width: 170px;

}
.btn-success2 {
		color: #fff;
		background-color: #9e420e;
}
.tooltip-msg-manage
{
	font-size: 22px;
	color: #A3A6AB;
	cursor: pointer;
}
.tooltip-msg-manage:hover
{
	color: #45669D;
}
.tootip-div
{
	font-size: 12px;
	font-style: italic;
	display: none;
	float:left;
}
