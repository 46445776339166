.modal-content {
    margin-top: 170px
}

.modal-backdrop {
    background-color: #131820;
    position: fixed!important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1039!important
}

.modal-backdrop.in {
    opacity: .9;
    filter: alpha(opacity=90)
}

.modal-body label,
.modal-body select {
    color: #354052;
    text-transform: capitalize
}

.modal-body .seperator {
    background: #e6eaee;
    height: 1px;
    margin-bottom: 16px
}

.modal-footer {
    background: #f0f3f8;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 20px;
    padding-right: 20px
}

.user-successfully-added .modal-body {
    padding: 0
}

.user-successfully-added .modal-body .user-info {
    border-top-right-radius: 4px!important;
    border-top-left-radius: 4px!important;
    height: 280px;
    display: block;
    width: 100%;
    color: #fff;
    padding-top: 53px;
    text-align: center;
    background: #2ea1f8
}

.user-successfully-added .modal-body .user-info .close-modal i {
    color: #70bbfb;
    position: absolute;
    top: 30px;
    right: 30px
}

.user-successfully-added .modal-body .user-info img.user-avatar {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: block;
    margin-bottom: 18px
}

.user-successfully-added .modal-body .user-info .user-name {
    font-size: 18px;
    color: #fff;
    text-transform: capitalize
}

.user-successfully-added .modal-body .user-info .user-designation {
    font-size: 14px;
    color: #d1dded;
    font-weight: 300
}

.user-successfully-added .modal-body .user-message {
    padding: 25px 50px;
    text-align: center
}

.user-successfully-added .modal-body .user-message h3 {
    font-size: 18px;
    color: #7f8fa4;
    font-weight: 600
}

.user-successfully-added .modal-body .user-message .user-message-description {
    font-size: 14px;
    color: #354052;
    line-height: 20px;
    font-weight: 300
}

.feedback-modal .modal-body {
    padding: 0
}

.feedback-modal .modal-body .user-info {
    text-align: center;
    background: #2ea1f8;
    border-top-right-radius: 4px!important;
    border-top-left-radius: 4px!important;
    height: 110px;
    font-weight: 600
}

.feedback-modal .modal-body .user-info .user-avatar {
    width: 90px;
    height: 90px;
    border: 3px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    margin-top: -55px;
    position: absolute;
    left: 0;
    right: 0
}

.feedback-modal .modal-body .user-info .support-heading {
    padding-top: 50px;
    font-size: 18px;
    color: #fff;
    text-transform: capitalize
}

.feedback-modal .modal-body .user-info .support-heading .close-modal {
    position: absolute;
    top: 17px;
    right: 17px;
    color: #70bbfb
}

.feedback-modal .modal-body .user-info .user-with {
    opacity: .8;
    font-size: 14px;
    color: #fff
}

.feedback-modal .modal-body .feedback-form {
    padding: 20px
}

.feedback-modal .modal-body .feedback-form i.fa-info-circle {
    margin-left: 10px;
    font-size: 16px;
    color: #d7d7d7
}

.feedback-modal .modal-body .feedback-form .form-heading {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #414f64;
    margin-bottom: 15px
}

.feedback-modal .modal-body .feedback-form .feedback-rate {
    text-align: center;
    margin-bottom: 20px
}

.feedback-modal .modal-body .feedback-form .feedback-rate li {
    margin-right: 15px
}

.feedback-modal .modal-body .feedback-form .feedback-rate li a {
    font-size: 14px;
    color: #8e939d;
    text-decoration: none;
    width: 35px;
    display: inline-block;
    height: 35px;
    text-align: center;
    border: 2px solid #d7d7d7;
    border-radius: 50%;
    line-height: 31px
}

.feedback-modal .modal-body .feedback-form .feedback-rate li.active a,
.feedback-modal .modal-body .feedback-form .feedback-rate li:hover a {
    color: #fff;
    background: #36af47;
    border-color: transparent
}

.feedback-modal .modal-body .feedback-form textarea {
    font-size: 14px;
    color: #354052;
    letter-spacing: .3px;
    line-height: 19px
}

.feedback-modal .modal-footer a {
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 600
}

.billing-modal .modal-body {
    padding: 0
}

.billing-modal .modal-body .tab-navigation {
    background: #2ea1f8;
    height: 96px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.billing-modal .modal-body .tab-navigation .list-inline {
    margin: 0
}

.billing-modal .modal-body .tab-navigation .list-inline li {
    height: 96px;
    width: 25%;
    float: left;
    text-align: center;
    margin: 0!important
}

.billing-modal .modal-body .tab-navigation .list-inline li a {
    margin: 0!important;
    color: #b5d7f0;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    text-decoration: none
}

.billing-modal .modal-body .tab-navigation .list-inline li a i {
    font-size: 40px;
    margin-top: 15px;
    margin-bottom: 10px
}

.billing-modal .modal-body .tab-navigation .list-inline li.active,
.billing-modal .modal-body .tab-navigation .list-inline li:hover {
    background-color: #1d8ce0
}

.billing-modal .modal-body .tab-navigation .list-inline li.active a,
.billing-modal .modal-body .tab-navigation .list-inline li:hover a {
    color: #fff
}

.billing-modal .modal-body .billing-content .mentions-holder {
    padding: 20px
}

.billing-modal .modal-body .billing-content h5 {
    text-transform: capitalize;
    margin-bottom: 0
}

.billing-modal .modal-body .billing-content p {
    font-size: 12px;
    color: #7f8fa4
}

.billing-modal .modal-body .billing-content .limit-stats span {
    color: #bd5011!important
}

.billing-modal .modal-body .billing-content .progress-stats {
    margin-top: 10px
}

.billing-modal .modal-body .billing-content .change-plan {
    margin-top: 15px
}

.billing-modal .modal-body .billing-content .payment-card {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px
}

.billing-modal .modal-body .billing-content .payment-card i.fa-warning {
    color: #ed1c24;
    margin-left: 5px
}

.billing-modal .modal-body .billing-content .payment-card img {
    width: 28px;
    height: auto;
    margin-right: 5px
}

.billing-modal .modal-body .billing-content .subscription-cancel {
    padding-left: 20px;
    padding-right: 20px
}

.billing-modal .modal-body .billing-content .subscription-cancel .cancel-notes,
.billing-modal .modal-body .billing-content .subscription-cancel .expiring-notes {
    padding: 15px;
    padding-bottom: 5px;
    margin-bottom: 20px
}

.billing-modal .modal-body .billing-content .subscription-cancel .cancel-notes a,
.billing-modal .modal-body .billing-content .subscription-cancel .expiring-notes a {
    text-decoration: none!important
}

.billing-modal .modal-body .billing-content .subscription-cancel .expiring-notes {
    background: #fffdfd;
    border: 1px solid #df0c0c;
}

.billing-modal .modal-body .billing-content .subscription-cancel .cancel-notes {
    background: #f0f3f8;
    border: 1px solid #dce1ea;
}

.billing-modal .modal-body .billing-content .subscription-cancel .subject {
    font-size: 14px;
    color: #354052;
    line-height: 19px;
    font-weight: 600
}

.billing-modal .modal-body .billing-content .subscription-cancel p {
    opacity: .59;
    font-family: SourceSansPro-Regular;
    font-size: 14px;
    color: #414f64;
    line-height: 19px
}

.setting-modal .modal-dialog {
    width: 630px
}

.setting-modal .modal-body {
    padding: 0
}

.setting-modal .modal-body .settings-block {
    text-transform: capitalize;
    background: #2ea1f8;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    letter-spacing: .4px
}

.setting-modal .modal-body .settings-block .close-modal i {
    color: #70bbfb
}

.setting-modal .modal-body .settings-list {
    background-color: #222c3c;
    margin-left: 0;
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.setting-modal .modal-body .settings-list li {
    margin-left: 30px;
    margin-right: 30px;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 3px solid transparent
}

.setting-modal .modal-body .settings-list li:first-child {
    margin-left: 10px
}

.setting-modal .modal-body .settings-list li:last-child {
    margin-right: 7px
}

.setting-modal .modal-body .settings-list li a {
    color: #848c98;
    padding: 0;
    padding-top: 14px;
    padding-bottom: 11px;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 400
}

.setting-modal .modal-body .settings-list li.active {
    border-bottom: 3px solid #2ea1f8
}

.setting-modal .modal-body .settings-list li.active a {
    color: #fff
}

.setting-modal .modal-body .settings-content {
    padding: 0;
    margin-bottom: 0;
    border: 0
}

.setting-modal .modal-body .list-group {
    margin-bottom: 0
}

.setting-modal .modal-body .list-group .list-group-item {
    border: 1px solid #f2f3f5;
    padding: 16px 30px;
    cursor: pointer;
    font-size: 14px;
    color: #354052;
    border-left: 0;
    border-right: 0
}

.setting-modal .modal-body .list-group .list-group-item .text-toggle {
    opacity: .6
}

.setting-modal .modal-body .list-group .list-group-item:hover {
    color: #354052!important
}

.setting-modal .settings-footer {
    padding: 12px 30px
}

.setting-modal .settings-footer .btn-send {
    background: #39b54a;
    border: 1px solid #249533;
    color: #fff;
    font-size: 14px;
    font-family: Source Sans Pro, sans-serif
}

.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden
}

.cmn-toggle+label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

input.cmn-toggle-round+label {
    padding: 2px;
    width: 48px;
    height: 24px;
    background-color: #ddd;
}

input.cmn-toggle-round+label:after,
input.cmn-toggle-round+label:before {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: ""
}

input.cmn-toggle-round+label:before {
    right: 1px;
    background-color: #f1f1f1;
    transition: background .4s
}

input.cmn-toggle-round+label:after {
    width: 24px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    transition: margin .4s
}

input.cmn-toggle-round:checked+label:before {
    background-color: #36af47
}

input.cmn-toggle-round:checked+label:after {
    margin-left: 20px
}

input.cmn-toggle-round-flat+label {
    padding: 2px;
    width: 48px;
    height: 24px;
    background-color: #ddd;
    transition: background .4s
}

input.cmn-toggle-round-flat+label:after,
input.cmn-toggle-round-flat+label:before {
    display: block;
    position: absolute;
    content: ""
}

input.cmn-toggle-round-flat+label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #fff;
    transition: background .4s
}

input.cmn-toggle-round-flat+label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 15px;
    background-color: #ddd;
    transition: margin .4s, background .4s
}

input.cmn-toggle-round-flat:checked+label {
    background-color: #36af47
}

input.cmn-toggle-round-flat:checked+label:after {
    margin-left: 20px;
    background-color: #36af47
}

input.cmn-toggle-yes-no+label {
    padding: 2px;
    width: 48px;
    height: 24px
}

input.cmn-toggle-yes-no+label:after,
input.cmn-toggle-yes-no+label:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #fff;
    font-family: Roboto Slab, serif;
    font-size: 20px;
    text-align: center;
    line-height: 20px
}

input.cmn-toggle-yes-no+label:before {
    background-color: red;
    content: attr(data-off);
    transition: transform .5s;
    backface-visibility: hidden
}

input.cmn-toggle-yes-no+label:after {
    background-color: #36af47;
    content: attr(data-on);
    transition: transform .5s;
    transform: rotateY(180deg);
    backface-visibility: hidden
}

input.cmn-toggle-yes-no:checked+label:before {
    transform: rotateY(180deg)
}

input.cmn-toggle-yes-no:checked+label:after {
    transform: rotateY(0)
}

.assign-modal .modal-dialog {
    width: 960px
}

.assign-modal .modal-body {
    padding: 0
}

.assign-modal .modal-body .assign-header {
    background: #2ea1f8;
    text-transform: capitalize;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    letter-spacing: .4px
}

.assign-modal .modal-body .assign-header .close-modal i {
    color: #70bbfb
}

.assign-modal .modal-body .table>tbody>tr>td:first-child,
.assign-modal .modal-body .table>thead>tr>th:first-child {
    padding-left: 30px
}

.assign-modal .modal-body .team-members-table {
    margin-bottom: 0
}

.assign-modal .modal-body .team-members-table thead .table-heading {
    color: #7f8fa4;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    background-color: #f0f3f8
}

.assign-modal .modal-body .team-members-table thead .table-heading th {
    font-weight: 400
}

.assign-modal .modal-body .team-members-table tbody .members-progress {
    cursor: pointer
}

.assign-modal .modal-body .team-members-table tbody .members-progress:hover {
    background-color: #1a91eb;
    color: #fff
}

.assign-modal .modal-body .team-members-table tbody .members-progress:hover .progress {
    background: #2573a7
}

.assign-modal .modal-body .team-members-table tbody .members-progress:hover .progress .progress-bar {
    background: #fff
}

.assign-modal .modal-body .team-members-table tbody .members-progress:hover .last-activity,
.assign-modal .modal-body .team-members-table tbody .members-progress:hover .user-image .user-name,
.assign-modal .modal-body .team-members-table tbody .members-progress:hover span,
.assign-modal .modal-body .team-members-table tbody .members-progress:hover span a {
    color: #fff
}

.assign-modal .modal-body .team-members-table tbody .members-progress .user-image,
.assign-modal .modal-body .team-members-table tbody .members-progress .user-image img {
    width: 40px;
    height: 40px
}

.assign-modal .modal-body .team-members-table tbody .members-progress .user-image .user-name {
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #354052;
    font-weight: 400;
    margin-left: 10px
}

.assign-modal .modal-body .team-members-table tbody .members-progress .progress {
    margin-bottom: 0;
    margin-top: 9px;
    width: 50%;
    float: left;
    height: 4px;
    background: #f0f3f8;
    margin-right: 10px
}

.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user1-progress {
    background: #ff7800
}

.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user2-progress {
    background: #2fabc1
}

.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user3-progress {
    background: #ff7800
}

.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user4-progress {
    background: #39b54a
}

.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user5-progress {
    background: #ff7800
}

.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user6-progress {
    background: #2fabc1
}

.assign-modal .modal-body .team-members-table tbody .members-progress span {
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    color: #354052;
    font-weight: 400
}

.assign-modal .modal-body .team-members-table tbody .members-progress span a {
    text-decoration: none
}

.assign-modal .modal-body .team-members-table tbody .members-progress .last-activity {
    color: #7f8fa4;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.achievement-modal .modal-body {
    padding: 0
}

.achievement-modal .modal-body .achievement-header {
    background: #2ea1f8;
    text-transform: capitalize;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    letter-spacing: .4px
}

.achievement-modal .modal-body .achievement-header .close-modal i {
    color: #70bbfb
}

.achievement-modal .modal-body .awards {
    background-color: #222c3c;
    padding: 25px 30px 20px;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    color: #fff
}

.achievement-modal .modal-body .table>tbody>tr>td {
    padding-left: 30px;
    padding-top: 23px;
    padding-bottom: 22px
}

.achievement-modal .modal-body .achievements {
    max-height: 485px
}

.achievement-modal .modal-body .achievements-table {
    margin-bottom: 0
}

.achievement-modal .modal-body .achievements-table .achievements-list {
    padding: 23px 30px
}

.achievement-modal .modal-body .achievements-table .achievements-list .medal {
    width: 50px;
    height: 50px
}

.achievement-modal .modal-body .achievements-table .achievements-list .medal-title {
    text-transform: uppercase;
    color: #9299a7;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    padding-left: 20px
}

.achievement-modal .modal-body .achievements-table .achievements-list .medal-title .text-muted {
    color: #354052;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    text-transform: none
}

.achievement-modal .modal-body .achievements-table .achievements-list .share-progress .btn-share {
    background: #de6926;
    border: 1px solid #df6708;
    color: #fff;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 7px 27px
}

.achievement-modal .modal-body .achievements-table .achievements-list .share-progress .help-text {
    text-transform: uppercase;
    font-size: 12px;
    color: #9299a7;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400
}

.achievement-modal .modal-body .achievements-table .achievements-list .share-progress .progress {
    background: #f0f3f8;
    height: 6px;
    width: 92px;
    margin: 0
}

.achievement-modal .modal-body .achievements-table .achievements-list .share-progress .progress .progress-bar {
    background: #ff7800;
}

.onboarding-modal .modal-dialog {
    width: 770px
}

.onboarding-modal .modal-body {
    padding: 0
}

.onboarding-modal .modal-body .onboard-header {
    background: #2ea1f8;
    text-transform: capitalize;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    letter-spacing: .4px
}

.onboarding-modal .modal-body .onboard-header .close-modal i {
    color: #70bbfb
}

.onboarding-modal .modal-body .app-process {
    background: #222c3c;
    margin-bottom: 0;
    margin-left: 0
}

.onboarding-modal .modal-body .app-process .no-style {
    color: #fff;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding-left: 32px;
    padding-right: 6px;
    line-height: 60px
}

.onboarding-modal .modal-body .app-process li {
    cursor: pointer
}

.onboarding-modal .modal-body .app-process li a {
    padding: 16px 15px
}

.onboarding-modal .modal-body .app-process li .steps {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    background: #313c4d;
    text-decoration: none;
    width: 28px;
    display: inline-block;
    height: 28px;
    text-align: center;
    border: 1px solid #313c4d;
    border-radius: 50%;
    margin-right: 10px;
    line-height: 28px
}

.onboarding-modal .modal-body .app-process li .completed {
    color: #848c98
}

.onboarding-modal .modal-body .app-process li span {
    color: #848c98;
    cursor: pointer
}

.onboarding-modal .modal-body .app-process li.active .steps {
    color: #fff;
    background-color: #de6926;
    border: 0 solid transparent;
    border-top: 1px solid transparent!important;
    border-left: 1px solid transparent!important;
    border-right: 1px solid transparent!important;
    background-image: none
}

.onboarding-modal .modal-body .app-process li.active span {
    color: #fff
}

.onboarding-modal .modal-body .app-process li.active .app-list {
    color: #fff;
    cursor: pointer
}

.onboarding-modal .modal-body .app-structure {
    padding: 37px 30px 0;
    margin-bottom: 0;
    border: 0 solid transparent
}

.onboarding-modal .modal-body .app-structure .app-heading {
    color: #354052;
    font-size: 18px;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

.onboarding-modal .modal-body .app-structure .app-content {
    font-size: 14px;
    color: #7f8fa4;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    margin: 0 auto;
    margin-top: 14px;
    padding-left: 78px;
    padding-right: 40px
}

.onboarding-modal .modal-body .app-structure .screen-image {
    margin-top: 33px
}

.onboarding-modal .modal-body .app-structure .skip-next .skip {
    margin-top: -40px
}

.onboarding-modal .modal-body .app-structure .skip-next .skip a {
    color: #7383a1;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    text-decoration: none
}

.onboarding-modal .modal-body .app-structure .skip-next .btn-next {
    margin-top: -45px;
    margin-right: -10px
}

.magnify {
    z-index: 20;
    border: 3px solid #999;
    position: relative;
    width: 900px;
    height: 640px;
    margin: 0 auto;
    overflow: hidden;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none
}

.magnify img {
    display: block
}

.element_to_magnify {
    position: relative;
    width: 900px;
    height: 640px;
    overflow: hidden
}

.element_to_magnify a {
    display: block;
    position: absolute;
    top: 80px;
    right: 80px;
    background-color: hsla(0, 0%, 78%, .25);
    padding: 4px;
    font-size: 18px;
    color: #fff
}

.element_to_magnify img {
    width: 100%;
    height: auto
}

.magnify_glass {
    z-index: 300;
    position: absolute;
    width: 200px;
    height: 200px;
    cursor: move
}

.mg_ring {
    pointer-events: none;
    background: none;
    -ms-box-shadow: inset 0 0 10px #000;
    box-shadow: inset 0 0 10px #000;
    border: 4px solid #ccc;
    border-radius: 50%;
    z-index: 20
}

.mg_ring,
.mg_zone {
    position: absolute;
    width: 100%;
    height: 100%
}

.mg_zone {
    border-radius: 50%;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: hidden
}

.pm_btn {
    border: 4px solid #ccc;
    z-index: 30;
    color: #333;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%
}

.pm_btn h2 {
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer
}

.minus {
    right: -24px;
    bottom: 30px
}

.plus {
    right: 0;
    bottom: -5px
}

.button {
    width: 30px;
    height: 30px;
    padding-top: 5px;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(50, 50, 50, .5);
    position: absolute;
    text-indent: -999em;
    border: 1px solid #000
}

.connectnewservice-modal .modal-body {
    padding: 0
}

.connectnewservice-modal .modal-body .service-header {
    background: #2ea1f8;
    text-transform: capitalize;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    letter-spacing: .4px
}

.connectnewservice-modal .modal-body .service-header .close-modal i {
    color: #70bbfb
}

.connectnewservice-modal .modal-body .authentication {
    padding: 30px
}

.connectnewservice-modal .modal-body .authentication .authenticate {
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize
}

.connectnewservice-modal .modal-body .authentication .authenticate .authentication-form {
    background: #fff;
    border: 1px solid #dfe3e9;
    font-size: 14px;
    color: #354052
}

.connectnewservice-modal .modal-body .authentication .connect-through .btn-payment {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #f2f4f7
}

.connectnewservice-modal .modal-body .authentication .connect-through .payment-form {
    background: #f2f4f7;
    border: 1px solid #ced0da;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    box-shadow: none
}

.connectnewservice-modal .modal-body .authentication .account-info {
    padding: 20px;
    margin-top: 25px;
    background: #f8fafc;
    border: 1px solid #dfe3e9;
    margin-bottom: 20px
}

.connectnewservice-modal .modal-body .authentication .account-info .info {
    color: #333c48;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px
}

.topbarsettings-modal .modal-dialog {
    width: 770px
}

.topbarsettings-modal .modal-body {
    padding: 0
}

.topbarsettings-modal .modal-body .topbar-header {
    padding: 20px 30px;
    background-color: #2ea1f8
}

.topbarsettings-modal .modal-body .topbar-header .top-settings {
    margin-bottom: 0
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li:first-child {
    padding-left: 0
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li:last-child {
    padding-right: 0
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li a {
    padding: 0;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    width: 40px;
    display: inline-block;
    height: 40px;
    text-align: center;
    border: 2px solid #1d8ce0;
    border-radius: 50%;
    line-height: 35px;
    margin-right: 0;
    background: #1d8ce0
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li .settings {
    font-size: 14px;
    margin-top: 10px;
    color: #fff;
    text-transform: capitalize;
    cursor: pointer
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li:hover a {
    background: #fff;
    color: #2ea1f8;
    border: 2px solid #2ea1f8
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li:hover a .settings {
    color: #fff
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li.active a {
    background: #fff;
    color: #2ea1f8;
    border: 2px solid #2ea1f8
}

.topbarsettings-modal .modal-body .topbar-header .top-settings li.active a .settings {
    color: #fff
}

.topbarsettings-modal .modal-body .top-settings-content {
    padding: 0;
    margin-bottom: 0;
    border: 0
}

.topbarsettings-modal .modal-body .toggle-settings-list {
    margin-bottom: 0
}

.topbarsettings-modal .modal-body .toggle-settings-list .list-group-item {
    border: 1px solid #f2f3f5;
    padding: 19px 30px;
    font-size: 14px;
    color: #354052;
    border-left: 0;
    border-right: 0
}

.topbarsettings-modal .modal-body .toggle-settings-list .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.darkmessage-modal {
    font-family: Source Sans Pro, sans-serif
}

.darkmessage-modal .dark-body {
    background: #222c3c;
    padding: 30px 30px 17px
}

.darkmessage-modal .dark-body .media .media-left {
    width: 60px;
    height: 60px
}

.darkmessage-modal .dark-body .media .media-body {
    padding-left: 10px;
    padding-top: 6px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    margin-left: 15px
}

.darkmessage-modal .dark-body .media .media-body .media-heading {
    font-size: 24px;
    color: #fff;
    font-weight: 400
}

.darkmessage-modal .dark-body .media .media-body .dark-close {
    margin-top: -34px;
    color: #636e7d
}

.darkmessage-modal .dark-body .media .media-body .circle {
    width: 12px;
    height: 12px;
    border: 2px solid #1a91eb;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
    margin-right: 10px
}

.darkmessage-modal .dark-body .select {
    margin-top: 25px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #273142
}

.darkmessage-modal .dark-body .hello,
.darkmessage-modal .dark-body .select .selection {
    border: 1px solid #313d4f;
    background: #273142;
    color: #fff;
    font-size: 14px;
    font-weight: 400
}

.darkmessage-modal .dark-body .hello {
    margin-top: 15px
}

.darkmessage-modal .dark-body .hello::-webkit-input-placeholder {
    color: #7c8ca5
}

.darkmessage-modal .dark-body .hello:-moz-placeholder,
.darkmessage-modal .dark-body .hello::-moz-placeholder {
    color: #7c8ca5
}

.darkmessage-modal .dark-body .hello:-ms-input-placeholder {
    color: #7c8ca5
}

.darkmessage-modal .dark-body .message-box {
    border: 1px solid #313d4f;
    background: #273142;
    margin-top: 14px;
    color: #fff;
    font-size: 14px;
    font-weight: 400
}

.darkmessage-modal .dark-body .message-box::-webkit-input-placeholder {
    color: #7c8ca5
}

.darkmessage-modal .dark-body .message-box:-moz-placeholder,
.darkmessage-modal .dark-body .message-box::-moz-placeholder {
    color: #7c8ca5
}

.darkmessage-modal .dark-body .message-box:-ms-input-placeholder {
    color: #7c8ca5
}

.darkmessage-modal .dark-footer {
    background: #273142;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: -4px
}

.darkmessage-modal .dark-footer .message {
    font-size: 14px;
    color: #fff;
    font-weight: 400
}

.darkmessage-modal .dark-footer .btn-send {
    background: #de6926;
    color: #fff;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize
}

.darkmessage-modal .dark-footer .check-message {
    line-height: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-transform: none
}

.darkmessage-modal .dark-footer .check-message input[type=checkbox] {
    position: inherit;
    margin-right: 10px
}

.createnew-modal .dark-body {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    background: #222c3c;
    padding: 30px 30px 20px
}

.createnew-modal .dark-body .body-title {
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin-top: 0;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-bottom: 25px
}

.createnew-modal .dark-body .dark-close {
    color: #636e7d;
    font-size: 12px;
    margin-top: 5px
}

.createnew-modal .dark-body .create {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

.createnew-modal .dark-body .create-form {
    border: 1px solid #313d4f;
    background: #273142;
    color: #fff
}

.createnew-modal .dark-body .create-form::-webkit-input-placeholder {
    color: #7c8ca5
}

.createnew-modal .dark-body .create-form:-moz-placeholder,
.createnew-modal .dark-body .create-form::-moz-placeholder {
    color: #7c8ca5
}

.createnew-modal .dark-body .create-form:-ms-input-placeholder {
    color: #7c8ca5
}

.createnew-modal .dark-body .rotate .potrait {
    border: 1px solid #313d4f;
    height: 146px;
    background: #273142;
    text-align: center;
    line-height: 123px;
    cursor: pointer
}

.createnew-modal .dark-body .rotate .potrait .selected {
    display: none;
    position: absolute;
    right: 25px;
    top: -43px
}

.createnew-modal .dark-body .rotate .potrait .selected i {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #fff;
    color: #1bb934;
    font-size: 12px;
    line-height: 18px
}

.createnew-modal .dark-body .rotate .potrait .image-text {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    line-height: 0;
    font-family: Source Sans Pro, sans-serif
}

.createnew-modal .dark-body .rotate .check-potrait {
    position: absolute
}

.createnew-modal .dark-body .rotate .potrait.active {
    background: #1bb934;
    border: 1px solid #1bb934;
}

.createnew-modal .dark-body .rotate .potrait.active .selected {
    display: block
}

.createnew-modal .create-footer {
    text-align: center;
    background: #273142;
    margin-top: -4px;
    padding: 18px 30px
}

.createnew-modal .create-footer .btn-finish {
    background: #de6926;
    font-size: 14px;
    color: #fff;
    font-weight: 400
}

.darksharing-modal .modal-body {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 0
}

.darksharing-modal .modal-body .share-header {
    background-color: #2ea1f8
}

.darksharing-modal .modal-body .share-header .share-list {
    margin-left: 0;
    margin-bottom: 0
}

.darksharing-modal .modal-body .share-header .share-list li {
    padding-left: 35px;
    padding-right: 35px;
    text-align: center;
    border: 2px solid transparent
}

.darksharing-modal .modal-body .share-header .share-list li:first-child {
    padding-left: 35px;
    margin-left: 10px
}

.darksharing-modal .modal-body .share-header .share-list li a {
    font-size: 40px;
    text-decoration: none;
    color: #b5d7f0
}

.darksharing-modal .modal-body .share-header .share-list li a .share-icon {
    font-size: 14px;
    color: #b5d7f0;
    text-align: center;
    text-transform: uppercase
}

.darksharing-modal .modal-body .share-header .share-list li.active {
    border-bottom: 2px solid #fff
}

.darksharing-modal .modal-body .share-header .share-list li.active a,
.darksharing-modal .modal-body .share-header .share-list li.active a .share-icon {
    color: #fff
}

.darksharing-modal .modal-body .sharing-content {
    padding: 0;
    margin-bottom: 0;
    border: 0;
    background: #222c3c;
}

.darksharing-modal .modal-body .sharing-details {
    padding: 30px 30px 25px
}

.darksharing-modal .modal-body .sharing-details .share-title {
    font-size: 21px;
    color: #fff;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-weight: 400;
    margin-top: 0
}

.darksharing-modal .modal-body .sharing-details .share-link {
    margin-top: 22px
}

.darksharing-modal .modal-body .sharing-details .share-link .share {
    font-size: 14px;
    color: #7c8ca5;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

.darksharing-modal .modal-body .sharing-details .share-link .more a {
    font-size: 14px;
    color: #1a91eb;
    font-size: 400;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-decoration: none
}

.darksharing-modal .modal-body .sharing-details .address-group {
    margin-top: 5px;
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0
}

.darksharing-modal .modal-body .sharing-details .address-group .btn-cpy,
.darksharing-modal .modal-body .sharing-details .address-group .share-address {
    background: #222c3c;
    border: 1px solid #313d4f;
    height: 46px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 0;
    border-top-right-radius: 0;
}

.darksharing-modal .modal-body .sharing-details .address-group .btn-cpy {
    width: 108px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.darksharing-modal .modal-body .sharing-details .url-bar {
    background: #273142;
    height: 46px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    border: 1px solid #313d4f;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-bottom: 25px
}

.darksharing-modal .modal-body .sharing-details .url-bar::-webkit-input-placeholder {
    color: #7c8ca5
}

.darksharing-modal .modal-body .sharing-details .url-bar:-moz-placeholder,
.darksharing-modal .modal-body .sharing-details .url-bar::-moz-placeholder {
    color: #7c8ca5
}

.darksharing-modal .modal-body .sharing-details .url-bar:-ms-input-placeholder {
    color: #7c8ca5
}

.darksharing-modal .modal-body .sharing-details .control-group {
    margin-bottom: 0
}

.darksharing-modal .modal-body .sharing-details .control-group .mail-header {
    font-size: 14px;
    color: #7c8ca5;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

.darksharing-modal .modal-body .sharing-details .control-group .selectize-input {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    font-family: Source Sans Pro, sans-serif
}

placeholder .darksharing-modal .modal-body .sharing-details .control-group .selectize-input::-webkit-input-placeholder {
    color: #7c8ca5
}

.darksharing-modal .modal-body .sharing-details .control-group .selectize-input:-moz-placeholder,
.darksharing-modal .modal-body .sharing-details .control-group .selectize-input::-moz-placeholder {
    color: #7c8ca5
}

.darksharing-modal .modal-body .sharing-details .control-group .selectize-input:-ms-input-placeholder {
    color: #7c8ca5
}

.darksharing-modal .modal-body .sharing-details .control-group .edit-button {
    background: #222c3c;
    border: 1px solid transparent;
    padding: 0;
    padding-left: 10px
}

.darksharing-modal .modal-body .sharing-details .control-group .edit-button .btn-edit {
    background: #273142;
    border: 1px solid #313d4f;
    height: 46px;
    margin-top: 19px;
    color: #fff;
    font-size: 14px;
    font-weight: 400
}

.darksharing-modal .modal-body .sharing-details .control-group .edit-button .edit-dropdown {
    right: 0;
    left: auto
}

.darksharing-modal .share-footer {
    background: #273142;
    margin-top: -4px;
    padding: 17px 30px
}

.darksharing-modal .share-footer a {
    font-size: 14px;
    color: #7f8fa4;
    line-height: 34px;
    font-family: Source Sans Pro, sans-serif
}

.darksharing-modal .share-footer .btn-share {
    background: #de6926;
    height: 36px
}

.darkfilter-modal .filter-panel {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 30px;
    background: #222c3c
}

.darkfilter-modal .filter-panel .filter-title .filter-header {
    margin: 0;
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin-top: -5px;
    font-family: Source Sans Pro, sans-serif
}

.darkfilter-modal .filter-panel .filter-title .close-modal {
    margin-top: -6px;
    color: #636e7d
}

.darkfilter-modal .filter-panel .filter-type {
    margin-top: 28px;
    font-family: Source Sans Pro, sans-serif
}

.darkfilter-modal .filter-panel .filter-type label {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding-bottom: 3px
}

.darkfilter-modal .filter-panel .filter-type .form-control {
    background: #273142;
    border: 1px solid #313d4f;
    color: #7f8fa4;
    height: 36px;
    font-size: 14px
}

.darkfilter-modal .filter-footer {
    background: #273142;
    margin-top: -4px;
    padding: 20px 30px
}

.darkfilter-modal .filter-footer a {
    font-size: 14px;
    color: #7f8fa4;
    line-height: 30px;
    font-family: Source Sans Pro, sans-serif
}

.darkfilter-modal .filter-footer .btn-filter {
    background: #de6926;
}

.filterdata-modal .filter-data {
    padding: 0;
    font-family: Source Sans Pro, sans-serif
}

.filterdata-modal .filter-data .filter-header {
    background: #2ea1f8;
    text-transform: capitalize;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    letter-spacing: .4px
}

.filterdata-modal .filter-data .filter-header .close-modal i {
    color: #70bbfb
}

.filterdata-modal .filter-data .filter-data {
    padding: 30px
}

.filterdata-modal .filter-data .filter-data .data-type {
    margin-bottom: 20px
}

.filterdata-modal .filter-data .filter-data .filter-type {
    font-family: Source Sans Pro, sans-serif
}

.filterdata-modal .filter-data .filter-data .filter-type label {
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 400;
    padding-bottom: 3px
}

.filterdata-modal .filter-data .filter-data .filter-type .form-control {
    background: #f2f4f7;
    border: 1px solid #ced0da;
    color: #354052;
    height: 36px;
    font-size: 14px
}

.filterdata-modal .filter-data-footer {
    background: #f0f3f8;
    border-color: #f0f3f8;
    padding: 13px 30px
}

.filterdata-modal .filter-data-footer a {
    font-size: 14px;
    color: #7f8fa4;
    line-height: 30px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-decoration: none
}

.filterdata-modal .filter-data-footer .btn-filter {
    background: #39b54a;
    border: 1px solid #249533;
}

.darknewimage-modal .dark-image {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 30px;
    background: #222c3c
}

.darknewimage-modal .dark-image .image-title .image-header {
    margin: 0;
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin-top: -5px;
    font-family: Source Sans Pro, sans-serif
}

.darknewimage-modal .dark-image .image-title .close-modal {
    margin-top: -6px;
    color: #636e7d
}

.darknewimage-modal .dark-image .add-to {
    padding-top: 28px;
    font-family: Source Sans Pro, sans-serif
}

.darknewimage-modal .dark-image .add-to label {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding-bottom: 3px
}

.darknewimage-modal .dark-image .add-to .help-text a {
    color: #7f8fa4;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400
}

.darknewimage-modal .dark-image .add-to .form-control {
    background: #273142;
    border: 1px solid #313d4f;
    color: #fff;
    height: 36px;
    font-size: 14px
}

.darknewimage-modal .dark-image .add-to .form-control::-webkit-input-placeholder {
    color: #7c8ca5
}

.darknewimage-modal .dark-image .add-to .form-control:-moz-placeholder,
.darknewimage-modal .dark-image .add-to .form-control::-moz-placeholder {
    color: #7c8ca5
}

.darknewimage-modal .dark-image .add-to .form-control:-ms-input-placeholder {
    color: #7c8ca5
}

.darknewimage-modal .dark-image .add-to .btn-group-justified {
    background: #273142;
    border: 1px solid #313d4f;
}

.darknewimage-modal .dark-image .add-to .btn-group-justified .btn-grp {
    background: #273142;
    border: 1px solid transparent;
    border: 0;
    color: #fff
}

.darknewimage-modal .dark-image .add-to .btn-grp.active {
    background: #de6926;
    border: 1px solid #1a91eb;
}

.darknewimage-modal .select-image {
    padding: 20px 25px 0;
    margin-top: 20px;
    border-top: 1px solid #273142;
    margin-left: -30px;
    margin-right: -30px
}

.darknewimage-modal .select-image .add-image {
    padding-right: 5px;
    padding-left: 5px
}

.darknewimage-modal .select-image img {
    width: 100%
}

.darknewimage-modal .image-footer {
    background: #273142;
    margin-top: -4px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 18px 30px
}

.darknewimage-modal .image-footer a {
    font-size: 14px;
    color: #7f8fa4;
    line-height: 30px;
    line-height: 34px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-decoration: none
}

.darknewimage-modal .image-footer .btn-publish {
    background: #de6926;
}

.darkflightform-modal .darkflight {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 30px 30px 15px;
    background: #222c3c
}

.darkflightform-modal .darkflight .flight-title .flight-header {
    margin: 0;
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin-top: -5px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    margin-right: 25px
}

.darkflightform-modal .darkflight .flight-title .arrival .journey {
    margin-top: 0;
    float: left;
    color: #fff
}

.darkflightform-modal .darkflight .flight-title .arrival .journey .check {
    margin-right: 15px;
    color: #7f8fa4;
    cursor: pointer
}

.darkflightform-modal .darkflight .flight-title .arrival .journey .check .select {
    margin-right: 5px
}

.darkflightform-modal .darkflight .flight-title .close-modal {
    margin-top: -6px;
    color: #636e7d
}

.darkflightform-modal .darkflight .flights-available {
    margin-top: 23px;
    margin-bottom: 0
}

.darkflightform-modal .darkflight .flights-available .input-group {
    border: 1px solid #313d4f;
    margin-bottom: 10px;
    height: 46px;
    background: #273142
}

.darkflightform-modal .darkflight .flights-available .input-group .location {
    border: 0 solid transparent;
    background: #273142;
    border-right: 0;
    height: 46px;
    color: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.darkflightform-modal .darkflight .flights-available .input-group .location::-webkit-input-placeholder {
    color: #7c8ca5
}

.darkflightform-modal .darkflight .flights-available .input-group .location:-moz-placeholder,
.darkflightform-modal .darkflight .flights-available .input-group .location::-moz-placeholder {
    color: #7c8ca5
}

.darkflightform-modal .darkflight .flights-available .input-group .location:-ms-input-placeholder {
    color: #7c8ca5
}

.darkflightform-modal .darkflight .flights-available .input-group .all {
    color: #fff;
    text-transform: capitalize;
    font-size: 14px
}

.darkflightform-modal .darkflight .flights-available .input-group .input-group-addon {
    background: #273142;
    border: 0;
    padding: 5px 12px
}

.darkflightform-modal .darkflight .flights-available .input-group .submit {
    width: 90px;
    height: 36px;
    background: #de6926;
    float: right;
    outline: none!important;
    border: 0 solid transparent
}

.darkflightform-modal .darkflight .flights-available .input-group .submit .fa {
    color: #fff
}

.darkflightform-modal .darkflight .preferences {
    font-size: 14px
}

.darkflightform-modal .darkflight .preferences .checkbox {
    margin: 0
}

.darkflightform-modal .darkflight .preferences .checkbox .prefer {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 23px
}

.darkflightform-modal .darkflight .preferences .checkbox .prefer input[type=checkbox] {
    margin-right: 14px;
    height: 18px;
    width: 18px
}

.darkflightform-modal .darkflight .preferences .check-route {
    color: #7f8fa4;
    font-size: 14px;
    text-decoration: none
}

.darkflightform-modal .darkflight .check-in-section {
    margin-top: 25px
}

.darkflightform-modal .darkflight .check-in-section .form-group {
    margin-bottom: 0
}

.darkflightform-modal .darkflight .check-in-section .form-group .cale {
    background: #273142;
    border: 1px solid #313d4f;
    color: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    height: 36px
}

.darkflightform-modal .darkflight .check-in-section .form-group .cale::-webkit-input-placeholder {
    color: #7c8ca5
}

.darkflightform-modal .darkflight .check-in-section .form-group .cale:-moz-placeholder,
.darkflightform-modal .darkflight .check-in-section .form-group .cale::-moz-placeholder {
    color: #7c8ca5
}

.darkflightform-modal .darkflight .check-in-section .form-group .cale:-ms-input-placeholder {
    color: #7c8ca5
}

.darkflightform-modal .darkflight .check-in-section .form-group .btn-calendar {
    background: #273142;
    border: 1px solid #313d4f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    height: 36px;
    cursor: pointer
}

.darkflightform-modal .darkflight .categories {
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.darkflightform-modal .darkflight .categories li {
    height: 36px
}

.darkflightform-modal .darkflight .categories li:first-child {
    margin-left: -20px
}

.darkflightform-modal .darkflight .categories li:last-child {
    margin-right: -25px
}

.darkflightform-modal .darkflight .categories li .filter {
    background: #273142;
    border: 1px solid #313d4f;
    font-size: 14px;
    color: #fff;
    font-weight: 400
}

.darkflightform-modal .flight-footer {
    text-align: center;
    background: #273142;
    margin-top: -4px;
    padding: 17px 30px
}

.darkflightform-modal .flight-footer .btn-search {
    background: #de6926;
    width: 100%;
    color: #fff;
    font-size: 14px;
    padding: 8px 16px;
    outline: none!important
}

.paymentwindow-modal .modal-body {
    padding: 0
}

.paymentwindow-modal .modal-body .image-section {
    padding-right: 0
}

.paymentwindow-modal .modal-body .rate-tag {
    position: absolute;
    bottom: 30px;
    border: 18px solid #de6926;
    height: 36px;
    line-height: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    width: 96px;
    cursor: pointer;
    border-right: 13px solid transparent
}

.paymentwindow-modal .modal-body .pay-for-image {
    width: 100%;
    height: auto
}

.paymentwindow-modal .modal-body .pay-here {
    padding-left: 0
}

.paymentwindow-modal .modal-body .payment-section {
    padding: 30px
}

.paymentwindow-modal .modal-body .payment-section .card-heading {
    font-size: 20px;
    font-weight: 400;
    margin-top: -8px;
    margin-bottom: 20px;
    text-transform: capitalize
}

.paymentwindow-modal .modal-body .payment-section .close-modal {
    margin-top: -12px
}

.paymentwindow-modal .modal-body .payment-section .close-modal i {
    color: #c5d0de;
    font-size: 12px
}

.paymentwindow-modal .modal-body .payment-section .input-group {
    margin-bottom: 15px
}

.paymentwindow-modal .modal-body .payment-section .input-group .icon {
    height: 36px;
    border: 1px solid #dfe3e9;
    border-right: 0;
    box-shadow: none;
    font-size: 16px;
    color: #354052;
    background: #fff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.paymentwindow-modal .modal-body .payment-section .input-group .icon .fa {
    opacity: .5
}

.paymentwindow-modal .modal-body .payment-section .input-group .enter-details {
    height: 36px;
    padding: 0;
    border: 1px solid #dfe3e9;
    border-left: 0;
    box-shadow: none;
    font-size: 14px;
    color: #354052;
    background: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.paymentwindow-modal .modal-body .payment-section .input-group .enter-card-details {
    height: 36px;
    padding: 0;
    border: 1px solid #dfe3e9;
    border-left: 0;
    box-shadow: none;
    font-size: 14px;
    color: #354052;
    border-right: 0;
    background: #fff;
}

.paymentwindow-modal .modal-body .payment-section .input-group .card-image {
    width: 28px;
    height: 18px;
    border-color: #dfe3e9!important;
    border: 1px solid #dfe3e9;
    background: #fff;
    border-left: 0
}

.paymentwindow-modal .modal-body .payment-section .input-group .card-image a img {
    width: 28px;
    height: 18px;
    opacity: 1
}

.paymentwindow-modal .modal-body .payment-section .input-group .btn-calendar {
    background: #fff
}

.paymentwindow-modal .modal-body .payment-section .input-group .month,
.paymentwindow-modal .modal-body .payment-section .input-group .number {
    border: 1px solid #dfe3e9;
    height: 36px;
    border-left: 0;
    padding-left: 0
}

.paymentwindow-modal .modal-body .payment-section .date {
    margin-bottom: 20px
}

.paymentwindow-modal .modal-body .payment-section .payment .btn-pay {
    background: #39b54a;
    border: 1px solid #249533;
    padding: 7px 12px;
    font-size: 14px;
    margin-bottom: 20px
}

.paymentwindow-modal .modal-body .payment-section .alternate {
    border-bottom: 1px solid #f2f3f5;
    position: relative
}

.paymentwindow-modal .modal-body .payment-section .alternate .divide {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    margin-top: -8px;
    height: 13px;
    font-size: 14px;
    color: #354052;
    background-color: #fff
}

.paymentwindow-modal .modal-body .payment-section .alternate .divide a {
    opacity: .5;
    color: #354052;
    text-decoration: none
}

.paymentwindow-modal .modal-body .payment-section .alternate-payment {
    margin-top: 21px
}

.paymentwindow-modal .modal-body .payment-section .alternate-payment .pay-alter {
    background: #de6926;
    border: 1px solid #df6708;
    padding: 7px 12px;
    font-size: 14px
}

.paymentwindow-modal .modal-body .payment-section .alternate-payment .pay-alter img {
    width: auto;
    margin-left: 4px;
    height: 16px
}

.profile-modal .modal-dialog {
    width: 630px
}

.profile-modal .modal-body {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 0
}

.profile-modal .modal-body .user-profile-pic {
    padding-right: 0
}

.profile-modal .modal-body .user-profile-pic a img {
    width: 100%;
    height: auto
}

.profile-modal .modal-body .user-profile-pic .user-profile-name {
    position: absolute;
    bottom: 35px;
    left: 50%;
    margin-left: -50px
}

.profile-modal .modal-body .user-profile-pic .user-profile-name .name {
    font-size: 20px;
    color: #fff;
    font-weight: 400
}

.profile-modal .modal-body .user-profile-pic .user-profile-name .designation {
    margin-top: 5px
}

.profile-modal .modal-body .user-profile-pic .user-profile-name .designation .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #1a91eb;
    float: left;
    margin-top: 4px;
    margin-right: 8px
}

.profile-modal .modal-body .user-profile-pic .user-profile-name .designation span {
    font-size: 14px;
    color: #fff
}

.profile-modal .modal-body .user-all-activities {
    padding-left: 0
}

.profile-modal .modal-body .user-activities {
    padding-right: 0;
    padding: 28px 30px 30px
}

.profile-modal .modal-body .user-activities .user-name {
    margin-top: -3px;
    font-size: 20px;
    font-weight: 400;
    color: #354052
}

.profile-modal .modal-body .user-activities .activity-header {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px
}

.profile-modal .modal-body .user-activities .activity-pic {
    float: left;
    margin-right: 15px
}

.profile-modal .modal-body .user-activities .activity-comments {
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    margin-top: -5px
}

.profile-modal .modal-body .user-activities .separate {
    border: 1px solid #f2f3f5;
    margin-top: 10px;
    margin-bottom: 12px
}

.profile-modal .modal-body .user-activities .sub-details {
    margin-bottom: 13px
}

.profile-modal .modal-body .user-activities .user-sub-details {
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    color: #354052
}

.profile-modal .modal-body .user-activities .help-text {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: #354052
}

.profile-modal .modal-body .user-activities .add-user {
    font-size: 14px;
    color: #354052;
    font-weight: 400
}

.profile-modal .modal-body .user-activities .select-activity {
    background: #f2f4f7;
    border: 1px solid #ced0da;
    height: 36px;
    margin-top: 3px
}

.profile-modal .modal-body .user-activities .well {
    color: #7f8fa4;
    letter-spacing: .4px;
    font-size: 14px;
    background: #f0f3f8;
    border: 1px solid #dce1ea;
    margin-top: 16px;
    margin-bottom: 0
}

.profile-modal .modal-body .user-activities .well .protected-user {
    margin: 0;
    color: #354052;
    font-weight: 400;
    letter-spacing: .4px
}

.profile-modal .modal-body .user-activities .well .protected-user .fa {
    font-size: 16px;
    margin-right: 7px
}

.darksignup-modal .modal-dialog {
    width: 750px
}

.darksignup-modal .modal-body {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    padding: 0
}

.darksignup-modal .modal-body .create-account {
    margin-right: 0;
    margin-left: 0;
    display: table
}

.darksignup-modal .modal-body .create-account .welcome-section {
    background: #de6926;
    padding: 30px 30px 23px;
    height: 100%;
    float: none;
    display: table-cell
}

.darksignup-modal .modal-body .create-account .welcome-section .welcome-icon a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    padding: 17px;
    background: #1267a7
}

.darksignup-modal .modal-body .create-account .welcome-section .welcome-icon a i {
    font-size: 16px;
    color: #fff
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .welcome {
    font-size: 22px;
    color: #fff;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-content {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    opacity: .6
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .buyer {
    font-size: 18px;
    color: #fff;
    font-weight: 400
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .benefits {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    opacity: .6;
    margin-bottom: 100px
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom {
    margin-bottom: 0
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom li {
    border-right: 1px solid #fff
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom li:last-child {
    border-right: 0
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom li:last-child a {
    margin-right: 0
}

.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom li a {
    color: #fff;
    margin-right: 8px;
    font-size: 12px;
    font-weight: 400;
    opacity: .6;
    text-decoration: none
}

.darksignup-modal .modal-body .create-account .account-section {
    background: #222c3c;
    height: 100%;
    width: 100%;
    float: none;
    display: table-cell;
    padding: 30px 30px 0
}

.darksignup-modal .modal-body .create-account .account-section .create {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 400
}

.darksignup-modal .modal-body .create-account .account-section .authenticate {
    font-size: 14px;
    color: #fff;
    font-weight: 400
}

.darksignup-modal .modal-body .create-account .account-section .authentication-form {
    background: #273142;
    height: 36px;
    border: 1px solid #313d4f;
    color: #fff;
    font-size: 14px;
    font-weight: 400
}

.darksignup-modal .modal-body .create-account .account-section .authentication-form::-webkit-input-placeholder {
    color: #7c8ca5
}

.darksignup-modal .modal-body .create-account .account-section .authentication-form:-moz-placeholder,
.darksignup-modal .modal-body .create-account .account-section .authentication-form::-moz-placeholder {
    color: #7c8ca5
}

.darksignup-modal .modal-body .create-account .account-section .authentication-form:-ms-input-placeholder {
    color: #7c8ca5
}

.darksignup-modal .modal-body .create-account .account-section .password-form {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.darksignup-modal .modal-body .create-account .account-section .secure {
    height: 36px;
    background: #273142;
    border: 1px solid #313d4f;
    border-left: 0;
    cursor: pointer
}

.darksignup-modal .modal-body .create-account .account-section .sign-up {
    margin-bottom: 20px
}

.darksignup-modal .modal-body .create-account .account-section .btn-continue {
    background: #de6926;
    color: #fff;
    font-size: 14px
}

.darksignup-modal .modal-body .create-account .account-section .darksign-footer {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: 20px;
    text-align: center;
    background: #273142;
    padding: 20px 30px;
    color: #7f8fa4;
    font-size: 14px
}

.darksignup-modal .modal-body .create-account .account-section .darksign-footer a {
    color: #fff;
    font-size: 14px;
    font-weight: 400
}

.darkphonemanager-modal .modal-content {
    font-family: Source Sans Pro, sans-serif
}

.darkphonemanager-modal .modal-content .modal-header {
    background: #273142;
    margin-left: 0;
    padding: 17px 23px 17px 21px;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.darkphonemanager-modal .modal-content .modal-header li a {
    font-size: 12px;
    color: #7f8fa4;
    padding: 0;
    text-transform: uppercase
}

.darkphonemanager-modal .modal-content .modal-header li a .fa {
    margin-right: 9px
}

.darkphonemanager-modal .modal-content .modal-header li.active a {
    color: #fff
}

.darkphonemanager-modal .modal-content .modal-header:before {
    content: "\A";
    border-style: solid;
    border-width: 0 15px 15px;
    border-color: #273142 transparent;
    position: absolute;
    left: 50%;
    margin-top: -30px
}

.darkphonemanager-modal .modal-content .dark-phone {
    background: #222c3c;
    margin-top: -2px;
    padding: 20px
}

.darkphonemanager-modal .modal-content .dark-phone .gallery {
    margin-left: 2px
}

.darkphonemanager-modal .modal-content .dark-phone .gallery li img {
    width: 98px;
    height: 98px
}

.darkphonemanager-modal .modal-content .dark-phone-footer {
    background: #273142;
    padding: 14px 20px 16px;
    margin-top: -2px;
    text-align: left
}

.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list {
    margin-bottom: 0;
    margin-left: 2px
}

.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list li a {
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    text-decoration: none;
    font-weight: 400
}

.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .app {
    background: #de6926;
    text-align: center;
    height: 20px;
    width: 110px
}

.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .audio {
    background: #26b10f;
    height: 20px;
    width: 131px;
    text-align: center
}

.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .photo {
    background: #9d8fe3;
    height: 20px;
    width: 89px;
    text-align: center
}

.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .other {
    background: #fdbb02;
    text-align: center;
    height: 20px;
    width: 100px
}

.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .percentage {
    background: #222c3c;
    text-align: center;
    height: 20px;
    width: 100px
}

.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .percentage a {
    font-size: 10px;
    color: #566378;
    text-decoration: none;
    font-weight: 400
}

.setnewproject-modal .modal-body {
    padding: 0;
    font-family: Source Sans Pro, sans-serif
}

.setnewproject-modal .modal-body .newproject-header {
    background: #2ea1f8;
    text-transform: capitalize;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    letter-spacing: .4px
}

.setnewproject-modal .modal-body .newproject-header .close-modal i {
    color: #70bbfb
}

.setnewproject-modal .modal-body .upload-section {
    padding: 30px
}

.setnewproject-modal .modal-body .upload-section .outer-box {
    padding: 6px;
    height: 162px;
    border: 1px solid #e6eaee;
    cursor: pointer
}

.setnewproject-modal .modal-body .upload-section .outer-box .drop-box {
    border: 3px dashed #ced0da;
    width: 100%;
    height: 100%;
    text-decoration: none
}

.setnewproject-modal .modal-body .upload-section .outer-box .drop-box i {
    color: #e2e3e9;
    font-size: 40px;
    margin-top: 31px
}

.setnewproject-modal .modal-body .upload-section .outer-box .drop-box .cloud-text {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    color: #7f8fa4;
    text-decoration: none;
    width: 220px;
    margin: 0 auto
}

.setnewproject-modal .modal-body .upload-section .outer-box .drop-box #my_project {
    display: none
}

.setnewproject-modal .modal-body .upload-section .tooltip {
    width: 290px
}

.setnewproject-modal .modal-body .upload-section .right-tip+.tooltip>.tooltip-inner,
.setnewproject-modal .modal-body .upload-section .tooltip .right-tip {
    color: red
}

.setnewproject-modal .modal-body .upload-section .set-up {
    margin-top: 20px;
    margin-bottom: 14px
}

.setnewproject-modal .modal-body .upload-section .set-up .set-name {
    color: #7f8fa4;
    font-size: 14px
}

.setnewproject-modal .modal-body .upload-section .set-up .enter-form {
    border: 1px solid #dfe3e9;
    height: 36px
}

.setnewproject-modal .modal-body .upload-section .selection {
    margin-bottom: 0
}

.setnewproject-modal .modal-body .upload-section .selection .select-project {
    background: #f2f4f7;
    border: 1px solid #ced0da;
    height: 46px
}

.setnewproject-modal .new-project-footer {
    text-align: center;
    background: #f0f3f8;
    padding: 12px 30px
}

.setnewproject-modal .new-project-footer .btn-create {
    background: #39b54a;
    border: 1px solid #249533;
    height: 36px
}

.selectlayout-modal .modal-body {
    padding: 0;
    font-family: Source Sans Pro, sans-serif
}

.selectlayout-modal .modal-body .layout-header {
    background: #2ea1f8;
    text-transform: capitalize;
    padding: 20px 30px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    letter-spacing: .4px
}

.selectlayout-modal .modal-body .layout-header .close-modal i {
    color: #70bbfb
}

.selectlayout-modal .modal-body .layout-section {
    padding: 20px 30px 5px
}

.selectlayout-modal .modal-body .layout-section .layout-text {
    font-size: 14px;
    color: #7f8fa4;
    font-weight: 400;
    margin-bottom: 20px;
    letter-spacing: .4px
}

.selectlayout-modal .modal-body .layout-section .layout-img {
    margin-bottom: 15px
}

.selectlayout-modal .modal-body .layout-section .layout-img img {
    width: 160px;
    height: 110px
}

.selectlayout-modal .modal-body .layout-section .layout-img .selected {
    position: absolute;
    left: 50%;
    margin-left: -28px;
    top: 50%;
    margin-top: -28px
}

.selectlayout-modal .modal-body .layout-section .layout-img .selected i {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #fff;
    color: #1bb934;
    font-size: 24px;
    line-height: 56px;
    text-align: center
}

.selectlayout-modal .layout-footer {
    background: #f0f3f8;
    padding: 12px 30px
}

.selectlayout-modal .layout-footer .btn-cancel {
    background: #fff;
    border: 1px solid #ced0da;
    height: 36px;
    color: #7383a1
}

.selectlayout-modal .layout-footer .btn-confirm {
    background: #39b54a;
    border: 1px solid #249533;
    height: 36px;
    color: #fff
}

.filemanager-modal .manager-panel {
    width: 830px
}

.filemanager-modal .manage-data {
    padding: 0
}

.filemanager-modal .manage-data .manage-header {
    background: #2ea1f8;
    text-transform: capitalize;
    padding: 12px 12px 12px 30px
}

.filemanager-modal .manage-data .manage-header .manage-text {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    letter-spacing: .4px
}

.filemanager-modal .manage-data .manage-header .search-form {
    width: 35%;
    margin-bottom: 0
}

.filemanager-modal .manage-data .manage-header .search-form .search {
    height: 36px;
    background: #1e8fe4;
    border: 1px solid #1a84d3;
    color: #fff;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.filemanager-modal .manage-data .manage-header .search-form .search-icon {
    background: #1e8fe4;
    border: 1px solid #1a84d3;
    color: #fff;
    height: 36px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.filemanager-modal .manage-data .manage-section {
    margin-left: 0;
    margin-right: 0;
    display: table
}

.filemanager-modal .manage-data .manage-section .file-manage-list {
    padding-left: 0;
    padding-right: 0;
    background: #222c3c;
    height: 100%;
    display: table-cell;
    float: none
}

.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li {
    color: #848c98;
    font-size: 14px;
    height: 36px;
    border-bottom: 1px solid #2a3546;
    line-height: 36px;
    padding-left: 20px;
    padding-right: 8px;
    cursor: pointer;
    border-left: 2px solid transparent
}

.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li a {
    color: #848c98;
    font-size: 14px;
    text-decoration: none
}

.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li a .badge {
    background: #323c4e;
    border-radius: 50px;
    margin-top: 5px;
    color: #848c98
}

.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li:hover {
    border-left: 2px solid #1a91eb;
    background: #2a3546
}

.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li:hover .badge,
.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li:hover a {
    color: #fff
}

.filemanager-modal .manage-data .manage-section .file-manage-list .bottom-icons {
    position: absolute;
    width: 100%;
    bottom: 15px;
    padding-left: 20px;
    padding-right: 8px
}

.filemanager-modal .manage-data .manage-section .file-manage-list .bottom-icons .btn-bottom {
    background: #222c3c;
    border: 1px solid #2a3546;
}

.filemanager-modal .manage-data .manage-section .file-manage-list .bottom-icons .btn-bottom i {
    color: #848c98
}

.filemanager-modal .manage-data .manage-section .manage-table {
    padding-left: 0;
    padding-right: 0;
    margin-right: -15px;
    height: 100%;
    width: 100%;
    float: none;
    display: table-cell
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table {
    margin-bottom: 0
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table thead .table-heading th {
    font-size: 14px;
    color: #7f8fa4
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file td .media .media-left img {
    width: 84px;
    height: 62px
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file td .media .media-body .media-heading {
    color: #354052;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file td .media .media-body .media-heading .sub-text {
    color: #7f8fa4
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file .created {
    font-size: 12px;
    color: #7f8fa4
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file .quality i {
    color: #f8cd51;
    font-size: 12px
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file .quality .no-color {
    color: #bbbfc5
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-footer {
    background: #f0f3f8;
    padding: 12px 30px;
    border-top: 0
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-footer .btn-cancel {
    background: #fff;
    border: 1px solid #ced0da;
    height: 36px;
    color: #7383a1
}

.filemanager-modal .manage-data .manage-section .manage-table .file-manage-footer .btn-confirm {
    background: #39b54a;
    border: 1px solid #249533;
    height: 36px;
    color: #fff
}

.activityprofile-modal .activity-panel {
    width: 830px;
    font-family: Source Sans Pro, sans-serif
}

.activityprofile-modal .user-activities {
    padding: 0
}

.activityprofile-modal .user-activities .activity-block {
    background: #2ea1f8;
    padding: 17px 30px
}

.activityprofile-modal .user-activities .activity-block img {
    width: 70px;
    height: 70px;
    position: absolute;
    top: -17px;
    border-radius: 50%
}

.activityprofile-modal .user-activities .activity-block span {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-left: 85px
}

.activityprofile-modal .user-activities .activity-block .close-modal i {
    color: #70bbfb
}

.activityprofile-modal .user-activities .activities-list {
    background-color: #222c3c;
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0
}

.activityprofile-modal .user-activities .activities-list li {
    margin-left: 15px;
    margin-right: 15px;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 3px solid transparent
}

.activityprofile-modal .user-activities .activities-list li:last-child {
    margin-right: 7px
}

.activityprofile-modal .user-activities .activities-list li a {
    color: #848c98;
    padding: 0;
    padding-top: 14px;
    padding-bottom: 11px;
    font-size: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 400
}

.activityprofile-modal .user-activities .activities-list .dots i {
    line-height: 47px;
    font-size: 12px;
    color: #848c98;
    margin-right: 15px;
    text-decoration: none
}

.activityprofile-modal .user-activities .activities-list li.active {
    border-bottom: 3px solid #2ea1f8
}

.activityprofile-modal .user-activities .activities-list li.active a {
    color: #fff
}

.activityprofile-modal .user-activities .activities-list .options-menu {
    right: 0;
    left: auto;
    top: 103px
}

.activityprofile-modal .user-activities .user-overview {
    margin-left: 0;
    margin-right: 0;
    background: #f4f6fa;
    padding: 30px 30px 20px;
    display: table
}

.activityprofile-modal .user-activities .user-overview .activities-section .left-panel {
    height: 100%;
    display: table-cell;
    float: none;
    padding-left: 0
}

.activityprofile-modal .user-activities .user-overview .activities-section .right-panel {
    height: 100%;
    display: table-cell;
    float: none;
    padding-right: 0
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-left-panel {
    max-height: 505px;
    overflow-y: auto
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-heading {
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    padding: 15px 20px;
    background: #f4f7fa;
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-heading .follow {
    margin-top: -9px
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-heading .follow a {
    color: #7f8fa4;
    text-decoration: none
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-heading .follow li.active a {
    color: #354052
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .activity-content {
    padding: 0;
    margin-bottom: 0;
    border: 0
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list {
    padding: 0 20px;
    margin-bottom: 0;
    background: #fff;
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list li:last-child {
    border-bottom: 0
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item {
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 2px solid #f2f3f5;
    padding-left: 0;
    padding-right: 0
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-left a img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 7px
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-body .media-heading {
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    margin-bottom: 15px
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-body img {
    width: 36px;
    height: 36px;
    border-radius: 50%
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-body .user-post-pics {
    margin-bottom: 0
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-body .user-post-pics li img {
    width: 36px;
    height: 36px;
    margin-bottom: 10px
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .panel-icon {
    font-size: 14px;
    color: #354052;
    font-weight: 400;
    text-decoration: none
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .panel-icon i {
    margin-right: 15px;
    color: #c2c2c2;
    font-size: 19px
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .integrated {
    text-decoration: none;
    font-size: 14px
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .public {
    color: #36af47;
    font-size: 14px
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item:hover .panel-icon .icon-twitter {
    color: #0af
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item:hover .panel-icon .icon-instagram {
    color: #125688
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item:hover .panel-icon .icon-spotify {
    color: #2ebd59
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item:hover .panel-icon .icon-apple {
    color: #464646
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .checkbox {
    margin-top: 0
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .checkbox input[type=checkbox],
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .checkbox input[type=radio] {
    margin-top: 2px;
    margin-right: 4px
}

.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .checkbox .appear {
    font-size: 14px;
    font-weight: 400;
    color: #354052;
    font-family: Source Sans Pro, sans-serif
}

.teamcommunication-modal .team-panel {
    width: 870px
}

.teamcommunication-modal .team-data {
    padding: 0
}

.teamcommunication-modal .team-data .team-section {
    background: #2ea1f8;
    margin-left: 0;
    margin-right: 0
}

.teamcommunication-modal .team-data .team-section .team-communicate {
    text-transform: capitalize;
    padding: 20px 30px;
    border-right: 1px solid #40a8f5
}

.teamcommunication-modal .team-data .team-section .team-communicate .team-header {
    color: #fff
}

.teamcommunication-modal .team-data .team-section .team-communicate .team-header .team-text {
    font-size: 18px;
    font-weight: 400
}

.teamcommunication-modal .team-data .team-section .team-communicate .team-header .contact {
    margin-bottom: 0
}

.teamcommunication-modal .team-data .team-section .team-communicate .team-header .contact li a i {
    margin-left: 20px;
    color: #fff;
    font-size: 16px
}

.teamcommunication-modal .team-data .team-section .team-members {
    padding: 20px;
    text-transform: capitalize
}

.teamcommunication-modal .team-data .team-section .team-members .team-header {
    color: #fff
}

.teamcommunication-modal .team-data .team-section .team-members .team-header .team-text {
    font-size: 18px;
    font-weight: 400
}

.teamcommunication-modal .team-data .team-section .team-members .team-header .close-modal i {
    color: #70bbfb
}

.teamcommunication-modal .team-data .communication-body {
    margin-left: 0;
    margin-right: 0
}

.teamcommunication-modal .team-data .communication-body .left-communicate {
    padding-left: 0;
    padding-right: 0
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel {
    border-right: 1px solid #f0f3f8;
    margin-bottom: 0
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list {
    border-radius: 0
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item {
    border: 1px solid #f0f3f8;
    border-right: 0;
    padding: 20px 30px
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-left a img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 4px
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body {
    color: #7f8fa4;
    font-size: 14px;
    font-weight: 400
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .media-heading {
    color: #354052;
    text-transform: capitalize
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .media-heading .time {
    color: #7f8fa4
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .alert-default {
    background: #f0f3f8;
    margin-top: 10px;
    height: 30px;
    font-size: 14px;
    padding: 6px 9px 6px 10px;
    margin-bottom: 0
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .alert-default i {
    margin-right: 9px
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .alert-default .remove .close {
    line-height: 0
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .alert-default .remove .close i {
    color: #7f8fa4;
    font-size: 14px
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer {
    background: #f0f3f8;
    padding: 21px 20px 19px
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group {
    margin-bottom: 0;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    border: 1px solid #dfe3e9;
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group .msg-form {
    border-right: 0;
    border: 1px solid #dfe3e9
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group .btn-link {
    border-left: 0!important;
    border: 1px solid #dfe3e9;
    background: #fff
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group .btn-link i {
    color: #b4bfd4
}

.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group .btn-link #my_file {
    display: none
}

.teamcommunication-modal .team-data .communication-body .right-team {
    padding-left: 0;
    padding-right: 0
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list {
    margin-bottom: 0
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list {
    border: 1px solid #f0f3f8;
    border-left: 0;
    padding: 11px 15px;
    cursor: pointer
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list:hover {
    background: #f0f3f8
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left a img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 3px
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left .user-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #7e8ca0;
    float: left;
    position: absolute;
    left: 45px;
    bottom: 10px
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left .online {
    background: #39b54a
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left .busy {
    background: #ff7800
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left .idle {
    background: #4fb7c5
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-body {
    padding-top: 10px
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-body .media-heading {
    color: #354052;
    font-size: 14px;
    font-weight: 400
}

.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-body .media-heading .team-posts {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fe7800;
    color: #fff;
    line-height: 20px;
    text-align: center
}