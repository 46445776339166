/* Selectize CSS */


/* there are duplicate css are defined from original CSS, in this that is removed */

.selectize-control.plugin-drag_drop.multi>.selectize-input>div.ui-sortable-placeholder {
    visibility: visible!important;
    background: #f2f2f2!important;
    background: rgba(0, 0, 0, .06)!important;
    border: 0 none!important;
    box-shadow: inset 0 0 12px 4px #fff
}

.selectize-control.plugin-drag_drop .ui-sortable-placeholder:after {
    content: '!';
    visibility: hidden
}

.selectize-control.plugin-drag_drop .ui-sortable-helper {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2)
}

.selectize-dropdown-header {
    position: relative;
    padding: 3px 12px;
    border-bottom: 1px solid #d0d0d0;
    background: #f8f8f8;
}

.selectize-dropdown-header-close {
    position: absolute;
    right: 12px;
    top: 50%;
    color: #333;
    opacity: .4;
    margin-top: -12px;
    line-height: 20px;
    font-size: 20px!important
}

.selectize-dropdown-header-close:hover {
    color: #000
}

.selectize-dropdown.plugin-optgroup_columns .optgroup {
    border-right: 1px solid #f2f2f2;
    border-top: 0 none;
    float: left;
    box-sizing: border-box
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
    border-right: 0 none
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
    display: none
}

.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
    border-top: 0 none
}

.selectize-control.plugin-remove_button [data-value] {
    position: relative;
    padding-right: 24px!important
}

.selectize-control.plugin-remove_button [data-value] .remove {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 17px;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    color: inherit;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
    padding: 1px 0 0;
    border-left: 1px solid transparent;
    box-sizing: border-box
}

.selectize-control.plugin-remove_button [data-value] .remove:hover {
    background: rgba(0, 0, 0, .05)
}

.selectize-control.plugin-remove_button [data-value].active .remove {
    border-left-color: transparent
}

.selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
    background: none
}

.selectize-control.plugin-remove_button .disabled [data-value] .remove {
    border-left-color: rgba(77, 77, 77, 0)
}

.selectize-control {
    position: relative
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
    color: #333;
    font-family: inherit;
    font-size: inherit;
    line-height: 20px;
    -webkit-font-smoothing: inherit
}

.selectize-control.single .selectize-input.input-active,
.selectize-input {
    background: #fff;
    cursor: text;
    display: inline-block
}

.selectize-input {
    border: 1px solid #ccc;
    padding: 6px 12px;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    box-shadow: none;
}

.selectize-control.multi .selectize-input.has-items {
    padding: 5px 12px 2px
}

.selectize-input.full {
    background-color: #fff
}

.selectize-input.disabled,
.selectize-input.disabled * {
    cursor: default!important
}

.selectize-input.focus {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .15)
}

.selectize-input.dropdown-active {
    border-radius: 0
}

.selectize-input>* {
    vertical-align: baseline;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline
}

.selectize-control.multi .selectize-input>div {
    cursor: pointer;
    margin: 0 3px 3px 0;
    padding: 1px 3px;
    background: #efefef;
    color: #333;
    border: 0 solid transparent
}

.selectize-control.multi .selectize-input>div.active {
    background: #428bca;
    color: #fff;
    border: 0 solid transparent
}

.selectize-control.multi .selectize-input.disabled>div,
.selectize-control.multi .selectize-input.disabled>div.active {
    color: gray;
    background: #fff;
    border: 0 solid rgba(77, 77, 77, 0)
}

.selectize-input>input {
    display: inline-block!important;
    padding: 0!important;
    min-height: 0!important;
    max-height: none!important;
    max-width: 100%!important;
    margin: 0!important;
    text-indent: 0!important;
    border: 0 none!important;
    background: none!important;
    line-height: inherit!important;
    -webkit-user-select: auto!important;
    box-shadow: none!important
}

.selectize-input>input::-ms-clear {
    display: none
}

.selectize-input>input:focus {
    outline: none!important
}

.selectize-input:after {
    content: ' ';
    display: block;
    clear: left
}

.selectize-input.dropdown-active:before {
    content: ' ';
    display: block;
    position: absolute;
    background: #fff;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0
}

.selectize-dropdown {
    position: absolute;
    z-index: 10;
    border: 1px solid #d0d0d0;
    background: #fff;
    margin: -1px 0 0;
    border-top: 0 none;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
}

.selectize-dropdown [data-selectable] {
    cursor: pointer;
    overflow: hidden
}

.selectize-dropdown [data-selectable] .highlight {
    background: rgba(255, 237, 40, .4);
}

.selectize-dropdown .optgroup-header,
.selectize-dropdown [data-selectable] {
    padding: 3px 12px
}

.selectize-dropdown .optgroup:first-child .optgroup-header {
    border-top: 0 none
}

.selectize-dropdown .optgroup-header {
    color: #777;
    background: #fff;
    cursor: default
}

.selectize-dropdown .active {
    background-color: #f5f5f5;
    color: #262626
}

.selectize-dropdown .active.create {
    color: #262626
}

.selectize-dropdown .create {
    color: rgba(51, 51, 51, .5)
}

.selectize-dropdown-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
    cursor: pointer
}

.selectize-control.single .selectize-input.input-active,
.selectize-control.single .selectize-input.input-active input {
    cursor: text
}

.selectize-control.single .selectize-input:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    right: 17px;
    margin-top: -3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #333 transparent transparent
}

.selectize-control.single .selectize-input.dropdown-active:after {
    margin-top: -4px;
    border-width: 0 5px 5px;
    border-color: transparent transparent #333
}

.selectize-control.rtl.single .selectize-input:after {
    left: 17px;
    right: auto
}

.selectize-control.rtl .selectize-input>input {
    margin: 0 4px 0 -2px!important
}

.selectize-control .selectize-input.disabled {
    opacity: .5;
    background-color: #fff
}

.selectize-dropdown,
.selectize-dropdown.form-control {
    height: auto;
    padding: 0;
    margin: 2px 0 0;
    z-index: 1000;
    background: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.selectize-dropdown .optgroup-header {
    font-size: 12px;
    line-height: 1.42857143
}

.selectize-dropdown .optgroup:first-child:before {
    display: none
}

.selectize-dropdown .optgroup:before {
    content: ' ';
    display: block;
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
    margin-left: -12px;
    margin-right: -12px
}

.selectize-dropdown-content {
    padding: 5px 0
}

.selectize-dropdown-header {
    padding: 6px 12px
}

.selectize-input {
    min-height: 40px
}

.selectize-input.dropdown-active {
    border-radius: 0px
}

.selectize-input.dropdown-active:before {
    display: none
}

.selectize-input.focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.has-error .selectize-input {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .selectize-input:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483
}

.selectize-control.multi .selectize-input.has-items {
    padding-left: 9px;
    padding-right: 9px
}

.selectize-control.multi .selectize-input>div {
    border-radius: 0px
}

.form-control.selectize-control {
    padding: 0;
    height: auto;
    border: none;
    background: none;
    box-shadow: none;
}