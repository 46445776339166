.navbar-brand {
    padding: 13px 15px
}

.navbar-default {
    box-shadow: 0 -1px 0 0 #e6eaee;
    border-width: 0;
}

.navbar-default .navbar-brand {
    padding: 13px 10px 13px 30px
}

.navbar-default>.navbar-nav>a {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize
}

.navbar-default>.navbar-nav>a .fa-angle-down {
    margin-left: 11px
}

.navbar-default>.navbar-nav .active>a {
    background-color: transparent!important
}

.navbar-default.navbar-blue {
    background: #1990ea
}

.navbar-default.navbar-blue .navbar-nav.navbar-left a {
    font-size: 14px;
    text-transform: capitalize;
    color: #fff
}

.navbar-default.navbar-blue .navbar-nav.navbar-left .dropdown-menu a {
    color: #333
}

.navbar-right .position-icons-list {
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 21px;
    border-right: 1px solid #2087d5
}

.navbar-right .position-icons-list li img {
    width: 14px;
    height: 14px
}

.navbar-right .admin-settings-list {
    padding-left: 20px
}

.navbar-right .admin-settings-list li {
    padding-left: 10px;
    padding-right: 10px
}

.navbar-right .admin-settings-list li i {
    color: #fff;
    font-size: 16px
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover,
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    background-color: transparent
}

.dropdown.user-image {
    padding-top: 17px
}

.dropdown.user-image .dropdown-toggle {
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
    color: transparent;
    margin-left: 10px;
    padding-left: 10px
}

.dropdown.user-image .dropdown-toggle i {
    color: #86caff;
    margin-left: 8px;
    padding-right: 5px
}

.dropdown.user-image.with-form {
    margin-right: 10px;
    margin-left: 5px
}

.navbar-heading {
    font-size: 18px;
    color: #354052;
    margin-top: 15px
}

.navbar-heading,
.navbar-heading .sub-text {
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    text-transform: capitalize
}

.navbar-heading .sub-text {
    font-weight: 400;
    font-size: 14px;
    color: #7f8fa4;
    border-left: 1px solid #dfe3e9;
    padding-left: 13px;
    margin-left: 9px
}

.navbar-heading.dark-background {
    color: #fff
}

.navbar-heading.dark-background .sub-text {
    border-left: 1px solid #7f8fa4
}

.play-list {
    margin-top: 17px
}

.play-list li {
    padding: 8px 15px;
    border-left: 1px solid #e5e9ef
}

.play-list li:first-child {
    border-left: 0;
    padding-left: 20px
}

.play-list li .btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 0;
    border: 2px solid #bfc7d8
}

.play-list li .btn i {
    color: #bfc7d8;
}

.navbar-nav li {
    position: relative
}

.navbar-nav li .activity-count {
    position: absolute;
    background: #ff7800;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    top: 14px;
    font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    right: 2px
}

.navbar-nav .dropdown-menu {
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #e6eaee;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box
}

.navbar-form {
    margin-top: 16px
}

.navbar-form .input-group .form-control {
    border-right-width: 0;
    height: 40px;
    margin-left: 7px
}

.navbar-form .input-group .input-group-btn .btn,
.navbar-form .input-group .input-group-btn a {
    background: none;
    border-color: #dfe3e9;
    color: #a1a7af;
    border-left-width: 0;
    height: 36px
}

.navbar-form button.btn {
    padding: 10px 25px;
    margin-left: 7px
}

.navbar li.button {
    padding-top: 18px
}

.navbar li.button a.btn {
    padding-top: 3px;
    padding-bottom: 5px
}

.navbar li.button a.btn i.fa {
    font-size: 16px!important
}

.navbar-icons>li>a {
    color: #bfc7d8!important;
    font-size: 24px!important
}

.navbar-primary {
    background: #2ea1f8
}

.navbar-primary .navbar-nav>li>a {
    color: #fff;
    font-size: 16px!important
}

.navbar-dark {
    background: #273142
}

.navbar-dark .navbar-nav>li>a {
    color: #7f8fa4!important;
    text-transform: capitalize;
    font-weight: 600
}

.navbar-dark .navbar-form .form-control {
    background: #222c3c;
    border: 1px solid #313d4f;
    color: #7f8fa4;
    border-right-width: 0
}

.navbar-dark .navbar-form .input-group-btn .btn,
.navbar-dark .navbar-form .input-group-btn a {
    background: #222c3c;
    border-color: #313d4f!important;
    color: #7f8fa4!important;
    border-left-width: 0
}

.navbar-dark .dropdown.user-image .dropdown-toggle i {
    color: #a1a7af
}