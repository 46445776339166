.create-body .create-list-group .list-group-item {
    border-color: #e6eaee!important
}

.create-body .create-list-group .list-group-item:last-child {
    border-bottom: 0;
    padding-bottom: 0
}

.create-body .create-list-group .form-control {
    float: none!important;
    width: 100%!important;
    border-color: #e6eaee!important
}

.create-body .create-list-group .create-form {
    padding-right: 0
}

.create-body .create-list-group .create-form .no-bg {
    background: #fff!important
}

.create-body .side-label {
    line-height: 25px;
    font-size: 14px
}

.create-body .btn-success-create {
    padding: 7px 20px
}

.create-body .line-separator {
    border-bottom: 1px solid #e6eaee
}

.create-body .select-option .radio-header {
    color: #354052;
    font-size: 14px;
    text-transform: none
}

.create-body .select-option i {
    margin-right: 5px;
    color: #7f8fa4
}

.create-body .select-option .radio-text {
    font-size: 14px;
    color: #7f8fa4;
    text-transform: none;
    margin-bottom: 0;
    margin-left: 15px
}

.right-panel .privacy-question {
    padding-bottom: 0
}

.right-panel .privacy-question .right-list-group {
    margin-bottom: 10px
}

.right-panel .privacy-question .right-list-group .list-group-item .holder {
    padding-left: 0
}

.right-panel .privacy-question .right-list-group .list-group-item:first-child {
    padding-top: 7px
}

.right-panel .privacy-question .right-list-group .list-group-item:last-child {
    padding-bottom: 0
}

.right-panel .privacy-question .right-list-group .list-group-item .right-side-label {
    font-size: 14px;
    text-transform: none;
    color: #354052;
    font-weight: 400
}

.right-panel .privacy-question .right-list-group .list-group-item .page-description {
    color: #7f8fa4;
    font-size: 12px;
    margin-top: 10px
}

.user-bio-block {
    background: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #e6eaee;
    padding: 18px 20px;
    font-size: 14px;
}

.user-bio-block .bio-header {
    color: #354052;
    font-weight: 600
}

.user-bio-block .privacy-type {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6eaee
}

.user-bio-block .privacy-type .fa {
    color: #354052;
    margin-right: 5px;
    margin-left: 5px
}

.user-bio-block .bio-description {
    padding: 13px 0;
    color: #5b6b81
}

.user-bio-block .bio-list {
    margin-bottom: 0
}

.user-bio-block .bio-list li {
    margin-bottom: 7px
}

.user-bio-block .bio-list li:last-child {
    margin-bottom: 0
}

.user-bio-block .bio-list li i {
    font-size: 16px;
    color: #333;
    width: 20px;
    margin-right: 5px
}

.user-bio-block .bio-list li span {
    color: #354052;
    font-weight: 600
}

.widget-best-pictures {
    background: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 0;
    border: 1px solid #e6eaee
}

.widget-best-pictures .picture {
    padding-bottom: 0;
    text-transform: capitalize
}

.widget-best-pictures .pull-right.show-all {
    padding-right: 17px
}

.widget-best-pictures .pull-right.show-all a {
    color: #bd5011
}

.widget-best-pictures .my-best-pictures {
    padding: 15px 15px 10px
}

.widget-best-pictures .my-best-pictures .best-pics {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px
}

.widget-best-pictures .my-best-pictures .best-pics a {
    display: inline-block
}

.make-announcements {
    background: #fff;
    border: 1px solid #e6eaee;
    padding: 25px 20px 10px
}

.make-announcements .form-group {
    margin-bottom: 0
}

.make-announcements .form-group .announcement-header {
    font-size: 14px;
    margin-bottom: 12px
}

.make-announcements .form-group .wrap {
    background: #fff;
}

.make-announcements .add {
    margin-top: 10px
}

.make-announcements .add .btn-add {
    background: #de6926;
    border: 1px solid #1b92ec;
    padding: 5px 16px
}

.smiley-list {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e6eaee
}

.smiley-list li {
    margin-left: 0
}

.smiley-list li a {
    font-size: 14px;
    margin-left: 0;
    border-bottom: 2px solid transparent;
    color: #354052;
    padding-bottom: 5px;
    padding-left: 0;
    padding-right: 0
}

.smiley-list li a .fa {
    color: #354052;
    border-right: 0
}

.smiley-list li.active {
    border-bottom: 2px solid #e06a26
}

.smiley-list li.active a {
    color: #354052!important
}

.smiley-pics-content {
    border: 0;
    padding: 5px 0 5px 15px;
    margin-bottom: 0;
    max-height: 120px;
    overflow: auto
}

.smiley-pics-content .smiley-post {
    padding-right: 3px;
    margin-top: 7px;
    display: inline-block
}

.modal-likes {
    width: 500px
}

.modal-likes .people-like-list {
    margin-bottom: 0;
    height: 360px;
    overflow-x: hidden;
    overflow-y: scroll
}

.modal-likes .people-like-list .list-group-item {
    border: 1px solid #e6eaee
}

.modal-likes .people-like-list .list-group-item:first-child {
    border-top: 0
}

.modal-likes .people-like-list .list-group-item:last-child {
    border-radius: 0
}

.modal-likes .people-like-list .list-group-item .media img {
    width: 40px;
    height: 40px;
}

.modal-likes .people-like-list .list-group-item .media .media-body .media-heading .user {
    text-decoration: none;
    color: #354052
}

.modal-likes .people-like-list .list-group-item .media .media-body .username {
    text-decoration: none;
    color: #7f8fa4
}

.modal-likes .people-like-list .list-group-item .media .media-body .media-heading {
    margin-bottom: 0;
    line-height: 20px!important
}

.modal-likes .people-like-list .list-group-item .media .media-body .media-heading .follow-user {
    padding: 6px 30px;
    margin-top: 3px
}

.modal-likes .people-like-list .list-group-item .media .media-body .media-heading .following-user {
    padding: 6px 20px
}

.album-modal {
    width: 500px
}

.album-modal .modal-dialog {
    margin: 30px auto
}

.album-modal .album-header {
    border-bottom: 0
}

.album-modal .album-header .modal-title {
    font-size: 18px;
    font-weight: 600
}

.album-modal .album-body {
    padding: 30px 16px
}

.album-modal .album-body .albums-list {
    margin-top: 12px;
    padding-left: 8px;
    padding-right: 8px;
    overflow-x: hidden;
    overflow-y: scroll
}

.album-modal .album-body .albums-list .album-images.active .album,
.album-modal .album-body .albums-list .album-images:hover .album {
    border-radius: 0
}

.album-modal .album-body .albums-list .album-images.active .album img,
.album-modal .album-body .albums-list .album-images:hover .album img {
    opacity: .7
}

.album-modal .album-body .albums-list .album-images.active .btn-remove {
    display: block
}

.album-modal .album-body .albums-list .album-images {
    margin-bottom: 14px;
    padding-left: 7px;
    padding-right: 7px
}

.album-modal .album-body .albums-list .album-images .album {
    background-color: #fff;
    width: 100%;
    height: 90px;
    overflow: hidden
}

.album-modal .album-body .albums-list .album-images .album img {
    width: 100%;
    height: auto;
}

.album-modal .album-body .albums-list .album-images .btn-remove {
    position: absolute;
    display: none;
    right: 10px;
    width: 16px;
    height: 16px;
    top: 2px;
    opacity: .7;
    padding: 0;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #000;
    background: #000;
    line-height: 12px
}

.album-modal .album-body .albums-list .album-images .btn-remove i {
    color: #e76877;
    font-size: 12px
}

.album-modal .album-body .album-box {
    border: 1px solid transparent!important;
    padding: 0;
    height: 110px!important
}

.album-modal .album-body .album-box .inner-box {
    background: #f7f8fa;
    padding: 10px;
    border: 2px dashed #c4cdd9!important
}

.album-modal .album-body .album-box .inner-box .inner-box-header {
    font-size: 16px;
    color: #354052;
    margin: 0
}

.album-modal .album-body .album-box .inner-box i {
    margin-top: 0!important;
    font-size: 36px;
    color: #1b92ec
}

.album-modal .album-body .album-buttons {
    margin-top: 15px
}

.album-modal .album-body .album-buttons .btn-danger {
    padding: 7px 22px;
    background: #e76877;
}

.album-modal .album-body .album-buttons .btn-default {
    padding: 6px
}

.album-modal .album-body .album-buttons .btn-success {
    padding: 7px 30px
}

.album-modal .album-body .album-buttons .right-buttons a {
    margin-left: 12px
}

.dark-well {
    background: #273142;
    border: 1px solid #e6eaee;
    padding: 11px 11px 11px 77px;
}

.dark-well p {
    color: #fff;
    margin: 0
}

.independent-themes .independent-block {
    background: #fff;
    border: 1px solid #e6eaee;
    margin-bottom: 20px
}

.independent-themes .independent-block .theme-image a img {
    width: 100%;
    height: 160px
}

.independent-themes .independent-block .theme-image .selected {
    display: none;
    text-transform: none;
    position: absolute;
    font-weight: 400;
    right: 36px;
    top: 20px;
    font-size: 12px;
    background: rgba(0, 0, 0, .59);
    border: 1px solid transparent;
    color: #fff;
    padding: 5px 17px
}

.independent-themes .independent-block .theme-sale {
    margin-top: 15px;
    margin-bottom: 5px
}

.independent-themes .independent-block .theme-sale .theme-details {
    margin-left: 10px
}

.independent-themes .independent-block .theme-sale .theme-details .theme-name {
    color: #354052;
    font-size: 14px
}

.independent-themes .independent-block .theme-sale .theme-details .theme-author {
    color: #7f8fa4;
    font-size: 12px
}

.independent-themes .independent-block .theme-sale .theme-rate {
    background: #e8eaf1;
    border: 1px solid #e8eaf1;
    font-size: 14px;
    color: #8d909f;
    height: 36px;
    width: 36px;
    text-align: center;
    line-height: 36px
}

.independent-themes .independent-block.active,
.independent-themes .independent-block:hover {
    background: #222c3c;
    border: 1px solid #e6eaee;
    padding: 10px;
    margin-bottom: 20px
}

.independent-themes .independent-block.active .theme-sale .theme-details .theme-name,
.independent-themes .independent-block:hover .theme-sale .theme-details .theme-name {
    color: #fff
}

.independent-themes .independent-block.active .theme-rate,
.independent-themes .independent-block:hover .theme-rate {
    background: rgba(25, 145, 235, .4);
    border: 1px solid #de6926;
    color: #fff
}

.independent-themes .independent-block.active .selected {
    display: block
}

.login-block.static-pages {
    width: 100%;
    padding-top: 0
}

.panel-body.static-body {
    padding: 0 30px 30px
}

.static-para {
    margin-bottom: 0;
    padding: 0px
}

.contact-panel .contact-login {
    padding: 0 15px;
    margin-top: 0
}

.contact-panel .contact-login .form-group {
    margin: 11px 0
}

.contact-panel .contact-login .form-group .form-control {
    padding: 13px
}

.contact-panel .contact-login .btn-submit {
    margin: 0;
    padding: 8px;
    min-width: 142px;
    outline: none
}

.delete_resposive_btn{
    margin-right: 32px;
}
.res_width_auto{
    min-width:auto !important;
}